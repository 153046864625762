import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import Button from 'components/Button';
import { GRAYS } from 'constants/theme';
import * as S from './PageOverview.styles';
import EditDetails from 'components/ManagePage/EditDetails';

interface Props {
  pageId: number;
  workoutTags: string[];
}

const PageDetails: React.FC<Props> = (props) => {
  const { pageId, workoutTags } = props;
  const [open, setIsOpen] = useState<boolean>(false);

  return (
    <S.Section>
      <EditDetails
        isOpen={open}
        onCancel={() => setIsOpen(false)}
        pageId={pageId}
        workoutTags={workoutTags}
      />
      <Row
        justifyContent="flex-start"
        style={{
          position: 'relative',
          paddingBottom: 16,
        }}
      >
        <Typography variant="h5">Details</Typography>
        <S.EditButton>
          <Button
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => setIsOpen(true)}
          >
            Edit
          </Button>
        </S.EditButton>
      </Row>
      <div
        style={{
          margin: '0 -16px',
          borderBottom: `2px solid ${GRAYS.light}`,
        }}
      />
      <Row
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ paddingTop: 16 }}
      >
        <Column justifyContent="flex-start" alignItems="flex-start">
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 300, paddingBottom: 12 }}
          >
            Workout Tags
          </Typography>
          <Row
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ flexWrap: 'wrap' }}
          >
            {workoutTags.map((val) => (
              <S.Chip key={val} primary>
                <Typography
                  variant="body1"
                  style={{
                    padding: '0 16px',
                    textAlign: 'center',
                    fontWeight: 600,
                    letterSpacing: 0.8,
                    lineHeight: 2,
                  }}
                >
                  {val}
                </Typography>
              </S.Chip>
            ))}
          </Row>
        </Column>
      </Row>
    </S.Section>
  );
};

export default PageDetails;
