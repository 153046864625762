import React, { useRef, useEffect } from 'react';
import AttendeeName from './AttendeeName';

import SendIcon from '@material-ui/icons/Send';
import { isMobile, isTablet } from 'react-device-detect';
import { useState, KeyboardEvent } from 'react';
import { FetchUserProfileResponse } from '@solin-fitness/types';
import Firebase, { ListenerType } from 'services/chat/Config/firebase';
import styled from 'styled-components';
import { Column } from 'components/Column';

const isMobileOrTablet = isMobile || isTablet;

export const ChatOuterContainer = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
`;

export const ChatInnerContainer = styled(Column)`
  margin-top: 10px;
  height: auto !important;
`;

export const TopWrap = styled.div`
  flex: 1;
`;

export const TextBox = styled.div`
  width: 275px;
  height: 227px;
  background: #ffffff;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 60px;
  right: 20px;

  z-index: 99;

  @media (max-width: 1200px) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 67px;
    box-shadow: none;
    border-radius: 0px;
    margin-top: -20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 550px) {
    display: none;
  }
`;

export const TextArea = styled.textarea`
  border: none;
  resize: none;
  outline: none;
  font-family: 'Raleway';
  font-size: 20px;
  line-height: 19px;
  color: #7d7d7d;
  margin: 5px;
  height: 125px;
  z-index: 25;
  white-space: pre-line;

  @media (max-width: 1200px) {
    height: 30px;
    color: #7d7d7d !important;
    width: 100%;
  }
`;

export const MobileButton = styled.div<{ isActive: boolean }>`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background: ${({ isActive, theme }) => (isActive ? '#548dbf' : '#548DBF')};
  position: absolute;
  bottom: 7px;
  right: 7px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;

  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const Name = styled.p`
  font-size: 15;
  text-align: right;
  color: #a2a2a2;

  @media (max-width: 1025px) {
    color: black;
    font-weight: 500;
  }
`;

export const ChatBubble = styled.div`
  color: white;
  background: ${({ theme }) => theme.main.primary};
  padding: 4px 12px;
  border-radius: 13px;
  margin-bottom: 7px;
  font-size: 12px;

  @media (max-width: 1025px) {
    padding: 0;
    margin-top: 4px;
    background: transparent;
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const Chat = styled.p`
  max-width: 200px;
  font-size: 12px;
  line-height: 1.4;
  text-align: right;

  @media (max-width: 1025px) {
    text-align: left;
    font-size: 15px;
    color: black;
    max-width: 100%;
    paddgin-right: 20px;
  }
`;

export const ChatWrap = styled.div`
  overflow: scroll;
  margin-top: 50px;
  padding: 30px;

  @media (max-width: 1025px) {
    margin-top: -10px;
    padding: 25px;
    padding-bottom: 80px;
  }
`;

interface Message {
  type: 'message';
  content?: string;
  timestamp: number;
  creator: {
    id: number;
    name: string;
    avataar: string;
  };
}

interface Props {
  messages: Message[];
  userData: FetchUserProfileResponse;
  currentChannelId: string;
}

const messageRef = Firebase.database().ref('messages');

const LiveChatMobile: React.FC<Props> = ({
  messages,
  userData,
  currentChannelId,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [chatText, setChatText] = useState('');

  const modifiedText = chatText.replace(/(?:\r\n|\r|\n)/g, '');

  const currentUser = userData;

  let messageListener: ListenerType | null = null;

  const addMessageListner = () => {
    messageListener = messageRef
      .child(currentChannelId)
      .on('child_added', (snap: any) => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
  };

  useEffect(() => {
    addMessageListner();

    return () => {
      messageRef.off('child_added', messageListener as ListenerType);
    };
  }, []);

  const handleChangeText = (e: any) => {
    setChatText(e.target.value);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && modifiedText !== '') {
      handleSend();
      setChatText('');
    }
  };

  const handleSend = () => {
    if (modifiedText.length === 0) {
      return;
    }

    messageRef
      .child(currentChannelId)
      .push()
      .set({
        type: 'message',
        content: chatText,
        timestamp: Firebase.database.ServerValue.TIMESTAMP,
        creator: {
          id: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          avataar: currentUser.profilePictureUrlPublicId,
        },
      });

    setChatText('');
  };

  return (
    <ChatOuterContainer>
      <ChatWrap>
        <ChatInnerContainer justifyContent="flex-end" alignItems="flex-start">
          {messages.map((val) => {
            const name = val.creator.name;
            return (
              <React.Fragment key={`${val.timestamp}${val.creator.id}`}>
                {val.creator.id === currentUser.id ? (
                  <Name>{name}</Name>
                ) : (
                  <AttendeeName userId={val.creator.id} name={name} />
                )}

                <ChatBubble>
                  <Chat>{val.content}</Chat>
                </ChatBubble>
              </React.Fragment>
            );
          })}
          <div ref={messagesEndRef} />
        </ChatInnerContainer>
      </ChatWrap>
      <TextBox>
        <TopWrap>
          <TextArea
            placeholder="start typing"
            value={chatText}
            onKeyPress={(e) => onKeyPress(e)}
            onChange={handleChangeText}
            style={{
              color: modifiedText.length > 0 ? '#F25252' : '#7d7d7d',
            }}
          />
          <MobileButton isActive={modifiedText.length > 0} onClick={handleSend}>
            <SendIcon style={{ fontSize: 29, color: 'white' }} />
          </MobileButton>
        </TopWrap>
      </TextBox>
    </ChatOuterContainer>
  );
};

export default LiveChatMobile;
