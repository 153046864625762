import styled from 'styled-components';
import { PRIMARY } from 'constants/theme';
import { Column } from 'components/Column';
import { isWindows, isMobile } from 'react-device-detect';

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  position: relative;
`;

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  padding: 40px;
  padding-right: 0px;
  padding-bottom: 0;
`;

export const MainVideo = styled.div`
  width: 100%;
  position: relative;
  filter: drop-shadow(0px 14px 18px rgba(0, 0, 0, 0.45));
  padding-right: ${isWindows ? '160px' : '120px'};
`;

export const SideColumn = styled(Column)`
  width: auto;
  height: 100vh;
  padding: 40px 15px 0px;
  line-height: 35px;
  align-items: flex-end;
`;

export const LeaveButton = styled.button`
  outline: none;
  border: none;
  background: ${({ theme }) => theme.main.lightSecondary};
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  max-height: 40px;
  min-height: 40px;
  margin-top: 15px;
  cursor: pointer;
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.main.secondary};
  margin-right: 10px;
  border-radius: 50%;
`;

export const LeaveButtonText = styled.div`
  font-family: Raleway;
  font-size: 15px;
  line-height: 19px;
  display: flex;
`;

export const Tags = styled.div`
  display: flex;
  margin-top: 0px;
`;

export const Tag = styled.div`
  padding: 7px 16px;
  background: #fdacac;
  border-radius: 22px;
  margin-right: 15px;
  color: white;
`;

export const TimerWrap = styled.div`
  position: absolute;
  bottom: 20px;
  left: 40px;
  background: rgba(84, 141, 191, 0.6);
  display: flex;
  padding: 0px 12px;
  border-radius: 30px;
  align-items: center;
  padding-right: 20px;
  color: white;
  font-family: Raleway;
`;

export const PeopleCount = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #548dbf;
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const AttendeesButtonDesktop = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.main.primary};
  transition: 200ms;
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const PeopleWrap = styled.div`
  margin-left: 50px;
  display: flex;

  img,
  div {
    margin-left: -10px;
  }
`;
