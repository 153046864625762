import styled from 'styled-components';
import { PRIMARY } from 'constants/theme';
import { ILiveWorkout } from 'types';
import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
  IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng';
import { useRef, useEffect, useState } from 'react';
import Timer from 'components/workoutTimer/timer';
import LiveChatMobile from './LiveChatMobile';
import { FetchUserProfileResponse } from '@solin-fitness/types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LoopIcon from '@material-ui/icons/Loop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import LiveFire from 'components/LiveWorkoutRoom/LiveFire';
import { isIOS } from 'react-device-detect';
import { useWindowSize } from 'util/helpers';
import Firebase from 'services/chat/Config/firebase';
import { isMobile, isTablet } from 'react-device-detect';
import Attendees from './Attendees';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MessageIcon from '@material-ui/icons/Message';

const isMobileOrTablet = isTablet || isMobile;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  background: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const PlayerWrap = styled.div<{ width: number; height: number }>`
  flex: 1;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 20px;
  filter: drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.45));
  height: ${({ width }) => `${(width - 100) * 1.78}px`};

  @media (min-width: 550px) {
    ${({ height, width }) => {
      if (isTablet) {
        return `
                height: calc(100vh - 200px);
                min-width: ${`${width - 280}px`};
                width: ${`${width - 280}px`};
            `;
      } else {
        return `
                height: calc(100vh - 120px);
                min-width: ${`${(height - 140) * 1.78}px`};
                width: ${`${(height - 140) * 1.78}px`};
                margin-top: 40px;
            `;
      }
    }}
    flex: inherit;
  }
`;

export const MainPlayer = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;

  .agora_video_player {
    border-radius: 20px;
  }
  div[class~='agora-video-player-track'] {
    border-radius: 20px !important;
  }
  & > * {
    border-radius: 20px;
  }
`;

export const ControlsWrap = styled.div<{ width: number; height: number }>`
  padding: 20px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: ${({ width }) => `${(width - 100) * 1.78}px`};
  margin-top: 20px;

  @media (min-width: 550px) {
    height: 40px;
    flex-direction: row;
    background: white;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex-flow: row-reverse;
    padding: 12px;
    margin-top: 40px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);

    ${({ width, height }) => {
      if (isTablet) {
        return `
                min-width: 230px;
            `;
      } else {
        return `
                min-width: ${`${width - 70 - (height - 150) * 1.78}px`};
            `;
      }
    }}
  }
`;

export const EndButton = styled.div`
  color: ${({ theme }) => theme.main.secondary};
  font-size: 18px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UsersWatching = styled.div`
  font-size: 16px;
  display: flex;
  color: #34393c;
  flex-direction: column;
  align-items: center;

  @media (min-width: 550px) {
    flex-direction: row;
    width: 45px;
    justify-content: space-between;
    margin-left: -3px;
    margin-right: 5px;

    svg {
      margin: 0 !important;
    }
  }
`;

export const ChatOpenWrap = styled.div`
  height: 55px;
  background: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  font-weight: 600;
  font-family: Raleway;

  @media (min-width: 550px) {
    display: none;
  }
`;

export const AttendeesButton = styled.div`
  height: 55px;
  background: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  font-weight: 600;
  font-family: Raleway;

  @media (min-width: 550px) {
    display: none;
  }
`;

export const CloseIconWrap = styled.div`
  color: #34393c;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const OpenedChatWrap = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100vw;
  height: calc(100vh - 110px);
  background: white;
  overflow: hidden;
  padding: 5px;
  padding-top: 60px;
`;

export const ChatTitle = styled.div`
  position: absolute;
  left: 30px;
  top: 25px;
  color: #34393c;
  font-size: 18px;
  font-weight: 500;
`;

export const HorizontalChat = styled.div`
  display: none;
  height: calc(95vh - 100px);

  @media (min-width: 550px) {
    display: block;
    overflow: scroll;
  }
`;

export const FireWrap = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
`;

export const AttendeesWrapMobileVertical = styled.div<{ width: number }>`
  position: absolute;
  top: 20px;
  left: 17px;
  z-index: 99;
  width: calc(100vw - 30px);
  height: ${({ width }) => `${(width - 100) * 1.78}px`};

  border-radius: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.95);
  filter: drop-shadow(0px 14px 18px rgba(0, 0, 0, 0.45));

  @media (min-width: 550px) {
    display: none;
  }
`;

export const RowAttendees = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AttendeesWrapHorizontal = styled.div`
  display: none;
  height: calc(95vh - 100px);
  padding-left: 30px;
  padding-top: 10px;

  @media (min-width: 550px) {
    display: block;
    overflow: scroll;
  }
`;

export const AttendeesWrapMobileHorizontalTitle = styled.p`
  font-family: Raleway;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #34393c;
  margin-bottom: 20px;
`;

interface Message {
  type: 'message';
  content?: string;
  timestamp: number;
  creator: {
    id: number;
    name: string;
    avataar: string;
  };
}

export interface MediaDeviceInfo {
  readonly deviceId: string;
  readonly groupId: string;
  readonly kind: MediaDeviceKind;
  readonly label: string;
  toJSON(): any;
}

interface Props {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  meetingId: string;
  uid: string | number;
  usersWatching: IAgoraRTCRemoteUser[];
  messages: Message[];
  userData: FetchUserProfileResponse;
  currentChannelId: string;
  handleLeave: () => void;
  isLeaving: boolean;
  client: IAgoraRTCClient;
}

const LiveRoomMobile = (props: Props) => {
  const [showChat, setShowChat] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeCamera, setActiveCamera] = useState('back');
  const [showAttendees, setShowAttendees] = useState(false);
  const [viewedMessages, setViewedMessages] = useState(props.messages.length);
  const container = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (!container.current) {
      return;
    }
    props.videoTrack?.play(container.current, { mirror: false });

    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);

  useEffect(() => {
    const getDevices = async () => {
      const devicesRes = await AgoraRTC.getDevices();
      setDevices(devicesRes);
    };
    getDevices();
  }, []);

  const handleSwitchCam = () => {
    const cameras = devices.filter((item) => item.kind === 'videoinput');

    const camera = cameras.filter(
      (item) =>
        item.label.includes(activeCamera === 'back' ? 'front' : 'back') ||
        item.label.includes(activeCamera === 'back' ? 'Front' : 'Back'),
    );

    setActiveCamera(activeCamera === 'back' ? 'front' : 'back');

    if (props.videoTrack) {
      // @ts-ignore
      props.videoTrack.setDevice(camera[0].deviceId).then(() => {
        props.client.publish([props.videoTrack as any]);
      });
    }
  };

  const unreadMessages = props.messages.length - viewedMessages;

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <PlayerWrap height={height} width={width}>
          <MainPlayer ref={container} className="video-player">
            <FireWrap>
              <LiveFire meetingId={props.meetingId} />
            </FireWrap>
          </MainPlayer>
        </PlayerWrap>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ControlsWrap height={height} width={width}>
            <EndButton onClick={props.handleLeave}>
              {props.isLeaving ? (
                <CircularProgress color="secondary" size={15} />
              ) : (
                'End'
              )}
            </EndButton>

            <Timer meetingId={props.meetingId} iconColor="#34393C" />
            <LoopIcon style={{ color: '#34393C' }} onClick={handleSwitchCam} />
            {width > 550 && (
              <>
                <UsersWatching onClick={() => setShowAttendees(true)}>
                  {props.usersWatching.length}
                  <VisibilityIcon
                    style={{
                      color: showAttendees ? '#548DBF' : '34393C',
                      marginTop: 5,
                    }}
                  />
                </UsersWatching>
                <MessageIcon
                  onClick={() => setShowAttendees(false)}
                  style={{
                    fontSize: 22,
                    marginTop: 3,
                    color: showAttendees ? '34393C' : '#548DBF',
                  }}
                />
              </>
            )}
          </ControlsWrap>
          {showAttendees ? (
            <AttendeesWrapHorizontal>
              <Attendees usersWatching={props.usersWatching} />
            </AttendeesWrapHorizontal>
          ) : (
            <HorizontalChat>
              <LiveChatMobile
                currentChannelId={props.currentChannelId}
                userData={props.userData}
                messages={props.messages}
              />
            </HorizontalChat>
          )}
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <ChatOpenWrap
          onClick={() => {
            setShowChat(true);
            setViewedMessages(props.messages.length);
          }}
        >
          <div>Chat {unreadMessages > 0 ? `(${unreadMessages})` : null}</div>
          <KeyboardArrowUpIcon style={{ color: '#34393C' }} />
        </ChatOpenWrap>
        <AttendeesButton
          style={{ marginLeft: 20 }}
          onClick={() => {
            setShowAttendees(true);
          }}
        >
          <div>Attendees</div>
          <KeyboardArrowUpIcon style={{ color: '#34393C' }} />
        </AttendeesButton>
      </div>

      {showAttendees && (
        <AttendeesWrapMobileVertical width={width}>
          <RowAttendees>
            <AttendeesWrapMobileHorizontalTitle>
              Attendees
            </AttendeesWrapMobileHorizontalTitle>
            <KeyboardArrowDownIcon
              style={{ marginTop: -15, color: '#34393C' }}
              onClick={() => setShowAttendees(!showAttendees)}
            />
          </RowAttendees>
          <Attendees usersWatching={props.usersWatching} />
        </AttendeesWrapMobileVertical>
      )}

      {showChat && (
        <OpenedChatWrap
          style={{
            height: isIOS ? 'calc(100vh - 110px)' : 'calc(100vh - 60px)',
          }}
        >
          <ChatTitle>Chat</ChatTitle>
          <CloseIconWrap onClick={() => setShowChat(false)}>
            <CloseIcon style={{ color: '#34393C' }} />
          </CloseIconWrap>
          <LiveChatMobile
            currentChannelId={props.currentChannelId}
            userData={props.userData}
            messages={props.messages}
          />
        </OpenedChatWrap>
      )}
    </Container>
  );
};

export default LiveRoomMobile;
