import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Layout from 'components/Layout';
import EventsManagement from 'components/Events/EventsManagement';
import EventForm from 'components/Events/EventForm';
import { useLocation } from 'react-router-dom';
import { EventResponse } from '@solin-fitness/types';
import EventRoomPage from './EventRoomPage';
import Event from './Event';

interface EventsLocationState {
  event: EventResponse;
}

const Events = () => {
  const { path } = useRouteMatch();
  const location = useLocation<EventsLocationState>();
  const event = location.state?.event;

  return (
    <Switch>
      <Route exact path={path}>
        <Layout title="Events">
          <EventsManagement />
        </Layout>
      </Route>
      <Route path={`${path}/create`}>
        <Layout title="Events">
          <EventForm />
        </Layout>
      </Route>
      <Route path={`${path}/:eventId/room`}>
        <EventRoomPage />
      </Route>
      <Route path={`${path}/:eventId/edit`}>
        <Layout title="Events">
          <EventForm event={event} />
        </Layout>
      </Route>
      <Route path={`${path}/:eventId`}>
        <Layout title="Events">
          <Event event={event} />
        </Layout>
      </Route>
    </Switch>
  );
};

export default Events;
