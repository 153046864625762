import styled from 'styled-components';

export const ButtonContainer = styled.div`
  padding-top: 36px;
  display: flex;
  justify-content: center;
  gap: 32px;
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  background-color: var(--bg, #fff);
  height: 60px;
  border-radius: 40px;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: filter 250ms;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonText = styled.p`
  color: var(--color, #000);
  font-size: 24px;
  font-family: 'Raleway';
  text-align: center;
  margin: auto;
`;

export const Label = styled.h4`
  font-family: 'Raleway';
  font-size: 18px;
  color: ${(p) => p.theme.text.primary};
  padding: 24px 12px 12px;
  font-weight: 300;
`;

export const Header = styled.h1`
  font-family: 'Raleway';
  font-size: 24px;
  color: ${(p) => p.theme.text.primary};
  font-weight: 400;
  padding-bottom: 18px;
`;

export const FormElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HelperText = styled.p`
  font-size: 14px;
  font-family: 'Raleway';
  color: ${(p) => p.theme.main.secondary};
  padding: 4px 14px;
`;
