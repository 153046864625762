import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/FormTextField';
import Button from 'components/Button';
import * as yup from 'yup';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import { Column } from 'components/Column';
import { uploadImage } from 'queries/file-uploads';
import { GRAYS } from 'constants/theme';
import CloseIcon from '@material-ui/icons/Close';
import { equals } from 'ramda';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { editPageMetadata } from 'queries/page';
import { EditConfigMetadata } from '@solin-fitness/types';
import CloudinaryImage from 'components/CloudinaryImage';
import UploadImage from 'components/UploadImageModal';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  pageId: number;
  pageLogoPublicId: string;
  lightLogoPublicId: string;
  pageName: string;
  pageDescription: string;
  pageBio: string;
}

export const DescriptionLength = styled.div`
  width: 100%;
  font-size: 0.8rem;
  text-align: right;
`;

const validationSchema = yup.object({
  name: yup.string().required().min(1),
  description: yup.string().required().min(1),
  bio: yup.string().required().min(1),
  pageLogoPublicId: yup.string().required(),
  lightLogoPublicId: yup.string().required(),
});

const EditMetadata: React.FC<Props> = (props) => {
  const {
    isOpen,
    onCancel,
    pageId,
    pageLogoPublicId,
    lightLogoPublicId,
    pageName,
    pageDescription,
    pageBio,
  } = props;
  const queryClient = useQueryClient();
  const hiddenLogoFileInput = useRef(null);
  const hiddenLightLogoFileInput = useRef(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const initialValues = useMemo(
    () => ({
      pageLogoPublicId,
      lightLogoPublicId,
      name: pageName,
      description: pageDescription,
      bio: pageBio,
    }),
    [pageLogoPublicId, lightLogoPublicId, pageName, pageDescription, pageBio],
  );

  const handleImage = useMutation(
    (fileToUpload: File) => uploadImage(fileToUpload),
    {
      onMutate: () => setIsUploading(true),
      onSuccess: () => {
        setIsUploading(false);
      },
      onError: (err: any) => {
        setIsUploading(false);
        setError(err?.message || 'Something went wrong 😔');
      },
    },
  );

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal top={10} width={600} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Edit Overview
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await editMetadata.mutateAsync({
            id: pageId,
            data: {
              pageLogoPublicId: data.pageLogoPublicId,
              lightLogoPublicId: data.lightLogoPublicId,
              pageDescription: data.description,
              bio: data.bio,
              pageName: data.name,
            },
          });

          setSubmitting(false);
          setError('');
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form style={{ marginBottom: 0, display: 'flex', flexWrap: 'wrap' }}>
            <Row
              justifyContent="space-around"
              alignItems="flex-start"
              style={{ paddingBottom: 36, gap: '12px' }}
            >
              <Column justifyContent="flex-start" style={{ width: '60%' }}>
                <FormTextField
                  label="Name"
                  name="name"
                  type="input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormTextField
                  label="Description"
                  name="description"
                  type="input"
                  multiline
                  rows={3}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <DescriptionLength>
                  {values.description.split('').length} / 50
                </DescriptionLength>

                <FormTextField
                  label="Bio"
                  name="bio"
                  type="input"
                  multiline
                  rows={5}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Column>
              <Column alignItems="flex-start">
                <Typography
                  variant="body1"
                  style={{ paddingLeft: 4, paddingBottom: 4 }}
                >
                  Logo
                </Typography>

                <UploadImage
                  fileName="pageLogo"
                  doNotUse16to9AspectRatio={true}
                  onFinished={(publicId) =>
                    setFieldValue('pageLogoPublicId', publicId)
                  }
                  buttonToRender={
                    <HoveredRow>
                      {isUploading ? (
                        <CircularProgress style={{ color: GRAYS.medium }} />
                      ) : (
                        values.pageLogoPublicId && (
                          <>
                            <Cancel onClick={() => console.log('hello')}>
                              <CloseIcon fontSize="small" />
                            </Cancel>
                            <Logo>
                              <CloudinaryImage
                                publicId={values.pageLogoPublicId}
                                width={128}
                                height={128}
                                alt="page logo"
                                fetchFormat={'auto'}
                                quality={'auto:best'}
                                crop={'pad'}
                              />
                            </Logo>
                          </>
                        )
                      )}
                    </HoveredRow>
                  }
                />

                <div style={{ marginTop: '10px' }}>
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: 4, paddingBottom: 4 }}
                  >
                    Light Logo
                  </Typography>
                  <UploadImage
                    fileName="lightLogo"
                    doNotUse16to9AspectRatio={true}
                    buttonToRender={
                      <HoveredRow>
                        {isUploading ? (
                          <CircularProgress style={{ color: GRAYS.medium }} />
                        ) : (
                          values.lightLogoPublicId && (
                            <>
                              <Cancel onClick={() => console.log('hello')}>
                                <CloseIcon fontSize="small" />
                              </Cancel>
                              <Logo>
                                <CloudinaryImage
                                  publicId={values.lightLogoPublicId}
                                  width={128}
                                  height={128}
                                  alt="light logo"
                                  fetchFormat={'auto'}
                                  quality={'auto:best'}
                                  crop={'pad'}
                                />
                              </Logo>
                            </>
                          )
                        )}
                      </HoveredRow>
                    }
                    onFinished={(publicId) =>
                      setFieldValue('lightLogoPublicId', publicId)
                    }
                  />
                </div>
              </Column>
            </Row>
            {equals(initialValues, values) ? (
              // if values unchanged, gray disabled save button
              <Button
                fullWidth
                disabled={true}
                variant="contained"
                size="large"
                color="secondary"
                type="submit"
                style={{
                  borderRadius: 35,
                }}
              >
                <Typography variant="h6">Save</Typography>
              </Button>
            ) : (
              <Button
                fullWidth
                disabled={isSubmitting}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{
                  borderRadius: 35,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="inherit" size="2rem" />
                ) : (
                  <Typography variant="h6">Save</Typography>
                )}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditMetadata;

const Cancel = styled(Row)`
  isolation: isolate;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.grays.medium};
  background-color: ${({ theme }) => theme.grays.light};
  opacity: 0.95;
  top: -15px;
  right: -15px;
`;

const HoveredRow = styled(Row)`
  width: 128px;
  height: 128px;
  border: 2px dashed;
  border-radius: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.grays.veryLight};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Logo = styled.div`
  img {
    margin-bottom: 0;
    padding: 2px;
    height: 100%;
    object-fit: contain;
  }
`;
