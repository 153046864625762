import { useEvents } from 'queries/events';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { Typography } from '@material-ui/core';
import EventCard from 'components/Events/EventCard';
import { EventResponse } from '@solin-fitness/types';
import { sort } from 'ramda';
import { useHistory } from 'react-router-dom';
import { TABLET } from 'constants/measurements';

const Container = styled.section`
  padding: 24px;
`;

const Header = styled.div`
  position: relative;
`;

const NewEventButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  @media (max-width: ${TABLET}) {
    position: relative;
  }
`;

const EventRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const EventCardWrapper = styled.div`
  padding: 24px 0;
  padding-right: 48px;
`;

const EventSection = styled.section`
  padding: 12px 0px 24px;
`;

const sortEvents = (events: EventResponse[]) =>
  sort((a: EventResponse, b: EventResponse) => {
    const aDate = new Date(a.startDate).getTime();
    const bDate = new Date(b.startDate).getTime();
    return aDate - bDate;
  }, events);

const EventsManagement: React.FC = () => {
  const history = useHistory();
  const { isLoading, data: events } = useEvents();

  const pastEvents: EventResponse[] = [];
  const upcomingEvents: EventResponse[] = [];
  const currentDate = new Date();

  events?.forEach((event) => {
    const eventDate = new Date(event.startDate);
    if (eventDate > currentDate) {
      upcomingEvents.push(event);
    } else {
      pastEvents.push(event);
    }
  });

  const sortedUpcomingEvents = sortEvents(upcomingEvents);
  const sortedPastEvents = sortEvents(pastEvents);

  return (
    <Container>
      <Header>
        <NewEventButtonContainer>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={
              () => history.push('events/create') // route change
            }
          >
            Create New Event
          </Button>
        </NewEventButtonContainer>
      </Header>
      <EventSection>
        <Typography variant="h5">Upcoming Events</Typography>
        {!!sortedUpcomingEvents.length ? (
          <EventRow>
            {sortedUpcomingEvents?.map((event, index) => (
              <EventCardWrapper key={`upcoming-${index}`}>
                <EventCard event={event} />
              </EventCardWrapper>
            ))}
          </EventRow>
        ) : (
          <Typography variant="body1">No upcoming events scheduled</Typography>
        )}
      </EventSection>
      {!!sortedPastEvents.length ? (
        <EventSection>
          <Typography variant="h5">Past Events</Typography>
          <EventRow>
            {sortedPastEvents?.map((event, index) => (
              <EventCardWrapper key={`past-${index}`}>
                <EventCard event={event} isPastEvent />
              </EventCardWrapper>
            ))}
          </EventRow>
        </EventSection>
      ) : null}
    </Container>
  );
};

export default EventsManagement;
