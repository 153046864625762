import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  CreateWorkoutRequest,
  UploadWorkoutFinishRequest,
} from '@solin-fitness/types';
import Layout from 'components/Layout';
import UploadWorkoutDetails from 'components/UploadWorkout/UploadWorkoutDetails';
import UploadVideoFile from 'components/UploadWorkout/UploadVideoFile';
import { api, apiWithFormData, Methods } from 'services/api';
import { MANAGE_WORKOUTS } from 'constants/routes';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { useHistory } from 'react-router-dom';
import { Page } from 'types';
import { getPage } from 'queries/page';
import { CircularProgress } from '@material-ui/core';

export interface UploadInfo {
  formData: FormData;
  fileId: string | undefined;
  uploadWorkoutId: number;
}

const UploadWorkout = () => {
  const [workoutId, setWorkoutId] = useState<number | undefined>(undefined);
  const [videoUploadFinished, setVideoUploadFinished] =
    useState<boolean>(false);
  const [skippedToLink, setSkipToLink] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [progress, setProgress] = useState<number>(0);
  const [uploadFinished, setUploadFinished] = useState<boolean>(false);
  const [isChunkUploadStarted, setIsChunkUploadStarted] =
    useState<boolean>(false);
  const { isLoading: isLoadingConfig, data: pageQuery } = useQuery<Page, Error>(
    'creatorPage',
    () => getPage(),
  );
  const queryClient = useQueryClient();
  const history = useHistory();

  // Initialize workout for chunk uploads
  const initializeWorkout = useMutation(
    async () =>
      await api<object & { id: number }>(Methods.post, '/workouts/init'),
    {
      onSuccess: (data) => setWorkoutId(data.id),
    },
  );

  // Finish workout for chunk uploads
  const finishWorkoutUpload = useMutation(
    async (request: UploadWorkoutFinishRequest) =>
      await api(Methods.patch, `/workouts/finish/${workoutId}`, request),
    {
      onError: (error: any) => {
        console.log(error);
        setUploadError(
          error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        );
      },
      onSuccess: () => {
        history.push(MANAGE_WORKOUTS);
        queryClient.invalidateQueries('workouts');
      },
    },
  );

  // Create workouts -> used skip to link
  const createWorkoutWithVideoUrl = useMutation(
    async (request: CreateWorkoutRequest) =>
      await api(Methods.post, '/workouts', request),
    {
      onError: (error: any) => {
        console.log(error);
        setUploadError(
          error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        );
      },
      onSuccess: () => {
        history.push(MANAGE_WORKOUTS);
        queryClient.invalidateQueries('workouts');
      },
    },
  );

  const onUploadProgress = (event: ProgressEvent) => {
    const { loaded, total } = event;
    const value = Math.round((loaded / total) * 100);
    setProgress(value);
  };

  // chunk upload mutation
  const videoUpload = useMutation(
    async (uploadInfo: UploadInfo) =>
      await apiWithFormData<{ videoUrl: string }>(
        '/upload/multiple',
        uploadInfo.formData,
        {
          onUploadProgress,
          xUploadIndex: uploadInfo.fileId,
          xWorkoutId: uploadInfo.uploadWorkoutId,
        },
      ),
    {
      onError: (error: any) => {
        console.log(error);

        setUploadFinished(false);
        setUploadError(
          error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        );
      },
      onSuccess: () => {
        console.log('success');
        setUploadFinished(true);
        setVideoUploadFinished(true);
        setProgress(0);
      },
    },
  );

  if (isLoadingConfig || !pageQuery) {
    return (
      <Layout title="Schedule workout">
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <CircularProgress />
        </div>
      </Layout>
    );
  }

  const workoutTags = pageQuery.config.workoutTags;

  return (
    <Layout title="Upload Workout">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!!uploadError && <Aside isError={true}>{uploadError}</Aside>}
        <UploadVideoFile
          initialize={initializeWorkout}
          handleVideoUpload={(uploadInfo: UploadInfo) =>
            videoUpload.mutateAsync(uploadInfo) as Promise<{ videoUrl: string }>
          }
          uploadFinished={uploadFinished}
          progress={progress}
          handleSkipToLink={(showDetailsForm: boolean) =>
            setSkipToLink(showDetailsForm)
          }
          handleIsChunkUpload={(isUpload: boolean) =>
            setIsChunkUploadStarted(isUpload)
          }
          skippedToLink={skippedToLink}
        />
        {skippedToLink && (
          <UploadWorkoutDetails
            workoutTags={workoutTags}
            workoutId={null}
            skippedToLink={skippedToLink}
            isVideoUploaded={videoUploadFinished}
            createWorkout={async (request) => {
              await createWorkoutWithVideoUrl.mutateAsync(
                request as CreateWorkoutRequest,
              );
            }}
          />
        )}
        {isChunkUploadStarted && (
          <UploadWorkoutDetails
            workoutTags={workoutTags}
            workoutId={workoutId as number}
            skippedToLink={skippedToLink}
            isVideoUploaded={videoUploadFinished}
            createWorkout={async (request) => {
              await finishWorkoutUpload.mutateAsync(
                request as UploadWorkoutFinishRequest,
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export default UploadWorkout;
