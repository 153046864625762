import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Column } from 'components/Column';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../dragTypes';
import ScheduleDropTile from './ScheduleDropTile';
import { WorkoutResponse } from '@solin-fitness/types';
import { MinCalendar } from '../CalendarCarousel';
import * as S from './CalendarTile.styles';
import * as I from 'components/shared/Icons';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import { SECONDARY } from 'constants/theme';
import WorkoutCalendarTile from './WorkoutCalendarTile';
import LiveWorkoutCalendarTile from './LiveWorkoutCalendarTile';

const getDayLabel = (d: Date): string => {
  switch (d.getDay()) {
    case 0:
      return 'sun';
    case 1:
      return 'mon';
    case 2:
      return 'tue';
    case 3:
      return 'wed';
    case 4:
      return 'thu';
    case 5:
      return 'fri';
    case 6:
      return 'sat';
    default:
      throw Error('Date does not have day');
  }
};

interface Props {
  date: Date;
  calendar: MinCalendar[];
}

const CalendarTile: React.FC<Props> = (props) => {
  const { date, calendar } = props;
  const {
    isScheduling: isSchedulingOverall,
    handleScheduling,
    handleWorkoutSelected,
    handleSelectedDate,
  } = useWorkoutDrop();
  const [isScheduling, setIsScheduling] = useState<boolean>(false);

  const currentDate = useMemo<Date>(() => new Date(), [date]);

  useEffect(() => {
    if (!isSchedulingOverall) {
      setIsScheduling(false);
    }
  }, [isSchedulingOverall]);

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.WORKOUT,
      canDrop: () => isCurrentDate() || currentDate.getTime() < date.getTime(),
      drop: (item: WorkoutResponse) => {
        handleSelectedDate(date);
        handleWorkoutSelected(item.id);
        handleScheduling(true);
        setIsScheduling(true);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [calendar, date, currentDate],
  );

  const isCurrentDate = useCallback(() => {
    const curr = new Date();
    return (
      curr.getMonth() === date.getMonth() && curr.getDate() === date.getDate()
    );
  }, [date]);

  /**
   * Case's
   * 1. Empty day
   * 2. Workout on day
   * 3. 2 Workout's on day
   * 4. Schedule on empty day
   * 5. Schedule on workout day
   */

  return (
    <S.DayContainer ref={drop} isOver={isOver && canDrop}>
      <S.CurrentDay isCurrentDate={isCurrentDate()} />
      <S.DayTitle inPast={currentDate.getTime() - 86400000 < date.getTime()}>
        {getDayLabel(date)}
      </S.DayTitle>
      <Column style={{ width: '100%', height: 200, padding: '12px 0px 8px' }}>
        {calendar.length
          ? calendar.map((val) => (
              <React.Fragment key={`${val.type}.${val.id}`}>
                {val.type === 'workoutDrop' ? (
                  <WorkoutCalendarTile
                    val={val}
                    canRemove={currentDate.getTime() < date.getTime()}
                  />
                ) : (
                  <LiveWorkoutCalendarTile val={val} />
                )}
              </React.Fragment>
            ))
          : !isScheduling && <S.EmptyDay isOver={isOver && canDrop} />}
        {isScheduling && (
          <ScheduleDropTile
            onCancel={() => {
              setIsScheduling(false);
              handleScheduling(false);
            }}
          />
        )}
      </Column>
    </S.DayContainer>
  );
};

export default CalendarTile;
