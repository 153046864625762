import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { SECONDARY, PRIMARY } from 'constants/theme';

const useStyles = makeStyles({
  root: {
    borderRadius: 35,
    border: 'none',
    textTransform: 'capitalize',
    fontFamily: 'Raleway',
    height: 74,
    padding: '28px 0',
    fontSize: 25,
  },
});

const ButtonComponent = (props: ButtonProps) => {
  const classes = useStyles();
  const buttonSignUpStyle = {
    boxShadow:
      props.variant !== 'text' ? '0px 24px 54px rgba(0, 0, 0, 0.35' : 'initial',
    fontWeight: props.variant !== 'text' ? 500 : 600,
    backgroundColor: props.variant !== 'text' ? SECONDARY : 'initial',
  };

  return (
    <Button
      className={classes.root}
      {...props}
      variant={props.variant}
      // @ts-ignore
      style={{ ...buttonSignUpStyle, ...props.style }}
    />
  );
};

export default ButtonComponent;
