import React, { Fragment, useEffect, useState, useRef } from 'react';
import MessageForm from './MessageForm/MessageForm';
import { Segment, Comment } from 'semantic-ui-react';
import { MyFirebase } from '../../Config';
import InnerMessage from './InnerMessage/InnerMessage';
import { connect } from 'react-redux';
import { setUserPosts } from '../../Redux-store/Redux-actions';
import { IMessage } from '../../Global-interfaces';
import { isMobile } from 'react-device-detect';

interface IProps {
  currentChannel: any;
  currentUser: any;
  isPrivateChannel: boolean;
  setUserPostsFunc: any;
}

const MessagePanel: React.FC<IProps> = ({
  currentChannel,
  currentUser,
  isPrivateChannel,
  setUserPostsFunc,
}) => {
  const [state, setState] = useState({
    privateChannel: isPrivateChannel,
    messageRef: MyFirebase.database().ref('messages'),
    privateMessagesRef: MyFirebase.database().ref('privateMessages'),
    usersRef: MyFirebase.database().ref('users'),
    messages: [],
    messagesLoading: true,
    numOfUniqueUsers: '',
    searchTerm: '',
    searchLoading: false,
    searchResults: [],
    isChannelStarred: false,
    typingRef: MyFirebase.database().ref('typing'),
    typingUsers: [],
    connectedRef: MyFirebase.database().ref('.info/connected'),
  });

  const messageEndPositionRef: any = useRef(null);

  useEffect(() => {
    if (messageEndPositionRef) {
      messageEndPositionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [state]);

  useEffect(
    () => {
      const addEventListner = (channelId: string) => {
        addMessageListner(channelId);
        addTypingEventListener(channelId);
      };

      const addTypingEventListener = (channelId: string) => {
        let typingUsers: any[] = [];
        state.typingRef.child(channelId).on('child_added', snap => {
          if (snap.key !== currentUser.uid) {
            typingUsers = typingUsers.concat({
              id: snap.key,
              name: snap.val(),
            });
            setState((prevState: any) => ({ ...prevState, typingUsers }));
          }
        });

        state.typingRef.child(channelId).on('child_removed', snap => {
          const index = typingUsers.findIndex(user => user.id === snap.key);
          if (index !== -1) {
            typingUsers = typingUsers.filter(user => user.id !== snap.key);
            setState((prevState: any) => ({ ...prevState, typingUsers }));
          }
        });

        state.connectedRef.on('value', snap => {
          if (snap.val() === true) {
            state.typingRef
              .child(channelId)
              .child(currentUser.uid)
              .onDisconnect()
              .remove(err => {
                if (err !== null) {
                  console.error(err);
                }
              });
          }
        });
      };

      const createMessageRef = () =>
        state.privateChannel ? state.privateMessagesRef : state.messageRef;

      const countUserPosts = (messages: [IMessage]) => {
        const userPosts = messages.reduce((acc: any, message) => {
          if (message.creator.name in acc) {
            acc[message.creator.name].count += 1;
          } else {
            acc[message.creator.name] = {
              avatar: message.creator.avataar,
              count: 1,
            };
          }
          return acc;
        }, {});
        setUserPostsFunc(userPosts);
      };

      const addMessageListner = (channelId: string) => {
        const loadedMesage: any = [];
        const ref = createMessageRef();
        ref.child(channelId).on('child_added', snap => {
          loadedMesage.push(snap.val());
          setState(prevState => ({
            ...prevState,
            messages: loadedMesage,
            messagesLoading: false,
          }));
          countUniqueUser(loadedMesage);
          countUserPosts(loadedMesage);
        });
      };

      const addUserStarListners = (channelId: string, userUid: string) => {
        state.usersRef
          .child(userUid)
          .child('starred')
          .once('value')
          .then(data => {
            if (data.val() !== null) {
              const channelIds = Object.keys(data.val());
              const prevStarred = channelIds.includes(channelId);
              setState((prevState: any) => ({
                ...prevState,
                isChannelStarred: prevStarred,
              }));
            }
          });
      };

      if (currentChannel && currentUser) {
        addEventListner(currentChannel.id);
        addUserStarListners(currentChannel.id, currentUser.uid);
      }
    },
    // eslint-disable-next-line
    [
      // state.messageRef,
      // state.privateChannel,
      // state.privateMessagesRef,
      // state.usersRef,
      // state.connectedRef,
      // state.typingRef,
      // currentChannel,
      // currentUser,
      // setUserPostsFunc,
    ],
  );

  const countUniqueUser = (messages: [IMessage]) => {
    const uniqueUser = messages.reduce((accumulator: any, currentValue) => {
      if (!accumulator.includes(currentValue.creator.name)) {
        accumulator.push(currentValue.creator.name);
      }
      return accumulator;
    }, []);
    const isPlural = uniqueUser.length > 1 || uniqueUser.length === 0;
    const numOfUniqueUsers = `${uniqueUser.length} user${isPlural ? 's' : ''}`;
    setState((prevState: any) => ({ ...prevState, numOfUniqueUsers }));
  };

  const getMessagesRef = () => {
    const { messageRef, privateMessagesRef, privateChannel } = state;

    return privateChannel ? privateMessagesRef : messageRef;
  };

  // const displayTypingUsers = (typingUsers: any[]) =>
  //   typingUsers.length > 0 &&
  //   typingUsers.map(user => (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         marginBottom: '0.2em',
  //         marginTop: 5,
  //       }}
  //       key={user.id}
  //     >
  //       <span className="user__typing">{user.name} is typing</span>
  //       <TypingComponent />
  //     </div>
  //   ))

  // const displayMessageSkeleton = (isMessageLoading: boolean) =>
  //   isMessageLoading ? (
  //     <React.Fragment>
  //       {[...Array(10)].map((_, i) => (
  //         <SkeletonComponent key={i} />
  //       ))}
  //     </React.Fragment>
  //   ) : null

  return (
    <Fragment>
      <Segment
        style={{
          marginBottom: 0,
          height: isMobile ? 'calc(100% - 75px)' : 'calc(100% - 80px)',
          padding: 10,
        }}
      >
        <Comment.Group className="messages" style={{ height: '100%' }}>
          {/* {displayMessageSkeleton(state.messagesLoading)} */}
          {state.searchTerm
            ? state.searchResults.length > 0 &&
              state.searchResults.map((message: IMessage) => (
                <InnerMessage
                  key={message.timestamp}
                  message={message}
                  user={currentUser}
                />
              ))
            : state.messages.length > 0 &&
              state.messages.map((message: IMessage) => (
                <InnerMessage
                  key={message.timestamp}
                  message={message}
                  user={currentUser}
                />
              ))}
          {/* {displayTypingUsers(state.typingUsers)} */}
          <div ref={messageEndPositionRef}></div>
        </Comment.Group>
      </Segment>

      <MessageForm
        currentChannel={currentChannel}
        currentUser={currentUser}
        isPrivateChannel={state.privateChannel}
        messageRef={getMessagesRef}
      />
    </Fragment>
  );
};

export default connect(null, { setUserPostsFunc: setUserPosts })(MessagePanel);
