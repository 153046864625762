import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
} from 'agora-rtc-sdk-ng';
import { useRef, useEffect, useState } from 'react';
import * as S from 'styles/meeting.styles';
import { ILiveWorkout } from 'types';
import $ from 'jquery';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { ProfileResponse } from '@solin-fitness/types';
import Timer from 'components/workoutTimer/timer';

export const TapToUnmute = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 8px;
  display: flex;
  position: absolute;
  z-index: 9999;
  top: 20px;
  color: white;
`;

export interface IVideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  isHost?: boolean;
  isLocal?: boolean;
  isLocalVideoMuted?: boolean;
  isLocalAudioMuted?: boolean;
  isMobileFullscreen: boolean;
  initialUnmuteDone: boolean;
  uid: string | number;
  videoUserData?: ProfileResponse;
  meetingId?: string;
}

const MediaPlayer = (props: IVideoPlayerProps) => {
  const [controlsAdded, setControlsAdded] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) {
      return;
    }
    props.videoTrack?.play(container.current, { mirror: false });

    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);

  useEffect(() => {
    // if (!props.isLocal && props.initialUnmuteDone) {

    if (!props.isLocal) {
      props.audioTrack?.play();
      props.audioTrack?.setVolume(100);
    }

    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack, props.isLocal]);

  const localVideoOff = props.isLocal && props.isLocalVideoMuted;

  const localAudioOff = props.isLocal && props.isLocalAudioMuted;

  if (!props.videoUserData && !props.isHost) {
    return null;
  }

  const isNotLaptop = isMobile || isTablet;
  const showingControls = !controlsAdded && props.isHost && isNotLaptop;

  return (
    <S.AspectRatio>
      <S.AspectRatioInnerWrapper>
        <S.MainPlayer
          isHost={props.isHost}
          ref={container}
          className="video-player"
          key={props.uid}
          isMobileFullscreen={props.isMobileFullscreen}
          onClick={() => {
            if (showingControls) {
              setControlsAdded(true);
              $('.agora_video_player')
                .attr('controls', 'controls')
                .removeAttr('muted');
            }
          }}
        >
          <Timer meetingId={props.meetingId as string} />

          {showingControls && (
            <S.TapToUnmute>
              <VolumeOffIcon style={{ ...S.IconStyle, marginRight: 7 }} />
              Tap to Unmute
            </S.TapToUnmute>
          )}
          {/* <MediaPlayerName
        localAudioOff={localAudioOff}
        videoUserData={props.videoUserData}
        audioTrack={props.audioTrack}
        isHost={props.isHost}
        workoutData={props.workoutData}
        videoTrack={props.videoTrack}
      /> */}

          {/* {localVideoOff && (
        <ProfileImage
          isVideoHost={props.isHost}
          videoUserData={{
            firstName: isTrainer
              ? user.firstName
              : videoUserData
              ? videoUserData.firstName
              : "",
            lastName: isTrainer
              ? user.lastName
              : videoUserData
              ? videoUserData.lastName
              : "",
            profilePictureUrl: isTrainer
              ? user.profilePictureUrl
              : videoUserData
              ? videoUserData.profilePictureUrl
              : "",
          }}
        />
      )} */}
        </S.MainPlayer>
      </S.AspectRatioInnerWrapper>
    </S.AspectRatio>
  );
};

export default MediaPlayer;
