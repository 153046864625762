import styled from 'styled-components';
import { Column } from 'components/Column';
import { Row } from 'components/Row';

export const Section = styled.section`
  width: 100%;
  box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.h4`
  font-family: Raleway;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: ${({ theme }) => theme.grays.dark};
`;

export const Week = styled.h6`
  font-size: 20px;
  color: ${({ theme }) => theme.main.primary};
  font-family: Roboto;
  font-weight: 700;
`;

export const Save = styled.button`
  font-size: 18px;
  background-color: ${({ theme }) => theme.main.secondary};
  font-family: Roboto;
  font-weight: 700;
  border: none;
  color: #fff;
  padding: 0px;
  height: 30px;
  margin: 0px 8px;

  width: 80px;
  border-radius: 40px;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const SearchContainerOuter = styled(Row)`
  width: 45%;
  height: 70px;
  border-radius: 40px;
  position: absolute;
  bottom: -30px;
  right: 10%;
  background-color: #fff;
  padding: 12px;
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.15);
`;

interface SearchProps {
  hasSearch: boolean;
}

export const SearchContainerInner = styled(Row)<SearchProps>`
  border-radius: 40px;
  background-color: ${({ hasSearch, theme }) =>
    hasSearch ? theme.main.lightSecondary : ''};
  width: 100%;
  height: 100%;
`;

export const SearchInput = styled.input<SearchProps>`
  border: none;
  outline: none;

  border-radius: 40px;
  padding: 0 36px;

  font-family: Raleway;
  font-weight: 500;
  font-size: 26px;
  line-height: 19px;
  color: ${({ hasSearch, theme }) =>
    !hasSearch ? theme.main.lightSecondary : '#FFF'};
  background-color: ${({ hasSearch, theme }) =>
    hasSearch ? theme.main.lightSecondary : ''};

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.main.lightSecondary};
  }
`;

export const FiltersContainer = styled(Row)`
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 36px;
  height: auto;
  width: 100%;
  padding-top: 18px;

  h5 {
    font-size: 30px;
    font-family: Raleway;
    font-weight: 700;
    line-height: 35px;
    color: ${({ theme }) => theme.main.lightSecondary};
  }
`;

export const CategoryContainer = styled(Column)<{ showCategories: boolean }>`
  position: absolute;
  top: 0;
  left: 36px;
  background-color: ${({ showCategories, theme }) =>
    showCategories ? theme.main.secondary : 'transparent'};
  border-radius: 30px;
  height: auto;
`;

export const CategoryList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 24px 0px;
  width: 100%;
`;

export const Category = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  color: #fff;
  font-size: 30px;
  font-family: Raleway;
  padding: 4px 24px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.main.lightSecondary};
  }
`;

export const Pagination = styled.span`
  position: absolute;
  right: 24px;
  font-size: 20px;
  color: ${({ theme }) => theme.main.primary};
  font-weight: bold;
  font-family: Roboto;
`;

export const EmptyWorkoutTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 175px;
  border-radius: 20px;
  background-color: hsla(33, 86%, 65%, 0.2);
  padding: 16px;
  margin: 24px;

  p {
    padding: 4px;
    font-size: 18px;
    font-family: Raleway;
    color: hsla(33, 86%, 65%);
  }
`;

export const ArrowButton = styled.button<{ isRight?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: hsla(0, 0%, 13%, 0.8);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  ${({ isRight }) => (isRight ? 'right: 0px' : 'left: 0px')};
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  outline: none;
  border: none;
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: hsla(0, 0%, 13%, 1);
  }
`;

export const LeftAbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -25px;
`;

export const RightAbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -25px;
`;
