import React, { useMemo, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { FormTextField } from 'components/FormTextField';
import SubmitButton from 'components/Button';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import { Column } from 'components/Column';
import { equals } from 'ramda';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import * as S from './PageOverview.styles';
import * as I from 'components/shared/Icons';
import { editPageMetadata } from 'queries/page';
import { EditConfigMetadata, PageTestimonials } from '@solin-fitness/types';
import * as yup from 'yup';
import styled from 'styled-components';
import PageTestimonial from './PageTestimonial';
import useThemeContext from 'hooks/useThemeContext';
import ImageUpload from 'components/FileUploads/ImageUpload/ImageUpload';

interface TestimonialInitialValues {
  testimonials: PageTestimonials[];
  newTestimonialImage: string;
  newTestimonialName: string;
  newTestimonialTestimonial: string;
}

const validationSchema = yup.object({
  testimonials: yup
    .array()
    .of(
      yup.object({
        image: yup.string().required().min(1),
        name: yup.string().required().min(1),
        testimonial: yup.string().required().min(0),
      }),
    )
    .required(),
});

const checkEmptyValues = (values: string[]): boolean =>
  !!values.filter((value) => !!!value).length;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  pageId: number;
  testimonials: PageTestimonials[];
}

const EditTestimonials = ({
  isOpen,
  onCancel,
  pageId,
  testimonials,
}: Props) => {
  const theme = useThemeContext();
  const initialValues: TestimonialInitialValues = {
    testimonials,
    newTestimonialImage: '',
    newTestimonialName: '',
    newTestimonialTestimonial: '',
  };

  const [error, setError] = useState('');
  const queryClient = useQueryClient();

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal top={10} width={800} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Edit Testimonials
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await editMetadata.mutateAsync({
            id: pageId,
            data: {
              testimonials: data.testimonials,
            },
          });

          setSubmitting(false);
          setError('');
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form style={{ marginBottom: 0 }}>
            <TestimonialContainer>
              <FieldArray name="testimonials">
                {(arrayHelpers) => (
                  <>
                    {values.testimonials.map((testimonial, index) => (
                      <PageTestimonial
                        key={index}
                        image={testimonial.image}
                        name={testimonial.name}
                        testimonial={testimonial.testimonial}
                        onDelete={() => arrayHelpers.remove(index)}
                      />
                    ))}
                    {values.testimonials.length < 3 ? (
                      <TestimonialFormContainer
                        key={values.testimonials.length}
                      >
                        <TestimonialImageUploadWrap>
                          <ImageUpload
                            circle
                            small
                            thumbnail={values.newTestimonialImage}
                            doNotUse16to9AspectRatio={true}
                            fileName="pageTestimonialImage"
                            handleThumbnail={(value) =>
                              setFieldValue('newTestimonialImage', value)
                            }
                          />
                        </TestimonialImageUploadWrap>
                        <TextField
                          label="Name"
                          variant="outlined"
                          placeholder="John Doe"
                          onChange={(e: any) =>
                            setFieldValue(
                              'newTestimonialName',
                              e.currentTarget.value,
                            )
                          }
                          type="input"
                        />
                        <TextField
                          label="Testimonial"
                          variant="outlined"
                          placeholder="This program is so amazing!"
                          type="input"
                          multiline
                          onChange={(e: any) =>
                            setFieldValue(
                              'newTestimonialTestimonial',
                              e.currentTarget.value,
                            )
                          }
                        />

                        <Button
                          bg={theme.main.primary}
                          disabled={checkEmptyValues([
                            values.newTestimonialImage,
                            values.newTestimonialName,
                            values.newTestimonialTestimonial,
                          ])}
                          onClick={() => {
                            arrayHelpers.push({
                              image: values.newTestimonialImage,
                              name: values.newTestimonialName,
                              testimonial: values.newTestimonialTestimonial,
                            });
                            setFieldValue('newTestimonialImage', '');
                            setFieldValue('newTestimonialName', '');
                            setFieldValue('newTestimonialTestimonial', '');
                          }}
                        >
                          <ButtonText color="#fff">Add Testimonial</ButtonText>
                        </Button>
                      </TestimonialFormContainer>
                    ) : null}
                  </>
                )}
              </FieldArray>
            </TestimonialContainer>
            <SubmitButton
              fullWidth
              disabled={isSubmitting}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              style={{
                borderRadius: 35,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="h6">Save</Typography>
              )}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const TestimonialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 12px;
  padding-top: 18px;
`;

const TestimonialFormContainer = styled.div`
  flex: 0 1 33.3333%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 36px;
`;

const TestimonialImageUploadWrap = styled.div`
  width: 150px;
  height: 150px;
  align-self: center;
`;

export const Button = styled.button<{ bg: string }>`
  border: none;
  width: 100%;
  background-color: ${(p) => p.bg};
  height: 44px;
  border-radius: 40px;
  box-shadow: 0px 14px 18px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 100ms;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: brightness(0.8);
  }
`;

export const ButtonText = styled.p<{ color: string }>`
  margin: auto;
  color: ${(p) => p.color};
  font-family: 'Brother';
  font-size: 16px;
  font-weight: medium;
`;

export const DescriptionLength = styled.div`
  width: 100%;
  margin-top: -6px;
  padding-right: 6px;
  font-size: 0.8rem;
  text-align: right;
`;

export default EditTestimonials;
