import React from 'react';
import * as I from 'components/shared/Icons';
import { SECONDARY } from 'constants/theme';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import { TimeOfDay } from 'context/WorkoutDropContext';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import * as S from './CalendarTile.styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    color: SECONDARY,
  },
});

const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const minutes = [0, 30];

interface Props {
  onCancel: () => void;
}

const ScheduleDropTile: React.FC<Props> = ({ onCancel }) => {
  const classes = useStyles();
  const { time, ampm, isSaving, handleTime, handleAmPm } = useWorkoutDrop();
  return (
    <S.ScheduleDay justifyContent="center">
      <S.Select
        name="time"
        disabled={isSaving}
        style={{ marginBottom: 4 }}
        defaultValue={JSON.stringify(time)}
        onChange={(e) => handleTime(JSON.parse(e.currentTarget.value))}
      >
        {hours.map((hour) =>
          minutes.map((minute) => (
            <option
              key={JSON.stringify([hour, minute])}
              value={JSON.stringify([hour, minute])}
            >{`${hour}:${minute < 1 ? '00' : minute}`}</option>
          )),
        )}
      </S.Select>
      <S.Select
        name="type"
        disabled={isSaving}
        defaultValue={ampm}
        onChange={(e) => handleAmPm(e.currentTarget.value as TimeOfDay)}
      >
        <option value={TimeOfDay.AM}>{TimeOfDay.AM}</option>
        <option value={TimeOfDay.PM}>{TimeOfDay.PM}</option>
      </S.Select>
      {!isSaving ? (
        <S.Exit onClick={() => onCancel()}>
          <I.X style={{ color: SECONDARY }} />
        </S.Exit>
      ) : (
        <CircularProgress className={classes.root} />
      )}
    </S.ScheduleDay>
  );
};

export default ScheduleDropTile;
