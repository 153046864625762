import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import styled from 'styled-components';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import Button from 'components/Button';
import { GRAYS } from 'constants/theme';
import * as S from './PageOverview.styles';
import EditImageAsset from './EditImageAsset';
import EditTeaserVideo from './EditTeaserVideo';
import CloudinaryImage from 'components/CloudinaryImage';

interface Props {
  teaserThumbnailPublicId: string;
  teaserVideo: string;
  secondaryPageImagePublicId: string;
  backgroundImage1PublicId: string;
  backgroundImage2PublicId: string;
  backgroundImage3PublicId: string;
  learnMoreImagePublicId: string;
  verticalBackgroundImage: string;
  lightLogoPublicId: string;
  pageId: number;
}

const PageAssets: React.FC<Props> = (props) => {
  const {
    teaserThumbnailPublicId,
    teaserVideo,
    secondaryPageImagePublicId,
    backgroundImage1PublicId,
    backgroundImage2PublicId,
    backgroundImage3PublicId,
    learnMoreImagePublicId,
    verticalBackgroundImage,
    pageId,
  } = props;
  const [openThumbnail, setOpenThumbnail] = useState<boolean>(false);
  const [openVideo, setOpenVideo] = useState<boolean>(false);
  const [openSecondary, setOpenSecondary] = useState<boolean>(false);
  const [openBackground1, setOpenBackground1] = useState<boolean>(false);
  const [openBackground2, setOpenBackground2] = useState<boolean>(false);
  const [openBackground3, setOpenBackground3] = useState<boolean>(false);
  const [openLearnMore, setOpenLearnMore] = useState<boolean>(false);
  const [openBackgroundVertical, setOpenBackgroundVertical] =
    useState<boolean>(false);
  return (
    <React.Fragment>
      <EditImageAsset
        isOpen={openThumbnail}
        onCancel={() => setOpenThumbnail(false)}
        pageId={pageId}
        aspectRatio={16 / 9}
        title="Teaser Thumbnail"
        name="page_teaser_thumbnail"
        asset="teaserThumbnailPublicId"
      />

      <EditImageAsset
        isOpen={openSecondary}
        onCancel={() => setOpenSecondary(false)}
        pageId={pageId}
        aspectRatio={16 / 9}
        title="Scheduled Live Session Image"
        name="secondary_page_image"
        asset="secondaryPageImagePublicId"
      />
      <EditImageAsset
        isOpen={openBackground3}
        onCancel={() => setOpenBackground3(false)}
        pageId={pageId}
        aspectRatio={16 / 9}
        title="Program & Challenges Thumbnail"
        name="background_image_3"
        asset="backgroundImage3PublicId"
      />
      <EditImageAsset
        isOpen={openLearnMore}
        onCancel={() => setOpenLearnMore(false)}
        pageId={pageId}
        aspectRatio={16 / 9}
        title="Learn More Image"
        name="learn_more_image"
        asset="learnMoreImagePublicId"
      />
      <EditImageAsset
        isOpen={openBackgroundVertical}
        onCancel={() => setOpenBackgroundVertical(false)}
        pageId={pageId}
        aspectRatio={9 / 16}
        title="Background Image Vertical"
        name="background_image_vertical"
        asset="verticalBackgroundImage"
      />
      <EditImageAsset
        isOpen={openBackground1}
        onCancel={() => setOpenBackground1(false)}
        pageId={pageId}
        aspectRatio={16 / 9}
        title="Background Image 1"
        name="background_image_1"
        asset="backgroundImage1PublicId"
      />
      <EditImageAsset
        isOpen={openBackground2}
        onCancel={() => setOpenBackground2(false)}
        pageId={pageId}
        aspectRatio={16 / 9}
        title="Background Image 2"
        name="background_image_2"
        asset="backgroundImage2PublicId"
      />

      <EditTeaserVideo
        isOpen={openVideo}
        onCancel={() => setOpenVideo(false)}
        pageId={pageId}
      />
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Teaser Video</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenVideo(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.VideoContainer>
            <TeaserVideo
              key={teaserVideo}
              controls
              onPause={() => undefined}
              controlsList="nodownload noremoteplayback"
            >
              <source src={teaserVideo} type="video/mp4" />
            </TeaserVideo>
          </S.VideoContainer>
          <S.VideoText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.VideoText>
        </S.Container>
      </S.Section>

      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Teaser Thumbnail</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenThumbnail(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <TeaserThumbnail>
              <CloudinaryImage
                publicId={teaserThumbnailPublicId}
                width={619}
                height={348}
                alt={'page teaser thumbnail'}
                fetchFormat={'auto'}
                quality={100}
                crop={'scale'}
              />
            </TeaserThumbnail>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>

      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Scheduled Live Session Image</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenSecondary(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <SecondaryPageImage>
              <CloudinaryImage
                publicId={secondaryPageImagePublicId}
                width={619}
                height={348}
                alt={'page teaser thumbnail'}
                fetchFormat={'auto'}
                quality={100}
                crop={'scale'}
              />
            </SecondaryPageImage>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">
              {'Programs & Challenges Thumbnail'}
            </Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenBackground3(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <BackgroundImage>
              <CloudinaryImage
                publicId={backgroundImage3PublicId}
                width={619}
                height={348}
                alt={'background image 3'}
                fetchFormat={'auto'}
                quality={100}
                crop={'scale'}
              />
            </BackgroundImage>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Learn More Image</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenLearnMore(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <BackgroundImage>
              <CloudinaryImage
                publicId={learnMoreImagePublicId}
                width={619}
                height={348}
                alt={'learn more image'}
                fetchFormat={'auto'}
                quality={100}
                crop={'scale'}
              />
            </BackgroundImage>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Background Image - Vertical</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenBackgroundVertical(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <BackgroundImage>
              {verticalBackgroundImage ? (
                <CloudinaryImage
                  publicId={verticalBackgroundImage}
                  width={619}
                  height={348}
                  alt={'background image vertical'}
                  fetchFormat={'auto'}
                  quality={100}
                  crop={'fit'}
                />
              ) : (
                <NoImageWrapper onClick={() => setOpenBackgroundVertical(true)}>
                  <Typography variant="h5" align="center">
                    No image found, please upload.
                  </Typography>
                </NoImageWrapper>
              )}
            </BackgroundImage>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page on mobile!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">
              Background Image 1 - Horizontal
            </Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenBackground1(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <BackgroundImage>
              <CloudinaryImage
                publicId={backgroundImage1PublicId}
                width={619}
                height={348}
                alt={'background image 1 - horizontal'}
                fetchFormat={'auto'}
                quality={100}
                crop={'scale'}
              />
            </BackgroundImage>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Background Image 2</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenBackground2(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <Divider />
        <S.Container>
          <S.ThumbnailContainer>
            <BackgroundImage>
              <CloudinaryImage
                publicId={backgroundImage2PublicId}
                width={619}
                height={348}
                alt={'background image 2'}
                fetchFormat={'auto'}
                quality={100}
                crop={'scale'}
              />
            </BackgroundImage>
          </S.ThumbnailContainer>
          <S.ThumbnailText>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                letterSpacing: 0.5,
                filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
              }}
            >
              Here is a preview of how it will look on your page!
            </Typography>
          </S.ThumbnailText>
        </S.Container>
      </S.Section>
    </React.Fragment>
  );
};

export default PageAssets;

const Divider = styled.div`
  margin: 0px -16px;
  border-bottom: 2px solid ${GRAYS.light};
`;

const TeaserVideo = styled.video`
  width: ${630 * (2 / 3)}px;
  height: ${420 * (2 / 3)}px;
  border-radius: 20px;
  outline: none;
  background-color: #000;
  filter: drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.4));
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TeaserThumbnail = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    margin-bottom: 0;
  }
`;

const BackgroundImage = styled.div`
  img {
    border-radius: 20px;
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
`;

const TeaserBorder = styled.div`
  width: 420px;
  height: ${420 * (9 / 16)}px;
  position: absolute;
  border: 10px solid white;
  border-radius: 50px;
  top: 13px;
  right: 13px;
  cursor: pointer;
`;

const SecondaryPageImage = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    /* filter: drop-shadow(60px 104px 124px rgba(0, 0, 0, 0.35)); */
  }
`;

const NoImageWrapper = styled.div`
  width: 619px;
  height: 348px;
  background-color: #f5f6fa;
  padding-top: 150px;
`;
