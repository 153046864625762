import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';

// TODO: Add pageUrl to the AuthState

interface AuthState {
  isSignedIn: boolean;
  userId: number | undefined;
  logUserIn: (userId: number) => void;
}

const useAuthStore = create<
  AuthState,
  SetState<AuthState>,
  GetState<AuthState>,
  StoreApiWithPersist<AuthState>
>(
  persist(
    (set, get) => ({
      isSignedIn: get()?.isSignedIn,
      userId: get()?.userId,
      logUserIn: (userId: number) =>
        set(() => ({
          isSignedIn: true,
          userId,
        })),
    }),
    {
      name: 'auth-storage',
    },
  ),
);

export default useAuthStore;
