import { MyFirebase } from './chat/Config';
import { getCurrentChannel } from './chat/Pages/Chat/ChatPage';

const timerRef = MyFirebase.database().ref('timer');

export interface TimerConfig {
  restSeconds: number;
  activeSeconds: number;
  numberOfSets: number;
  started: boolean;
  isPaused: boolean;
  activeTimerSec: number;
  restTimerSec: number;
  currentSet: number;
  isRunning: boolean;
}

export const setTimerConfigAndStart = (
  meetingId: string,
  configTimer: TimerConfig,
) => {
  const currentChannelId = getCurrentChannel(meetingId).id;

  timerRef
    .child(currentChannelId)
    .set({ config: configTimer })
    .then(() => {
      console.log('timer save to firebase');
    });
};

export const updateOnFirebase = (
  meetingId: string,
  configTimer: TimerConfig,
) => {
  const currentChannelId = getCurrentChannel(meetingId).id;

  timerRef
    .update({ [`${currentChannelId}/config`]: configTimer })
    .then((res) => {
      // console.log('res', res);
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const removeOnFirebase = (meetingId: string) => {
  const currentChannelId = getCurrentChannel(meetingId).id;
  // @ts-ignore
  updateOnFirebase(meetingId, { isRunning: false });
  timerRef.child(currentChannelId).remove();
};
