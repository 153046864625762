import { useContext } from 'react';
import { ProgramBuilderContext } from 'context/ProgramBuilderContext';

export const useProgramBuilder = () => {
  const context = useContext(ProgramBuilderContext);
  if (context === undefined) {
    throw new Error(
      'useProgramBuilder must be used within a ProgramBuilderProvider',
    );
  }
  return context;
};
