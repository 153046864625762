import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import PublishIcon from '@material-ui/icons/Publish';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import { uploadImage } from 'queries/file-uploads';
import { GRAYS, LIGHT_PRIMARY } from 'constants/theme';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { editPageMetadata } from 'queries/page';
import { Column } from 'components/Column';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { EditConfigMetadata } from '@solin-fitness/types';
import CloudinaryImage from 'components/CloudinaryImage';
import UploadImage from 'components/UploadImageModal';

const getPayload = (asset: String, imagePublicId: string) => {
  switch (asset) {
    case 'teaserThumbnailPublicId':
      return {
        teaserThumbnailPublicId: imagePublicId,
      };
    case 'secondaryPageImagePublicId':
      return {
        secondaryPageImagePublicId: imagePublicId,
      };
    case 'backgroundImage1PublicId':
      return {
        backgroundImage1PublicId: imagePublicId,
      };
    case 'backgroundImage2PublicId':
      return {
        backgroundImage2PublicId: imagePublicId,
      };
    case 'backgroundImage3PublicId':
      return {
        backgroundImage3PublicId: imagePublicId,
      };
    case 'learnMoreImagePublicId':
      return {
        learnMoreImagePublicId: imagePublicId,
      };
    case 'verticalBackgroundImage':
      return {
        verticalBackgroundImage: imagePublicId,
      };
    default:
      console.error('no asset found/provided');
      return {};
  }
};

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  pageId: number;
  aspectRatio: number;
  title: string;
  name: string;
  asset: string;
}

interface InitialValues {
  thumbnail: File | null;
}

const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr: any = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const EditImageAsset: React.FC<Props> = (props) => {
  const { isOpen, onCancel, pageId, aspectRatio, title, name, asset } = props;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const initialValues = useMemo<InitialValues>(
    () => ({
      thumbnail: null,
    }),
    [],
  );
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [imagePublicId, setImagePublicId] = useState<string>('');
  const [completedCrop, setCompletedCrop] = useState<Crop>({});
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const queryClient = useQueryClient();

  const handleImage = useMutation(
    (fileToUpload: File) => uploadImage(fileToUpload),
    {
      onError: (err: any) => {
        setError(err?.message || 'Something went wrong 😔');
      },
    },
  );

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (completedCrop && previewCanvasRef.current && imgRef.current) {
      const currImage = imgRef.current;
      const canvas = previewCanvasRef.current;

      const scaleX = currImage.naturalWidth / currImage.width;
      const scaleY = currImage.naturalHeight / currImage.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return;
      }

      const pixelRatio = window.devicePixelRatio;

      canvas.width = (completedCrop.width ?? 0) * pixelRatio;
      canvas.height = (completedCrop.height ?? 0) * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        currImage,
        (completedCrop.x ?? 0) * scaleX,
        (completedCrop.y ?? 0) * scaleY,
        (completedCrop.width ?? 0) * scaleX,
        (completedCrop.height ?? 0) * scaleY,
        0,
        0,
        completedCrop.width ?? 0,
        completedCrop.height ?? 0,
      );
    }
  }, [completedCrop]);

  const handleSave = async (uploadedFile: File) => {
    const date = new Date().getTime();

    // need to convert to blob and then to new file in order to update file name
    const blob = uploadedFile.slice(0, uploadedFile.size, uploadedFile.type);
    const fileToSave = new File([blob], `${date}_${name}`, {
      type: 'image/png',
    });

    if (!fileToSave) {
      return;
    }

    // upload image
    const { publicId } = await handleImage.mutateAsync(fileToSave);
    setImagePublicId(publicId);
  };

  const handleFinish = async (publicId: string) => {
    const payload = getPayload(asset, publicId);

    // TODO loader saving

    // save metadata
    await editMetadata.mutateAsync({
      id: pageId,
      data: payload,
    });
    onCancel();
  };

  return (
    <Modal top={1} width={800} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        {title}
      </Typography>
      {!imagePublicId && (
        <>
          <Typography variant="h6">
            Recommended Aspect Ratio:{' '}
            {aspectRatio === 9 / 16 ? '9:16 (for mobile)' : '16:9'}
          </Typography>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Please crop image before uploading if desired.
          </Typography>
        </>
      )}

      <UploadImage
        onFinished={(publicId) => handleFinish(publicId)}
        isNotModal
        fileName={name}
        aspectRatio={aspectRatio}
      />

      {/* {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          const payload = getPayload(asset, imagePublicId);

          // save metadata
          await editMetadata.mutateAsync({
            id: pageId,
            data: payload,
          });

          setSubmitting(false);
          resetForm();
          setImage(null);
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form style={{ marginBottom: 0 }}>
            <DropZone
              onClick={() => hiddenFileInput.current?.click()}
              style={{
                display: image ? 'none' : 'flex',
              }}
            >
              <Column>
                <PublishIcon fontSize="large" color="primary" />
                <Typography
                  variant="h5"
                  align="center"
                  style={{ width: '70%', fontWeight: 700, letterSpacing: 0.8 }}
                >
                  Select a file to upload
                </Typography>
              </Column>
              <input
                type="file"
                accept=".jpg,.png"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={async (e) => {
                  const files = e.currentTarget.files;
                  if (files && files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                      setImage(reader.result),
                    );
                    reader.readAsDataURL(files[0]);
                    setFieldValue('thumbnail', files[0]);

                    await handleSave(files[0]);
                  }
                }}
              />
            </DropZone>
            {values.thumbnail && imagePublicId && (
              <>
                <div
                  style={{
                    borderTop: `2px solid ${GRAYS.light}`,
                    marginLeft: -30,
                    marginRight: -30,
                    marginTop: 24,
                    marginBottom: 24,
                  }}
                />
                <Row justifyContent="flex-start" style={{ marginBottom: 36 }}>
                  <div style={{ position: 'relative' }}>
                    <CloudinaryImage
                      publicId={imagePublicId}
                      width={750}
                      height={450}
                      alt={'asset preview'}
                      fetchFormat={'auto'}
                      quality={'auto'}
                      crop={'scale'}
                      style={{ borderRadius: 20 }}
                    />
                  </div>
                </Row>
                <Row justifyContent="flex-start" style={{ marginBottom: 36 }}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 700,
                      letterSpacing: 0.5,
                      filter: 'drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25))',
                    }}
                  >
                    Here is a preview of your image!
                  </Typography>
                </Row>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  style={{
                    borderRadius: 35,
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size="2rem" />
                  ) : (
                    <Typography variant="h6">Save</Typography>
                  )}
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik> */}
    </Modal>
  );
};

export default EditImageAsset;

const DropZone = styled(Row)`
  height: 500px;
  border: 3px dashed ${({ theme }) => theme.main.lightPrimary};
  border-radius: 24px;

  &:hover {
    opacity: 0.5;
    background-color: ${({ theme }) => theme.grays.light};
  }
`;

export const TeaserBorder = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: absolute;
  border: 10px solid white;
  border-radius: 50px;
  top: 13px;
  right: 13px;
`;
