import styled from 'styled-components';
import { useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import CloudinaryImage from 'components/CloudinaryImage';
import EditIcon from '@material-ui/icons/Edit';
import CameraIcon from '@material-ui/icons/CameraAlt';
import * as Colors from 'constants/theme';
import { useQueryClient, useMutation } from 'react-query';
import { EditProfileRequest } from '@solin-fitness/types';
import { uploadImage } from 'queries/file-uploads';
import { editUserProfile } from 'queries/user';

export const UserInfoContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: center;
    padding: 30px 0px;
  }
`;

export const ProfilePictureWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
`;

export const AbsoluteCenter = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const HoverWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
`;

export const CameraInputContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.grays.light};
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

interface Props {
  profilePictureUrlPublicId?: string;
}

const EditProfilePic = ({ profilePictureUrlPublicId }: Props) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [, setError] = useState<string>('');
  const queryClient = useQueryClient();

  const handleImage = useMutation(
    (fileToUpload: File) => uploadImage(fileToUpload),
    {
      onMutate: () => setIsUploading(true),

      onError: (err: any) => {
        setIsUploading(false);
        setError(err?.message || 'Something went wrong 😔');
      },
    },
  );

  const handleEditProfile = useMutation(
    (data: EditProfileRequest) => editUserProfile(data),
    {
      onError: (err: any) => setError(err?.message || 'Please notify devs 🙈'),
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        setError('');
        setIsUploading(false);
      },
    },
  );

  return (
    <UserInfoContainer>
      {profilePictureUrlPublicId ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ position: 'relative', width: 100, height: 100 }}>
            <ProfilePictureWrapper style={{ opacity: isUploading ? 0.5 : 1 }}>
              <CloudinaryImage
                publicId={profilePictureUrlPublicId}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                alt={'user profile picture'}
                width={100}
                height={100}
                crop={'fill'}
                quality={'auto:best'}
                fetchFormat={'auto'}
              />
            </ProfilePictureWrapper>

            {isUploading && (
              <AbsoluteCenter>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 100,
                  }}
                >
                  <CircularProgress />
                </div>
              </AbsoluteCenter>
            )}
          </div>
          <HoverWrap
            style={{ marginTop: 16 }}
            onClick={() => hiddenInputRef.current?.click()}
          >
            <EditIcon
              style={{
                color: 'gray',
              }}
            />
          </HoverWrap>
        </div>
      ) : (
        <CameraInputContainer onClick={() => hiddenInputRef.current?.click()}>
          {/* below used to center camera icon */}
          <div
            style={{
              marginRight: -1,
              marginTop: -2,
              opacity: isUploading ? 0.5 : 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CameraIcon />
          </div>
          {isUploading && (
            <AbsoluteCenter>
              <div>
                <CircularProgress />
              </div>
            </AbsoluteCenter>
          )}
        </CameraInputContainer>
      )}
      <input
        ref={hiddenInputRef}
        type="file"
        accept=".jpg,.png"
        style={{ display: 'none' }}
        onChange={async (e) => {
          const files = e.currentTarget.files;
          if (files && files.length > 0) {
            const upload = files[0];
            const date = new Date().getTime();

            // new file to update name
            const new_upload = new File([upload], `${date}_profile_picture`, {
              type: upload.type,
            });

            const { publicId } = await handleImage.mutateAsync(new_upload);
            await handleEditProfile.mutateAsync({
              profilePictureUrlPublicId: publicId,
            });
          }
        }}
      />
    </UserInfoContainer>
  );
};

export default EditProfilePic;
