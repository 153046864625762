import { api, Methods } from 'services/api';
import { useMutation, useQueryClient } from 'react-query';

const logoutUser = async () => api(Methods.post, '/logout');

const useLogoutUser = () => {
  const queryClient = useQueryClient();

  return useMutation((_: number) => logoutUser(), {
    onSuccess: () => {
      localStorage.clear();
      queryClient.clear();
    },
  });
};

export default useLogoutUser;
