import React from 'react';
import Layout from 'components/Layout';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';
import { CreatorAnalyticsTable } from 'components/CreatorAnalyticsTable';
import { UserAnalyticsTable } from 'components/UserAnalyticsTable';
import { WorkoutAnalyticsTable } from 'components/WorkoutAnalyticsTable';
import { LiveWorkoutAnalyticsTable } from 'components/LiveWorkoutAnalyticsTable';
import { BalanceAnalytics } from 'components/BalanceAnalytics';
import { PaymentsAnalyticsTable } from 'components/PaymentsAnalyticsTable';
import { SubscriptionsAnalyticsTable } from 'components/SubscriptionsAnalyticsTable';
import { CustomersAnalyticsTable } from 'components/CustomersAnalyticsTable';

enum AnalyticsTabs {
  CREATOR_LIST = 'Creators',
  USER_LIST = 'Users',
  WORKOUTS = 'Workouts',
  LIVE_WORKOUTS = 'Live Workouts',
  BALANCE = 'Balance',
  PAYMENTS = 'Payments',
  SUBSCRIPTIONS = 'Subscriptions',
  CUSTOMERS = 'Customers',
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 50px;
`;

const WidthWrap = styled.div`
  width: 100%;
`;

const AnalyticsPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: any) => ({
    id: `analytics-tab-${index}`,
    'aria-controls': `analytics-tabpanel-${index}`,
  });

  return (
    <Layout title="Analytics">
      <Container>
        <WidthWrap>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Analytics Tabs"
              variant="scrollable"
              scrollButtons="on"
            >
              <Tab label={AnalyticsTabs.CREATOR_LIST} {...a11yProps(0)} />
              <Tab label={AnalyticsTabs.USER_LIST} {...a11yProps(1)} />
              <Tab label={AnalyticsTabs.WORKOUTS} {...a11yProps(2)} />
              <Tab label={AnalyticsTabs.LIVE_WORKOUTS} {...a11yProps(3)} />
              <Tab label={AnalyticsTabs.BALANCE} {...a11yProps(4)} />
              <Tab label={AnalyticsTabs.PAYMENTS} {...a11yProps(5)} />
              <Tab label={AnalyticsTabs.SUBSCRIPTIONS} {...a11yProps(6)} />
              <Tab label={AnalyticsTabs.CUSTOMERS} {...a11yProps(7)} />
            </Tabs>
          </AppBar>
        </WidthWrap>
        <WidthWrap>
          {value === 0 && <CreatorAnalyticsTable />}
          {value === 1 && <UserAnalyticsTable />}
          {value === 2 && <WorkoutAnalyticsTable />}
          {value === 3 && <LiveWorkoutAnalyticsTable />}
          {value === 4 && <BalanceAnalytics />}
          {value === 5 && <PaymentsAnalyticsTable />}
          {value === 6 && <SubscriptionsAnalyticsTable />}
          {value === 7 && <CustomersAnalyticsTable />}
        </WidthWrap>
      </Container>
    </Layout>
  );
};

export default AnalyticsPage;
