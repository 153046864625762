import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import TimerIcon from '@material-ui/icons/Timer';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {
  setTimerConfigAndStart,
  updateOnFirebase,
  removeOnFirebase,
} from 'services/firebaseTimer';
import { isMobile, isTablet } from 'react-device-detect';

const isMobileOrTablet = isTablet || isMobile;

export const Wrap = styled.div`
  position: absolute;
  bottom: 25px;
  left: 30px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border-radius: 30px;
  background: rgba(84, 141, 191, 0.7);
  z-index: 99;

  @media (max-width: 1025px) {
    left: 20px;
    bottom: initial;
    top: 20px;
    border-radius: 20px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Title = styled.div`
  color: white;
  margin-bottom: 2px;
`;

export const ValueText = styled.div`
  color: #fdacac;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  width: 100px;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const TrainerControlsTimer = styled.div`
  position: absolute;
  bottom: 0px;
`;

export const SecText = styled.div`
  font-family: Raleway;
  font-size: 16px;
  line-height: 19px;
  color: #548dbf;
  margin-top: 15px;
`;

export const CircleWrap = styled.div<{ isActiveTimer: boolean }>`
  width: 140px;
  height: 140px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: Roboto;
  font-weight: bold;
  font-size: 46px;
  line-height: 15px;
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;

  @media (max-width: 1025px) {
    left: 20px;
    border-radius: 20px;
  }
`;

export const TimerWrap = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(84, 141, 191, 0.7);
  display: flex;
  padding: 0px 12px;
  border-radius: 30px;
  align-items: center;
  padding-right: 20px;
  color: white;
  font-family: Raleway;
  cursor: pointer;
  z-index: 99;

  @media (max-width: 1025px) {
    background: transparent;
    left: initial;
    position: relative;
    right: initial;
    bottom: initial;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 550px) {
    width: auto;
  }
`;

export const IconStyleBlack = { color: 'black' };
export const IconStyle = { color: 'white' };

interface Props {
  meetingId: string;
  iconColor?: string;
}

const timerSound = 'https://storage.googleapis.com/solin-cdn/workoutTimer.mp3';
const audio = new Audio(timerSound);

const Timer = ({ meetingId, iconColor }: Props) => {
  const [activeSeconds, setActiveSeconds] = useState(40);
  const [restSeconds, setRestSeconds] = useState(20);
  const [numberOfSets, setNumberOfSets] = useState(3);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [restTimerStarted, setRestTimerStarted] = useState(false);
  const [activeTimerSec, setActiveTimerSec] = useState(0);
  const [restTimerSec, setRestTimerSec] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const isTrainer = true;

  useEffect(() => {
    audio.setAttribute('crossorigin', 'anonymous');
    audio.crossOrigin = 'anonymous';

    let interval: any = null;
    if (timerStarted) {
      interval = setInterval(() => {
        if (activeTimerSec === 1) {
          playBeep();
        }
        if (activeTimerSec === 0) {
          clearInterval(interval);
          setTimerStarted(false);
          setRestTimerStarted(true);
          setRestTimerSec(restSeconds);
          handleUpdateOnFirebase({
            restTimerSec: restSeconds,
            restTimerStarted: true,
            timerStarted: false,
          });
        } else {
          handleUpdateOnFirebase({ activeTimerSec: activeTimerSec - 1 });
          setActiveTimerSec((activeTimerSeconds) => activeTimerSeconds - 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerStarted, activeTimerSec]);

  useEffect(() => {
    let interval: any = null;
    if (restTimerStarted) {
      interval = setInterval(() => {
        if (restTimerSec === 1) {
          playBeep();
        }

        if (restTimerSec === 0) {
          clearInterval(interval);
          setRestTimerStarted(false);
          if (currentSet === numberOfSets) {
            setTimerStarted(false);
            setCurrentSet(0);
            handleUpdateOnFirebase({
              currentSet: 0,
              timerStarted: false,
              restTimerStarted: false,
              isRunning: false,
            });
            removeOnFirebase(meetingId);
          } else {
            handleUpdateOnFirebase({
              currentSet: currentSet + 1,
              timerStarted: true,
              activeTimerSec: activeSeconds,
              restTimerStarted: false,
            });
            setCurrentSet(currentSet + 1);
            setTimerStarted(true);
            setActiveTimerSec(activeSeconds);
          }
        } else {
          handleUpdateOnFirebase({ restTimerSec: restTimerSec - 1 });
          setRestTimerSec((restTimerSeconds) => restTimerSeconds - 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [restTimerStarted, restTimerSec]);

  const playBeep = () => {
    setTimeout(() => {
      audio.play();
      audio.muted = false;
    }, 200);
  };
  const handleStartTimer = () => {
    setTimerConfigAndStart(meetingId, {
      numberOfSets,
      restSeconds,
      activeSeconds,
      started: true,
      isPaused: false,
      activeTimerSec,
      restTimerSec,
      currentSet: 1,
      isRunning: true,
    });
    setCurrentSet(1);
    setTimerStarted(true);
    setActiveTimerSec(activeSeconds);
    setIsSettingsVisible(false);
    audio.play();
    audio.muted = false;
  };
  const handleClose = () => {
    setIsSettingsVisible(false);
  };

  const isTimerRunning = timerStarted || restTimerStarted || isPaused;

  const handelTimerClick = () => {
    if (!isTimerRunning) {
      setIsSettingsVisible(!isSettingsVisible);
    }
  };

  const handlePause = () => {
    const isActiveTimer = activeTimerSec > 0;
    if (isActiveTimer) {
      setTimerStarted(isPaused ? true : false);
    } else {
      setRestTimerStarted(isPaused ? true : false);
    }
    setIsPaused(!isPaused);
    handleUpdateOnFirebase({ isPaused: !isPaused });
  };

  const handleUpdateOnFirebase = (data?: any) => {
    updateOnFirebase(meetingId, {
      numberOfSets:
        data.numberOfSets !== undefined ? data.numberOfSets : numberOfSets,
      restSeconds:
        data.restSeconds !== undefined ? data.restSeconds : restSeconds,
      activeSeconds:
        data.activeSeconds !== undefined ? data.activeSeconds : activeSeconds,
      started: true,
      isPaused: data.isPaused !== undefined ? data.isPaused : isPaused,
      activeTimerSec:
        data.activeTimerSec !== undefined
          ? data.activeTimerSec
          : activeTimerSec,
      restTimerSec:
        data.restTimerSec !== undefined ? data.restTimerSec : restTimerSec,
      currentSet: data.currentSet !== undefined ? data.currentSet : currentSet,
      isRunning: data.isRunning !== undefined ? data.isRunning : true,
    });
  };

  const handleStop = () => {
    setActiveTimerSec(activeSeconds);
    setTimerStarted(false);
    setRestTimerStarted(false);
    setIsPaused(false);
    removeOnFirebase(meetingId);
  };

  return (
    <>
      {!isMobileOrTablet && !isSettingsVisible && !isTimerRunning && (
        <TimerWrap onClick={handelTimerClick}>
          <Tooltip title="Timer" placement="top">
            <IconButton onClick={handelTimerClick}>
              <TimerIcon style={{ color: iconColor || 'white' }} />
            </IconButton>
          </Tooltip>
          {!isMobileOrTablet && <>Timer</>}
        </TimerWrap>
      )}

      {isMobileOrTablet && (
        <TimerWrap onClick={handelTimerClick}>
          <Tooltip title="Timer" placement="top">
            <IconButton onClick={handelTimerClick}>
              <TimerIcon style={{ color: iconColor || 'white' }} />
            </IconButton>
          </Tooltip>
          {!isMobileOrTablet && <>Timer</>}
        </TimerWrap>
      )}

      {isTimerRunning && (
        <CircleWrap isActiveTimer={activeTimerSec > 0}>
          <div style={{ color: 'red', fontSize: 14, marginBottom: 20 }}>
            Set {currentSet} {activeTimerSec === 0 && '- Rest'}
          </div>
          <div
            style={{
              marginBottom: 25,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {activeTimerSec > 0 ? activeTimerSec : restTimerSec}
            <SecText>sec</SecText>
          </div>
          {isTrainer && (
            <TrainerControlsTimer>
              <Tooltip title={isPaused ? 'Play' : 'Pause'} placement="bottom">
                <IconButton onClick={handlePause}>
                  {isPaused ? (
                    <PlayCircleOutlineIcon style={IconStyleBlack} />
                  ) : (
                    <PauseCircleOutlineIcon style={IconStyleBlack} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Stop" placement="bottom">
                <IconButton onClick={handleStop}>
                  <HighlightOffIcon style={IconStyleBlack} />
                </IconButton>
              </Tooltip>
            </TrainerControlsTimer>
          )}
        </CircleWrap>
      )}
      {isSettingsVisible && (!timerStarted || !restTimerStarted) && (
        <Wrap>
          <Title>Active</Title>
          <Row>
            <IconButton
              onClick={() => {
                if (activeSeconds === 0) {
                  return;
                }
                setActiveSeconds(activeSeconds - 5);
                setActiveTimerSec(activeSeconds - 5);
              }}
            >
              <RemoveCircleOutlineIcon style={IconStyle} />
            </IconButton>
            <ValueText>{activeSeconds} sec</ValueText>
            <IconButton
              onClick={() => {
                setActiveSeconds(activeSeconds + 5);
                setActiveTimerSec(activeSeconds + 5);
              }}
            >
              <AddCircleOutlineIcon style={IconStyle} />
            </IconButton>
          </Row>

          <Title>Rest</Title>
          <Row>
            <IconButton
              onClick={() => {
                if (restSeconds === 0) {
                  return;
                }
                setRestSeconds(restSeconds - 5);
              }}
            >
              <RemoveCircleOutlineIcon style={IconStyle} />
            </IconButton>
            <ValueText>{restSeconds} sec</ValueText>
            <IconButton onClick={() => setRestSeconds(restSeconds + 5)}>
              <AddCircleOutlineIcon style={IconStyle} />
            </IconButton>
          </Row>
          <Title>Sets</Title>
          <Row>
            <IconButton
              onClick={() => {
                setNumberOfSets(numberOfSets - 1);
                if (numberOfSets === 0) {
                  return;
                }
              }}
            >
              <RemoveCircleOutlineIcon style={IconStyle} />
            </IconButton>
            <ValueText>{numberOfSets}</ValueText>
            <IconButton onClick={() => setNumberOfSets(numberOfSets + 1)}>
              <AddCircleOutlineIcon style={IconStyle} />
            </IconButton>
          </Row>
          <ButtonRow>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartTimer}
              startIcon={<TimerIcon />}
              style={{
                marginRight: 10,
                height: 44,
                fontSize: 15,
                padding: '3px 22px',
              }}
            >
              <b>Start</b> timer
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={handleClose}
              style={{
                color: 'white',
                height: 40,
                fontSize: 15,
                marginBottom: -20,
              }}
            >
              Close
            </Button>
          </ButtonRow>
        </Wrap>
      )}
    </>
  );
};

export default Timer;
