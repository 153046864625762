import React, { useState } from 'react';
import styled from 'styled-components';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/FormTextField';
import Button from 'components/Button';
import * as yup from 'yup';
import { createPageClassPrice } from 'queries/page';
import { CreateClassPriceRequest } from '@solin-fitness/types';
import { Modal } from 'components/Modal';

export const Aside = styled.aside<{ isError: boolean }>`
  padding: 16px;
  margin-bottom: 24px;

  border-color: ${({ isError, theme }) =>
    isError ? theme.main.secondary : theme.main.primary};
  border-left: 8px solid
    ${({ isError, theme }) =>
      isError ? theme.main.secondary : theme.main.primary};
  background-color: ${({ isError, theme }) =>
    isError ? theme.main.lightSecondary : theme.main.lightPrimary};
  border-radius: 8px;
  text-align: center;
  line-height: 1.5;
  font-size: 1.25rem;
`;

export const SuccessAside = styled.aside<{ isSuccess: boolean }>`
  padding: 16px;
  margin-bottom: 24px;
  color: white;
  border-color: ${({ theme }) => theme.success.primary};
  border-left: 8px solid ${({ theme }) => theme.success.primary};
  background-color: ${({ theme }) => theme.success.lightPrimary};
  border-radius: 8px;
  text-align: center;
  line-height: 1.5;
  font-size: 1.25rem;
  margin-top: 40px;
`;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required().min(1),
  description: yup.string().required().min(1),
  amount: yup.number().required().min(1),
});

const initalClassPriceValues = {
  amount: 0.0,
  name: '',
  description: '',
};

const CreateClassPrice: React.FC<Props> = ({ isOpen, onCancel }) => {
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
  const createClassPrice = useMutation(
    (data: CreateClassPriceRequest) => createPageClassPrice(data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Create Class Price
      </Typography>
      {!!!error && (
        <Aside isError={false}>
          All values below are <strong>final</strong>. You can create additional
          prices if needed.
        </Aside>
      )}
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initalClassPriceValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          console.log(data);
          await createClassPrice.mutateAsync({
            ...data,
            amount: Math.round(data.amount * 10000) / 100,
          });
          setSubmitting(false);
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ marginBottom: 0 }}>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6" children="Class Price Info" />
              <FormTextField label="Name" name="name" type="input" />
              <FormTextField
                label="Description"
                name="description"
                type="input"
              />
            </div>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6" children="Pricing" />
              <FormTextField
                label="Price"
                name="amount"
                type="number"
                placeholder="0.00"
              />
            </div>
            <Button
              fullWidth
              disabled={isSubmitting}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              style={{
                borderRadius: 35,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="h6">Submit</Typography>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateClassPrice;
