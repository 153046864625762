import styled from 'styled-components';
import { PHONE } from 'constants/measurements';

export const Nav = styled.div<{ isMobileMenu?: boolean }>`
  vertical-align: top;
  padding-top: 90px;
  height: 100vh;
  min-width: 230px;
  overflow: auto;
  background: ${({ theme }) => theme.padding};
  border-right: 2px solid ${({ theme }) => theme.grays.light};

  @media (max-width: ${PHONE}) {
    display: ${({ isMobileMenu }) => (isMobileMenu ? 'flex' : 'none')};
    flex-direction: column;
    width: 300px;
    padding-top: 10px;
  }
`;

export const Logo = styled.img`
  height: 32px;
`;

export const SideMenuLogoWrap = styled.div`
  padding: 10px;
  padding-bottom: 30px;
`;
