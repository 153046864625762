import { CircularProgress } from '@material-ui/core';
import CloudinaryImage from 'components/CloudinaryImage';
import { CircleCheck, X } from 'components/shared/Icons';
import Upload from 'components/shared/Icons/Upload';
import useThemeContext from 'hooks/useThemeContext';
import React, { useState } from 'react';
import styled from 'styled-components';
import UploadImage from 'components/UploadImageModal';

const RemoveButton = styled.button<{ circle?: boolean }>`
  display: ${(p) => (p.circle ? 'none' : 'block')};
  padding: 0;
  border: none;
  background: white;
  opacity: 0.8;
  cursor: pointer;

  width: 32px;
  height: 32px;

  position: absolute;
  top: ${(p) => (p.circle ? 0 : '8px')};
  right: ${(p) => (p.circle ? 0 : '8px')};
  left: ${(p) => (p.circle ? 0 : undefined)};
  bottom: ${(p) => (p.circle ? 0 : undefined)};
  border-radius: 50%;
  margin: auto;

  &:hover {
    display: block;
  }
`;

const FileInputContainer = styled.div<{
  borderRadius?: string;
}>`
  --bg-color: hsl(208deg, 25%, 95%);
  --bg-color-hover: hsl(208deg, 45%, 95%);
  width: 100%;
  height: 100%;
  border: dashed ${(p) => p.theme.main.lightPrimary};
  border-radius: ${(p) => p.borderRadius || '8px'};
  position: relative;
  background: var(--bg-color);
  transition: 200ms;
  overflow: ${(p) => (p.borderRadius ? 'hidden' : 'visible')};

  &:hover {
    background-color: var(--bg-color-hover);
  }

  &:hover ${RemoveButton} {
    display: block;
  }
`;

const FileInput = styled.input`
  /* visibility: hidden; */
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FileDetailsContainer = styled.div`
  position: absolute;
  inset: -3.5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const FileDetailsText = styled.p<{ small?: boolean }>`
  font-size: ${(p) => (p.small ? '14px' : '20px')};
  font-family: 'Raleway';
  padding: 0 24px;
  text-align: center;
  word-break: break-word;
`;

interface Props {
  thumbnail: string;
  handleThumbnail: (value: string) => void;
  circle?: boolean;
  small?: boolean;
  fileName: string;
  doNotUse16to9AspectRatio?: boolean;
  aspectRatio?: number;
}

const ImageUpload: React.FC<Props> = ({
  thumbnail,
  handleThumbnail,
  circle,
  small,
  fileName,
  doNotUse16to9AspectRatio,
  aspectRatio = 16 / 9,
}) => {
  const theme = useThemeContext();
  const [uploading, setIsUploading] = useState<boolean>(false);

  const iconSize = small ? 24 : 40;

  return (
    <FileInputContainer borderRadius={circle ? '50%' : undefined}>
      {!uploading && !!!thumbnail ? (
        <UploadImage
          onFinished={(publicId) => handleThumbnail(publicId)}
          fileName={fileName}
          aspectRatio={aspectRatio}
          doNotUse16to9AspectRatio={doNotUse16to9AspectRatio}
          buttonToRender={
            <FileDetailsContainer>
              <Upload
                color={theme.main.primary}
                width={iconSize}
                height={iconSize}
                strokeWidth={1.5}
              />
              <FileDetailsText small={small}>
                Click here to{' '}
                <span style={{ color: theme.main.primary }}>browse</span>
              </FileDetailsText>
            </FileDetailsContainer>
          }
        />
      ) : null}
      {uploading ? (
        <FileDetailsContainer>
          <CircularProgress />
        </FileDetailsContainer>
      ) : null}
      {!!thumbnail ? (
        <FileDetailsContainer>
          <CloudinaryImage
            publicId={thumbnail}
            style={{
              objectFit: 'contain',
              height: '100%',
              width: 'fit-content',
            }}
          />
        </FileDetailsContainer>
      ) : null}
      {!!thumbnail ? (
        <RemoveButton circle={circle} onClick={() => handleThumbnail('')}>
          <X color="black" />
        </RemoveButton>
      ) : null}
    </FileInputContainer>
  );
};

export default ImageUpload;
