import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';
import { Modal } from 'components/Modal';
import { WorkoutResponse } from '@solin-fitness/types';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/FormTextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/Button';
import * as yup from 'yup';
import {
  scheduleWorkoutDrop as schedulePageWorkoutDrop,
  ScheduleWorkoutDropRequest,
} from 'queries/workouts';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';

interface Props {
  workout: WorkoutResponse;
  isOpen: boolean;
  onCancel: () => void;
}

const validationSchema = yup.object({
  startDate: yup.string().required(),
  time: yup
    .string()
    .required()
    .test(
      'is-time',
      "Start time's must be in 30 minute intervals.",
      (value) => {
        const minute = Number(value?.substring(3, 5));
        return minute % 30 === 0;
      },
    ),
});

const ScheduleWorkoutDrop: React.FC<Props> = ({
  workout,
  onCancel,
  isOpen,
}) => {
  const [error, setError] = useState<string>('');
  const currDate = useMemo(() => DateTime.fromJSDate(new Date()), []);
  const queryClient = useQueryClient();

  const scheduleDrop = useMutation(
    ({ data, id }: { data: ScheduleWorkoutDropRequest; id: number }) =>
      schedulePageWorkoutDrop(data, id),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        ),
      onSuccess: () => queryClient.invalidateQueries('workouts'),
    },
  );

  return (
    <Modal open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 8 }}>
        Schedule Workout Drop
      </Typography>
      <Typography variant="h5" color="primary" style={{ marginBottom: 16 }}>
        {workout.title}
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={{
          startDate: currDate.toFormat('yyyy-MM-dd'),
          time: currDate.toFormat('T'),
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setError('');
          const curr = DateTime.fromJSDate(new Date());
          // need combine date and time to one DateTime
          const { startDate, time } = data;
          const hour = Number(time.substring(0, 2));
          const minute = Number(time.substring(3, 5));
          const releaseDate = DateTime.fromISO(startDate);
          releaseDate.set({ hour, minute });
          if (releaseDate.toMillis() > curr.toMillis()) {
            await scheduleDrop.mutateAsync({
              data: {
                isDrop: true,
                releaseDate: String(releaseDate.toJSDate()),
              },
              id: workout.id,
            });
            resetForm();
          } else {
            setError(
              'Workout drop needs to be scheduled for a date in the future.',
            );
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ marginBottom: 0 }}>
            <div style={{ marginBottom: 36 }}>
              <FormTextField
                label="Workout Date"
                name="startDate"
                type="date"
                data-test="createLW-date"
                min="2015-01-01"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormTextField
                label="Workout Time"
                name="time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <Button
              fullWidth
              disabled={isSubmitting}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              style={{
                borderRadius: 35,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="h6">Submit</Typography>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ScheduleWorkoutDrop;
