import { createMuiTheme } from '@material-ui/core/styles';

export const TEXT_COLOR = 'rgba(0,0,0,0.75)';
const PAGE_BG = 'white';
const SOLINTV_LIVE_BACKGROUND = 'white';

const THEME_TYPE = 'light';

export const PRIMARY = '#548DBF';
export const LIGHT_PRIMARY = '#CCDDF3';
export const SECONDARY = '#F25252';
export const LIGHT_SECONDARY = '#FDACAC';

export const SUCCESS_PRIMARY = '#3E8C75';
export const SUCCESS_LIGHT_PRIMARY = '#49A68B';

export const GRAYS = {
  veryDark: 'rgb(50, 50, 52)',
  dark: 'rgb(106, 106, 109)',
  medium: 'rgb(162, 162, 166)',
  light: 'rgb(218, 218, 223)',
  veryLight: 'rgb(248, 248, 254)',
};

export const imagePlaceholder =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzSc0E_-ezcw1juku7x_q9rIVtGDEFGDsZnA&usqp=CAU';

export const muiTheme = createMuiTheme({
  palette: {
    type: THEME_TYPE,
    primary: {
      main: PRIMARY,
    },
    secondary: { main: SECONDARY },
    text: {
      primary: '#34393C',
      secondary: '#585f63',
    },
    background: {
      default: '#F9F9F9',
    },
  },
  typography: {
    fontFamily: ['Raleway', 'sans-serif'].join(','),
    fontSize: 16,
  },
});

export const themeColors = {
  primary: PRIMARY,
  headerBg: SECONDARY,
  pageBg: PAGE_BG,
  textColor: TEXT_COLOR,
  pageBgPadding: THEME_TYPE === 'light' ? '#f5f6fa' : '#212121',
  liveBottomBanner: PRIMARY,
  liveBackground: SOLINTV_LIVE_BACKGROUND,
};

export const theme = {
  background: '#F9F9F9',
  padding: '#F5F6FA',
  text: {
    primary: '#34393C',
    secondary: '#585f63',
  },
  main: {
    primary: PRIMARY,
    lightPrimary: LIGHT_PRIMARY,
    secondary: SECONDARY,
    lightSecondary: LIGHT_SECONDARY,
  },
  success: {
    primary: SUCCESS_PRIMARY,
    lightPrimary: SUCCESS_LIGHT_PRIMARY,
  },
  grays: GRAYS,
};

// Reds
export const RED = '#e02f64';
export const DARK_RED = '#c21d41';
export const PINK = '#ffdbe6';
export const RED_BORDER = 'rgba(245, 112, 128, 0.25)';

// Yellows
export const GOLD = '#9F6000';
export const POLLEN = '#FFF5CC';
export const YELLOW = '#F7E020';

// Other
export const ORANGE = '#FFA500';
export const GREEN = '#028F05';

/**
 * Blues
 */

export const OUTLINE = 'rgba(0, 123, 255, 0.25)';
export const SKY = '#e6f2ff';
export const SNOW = '#f0f2f5';
export const TRANSLUCENT_BLUE = 'rgba(65, 143, 222, 0.1)';

export const LIGHT_BLUE = 'rgb(80, 158, 208)';
export const BLUE = 'rgb(65, 143, 222)';
export const DARK_BLUE = 'rgb(50, 128, 207)';

/**
 * Neutrals
 */

export const BLACK = 'rgb(50, 50, 52)';
export const BLACK_ALPHA = (alpha: number): string =>
  `rgba(50, 50, 52, ${alpha})`;
export const SHADE = 'rgba(0, 0, 0, 0.1)';
export const BORDER = 'rgba(0, 0, 0, 0.1)';
export const WHITE = '#FFFFFF';
export const WHITE_ALPHA = (alpha: number): string =>
  `rgba(255, 255, 255, ${alpha})`;
