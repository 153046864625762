import React from 'react';
import styled from 'styled-components';
import {
  Comment,
  CommentContent,
  CommentAuthor,
  CommentMetadata,
  Image,
} from 'semantic-ui-react';
import moment from 'moment';
import { IMessage, IUser } from '../../.../../../Global-interfaces';
import * as Colors from 'constants/theme';

export const ProfileWrapSchedule = styled.div`
  border-radius: 50%;
  font-size: 12px;
  background: ${Colors.themeColors.primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  float: left;
  margin-right: 14px;
  width: 35px;
  height: 35px;
`;

export const ImageUser = styled.img`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-top: 26px;
`;

export const TextCom = styled.span`
  margin: 0.25em 0 0.5em;
  font-size: 1em;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
  line-height: 15px;
`;

interface IProps {
  message: IMessage;
  user: IUser;
}

const isOwnMessage = (message: IMessage, user: IUser) =>
  message.creator.id === user.uid ? 'message__self' : '';

const isImage = (message: IMessage) =>
  message.hasOwnProperty('image') && !message.hasOwnProperty('content');

const timeFromNow = (time: string) => moment(time).fromNow();

const InnerMessage: React.FC<IProps> = ({ message, user }) => (
  <Comment>
    {message.creator.avataar ? (
      <ProfileWrapSchedule style={{ backgroundColor: 'transparent' }}>
        <ImageUser src={message.creator.avataar} />
      </ProfileWrapSchedule>
    ) : (
      <ProfileWrapSchedule>
        {`${message.creator.name.match(/\b(\w)/g)?.join('')}`}
      </ProfileWrapSchedule>
    )}

    <CommentContent className={isOwnMessage(message, user)}>
      <CommentAuthor as="a"> {message.creator.name} </CommentAuthor>
      <CommentMetadata> {timeFromNow(message.timestamp)} </CommentMetadata>
      <br />
      {isImage(message) ? (
        <Image src={message.image} className="message__image" />
      ) : (
        <TextCom> {message.content} </TextCom>
      )}
    </CommentContent>
  </Comment>
);

export default InnerMessage;
