import React from 'react';
import { MinCalendar } from '../CalendarCarousel';
import * as S from './CalendarTile.styles';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import { getStartTime } from 'util/date-fns';
import CloudinaryImage from 'components/CloudinaryImage';

interface Props {
  val: MinCalendar;
}

const LiveWorkoutCalendarTile: React.FC<Props> = ({ val }) => {
  const { isScheduling, handleScheduling } = useWorkoutDrop();

  return (
    <S.LiveWorkoutDay
      style={{
        position: 'relative',
      }}
    >
      {val.thumbnailPublicId && (
        <>
          <S.ThumbnailImageWrapper>
            <CloudinaryImage
              publicId={val.thumbnailPublicId}
              style={{ width: '100%', height: '100%', borderRadius: '20px' }}
              alt={'live workout thumbnail'}
              fetchFormat={'auto'}
              quality={'auto'}
              crop={'scale'}
            />
          </S.ThumbnailImageWrapper>
        </>
      )}
      <S.WorkoutDetailsContainer
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <S.WorkoutDetails>
          &bull;{' '}
          <span style={{ opacity: 0.8 }}>
            {getStartTime(val.hour, val.minute, val.time)}
          </span>
          <br />
          {val.title}
        </S.WorkoutDetails>
      </S.WorkoutDetailsContainer>
    </S.LiveWorkoutDay>
  );
};

export default LiveWorkoutCalendarTile;
