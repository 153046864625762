import React from 'react';
import { useQuery } from 'react-query';
import { getPage } from 'queries/page';
import { Page } from 'types';
import { Loading } from 'components/Loading';

const useConfig = () => {
  const { isLoading, data } = useQuery<Page, Error>(
    'creatorPage',
    () => getPage(),
    {
      cacheTime: 30000,
      staleTime: 10000000,
    },
  );

  return { isLoading, data, Loading };
};

export default useConfig;
