import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import MyFirebase, { ListenerType } from 'services/chat/Config/firebase';
import { getCurrentChannel } from 'services/chat/Pages/Chat/ChatPage';
import FireIcon from 'assets/images/fireIcon.png';

import { PRIMARY } from 'constants/theme';

export const FireCount = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 33px;
  color: ${({ theme }) => theme.main.secondary};
  margin-right: 10px;
`;

export const FireIconEl = styled.img`
  width: 22px;
  height: 27px;
  margin: 0;
  margin-top: -3px;
`;

export const Wrap = styled.div`
  display: flex;
  margin-left: 50px;
  align-items: center;
`;

const fireRef = MyFirebase.database().ref('fire');

interface Props {
  meetingId: string;
}

const Fire = (props: Props) => {
  const [fires, setFires] = useState<any>([]);

  const currentChannelId = getCurrentChannel(props.meetingId).id;

  let fireListener: ListenerType | null = null;

  useEffect(() => {
    const ref = fireRef;
    const loadedFires: any = [];
    fireListener = ref
      .child(currentChannelId)
      .on('child_added', (snap: any) => {
        loadedFires.push(snap.val());
        setFires(() => [...loadedFires]);
      });
    return () => {
      ref.off('child_added', fireListener as ListenerType);
    };
  }, []);

  return (
    <Wrap>
      <FireCount>{fires.length}</FireCount>
      <FireIconEl src={FireIcon}></FireIconEl>
    </Wrap>
  );
};

export default Fire;
