import React, { useMemo, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { FormTextField } from 'components/FormTextField';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import { Column } from 'components/Column';
import { equals } from 'ramda';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import * as S from './PageOverview.styles';
import * as I from 'components/shared/Icons';
import { editPageMetadata } from 'queries/page';
import { EditConfigMetadata } from '@solin-fitness/types';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  pageId: number;
  workoutTags: string[];
}

const EditDetails: React.FC<Props> = (props) => {
  const { isOpen, onCancel, pageId, workoutTags } = props;
  const initialValues = useMemo(
    () => ({
      workoutTags,
      newWorkoutTag: '',
    }),
    [workoutTags],
  );
  const [error, setError] = useState('');
  const queryClient = useQueryClient();

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal top={10} width={600} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Edit Details
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await editMetadata.mutateAsync({
            id: pageId,
            data: {
              workoutTags: data.workoutTags,
            },
          });

          setSubmitting(false);
          setError('');
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form style={{ marginBottom: 0 }}>
            <Column alignItems="flex-start">
              <Column alignItems="flex-start" style={{ paddingBottom: 24 }}>
                <Typography variant="h6">Workout Tags</Typography>
                <FieldArray name="workoutTags">
                  {(arrayHelpers) => (
                    <>
                      <Row style={{ paddingBottom: 12 }}>
                        <FormTextField name="newWorkoutTag" />
                        <Button
                          size="large"
                          onClick={() => {
                            arrayHelpers.push(values.newWorkoutTag);
                            setFieldValue('newWorkoutTag', '');
                          }}
                          color="primary"
                          style={{ margin: 16, fontSize: '1.2rem' }}
                        >
                          add
                        </Button>
                      </Row>
                      <Row
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ flexWrap: 'wrap' }}
                      >
                        {values.workoutTags.map((val, index) => (
                          <S.Chip key={val} primary>
                            <Row>
                              <Typography
                                variant="body1"
                                style={{
                                  padding: '0 10px',
                                  textAlign: 'center',
                                  fontWeight: 600,
                                  letterSpacing: 0.8,
                                  lineHeight: 2,
                                }}
                              >
                                {val}
                              </Typography>
                              <div
                                onClick={() => arrayHelpers.remove(index)}
                                style={{ paddingRight: '10px' }}
                              >
                                <I.X />
                              </div>
                            </Row>
                          </S.Chip>
                        ))}
                      </Row>
                    </>
                  )}
                </FieldArray>
              </Column>
            </Column>
            {!equals(
              {
                workoutTags: initialValues.workoutTags,
              },
              {
                workoutTags: values.workoutTags,
              },
            ) && (
              <Button
                fullWidth
                disabled={isSubmitting}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{
                  borderRadius: 35,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="inherit" size="2rem" />
                ) : (
                  <Typography variant="h6">Save</Typography>
                )}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditDetails;
