import { CircularProgress } from '@material-ui/core';
import { X } from 'components/shared/Icons';
import Upload from 'components/shared/Icons/Upload';
import useThemeContext from 'hooks/useThemeContext';
import { uploadDocument } from 'queries/file-uploads';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const RemoveButton = styled.button<{ circle?: boolean }>`
  display: ${(p) => (p.circle ? 'none' : 'block')};
  padding: 0;
  border: none;
  background: white;
  opacity: 0.8;
  cursor: pointer;

  width: 32px;
  height: 32px;

  position: absolute;
  top: ${(p) => (p.circle ? 0 : '8px')};
  right: ${(p) => (p.circle ? 0 : '8px')};
  left: ${(p) => (p.circle ? 0 : undefined)};
  bottom: ${(p) => (p.circle ? 0 : undefined)};
  border-radius: 50%;
  margin: auto;

  &:hover {
    display: block;
  }
`;

const FileInputContainer = styled.div<{
  borderRadius?: string;
}>`
  --bg-color: hsl(208deg, 25%, 95%);
  --bg-color-hover: hsl(208deg, 45%, 95%);
  width: 100%;
  height: 100%;
  border: dashed ${(p) => p.theme.main.lightPrimary};
  border-radius: ${(p) => p.borderRadius || '8px'};
  position: relative;
  background: var(--bg-color);
  transition: 200ms;
  overflow: ${(p) => (p.borderRadius ? 'hidden' : 'visible')};

  &:hover {
    background-color: var(--bg-color-hover);
  }

  &:hover ${RemoveButton} {
    display: block;
  }
`;

const FileInput = styled.input`
  /* visibility: hidden; */
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FileDetailsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const FileDetailsText = styled.p<{ small?: boolean }>`
  font-size: ${(p) => (p.small ? '14px' : '20px')};
  font-family: 'Raleway';
  padding: 0 24px;
  text-align: center;
  word-break: break-word;
`;

interface Props {
  document: string;
  handleDocument: (value: string) => void;
  circle?: boolean;
  small?: boolean;
}

const DocumentUpload: React.FC<Props> = ({
  document,
  handleDocument,
  circle,
  small,
}) => {
  const theme = useThemeContext();
  const [uploading, setIsUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFile = useMutation(
    (fileToUpload: File) => uploadDocument(fileToUpload),
    {
      onMutate: () => setIsUploading(true),
      onSuccess: () => {
        setIsUploading(false);
      },
      onError: (err: any) => {
        setIsUploading(false);
        // setError(err?.message || 'Something went wrong 😔');
      },
    },
  );

  const iconSize = small ? 24 : 40;

  return (
    <FileInputContainer borderRadius={circle ? '50%' : undefined}>
      {!uploading && !!!document ? (
        <FileDetailsContainer>
          <Upload
            color={theme.main.primary}
            width={iconSize}
            height={iconSize}
          />
          <FileDetailsText small={small}>
            Click here to{' '}
            <span style={{ color: theme.main.primary }}>browse</span> documents
          </FileDetailsText>
        </FileDetailsContainer>
      ) : null}
      {uploading ? (
        <FileDetailsContainer>
          <CircularProgress />
        </FileDetailsContainer>
      ) : null}
      {!!document ? (
        <FileDetailsContainer>
          <FileDetailsText small={small}>
            Uploaded document{' '}
            <span style={{ color: theme.main.primary }}>{file?.name}</span>
          </FileDetailsText>
        </FileDetailsContainer>
      ) : null}
      <FileInput
        type="file"
        accept=".txt,.pdf,.doc,.docx"
        onChange={async (e) => {
          const files = e.currentTarget.files;
          if (files && files.length > 0) {
            const upload = files[0];
            setFile(upload);

            const date = new Date().getTime();

            // new file to update name
            const new_upload = new File([upload], `${date}_document_upload`, {
              type: upload.type,
            });

            const { documentUrl } = await handleFile.mutateAsync(new_upload);
            handleDocument(documentUrl);
          }
        }}
        style={{
          display: file && document ? 'none' : 'block',
        }}
      />
      {!!document ? (
        <RemoveButton circle={circle} onClick={() => handleDocument('')}>
          <X color="black" />
        </RemoveButton>
      ) : null}
    </FileInputContainer>
  );
};

export default DocumentUpload;
