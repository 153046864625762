import React, { useMemo } from 'react';
import { WorkoutAnalyticsResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { Column, Row } from 'react-table';
import { api, Methods } from 'services/api';
import { Table } from 'components/Table';
import WorkoutOverviewCell from 'components/ManageWorkoutsTable/WorkoutOverviewCell';
import { DateTime } from 'luxon';
import { Loading } from 'components/Loading';

const WorkoutAnalyticsTable: React.FC = () => {
  const { isLoading, data: workouts } = useQuery<WorkoutAnalyticsResponse[]>(
    'workouts',
    async () =>
      api<WorkoutAnalyticsResponse[]>(Methods.get, '/analytics/workouts'),
  );

  const customSort = useMemo(
    () => (
      rowA: Row<WorkoutAnalyticsResponse>,
      rowB: Row<WorkoutAnalyticsResponse>,
    ) => {
      const aTitle = rowA.values.title;
      const bTitle = rowB.values.title;
      return aTitle < bTitle ? -1 : 1;
    },
    [],
  );

  const columns = useMemo<Column<WorkoutAnalyticsResponse>[]>(
    () => [
      {
        Header: 'Workout',
        accessor: 'title',
        sortType: customSort,
        Cell: ({ row }: { row: { original: WorkoutAnalyticsResponse } }) => (
          <WorkoutOverviewCell
            thumbnailPublicId={row.original.thumbnailPublicId}
            title={row.original.title}
            description={row.original.description}
          />
        ),
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: (info: any) =>
          DateTime.fromISO(
            new Date(info.row.original.createdAt).toISOString(),
          ).toFormat('D'),
      },
      {
        Header: 'Published',
        accessor: 'published',
        Cell: (info: any) => (info.row.original.published ? 'Yes' : 'No'),
      },
      {
        Header: 'Views',
        accessor: 'views',
      },
      {
        Header: 'Minutes Watched',
        accessor: 'minutesWatched',
      },
      {
        Header: 'Upload Status',
        accessor: 'uploadStatus',
      },
    ],
    [],
  );

  const data = useMemo<WorkoutAnalyticsResponse[]>(
    () => (workouts ? workouts : []),
    [workouts],
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table<WorkoutAnalyticsResponse> columns={columns} data={data} />
      )}
    </div>
  );
};

export default WorkoutAnalyticsTable;
