import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { Row } from 'components/Row';
import Button from 'components/Button';
import { GRAYS } from 'constants/theme';
import * as S from './PageOverview.styles';
import { PageTestimonials as Testimonials } from '@solin-fitness/types';
import styled from 'styled-components';
import PageTestimonial from './PageTestimonial';
import EditTestimonials from './EditTestimonials';

interface Props {
  pageId: number;
  testimonials: Testimonials[];
}

const PageTestimonials = ({ pageId, testimonials }: Props) => {
  const [open, setIsOpen] = useState<boolean>(false);

  return (
    <S.Section>
      <EditTestimonials
        isOpen={open}
        onCancel={() => setIsOpen(false)}
        pageId={pageId}
        testimonials={testimonials}
      />
      <S.Container>
        <Typography variant="h4">Testimonials</Typography>
        <S.EditButton>
          <Button
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => setIsOpen(true)}
          >
            Edit
          </Button>
        </S.EditButton>
      </S.Container>
      <div
        style={{
          margin: '0 -16px',
          borderBottom: `2px solid ${GRAYS.light}`,
        }}
      />
      <TestimonialContainer>
        {testimonials.map(({ name, image, testimonial }) => (
          <PageTestimonial
            name={name}
            image={image}
            testimonial={testimonial}
          />
        ))}
      </TestimonialContainer>
    </S.Section>
  );
};

const TestimonialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  padding-top: 18px;
`;

export default PageTestimonials;
