import React, { useEffect, useState, useCallback } from 'react';
import * as S from 'styles/login-live.styles';
import Button from '@material-ui/core/Button';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4 } from 'uuid';
import { UseMutationResult } from 'react-query';
import * as Colors from 'constants/theme';
import PublishIcon from '@material-ui/icons/Publish';
import { UploadInfo } from 'pages/UploadVideo';

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number },
) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box minWidth={35}>
      <Typography variant="body2" color="textSecondary">{`${Math.round(
        props.value,
      )}%`}</Typography>
    </Box>
  </Box>
);

const chunkSize = 1048576 * 3; // bytes (in binary) per MB

interface Props {
  initialize: UseMutationResult<
    object & {
      id: number;
    },
    unknown,
    void,
    unknown
  >;
  handleVideoUpload: (uploadInfo: UploadInfo) => Promise<{ videoUrl: string }>;
  uploadFinished: boolean;
  progress: number;
  handleSkipToLink: (value: boolean) => void;
  handleIsChunkUpload: (value: boolean) => void;
  skippedToLink: boolean;
}

const UploadVideoFile = (props: Props) => {
  const {
    initialize,
    handleVideoUpload,
    handleIsChunkUpload,
    handleSkipToLink,
    skippedToLink,
    uploadFinished,
    progress,
  } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [fileSize, setFileSize] = useState<number>(0);
  const [chunkCount, setChunkCount] = useState<number>(0);
  const [uploadTimer, setUploadTimer] = useState<number>(0);
  const [uploadStart, setUploadStart] = useState<boolean>(false);

  const setFormData = useCallback((): FormData | undefined => {
    const formData = new FormData();
    if (!file) {
      return undefined;
    }

    let startChunk = 0;
    let endChunk = chunkSize;
    for (let i = 0; i < chunkCount; i++) {
      const chunk = file.slice(startChunk, endChunk);
      startChunk = endChunk;
      endChunk =
        endChunk + chunkSize < fileSize ? endChunk + chunkSize : fileSize;
      formData.append('chunk', chunk, `${fileId}.${i}`);
    }

    return formData;
  }, [chunkCount, file, fileId, fileSize]);

  /**
   * handles on deman video upload via chunk upload endpoint
   */
  const uploadFile = useCallback(async () => {
    try {
      const formData = setFormData();

      if (!formData) {
        console.log('Form data undefined: ', formData);
        return;
      }
      setUploadStart(true);
      handleIsChunkUpload(true);
      const workout = await initialize.mutateAsync();

      await handleVideoUpload({
        formData,
        fileId,
        uploadWorkoutId: workout.id,
      });

      setUploadStart(false);
      setFile(undefined);
    } catch (err) {
      console.log(err);
    }
  }, [fileId, setFormData]);

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file, uploadFile]);

  useEffect(() => {
    if (uploadStart) {
      window.addEventListener('beforeunload', alertUser);
      setTimeout(() => {
        setUploadTimer(uploadTimer + 1);
      }, 1000);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [uploadStart, uploadTimer]);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const target = e.target.files[0];
      setFile(target);
      setFileId(uuidv4());
      setChunkCount(
        target.size % chunkSize === 0
          ? target.size / chunkSize
          : Math.floor(target.size / chunkSize) + 1,
      );
      setFileSize(target.size);
    }
  };

  if (skippedToLink) {
    return null;
  }

  return (
    <div>
      <S.Container
        style={{
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        {uploadFinished ? (
          <Typography variant="subtitle1">
            Video was uploaded and is now processing!
          </Typography>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '60%',
            }}
          >
            <S.UploadIconCircle>
              <PublishIcon
                style={{ color: Colors.themeColors.primary, fontSize: 55 }}
              />
            </S.UploadIconCircle>
            <Button
              variant="contained"
              component="label"
              color="primary"
              style={{ width: 150, margin: 40 }}
            >
              Select File
              <input
                type="file"
                hidden
                accept="video/mp4"
                onChange={(e) => onChangeFile(e)}
              />
            </Button>

            <div style={{ width: '100%' }}>
              {uploadStart ? (
                <LinearProgressWithLabel value={progress} />
              ) : (
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                  Select video to go with on-demand workout.
                </Typography>
              )}
            </div>
            {!uploadStart && (
              <Button
                variant="text"
                color="primary"
                onClick={() => handleSkipToLink(true)}
                style={{ marginTop: 20 }}
              >
                or upload video link
              </Button>
            )}
          </div>
        )}
      </S.Container>
    </div>
  );
};

export default UploadVideoFile;
