import Uppy from '@uppy/core';
import { API_ROOT } from 'services/api';
import { getPageUrl } from 'services/auth';
import XHRUpload from '@uppy/xhr-upload';
import { useState } from 'react';
import styled from 'styled-components';
import { DashboardModal, useUppy, Dashboard } from '@uppy/react';
import ImageEditor, { ImageEditorOptions } from '@uppy/image-editor';
import Button from '@material-ui/core/Button';

import '@uppy/status-bar/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';

const RequiredLabel = styled.span`
  color: red;
`;

interface Props {
  fieldName?: string;
  fileName: string;
  onFinished: (uploadedImageUrl: string) => void;
  isNotModal?: boolean;
  buttonToRender?: JSX.Element;
  doNotUse16to9AspectRatio?: boolean;
  imageUploaded?: boolean;
  aspectRatio?: number;
}

const UploadImage = ({
  fieldName,
  onFinished,
  isNotModal,
  fileName,
  buttonToRender,
  doNotUse16to9AspectRatio,
  imageUploaded,
  aspectRatio = 16 / 9,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const imageEditorSettings: ImageEditorOptions = {
    quality: 1,
    cropperOptions: {
      viewMode: 1,
      background: false,
      responsive: true,
      croppedCanvasOptions: {},
      aspectRatio: doNotUse16to9AspectRatio ? undefined : aspectRatio,
      autoCropArea: 1,
    },
    actions: {
      revert: true,
      rotate: true,
      granularRotate: false,
      flip: true,
      zoomIn: true,
      zoomOut: true,
      cropSquare: doNotUse16to9AspectRatio ? true : false,
      cropWidescreen: true,
      cropWidescreenVertical: doNotUse16to9AspectRatio ? true : false,
    },
  };

  const beforeUpload = (files: any) => {
    const date = new Date().getTime();
    const updatedFiles = {};
    Object.keys(files).forEach((fileID) => {
      const uploadedFile = files[fileID].data;
      const blob = uploadedFile.slice(0, uploadedFile.size, uploadedFile.type);
      const fileToSave = new File([blob], `${date}_${fileName}`, {
        type: 'image/png',
      });

      // @ts-ignore
      updatedFiles[fileID] = {
        ...files[fileID],
        data: fileToSave,
        name: `${date}_${fileName}`,
        meta: {
          ...files[fileID].meta,
          name: `${date}_${fileName}`,
        },
      };
    });
    return updatedFiles;
  };

  const uppy = useUppy(() => {
    const headers = {
      'x-page': getPageUrl(),
    };

    const corsHeaders = {
      'Access-Control-Allow-Origin': '*',
    };

    const companionUrl = 'https://companion.uppy.io';
    return (
      new Uppy({
        meta: { type: 'image' },
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
          maxFileSize: 20000000, // 20MB
        },
        onBeforeUpload: beforeUpload,
      })
        // .use(GoogleDrive, { companionUrl, companionHeaders: corsHeaders })
        // .use(Dropbox, { companionUrl, companionHeaders: corsHeaders })
        // .use(Instagram, { companionUrl, companionHeaders: corsHeaders })
        .use(ImageEditor, imageEditorSettings)
        .use(XHRUpload, {
          withCredentials: true,
          endpoint: `${API_ROOT}/upload/image`,
          headers,
          formData: true,
          fieldName: fieldName || 'image',
        })
    );
  });

  uppy.on('complete', (result) => {
    const publicId = result.successful[0].response?.body.publicId;
    onFinished(publicId as string);
  });

  return (
    <div>
      {isNotModal ? (
        <Dashboard
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          plugins={[
            // 'Instagram',
            // 'GoogleDrive',
            // 'Dropbox',
            'ImageEditor',
          ]}
        />
      ) : (
        <>
          {buttonToRender ? (
            <div onClick={() => setOpenModal(true)}>{buttonToRender}</div>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={() => setOpenModal(true)}
              >
                Select File
              </Button>
              {!imageUploaded && <RequiredLabel> * Required</RequiredLabel>}
            </>
          )}

          <DashboardModal
            uppy={uppy}
            closeModalOnClickOutside
            closeAfterFinish
            proudlyDisplayPoweredByUppy={false}
            open={openModal}
            onRequestClose={() => {
              uppy.reset();
              setOpenModal(false);
            }}
            plugins={[
              // 'Instagram',
              // 'GoogleDrive',
              // 'Dropbox',
              'ImageEditor',
            ]}
          />
        </>
      )}
    </div>
  );
};

export default UploadImage;
