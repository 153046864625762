import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { TextField, StandardTextFieldProps } from '@material-ui/core';

interface TextFieldPropsBase
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'value' | 'error' | 'variant' | 'helperText'
  > {
  readonly name: string;
  readonly placeholder?: string;
}

type TextFieldProps = TextFieldPropsBase & FieldAttributes<{}>;

const FormTextField: React.FC<TextFieldProps> = (props) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      fullWidth
      helperText={errorText}
      error={!!errorText}
      margin="normal"
      variant="outlined"
    />
  );
};

export default FormTextField;
