import React, { useMemo } from 'react';
import { CreatorAnalyticsResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Column } from 'react-table';
import { api, Methods } from 'services/api';
import { Table } from 'components/Table';
import { Loading } from 'components/Loading';
import { formatPrice } from 'util/helpers';

const CreatorAnalyticsTable: React.FC = () => {
  const { isLoading, data: creatorList } = useQuery<CreatorAnalyticsResponse[]>(
    'creatorList',
    async () =>
      api<CreatorAnalyticsResponse[]>(Methods.get, '/analytics/creators'),
  );

  const columns = useMemo<Column<CreatorAnalyticsResponse>[]>(
    () => [
      {
        Header: 'Creator',
        accessor: 'name',
      },
      {
        Header: 'Live Classes',
        accessor: 'numberOfLiveClasses',
      },
      {
        Header: 'Sign Ups',
        accessor: 'numberOfSignUps',
      },
      {
        Header: 'Drop In Earnings',
        accessor: 'earnedFromDropIns',
        Cell: (info: any) => formatPrice(info.row.original.earnedFromDropIns),
      },
      {
        Header: 'Workouts',
        accessor: 'numberOfWorkouts',
      },
    ],
    [],
  );

  const data = useMemo<CreatorAnalyticsResponse[]>(
    () => (creatorList ? creatorList : []),
    [creatorList],
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table<CreatorAnalyticsResponse> columns={columns} data={data} />
      )}
    </div>
  );
};

export default CreatorAnalyticsTable;
