import { ProgramType } from '@solin-fitness/types';
import CloudinaryImage from 'components/CloudinaryImage';
import useThemeContext from 'hooks/useThemeContext';
import React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { useState, useEffect } from 'react';
import { getPageUrl } from 'services/auth';
import { editProgram } from 'queries/programs';
import { useMutation, useQueryClient } from 'react-query';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 30px;
  color: #fff;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.75);
  letter-spacing: 1px;
  padding: 12px;
  text-align: center;
`;

const Chip = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: var(--color, ${(p) => p.theme.main.primary});
`;

const CopyWrap = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
`;

const ChipText = styled.p`
  font-family: 'Raleway';
  font-size: 16px;
  color: hsl(200deg, 100%, 20%);
  padding: 10px 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const PublishWrapper = styled.div`
  background: white;
  padding: 2px 8px;
  border-radius: 30px;
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 14px;
`;

const Button = styled.button`
  border: none;
  color: ${(p) => p.theme.main.primary};
  padding: 10px 14px;
  border-radius: 30px;
  transition: 200ms;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const PublishButtonText = styled.p`
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 12px;
  display: flex;
  gap: 12px;
  width: 100%;
`;

interface Props {
  title: string;
  thumbnail: string;
  type: ProgramType;
  handleClick: () => void;
  published?: boolean;
  id?: number;
  handleViewPurchases?: () => void;
}
const REACT_APP_SOLIN_STREAM_URL = process.env.REACT_APP_SOLIN_STREAM_URL;

const ProgramCard: React.FC<Props> = ({
  title,
  thumbnail,
  type,
  handleClick,
  published,
  id,
  handleViewPurchases,
}) => {
  const [publishedToggle, setPublishedToggle] = useState<boolean>(!!published);
  const [copiedLink, setCopiedLink] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const xPage = getPageUrl();

  const theme = useThemeContext();
  const chipColor =
    type === ProgramType.PROGRAM
      ? theme.main.lightPrimary
      : theme.main.lightSecondary;

  useEffect(() => {
    if (copiedLink) {
      setTimeout(() => {
        if (navigator) {
          const page = getPageUrl();
          // todo: maybe move this to an env var if we care to
          const path = `https://solin.stream/${page}/program/${id}`;
          navigator.clipboard.writeText(path);
          setCopiedLink(false);
        }
      }, 500);
    }
  }, [copiedLink]);

  const handleTogglePublished = async () => {
    setPublishedToggle(!publishedToggle);
    await updateProgram.mutateAsync();
  };

  const updateProgram = useMutation(
    () => editProgram(id as number, { published: !publishedToggle }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('programs');
      },
    },
  );

  return (
    <Container onClick={handleClick}>
      <CloudinaryImage
        publicId={thumbnail}
        height={300}
        style={{
          objectFit: 'cover',
          filter: 'brightness(0.9)',
        }}
      />
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <CopyWrap>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setCopiedLink(true);
          }}
        >
          {copiedLink ? 'Copied' : 'Copy link'}
        </Button>
      </CopyWrap>
      <Chip style={{ '--color': chipColor }}>
        <ChipText>{type}</ChipText>
      </Chip>
      {!!id ? (
        <BottomContainer>
          <a
            href={`${REACT_APP_SOLIN_STREAM_URL}${xPage}/program/${id}/community`}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            <Button>Community Chat</Button>
          </a>
          <div style={{ flex: 1 }} />

          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleViewPurchases?.();
            }}
          >
            Purchases
          </Button>
          <PublishWrapper onClick={(e) => e.stopPropagation()}>
            <PublishButtonText>
              {publishedToggle ? 'Published' : 'Not Published'}
            </PublishButtonText>
            <Switch
              checked={publishedToggle}
              size="medium"
              onChange={handleTogglePublished}
              color="primary"
            />
          </PublishWrapper>
        </BottomContainer>
      ) : null}
    </Container>
  );
};
export default ProgramCard;
