import { api, Methods } from 'services/api';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';

type ResourceId = string;

const appID = process.env.REACT_APP_AGORA_APP_ID || '';
const customApiRoot = 'https://api.agora.io';

const agoraCustomerId = '62890fdfa3c6457bb641145e69f22ba2'; // TODO move to .env
const agoraCustomerSecret = '8a51ba2744ac4c37943c805607f75cc1'; // TODO move to .env
const base64Encoded = btoa(`${agoraCustomerId}:${agoraCustomerSecret}`);
const customAuthHeader = `Basic ${base64Encoded}`;

const mode = 'mix';

export const getResourceId = async (
  channelName: string,
  uid: string,
): Promise<ResourceId | null> => {
  const body = {
    cname: channelName,
    uid: `${uid}`,
    clientRequest: {
      resourceExpiredHour: 24,
    },
  };
  return api<any>(
    Methods.post,
    `/v1/apps/${appID}/cloud_recording/acquire`,
    body,
    {
      customAuthHeader,
      customApiRoot,
    },
  ).then((res) => res.resourceId as ResourceId);
};

interface StartResponse {
  sid: string;
  resourceId: string;
}

const transcodingConfigOpts = {
  '720p_3': {
    width: 1280,
    height: 720,
    fps: 30,
    bitrate: 1710,
  },
  '1080p_1': {
    width: 1920,
    height: 1080,
    fps: 60,
    bitrate: 6500,
  },
  '1440p_1': {
    width: 1920,
    height: 1080,
    fps: 60,
    bitrate: 6500,
  },
};
export const startRecording = async (
  channelName: string,
  uid: string,
  resourceId: string,
  hostId: string,
  trainerData: {
    firstName: string;
    lastName: string;
    id: number | string;
  },
  videoQuality: '1080p_1' | '720p_3' | '1440p_1',
): Promise<StartResponse | null> => {
  const date = new Date();

  const trainerWithouSpacesAndSpecialChars = {
    ...trainerData,
    firstName: trainerData.firstName
      .replace(/ /g, '')
      .replace(/[^a-zA-Z ]/g, ''),
    lastName: trainerData.lastName.replace(/ /g, '').replace(/[^a-zA-Z ]/g, ''),
  };
  const trainer = trainerWithouSpacesAndSpecialChars;

  const fileNamePrefix = [
    `${trainer.firstName}${
      trainer.lastName === ' ' ? 'NoLastName' : trainer.lastName
    }${trainer.id}`,
    `${channelName}id${DateTime.fromJSDate(date).toFormat('LLLdhhmm')}`,
  ];

  const body = {
    cname: `${channelName}`,
    uid: `${uid}`,
    clientRequest: {
      recordingConfig: {
        maxIdleTime: 30,
        streamTypes: 2,
        channelType: 0,
        videoStreamType: 0,
        subscribeVideoUids: [`${hostId}`],
        subscribeAudioUids: [`${hostId}`],
        subscribeUidGroup: 0,
        transcodingConfig: transcodingConfigOpts[videoQuality],
      },
      storageConfig: {
        vendor: 1,
        region: 2,
        bucket: 'solintv',
        accessKey: 'AKIAJPZOTM3TBTMEOFEA', // TODO move to .env
        secretKey: '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', // TODO move to .env
        fileNamePrefix,
      },
    },
  };

  return api<StartResponse>(
    Methods.post,
    `/v1/apps/${appID}/cloud_recording/resourceid/${resourceId}/mode/${mode}/start`,
    body,
    {
      customAuthHeader,
      customApiRoot,
    },
  ).then((res) => res);
};

interface StopResponse {
  resourceId: string;
  sid: string;
  serverResponse: {
    fileList: string;
    fileListMode: string;
    uploadingStatus: 'uploaded' | string;
  };
}

export const stopRecordingAndCreateWorkout = async (
  resourceId: string,
  sid: string,
  channelName: string,
  uid: string,
  workoutId: number,
) => {
  const stopResponse = await stopRecording(resourceId, sid, channelName, uid);

  if (stopResponse) {
    await createWorkoutFromRecording(
      workoutId,
      stopResponse.serverResponse.fileList,
    );
    window.location.reload();
  }
};

export const stopRecording = async (
  resourceId: string,
  sid: string,
  channelName: string,
  uid: string,
): Promise<StopResponse | null> => {
  const body = {
    cname: `${channelName}`,
    uid: `${uid}`,
    clientRequest: {},
  };

  return api<StopResponse>(
    Methods.post,
    `/v1/apps/${appID}/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/${mode}/stop`,
    body,
    {
      customAuthHeader,
      customApiRoot,
    },
  ).then((res) => res);
};

type createWorkoutFromRecResponse = any;

export const createWorkoutFromRecording = async (
  liveWorkoutId: number,
  fileName: string,
): Promise<createWorkoutFromRecResponse> => {
  const body = {
    id: liveWorkoutId,
    fileName,
  };
  return api<createWorkoutFromRecResponse>(
    Methods.post,
    '/workouts/create-from-recording',
    body,
  ).then((res) => res);
};
