import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import Layout from 'components/Layout';
import { FormTextField } from 'components/FormTextField';
import * as S from 'styles/login-live.styles';
import * as I from 'components/shared/Icons';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import * as Colors from 'constants/theme';
import { BtnSelect } from 'components/BtnSelect';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import * as yup from 'yup';
import { api, Methods } from 'services/api';
import { uploadImage } from 'queries/file-uploads';
import { MANAGE_WORKOUTS } from 'constants/routes';
import { PRIMARY } from 'constants/theme';
import { WorkoutResponse } from '@solin-fitness/types';
import { useLocation } from 'react-router-dom';
import { Page } from 'types';
import { getPage } from 'queries/page';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CloudinaryImage from 'components/CloudinaryImage';
import UploadImage from 'components/UploadImageModal';
import useAuthStore from 'hooks/useAuthStore';

export const Spacer = styled.div`
  margin-top: 20px;
`;

export const ImagePreview = styled.div`
  margin-bottom: 20px;
  object-fit: contain;
  background-color: #000000;
`;

interface InitialValues {
  thumbnailFile: string | undefined;
  thumbnailPreview: string | undefined;
  title: string;
  description: string;
  descriptionTwo: string | undefined;
  spotifyLink: string;
  length: number;
  tags: string[];
  videoUrl: string;
}

interface EditWorkoutRequest {
  title?: string;
  description?: string;
  descriptionTwo?: string;
  spotifyLink?: string;
  type?: string;
  tags?: string[];
  thumbnailPublicId?: string;
  length?: number;
}

const validationSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  tags: yup.array().optional(),
  length: yup.number().min(0).max(120).optional(),
  descriptionTwo: yup.string().optional().nullable(),
  spotifyLink: yup.string().optional().nullable(),
  thumbnailFile: yup.string().optional().nullable(),
  thumbnailPreview: yup.string().optional().nullable(),
});

const EditWorkoutVideo = (): React.ReactElement => {
  const location = useLocation<{ editWorkoutData: WorkoutResponse }>();
  const editWorkoutData = location.state.editWorkoutData;
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const pageQuery = useQuery<Page, Error>('creatorPage', () => getPage());
  const config = pageQuery.data?.config;
  const { userId } = useAuthStore();

  const initialWorkoutValues: InitialValues = {
    thumbnailFile: editWorkoutData
      ? editWorkoutData.thumbnailPublicId
      : undefined,
    thumbnailPreview: editWorkoutData
      ? editWorkoutData.thumbnailPublicId
      : undefined,
    title: editWorkoutData ? editWorkoutData.title : '',
    description: editWorkoutData ? editWorkoutData.description : '',
    descriptionTwo:
      editWorkoutData && editWorkoutData.descriptionTwo
        ? editWorkoutData.descriptionTwo
        : undefined,
    spotifyLink: editWorkoutData ? editWorkoutData.spotifyLink : '',
    length: editWorkoutData ? editWorkoutData.length : 60,
    tags: editWorkoutData ? editWorkoutData.tags : [],
    videoUrl: editWorkoutData ? editWorkoutData.videos[0].videoUrl : '',
  };

  const handleImage = useMutation(
    (fileToUpload: File) => uploadImage(fileToUpload),
    {
      onMutate: () => setIsUploading(true),
      onSuccess: ({ publicId }) => {
        setIsUploading(false);
      },
      onError: (err: any) => {
        setIsUploading(false);
        setError(err?.message || 'Something went wrong 😔');
      },
    },
  );

  const editWorkout = useMutation(
    async (request: { videoUrl: string; workout: EditWorkoutRequest }) =>
      await api(Methods.patch, `/workouts/${editWorkoutData?.id}`, request),
    {
      onError: (editError: any) => {
        console.log(editError);
        if (editError) {
          setError(
            editError?.message ||
              'Something went wrong and the workout was not updated. Please try again and contact your account manager if the issue continues.',
          );
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('workouts');
      },
    },
  );

  const history = useHistory();

  if (!config) {
    return (
      <Layout title={'Editing Workout'}>
        <div
          style={{
            paddingTop: '10%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      </Layout>
    );
  }

  return (
    <Layout title={'Editing Workout'}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => history.push(MANAGE_WORKOUTS)}
      >
        <I.ChevronLeft lg color={PRIMARY} />
        <div style={{ marginLeft: 12 }}>Back</div>
      </div>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <S.Container>
        <Formik
          validateOnChange={true}
          initialValues={initialWorkoutValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            // edit workout
            const editWorkoutRequest: EditWorkoutRequest = {
              title: data.title,
              description: data.description,
              descriptionTwo: data.descriptionTwo,
              tags: data.tags,
              length: data.length,
              spotifyLink: data.spotifyLink || undefined,
              thumbnailPublicId: data.thumbnailFile || undefined,
            };

            await editWorkout.mutateAsync({
              workout: editWorkoutRequest,
              videoUrl: data.videoUrl,
            });

            setSubmitting(false);
            resetForm();
            history.push(MANAGE_WORKOUTS);
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => (
            <Form>
              <>
                <S.WrapCol>
                  <Spacer />

                  <>
                    <FormTextField
                      label="Title"
                      type="text"
                      name="title"
                      id="workoutTitle"
                      value={values.title}
                    />
                    <Spacer />
                  </>

                  <>
                    <Typography
                      style={{
                        color: Colors.themeColors.textColor,
                        fontSize: 16,
                      }}
                    >
                      Description
                    </Typography>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          'bulletedList',
                          'numberedList',
                          'blockQuote',
                        ],
                        link: {
                          addTargetToExternalLinks: true,
                        },
                      }}
                      data={values.description || ''}
                      onChange={(event: Event, editor: ClassicEditor) => {
                        const data = editor.getData();
                        setFieldValue('description', data);
                      }}
                    />
                    <Spacer />
                  </>

                  <>
                    <FormTextField
                      value={values.spotifyLink}
                      label="Music Playlist Link"
                      id="workoutSpotify"
                      name="spotifyLink"
                      multiline={true}
                    />
                    <Spacer />
                  </>

                  <>
                    <FormTextField
                      value={values.videoUrl}
                      label="Video Url"
                      name="videoUrl"
                      id="workoutVideoUrl"
                    />
                    <Spacer />
                  </>

                  <>
                    <Spacer />
                    <Typography
                      id="discrete-slider"
                      gutterBottom
                      style={{
                        color: Colors.themeColors.textColor,
                        fontSize: 16,
                      }}
                    >
                      Duration (in minutes)
                    </Typography>
                    <Slider
                      value={values.length}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={120}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: number | number[],
                      ) => setFieldValue('length', value)}
                    />
                  </>

                  <>
                    <Spacer />
                    <BtnSelect
                      options={config?.workoutTags?.map((item: any) => ({
                        key: item,
                        value: item,
                      }))}
                      values={values.tags || []}
                      testId="workoutType"
                      label="Type (select all that apply)"
                      onClick={(selectValues): void =>
                        setFieldValue('tags', selectValues)
                      }
                    />
                  </>

                  <>
                    {isUploading ? (
                      <CircularProgress
                        style={{ color: Colors.GRAYS.medium }}
                      />
                    ) : (
                      values.thumbnailPreview && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <ImagePreview>
                            <CloudinaryImage
                              publicId={values.thumbnailPreview || ''}
                              width={350}
                              height={200}
                              alt={'preview thumbnail'}
                              fetchFormat={'auto'}
                              quality={'auto'}
                              crop={'scale'}
                            />
                          </ImagePreview>
                          <div style={{ paddingRight: 20 }} />
                          <IconButton
                            color="primary"
                            aria-label="remove thumbnail"
                            onClick={() => {
                              setFieldValue('thumbnailPreview', null);
                              setFieldValue('thumbnailFile', null);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      )
                    )}

                    <UploadImage
                      fileName="drop"
                      onFinished={(publicId) => {
                        setFieldValue('thumbnailFile', publicId);
                        setFieldValue('thumbnailPreview', publicId);
                      }}
                      imageUploaded={!!values.thumbnailPreview}
                    />

                    <S.Spacer style={{ marginBottom: 50 }} />
                  </>

                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{
                      borderRadius: 35,
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress color="inherit" size="2rem" />
                    ) : (
                      <Typography variant="h6">Save Workout</Typography>
                    )}
                  </Button>
                  <Spacer />
                </S.WrapCol>
              </>
            </Form>
          )}
        </Formik>
      </S.Container>
    </Layout>
  );
};

export default EditWorkoutVideo;
