import styled from 'styled-components';
import { TABLET } from '../constants/measurements';
import * as Colors from 'constants/theme';
import { isTablet } from 'react-device-detect';

const ASPECT_RATIO = 16 / 9;

const vh = typeof window !== 'undefined' ? window.innerHeight * 0.01 : 0;

const PHONE = TABLET;

export const TrainerPlaceholer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  span {
    background: white;
    padding: 10px;
    color: black;
  }
`;

export const NextPersonWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.6);
  width: 45px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const NextPersonIcon = styled.img`
  width: 20px;
`;

export const ContainerLive = styled.div<{ isMobileHorizonal: boolean }>`
  width: 100vw;
  height: ${({ isMobileHorizonal }) =>
    isMobileHorizonal ? 'auto' : `calc(${vh}px * 100)`};
  background: rgba(0, 0, 0, 0.65);
  position: relative;
  display: flex;
  flex-direction: ${({ isMobileHorizonal }) =>
    isMobileHorizonal ? 'column' : 'row'};

  .fullscreen {
    width: 100%;
    height: 40%;
    display: flex;
  }

  ${({ isMobileHorizonal }) => {
    if (isTablet && isMobileHorizonal) {
      return `
      flex-direction: column;
      height: calc(${vh}px * 100);

      .fullscreen {
        width: 100%;
        height: 100%;
        display: flex;
      }
      `;
    }
  }}

  @media (max-width: ${PHONE}) {
    flex-direction: column;
  }
`;

export const AspectRatio = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
`;

export const AspectRatioInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const LoaderWrapLive = styled.div`
  width: 100vw;
  height: calc(${vh}px * 100);
  display: flex;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
`;

// Made isChatExpanded usage redundant here, it's for testing. (previous value: 250px)
export const BottomBanner = styled.div<{
  isVideoFullscreen: boolean;
  isChatExpanded: boolean;
}>`
  width: ${({ isVideoFullscreen, isChatExpanded }) =>
    isVideoFullscreen
      ? '100%'
      : `calc(100% - ${isChatExpanded ? '600px' : '600px'})`};
  margin-left: ${({ isVideoFullscreen }) => (isVideoFullscreen ? 0 : '250px')};
  height: 80px;
  background: ${Colors.themeColors.liveBottomBanner};
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 2;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  ${() => {
    if (isTablet) {
      return `
      display: none;
      `;
    }
  }}

  @media (max-width: ${PHONE}) {
    display: none;
  }
`;

export const MeetingName = styled.div`
  width: 300px;
  padding: 0 10px;
  color: white;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100px;
  margin: 0;
`;

export const ControlsWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconControlWrap = styled.div`
  margin: 0 13px;
  border-radius: 50%;
`;

export const RightControls = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
}
`;

export const TopBannerSection = styled.div`
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const TopBanner = styled.div`
  width: 230px;
  height: 50px;
  background: white;
  position: absolute;
  top: 0px;
  right: 0;
  border-bottom-left-radius: 15px;
  display: flex;
  z-index: 2;
`;

export const MyVideo = styled.div`
  background: darkgrey;
  width: 90px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SideViewWrap = styled.div<{ isVideoFullscreen: boolean }>`
  background: white;
  width: 250px;
  height: calc(${vh}px * 100);
  z-index: 2;
  display: ${({ isVideoFullscreen }) => (isVideoFullscreen ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);

  ${() => {
    if (isTablet) {
      return `
       display: none;
        `;
    }
  }}

  @media (max-width: ${PHONE}) {
    display: none;
  }
`;

export const ChatWrap = styled.div<{
  isVideoFullscreen: boolean;
  isMobileFullscreen: boolean;
}>`
  background: #3c3c3c;
  width: 350px;
  height: calc(${vh}px * 100);
  z-index: 2;
  display: ${({ isVideoFullscreen, isMobileFullscreen }) =>
    isVideoFullscreen || isMobileFullscreen ? 'none' : 'flex'};
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);

  ${() => {
    if (isTablet) {
      return `
      height: 60%;
      width: 100%;
      `;
    }
  }}

  @media (max-width: ${PHONE}) {
    height: 60%;
    width: 100%;
  }
`;

export const ChatTitle = styled.div`
  color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: -44px;
  @media (max-width: ${PHONE}) {
    margin-left: 0;
  }
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  background: ${Colors.themeColors.liveBottomBanner};
`;

export const IconStyle = { color: 'white' };

export const BarImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0;
`;

export const ClosedChat = styled.div`
  background: ${Colors.themeColors.liveBottomBanner};
  width: 350px;
  height: calc(${vh}px * 100);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  z-index: 2;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
`;

export const MobileControls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: none;
  ${() => {
    if (isTablet) {
      return `
      display: block;
      `;
    }
  }}

  @media (max-width: ${PHONE}) {
    display: block;
  }
`;

export const MobileControlsTop = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
`;

export const MobileControlsBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

export const TapToUnmute = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 8px;
  display: flex;
  position: absolute;
  z-index: 9999;
  top: 20px;
  color: white;
  align-items: center;
`;

export const MainPlayer = styled.div<{
  isMobileFullscreen: boolean;
  isHost: boolean | undefined;
}>`
  z-index: ${({ isMobileFullscreen }) => (isMobileFullscreen ? '-1' : '1')};
  width: 100%;
  height: ${({ isHost }) => (isHost ? '100%' : '160px')};
  min-height: 160px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  .agora_video_player {
    border-radius: 20px;
  }
  div[class~='agora-video-player-track'] {
    border-radius: 20px !important;
  }
  & > * {
    border-radius: 20px;
  }
`;

export const PersonName = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  color: white;
`;

export const MicOff = styled.div`
  position: absolute;
  z-index: 3;
  background: #fe5353;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  background: #fe5353;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const VideoWrap = styled.div<{
  isVideoFullscreen: boolean;
  width: number;
  height: number;
  isChatExpanded: boolean;
  isMobileFullscreen: boolean;
  isMobileHorizonal: boolean;
}>`
  ${({ isVideoFullscreen, width }) => {
    const widthMinus = 600;
    const innerViewWidth = isVideoFullscreen
      ? width - widthMinus / 1.75
      : width - widthMinus;

    return `
      width: ${innerViewWidth}px;
      max-width: ${innerViewWidth}px;
      height: ${innerViewWidth / ASPECT_RATIO}px;
      max-height: ${innerViewWidth / ASPECT_RATIO}px;
      margin-left: ${isVideoFullscreen ? 160 : 0}px;
    `;
  }}
  flex: 1;
  align-self: center;
  margin-bottom: 90px;
  display: flex;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;

  justify-content: center;
  position: relative;

  ${({ isMobileHorizonal }) => {
    if (isTablet && isMobileHorizonal) {
      return `
      margin: 0 !important;
      height: 100vh !important;
      width: 100vw !important;
      transform: rotate(0deg) !important;
      max-height: 100vh !important;
      max-width: 100vw !important;
      `;
    }
  }}

  @media (max-width: ${TABLET}) {
    height: 100%;
    width: 100%;
    margin: 0;
    max-height: 100vh;
    max-width: 100vw;

    ${({ isMobileFullscreen, isMobileHorizonal }) => {
      if (isMobileHorizonal) {
        return `
        margin: 0;
        height: 100vh;
        width: 100vw;
        transform: rotate(0deg);
        `;
      }
      if (isMobileFullscreen) {
        return `
        height: 89vw;
        width: 100vh;
        transform: rotate(90deg);
        margin: 0;
        transform-origin: bottom;
        margin-left: -47vh;
      `;
      }
    }}
  }
`;

export const OtherStreamWrap = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;
  position: absolute;
  top: 0px;
  z-index: 2;
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  background: ${Colors.themeColors.liveBackground};
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  flex-direction: column;
  overflow: scroll;
`;

export const AudienceList = styled.div`
  margin-bottom: 15px;
`;

export const AudienceHeader = styled.div`
  display: flex;
  align-items: center;
  background: ${Colors.themeColors.liveBackground};
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: ${Colors.themeColors.textColor};
`;

export const AudienceMember = styled.div`
  display: flex;
  align-items: center;
  background: ${Colors.themeColors.liveBackground};
  color: ${Colors.themeColors.textColor};
  padding: 10px;
`;

export const AudienceImage = styled.div`
  position: relative;
  margin-right: 20px;
  margin-left: -10px;
  width: 40px;
  height: 40px;
`;
