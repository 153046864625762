import CloudinaryImage from 'components/CloudinaryImage';
import styled from 'styled-components';

const Container = styled.div`
  flex: 0 1 33.3333%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 36px;
`;

const Text = styled.div`
  font-family: 'Raleway';
  font-size: 16px;
  color: #000;
  text-align: center;
  padding: 0 16px;
`;

const ImageWrap = styled.div`
  max-width: 150px;
  max-height: 150px;
  flex-shrink: 0;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  align-self: center;
  overflow: hidden;
`;

const DeleteButton = styled.button`
  margin-top: 16px;
  border: none;
  height: 44px;
  background-color: none;
  color: ${(p) => p.theme.main.secondary};
  border-radius: 40px;
  cursor: pointer;
  transition: 100ms;
  font-family: 'Brother';
  font-size: 16px;
  font-weight: medium;
  width: fit-content;
  padding: 0 18px;
  align-self: center;

  &:hover {
    color: #fff;
    background-color: ${(p) => p.theme.main.secondary};
  }
`;

interface Props {
  image: string;
  name: string;
  testimonial: string;
  onDelete: () => void;
}

const ProgramTestimonial = ({ image, name, testimonial, onDelete }: Props) => (
  <Container>
    <ImageWrap>
      <CloudinaryImage publicId={image} width={150} height={150} crop="fit" />
    </ImageWrap>
    <Text>{name}</Text>
    <Text>
      <span style={{ fontStyle: 'italic' }}>{`"${testimonial}"`}</span>
    </Text>
    <DeleteButton type="button" onClick={() => onDelete()}>
      Delete
    </DeleteButton>
  </Container>
);

export default ProgramTestimonial;
