import { WorkoutResponse } from '@solin-fitness/types';
import { Row } from 'components/Row';
import React, { useMemo, useRef } from 'react';
import * as S from '../DropCalendar.styles';
import * as I from 'components/shared/Icons';
import { PRIMARY } from 'constants/theme';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import WorkoutTile from '../WorkoutTile/WorkoutTile';

interface Props {
  workouts: WorkoutResponse[];
}

const WorkoutCarousel: React.FC<Props> = ({ workouts }) => {
  const { isScheduling, workoutPageIndex, handleWorkoutPageIndex } =
    useWorkoutDrop();
  const workoutCarouselRef = useRef<Carousel>(null);
  const pageLength = useMemo(() => Math.ceil(workouts.length / 3), [workouts]);

  return (
    <div style={{ position: 'relative' }}>
      {workouts.length ? (
        <>
          <Carousel
            ref={workoutCarouselRef}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 3,
              },
            }}
            swipeable={false}
            draggable={false}
            arrows={false}
          >
            {workouts.map((val) => (
              <div key={val.id} style={{ padding: 16 }}>
                <WorkoutTile workout={val} canSchedule={!isScheduling} />
              </div>
            ))}
          </Carousel>
          <S.LeftAbsoluteContainer>
            <S.ArrowButton
              style={{ left: -25, opacity: workoutPageIndex === 1 ? 0.5 : 1 }}
              disabled={workoutPageIndex === 1}
              onClick={() => {
                if (workoutCarouselRef.current) {
                  const currentSlide =
                    workoutCarouselRef.current.state.currentSlide;
                  workoutCarouselRef.current.goToSlide(currentSlide - 3);
                  handleWorkoutPageIndex(workoutPageIndex - 1);
                }
              }}
            >
              <I.ChevronLeft color={'#CDCDCD'} style={{ marginLeft: -4 }} />
            </S.ArrowButton>
          </S.LeftAbsoluteContainer>
          <S.RightAbsoluteContainer>
            <S.ArrowButton
              isRight
              disabled={workoutPageIndex === pageLength}
              style={{
                right: -25,
                opacity: workoutPageIndex === pageLength ? 0.5 : 1,
              }}
              onClick={() => {
                if (workoutCarouselRef.current) {
                  const currentSlide =
                    workoutCarouselRef.current.state.currentSlide;
                  workoutCarouselRef.current.goToSlide(currentSlide + 3);
                  handleWorkoutPageIndex(workoutPageIndex + 1);
                }
              }}
            >
              <I.ChevronRight color={'#CDCDCD'} style={{ marginRight: -4 }} />
            </S.ArrowButton>
          </S.RightAbsoluteContainer>
        </>
      ) : (
        <Row>
          <S.EmptyWorkoutTile />
          <S.EmptyWorkoutTile>
            <I.AlertCircleIcon color="hsla(33, 86%, 65%)" />
            <p>no matching results</p>
          </S.EmptyWorkoutTile>
          <S.EmptyWorkoutTile />
        </Row>
      )}
    </div>
  );
};

export default WorkoutCarousel;
