import { useState } from 'react';
import * as S from '../styles/Login.styles';
import { Text, Button } from 'components';
import { useMutation } from 'react-query';
import { getLogin } from 'queries/auth';
import { useHistory } from 'react-router-dom';
import { savePageUrl, saveUser } from 'services/auth';
import * as Routes from 'constants/routes';
import { fetchUserProfile } from 'queries/user';
import { Formik } from 'formik';
import { FormTextField } from 'components/FormTextField';
import { CircularProgress, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import LogoSignUp from 'assets/images/logoSignUp.svg';
import useAuthStore from 'hooks/useAuthStore';
import * as Sentry from '@sentry/browser';

const initialLoginValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().required().min(1),
  password: yup.string().required().min(1),
});

const Login = () => {
  const [error, setError] = useState('');
  const history = useHistory();
  const { logUserIn } = useAuthStore();

  const handleLogin = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      getLogin(email, password),
    {
      onError: (err: any) => setError(err?.message || 'Please notify devs 🙈'),
    },
  );

  return (
    <S.Container>
      <S.Logo src={LogoSignUp} />
      <Formik
        initialValues={initialLoginValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          const {
            data: { userId },
            pageUrl,
          } = await handleLogin.mutateAsync({
            email: data.email,
            password: data.password,
          });

          const user = await fetchUserProfile();
          Sentry.setUser({ email: user.email, id: `${user.id}` });
          saveUser(user);
          savePageUrl(pageUrl);
          logUserIn(userId);
          setSubmitting(false);
          resetForm();
          history.push(Routes.CREATOR_SCHEDULE);
        }}
      >
        {({ isSubmitting }) => (
          <S.FormContainer>
            <Text variant="h5" style={{ marginTop: 20, marginBottom: 36 }}>
              Log in to your account
            </Text>
            {!!error && <Aside isError={true}>{error}</Aside>}
            <FormTextField
              placeholder="Email"
              name="email"
              style={{ marginTop: 20 }}
            />
            <FormTextField
              name="password"
              placeholder="Password"
              style={{ marginTop: 20 }}
              type="password"
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: 20,
                borderRadius: 35,
                height: 75,
                fontFamily: 'Raleway',
                fontSize: 36,
              }}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="subtitle1">Login</Typography>
              )}
            </Button>
          </S.FormContainer>
        )}
      </Formik>
    </S.Container>
  );
};

export default Login;
