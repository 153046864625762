import styled from 'styled-components';
import { Column } from 'components/Column';
import { Row } from 'components/Row';

interface SearchProps {
  hasSearch: boolean;
}

export const SearchContainerInner = styled(Row)<SearchProps>`
  border-radius: 40px;
  background-color: ${({ hasSearch, theme }) =>
    hasSearch ? theme.main.lightSecondary : ''};
  width: 100%;
  height: 100%;
`;

export const ContainerOuter = styled.div`
  max-width: min-content;
  border-radius: 40px;
  background-color: #fff;
  margin: 12px;
  margin-bottom: 4px;
  padding: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const SearchInput = styled.input<SearchProps>`
  border: none;
  outline: none;

  border-radius: 40px;
  padding: 0 36px;
  max-width: 100%;
  font-family: Raleway;
  font-weight: 500;
  font-size: 26px;
  line-height: 19px;
  color: ${({ hasSearch, theme }) =>
    !hasSearch ? theme.main.lightSecondary : '#FFF'};
  background-color: ${({ hasSearch, theme }) =>
    hasSearch ? theme.main.lightSecondary : ''};

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.main.lightSecondary};
  }
`;

export const LoaderOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;
