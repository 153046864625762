import { Row } from 'components/Row';
import styled from 'styled-components';

export const ItemWrap = styled.div`
  filter: drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.3));
  border-radius: 20px;
  cursor: move;
`;

export const ItemImage = styled.div`
  img {
    border-radius: 20px;
    object-fit: cover;
    margin-bottom: 0;
  }
`;

export const ItemTitle = styled.div`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.main.primary};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const DraggingContainer = styled(Row)`
  width: 275px;
  height: 175px;
  border-radius: 20px;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.main.primary};
  border: 2px dashed #fff;
  color: ${({ theme }) => theme.main.lightPrimary};
  font-size: 22px;
  font-family: Roboto;
  font-weight: bold;
`;
