import styled from 'styled-components';
import { Row } from 'components/Row';
import { PHONE } from 'constants/measurements';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  border: 2px solid ${({ theme }) => theme.grays.light};
  border-radius: 8px;
  padding: 16px;
`;

export const Logo = styled.div`
  width: 96px;

  img {
    object-fit: contain;
    border: 1.5px dotted ${({ theme }) => theme.grays.medium};
    border-radius: 4px;
    margin-bottom: 0;
  }
`;

export const EditButton = styled.div`
  margin-left: auto;
  margin-bottom: auto;
  padding-left: 6px;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const HeaderWrapper = styled.div`
  float: left;
  max-width: calc(100% - 100px);
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 100px);
  gap: 8px;
`;

export const VideoContainer = styled.div`
  padding-top: 12px;
  @media (max-width: 1100px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const VideoText = styled.div`
  margin: auto;
  width: 40%;
  padding-top: 12px;
  @media (max-width: 1100px) {
    margin-left: auto;
    width: 90%;
  }
`;

export const ThumbnailContainer = styled.div`
  padding-top: 12px;
  @media (max-width: 1300px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ThumbnailText = styled.div`
  margin: auto;
  padding-top: 12px;
  width: 30%;
  @media (max-width: 1300px) {
    padding-left: 20px;
    width: 90%;
  }
`;

export const Bullets = styled.div`
  padding: 18px 36px 0px;
  @media (max-width: 500px) {
    padding: 18px 8px 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: stretch;
  @media (max-width: 500px) {
  }
`;

export const ColorSwab = styled.div<{ color: string }>`
  width: 36px;
  height: 36px;
  border: 2px solid ${({ theme }) => theme.grays.light};
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

export const ColoredRow = styled(Row)`
  height: 96px;
  margin-bottom: -24px;
`;

export const Chip = styled.div<{ primary?: boolean }>`
  min-width: 70px;
  height: 40px;
  background-color: ${({ primary, theme }) =>
    primary ? theme.main.lightPrimary : theme.main.lightSecondary};
  border: 3px solid
    ${({ primary, theme }) =>
      primary ? theme.main.primary : theme.main.secondary};
  border-radius: 20px;
  color: ${({ primary, theme }) =>
    primary ? theme.main.primary : theme.main.secondary};
  margin: 0 8px 8px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
