import { WorkoutResponse } from '@solin-fitness/types';
import Dropdown, { DropdownOption } from 'components/Dropdown/Dropdown';
import { Loading } from 'components/Loading';
import useConfig from 'hooks/useConfig';
import { useGetWorkouts } from 'queries/hooks/useGetWorkouts';
import React, { useState } from 'react';
import styled from 'styled-components';
import OnDemandWorkoutTile from './OnDemandWorkoutTile';

const WorkoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const DEFAULT_VALUE = 'all';
const DEFAULT_LABEL = 'All Clips';

const OnDemandWorkoutPicker = () => {
  const { isLoading, data: config } = useConfig();
  const { isLoading: isLoadingWorkouts, data } = useGetWorkouts();

  const [selectedTag, setSelectedTag] = useState<string | number>(
    DEFAULT_VALUE,
  );

  if (isLoading || isLoadingWorkouts) {
    return <Loading />;
  }

  const sortingOptions: DropdownOption[] = [
    { value: DEFAULT_VALUE, label: DEFAULT_LABEL },
    ...(config?.config.workoutTags.map((tag: string) => ({
      value: tag,
      label: tag,
    })) ?? []),
  ];

  const workouts = data || [];

  const handleSelect = (option: DropdownOption) => {
    setSelectedTag(option.value);
  };

  const filteredWorkouts =
    selectedTag === DEFAULT_VALUE
      ? workouts
      : workouts.filter((w) => w.tags?.includes(selectedTag as string));

  return (
    <WorkoutContainer>
      <div
        style={{ position: 'sticky', top: 0, zIndex: 1, padding: '0 4px 0' }}
      >
        <Dropdown
          options={sortingOptions}
          onSelect={handleSelect}
          showShadow
          defaultOption={sortingOptions[0]}
          wrapStyle={{ backgroundColor: '#fff' }}
        />
      </div>
      {filteredWorkouts?.map((workout, i) => (
        <div style={{ alignSelf: 'center' }} key={workout.id}>
          <OnDemandWorkoutTile workout={workout} />
        </div>
      ))}
    </WorkoutContainer>
  );
};

export default OnDemandWorkoutPicker;
