import * as React from 'react';
import s from 'styled-components';
import { Container } from './Grid';
import { maxWidth, PHONE } from 'constants/measurements';

export const SectionTag = s.section`
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
  ${maxWidth(PHONE)} {
    padding-top: calc(1rem + 2.5vh);
    padding-bottom: calc(1rem + 2.5vh);
  }

`;

interface ISectionProps {
  children: React.ReactNode | React.ReactNodeArray;
  style?: any;
}

/**
 * Wrapper component to space parts of a page out vertically and horizontally
 * via a `<Container />`.
 */
export const Section = ({
  children,
  ...props
}: ISectionProps): React.ReactElement => (
  <SectionTag {...props}>
    <Container>{children}</Container>
  </SectionTag>
);
