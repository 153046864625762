import React, { useState } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './programDragTypes';
import {
  ProgramSessionType,
  ProgramTextSessionRequest,
  ProgramDocumentSessionRequest,
  WorkoutResponse,
  ProgramSessionResponse,
} from '@solin-fitness/types';
import { motion } from 'framer-motion';
import TextProgramSession from './TextProgramSession';
import {
  ProgramSessionEmptyType,
  ProgramSessionInterface,
  ProgramOnDemandSessionRequest,
} from 'context/ProgramBuilderContext';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import CloudinaryImage from 'components/CloudinaryImage';
import TrashIcon from 'components/shared/Icons/Trash';
import { EditIcon } from 'components/shared/Icons/Edit';
import DeleteModal from './DeleteModal';
import DocumentProgramSession from './DocumentProgramSession';
import LiveWorkoutProgramSession from './LiveWorkoutProgramSession';
import useThemeContext from 'hooks/useThemeContext';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.section`
  display: flex;
  gap: 32px;
`;

const SessionContainer = styled.div<{ isOver: boolean }>`
  width: 171px;
  height: 96px;
  border-radius: 20px;
  border: 5px solid ${(p) => p.theme.main.secondary};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);

  transition: 200ms ease-out;
  transform: ${(p) => (p.isOver ? 'scale(1.1)' : undefined)};
`;

const TrashIconWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TrashIconContainer = styled.div`
  padding: 8px;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    transform: scale(1.1);
  }
`;

const ThumbnailImageContainer = styled.div`
  width: 171px;
  height: 96px;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 171px;
  height: 96px;
  border-radius: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1), 0 32px 32px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  filter: brightness(1);
  transition: 200ms ease-out;

  &:hover ${ThumbnailImageContainer} {
    filter: brightness(0.6);
  }

  ${TrashIconWrapper} {
    display: none;
  }

  &:hover ${TrashIconWrapper} {
    display: flex;
  }
`;

const SessionTag = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 25px;
  background: hsl(0deg 0% 0% / 0.7);
  border-radius: 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tag = styled.p`
  font-family: 'Brother';
  font-weight: 500;
  font-size: 14px;
  color: var(--color);
  text-transform: uppercase;
  padding: 0px 8px;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 240px;
  gap: 24px;
`;

const DragAndDropText = styled.p`
  padding: 0 12px;
  font-family: 'Raleway';
  font-size: 16px;
  color: #000;
  line-height: 20px;
`;

const StyledTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  border: none;
  padding: 6px;
`;

const AddButton = styled(motion.button)`
  border: none;
  width: 100%;
  height: 44px;
  border-radius: 40px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.08),
    0 4px 4px rgba(0, 0, 0, 0.06), 0 8px 8px rgba(0, 0, 0, 0.06),
    0 16px 16px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: solid #fff;
  transition: 250ms ease-out;
  cursor: pointer;

  &:hover {
    border: solid ${(p) => p.theme.main.secondary};
  }
`;

const AddButtonText = styled.p`
  padding: 0 16px;
  margin: auto 0;
  color: ${(p) => p.theme.main.secondary};
  font-family: 'Brother';
  font-size: 16px;
  font-weight: medium;
`;

const ProgramSessionContainer = styled.div`
  position: relative;
`;

const Title = styled.h4`
  position: absolute;
  top: -16px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  color: ${(p) => p.theme.main.primary};
  padding: 0 8px;
  max-width: 171px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

interface Props {
  sessionData: ProgramSessionInterface;
  week: number;
  day: number;
  sessionIndex: number;
}

const ProgramSession: React.FC<Props> = ({
  sessionData,
  week,
  day,
  sessionIndex,
}) => {
  const theme = useThemeContext();
  const { handleSession, handleDeleteSession, workoutTags } =
    useProgramBuilder();

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ON_DEMAND_WORKOUT,
      canDrop: () => sessionData.type === ProgramSessionEmptyType.EMPTY,
      drop: (item: WorkoutResponse) => {
        // handle adding session
        const data: ProgramOnDemandSessionRequest = {
          type: ProgramSessionType.ON_DEMAND_WORKOUT,
          workoutId: item.id,
          week,
          day,
          session: sessionIndex,
          thumbnail: item.thumbnailPublicId,
          title: item.title,
        };
        handleSession(data);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [sessionData],
  );

  const [openText, setOpenText] = useState<boolean>(false);
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLiveWorkout, setOpenLiveWorkout] = useState<boolean>(false);

  const handleEdit = () => {
    if (sessionData.type === 'text') {
      setOpenText(true);
    }

    if (sessionData.type === 'document') {
      setOpenDocument(true);
    }
  };

  const handleTextSession = (
    data: Omit<ProgramTextSessionRequest, 'type' | 'week' | 'day' | 'session'>,
    isEditingSession?: boolean,
  ) => {
    handleSession(
      {
        ...data,
        type: ProgramSessionType.TEXT,
        week,
        day,
        session: sessionIndex,
      },
      isEditingSession,
    );
  };

  const handleDocumentSession = (
    data: Omit<
      ProgramDocumentSessionRequest,
      'type' | 'week' | 'day' | 'session'
    >,
    isEditingSession?: boolean,
  ) => {
    handleSession(
      {
        ...data,
        type: ProgramSessionType.DOCUMENT,
        week,
        day,
        session: sessionIndex,
      },
      isEditingSession,
    );
  };

  const renderModals = () => (
    <>
      {openText && (
        <TextProgramSession
          isOpen={openText}
          onCancel={() => setOpenText(false)}
          handleAddSession={handleTextSession}
          editValues={
            sessionData.type === ProgramSessionType.TEXT
              ? (sessionData as ProgramSessionResponse)
              : undefined
          }
        />
      )}
      {openDocument && (
        <DocumentProgramSession
          isOpen={openDocument}
          onCancel={() => setOpenDocument(false)}
          handleAddSession={handleDocumentSession}
          editValues={
            sessionData.type === ProgramSessionType.DOCUMENT
              ? (sessionData as ProgramSessionResponse)
              : undefined
          }
        />
      )}
      {openLiveWorkout && (
        <LiveWorkoutProgramSession
          isOpen={openLiveWorkout}
          onCancel={() => setOpenLiveWorkout(false)}
          workoutTags={workoutTags}
          week={week}
          day={day}
          sessionIndex={sessionIndex}
        />
      )}
    </>
  );
  switch (sessionData.type) {
    case ProgramSessionEmptyType.EMPTY:
      return (
        <>
          {renderModals()}
          <Wrapper>
            <SessionContainer ref={drop} isOver={isOver && canDrop} />
            <SelectionContainer>
              <DragAndDropText>
                Drag and drop from current library OR add new content below
              </DragAndDropText>
              <AddButton
                whileTap={{ scale: 0.95 }}
                onClick={() => setOpenText(true)}
              >
                <AddButtonText>+ Add Text</AddButtonText>
              </AddButton>
              <AddButton
                whileTap={{ scale: 0.95 }}
                onClick={() => setOpenDocument(true)}
              >
                <AddButtonText>+ Add Document</AddButtonText>
              </AddButton>
              <AddButton
                whileTap={{ scale: 0.95 }}
                onClick={() => setOpenLiveWorkout(true)}
              >
                <AddButtonText>+ Add Live Workout</AddButtonText>
              </AddButton>
            </SelectionContainer>
          </Wrapper>
        </>
      );
    case ProgramSessionType.TEXT:
    case ProgramSessionType.ON_DEMAND_WORKOUT:
    case ProgramSessionType.DOCUMENT:
    case ProgramSessionType.LIVE_WORKOUT:
      const sessionTag: { text: string; color: string } =
        sessionData.type === ProgramSessionType.ON_DEMAND_WORKOUT
          ? { text: 'video', color: theme.main.primary }
          : sessionData.type === ProgramSessionType.LIVE_WORKOUT
          ? { text: 'live', color: theme.main.secondary }
          : sessionData.type === ProgramSessionType.DOCUMENT
          ? { text: 'doc', color: theme.main.lightSecondary }
          : { text: 'text', color: theme.main.lightSecondary };

      return (
        <ProgramSessionContainer>
          {renderModals()}
          <ThumbnailContainer>
            <ThumbnailImageContainer>
              <CloudinaryImage
                publicId={sessionData.thumbnail}
                alt="program session thumbnail"
                width={171}
                height={96}
              />
            </ThumbnailImageContainer>
            <SessionTag>
              <Tag style={{ '--color': sessionTag.color }}>
                {sessionTag.text}
              </Tag>
            </SessionTag>
            <TrashIconWrapper>
              <TrashIconContainer onClick={() => setOpenDeleteModal(true)}>
                <TrashIcon color="#D8D8D8" />
              </TrashIconContainer>
              {sessionData.type === 'onDemandWorkout' ||
              sessionData.type === 'liveWorkout' ? (
                <Tooltip
                  title={
                    sessionData.type === 'liveWorkout'
                      ? 'Editing Live Workout can be done in the schedule'
                      : 'Editing OnDemand Workout can be done in the workout library'
                  }
                  placement="top"
                >
                  <TrashIconContainer onClick={handleEdit}>
                    <EditIcon color="#D8D8D8" />
                  </TrashIconContainer>
                </Tooltip>
              ) : (
                <TrashIconContainer onClick={handleEdit}>
                  <EditIcon color="#D8D8D8" />
                </TrashIconContainer>
              )}
            </TrashIconWrapper>
          </ThumbnailContainer>
          <Title>{sessionData.title}</Title>
          <DeleteModal
            isOpen={openDeleteModal}
            onCancel={() => setOpenDeleteModal(false)}
            primaryText="Are you sure you want to delete this session?"
            handleDelete={() => {
              handleDeleteSession(week, day, sessionIndex);
              setOpenDeleteModal(false);
            }}
          />
        </ProgramSessionContainer>
      );
    default:
      return null;
  }
};
export default ProgramSession;
