import React, { ReactNode, ReactNodeArray } from 'react';
import s, { css, FlattenSimpleInterpolation } from 'styled-components';

import {
  minWidth,
  maxWidth,
  PHONE,
  TABLET,
  M3,
  M1,
  M2,
  M4,
} from '../../constants/measurements';

const percent = (numCols: number): string => `${(numCols / 12) * 100}%`;

export const SNOW = '#f0f2f5';
export const WHITE = '#fff';

interface IContainerTagProps {
  background?: string;
}

/**
 * Wrapper component for spacing content horizontally
 *
 * @param props.background - color for background
 */
export const Container = s.div<IContainerTagProps>`
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
  display: block;
  background: ${({ background }): string => background || 'transparent'};

  ${minWidth(PHONE)} {
    padding-right: calc(1rem + 2.5%);
    padding-left: calc(1rem + 2.5%);
  }

  ${minWidth(TABLET)} {
    padding-right: calc(1rem + 5%);
    padding-left: calc(1rem + 5%);
  }
`;

interface ISpacerProps {
  hiddenOnMobile?: boolean;
  m1?: boolean;
  m2?: boolean;
  m3?: boolean;
  m4?: boolean;
}

/**
 * Component for spacing content vertically
 *
 * @param props.hiddenOnMobile - if spacer should be hidden on small screens
 * @param props.m1
 * @param props.m2
 * @param props.m3
 * @param props.m4
 */
export const Spacer = s.div<ISpacerProps>(
  ({ hiddenOnMobile, m1, m2, m3 = true, m4 }) => css`
    display: block;
    width: 100%;
    height: ${m1 ? M1 : m2 ? M2 : m3 ? M3 : m4 ? M4 : '0'};
    ${hiddenOnMobile ? `${maxWidth(PHONE)} { display: none; }` : ''}
  `,
);

/**
 * Wrapper component for adding flex display
 */
export const Flex = s.div`
  width: 100%;
  display: flex;
`;

interface IRowProps {
  margin?: string;
  alwaysFlex?: boolean;
}

/**
 * Wrapper component for housing columns of content
 *
 * Generally, we have a DOM structured like:
 *
 * `Container` has many `Rows` has many `Cols`
 *
 * @param props.alwaysFlex - if the row should have display flex on mobile. By
 *                           default display is changed to block on small
 *                           devices which is normally best for mobile spacing.
 * @param props.margin     - margin to place between children. NOTE the same
 *                           `margin` should be applied to all child `Col`
 *                           components of this `Row`
 */
export const Row = s.div<IRowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  ${({ alwaysFlex }): string =>
    !alwaysFlex ? `${maxWidth(PHONE)} { display: block; }` : ''}

  ${({ margin }): string =>
    margin
      ? `margin-left: -${margin};
         margin-right: -${margin};
         width: calc(100% + ${margin} + ${margin});`
      : ''}
`;

export interface IColProps {
  /**
   * Fix the width of the column
   *
   * If no width is provided, width is determined by other props or flex layout
   */
  width?: string;

  /**
   * Number of columns to take up on small screens and anything larger
   *
   * Should be between 1 and 12
   */
  sm?: number;

  /**
   * Number of columns to offset (shift left) on small screens and anything
   * larger
   */
  offsetSm?: number;

  /**
   * Number of columns to take up on mid-sized screens and anything larger
   *
   * Should be between 1 and 12
   */
  md?: number;

  /**
   * Number of columns to offset (shift left) on mid-sized screens and anything
   * larger
   */
  offsetMd?: number;

  /**
   * Number of columns to take up on larger screens
   *
   * Should be between 1 and 12
   */
  lg?: number;

  /**
   * Number of columns to offset (shift left) on larger screens
   */
  offsetLg?: number;

  /**
   * If column should have display flex. NOTE this will alter how children of
   * the column are rendered.
   */
  flex?: boolean;

  /**
   * Margin between `Col` components in the same `Row`
   *
   * @see Row
   */
  margin?: string;

  /**
   * Contents inside of the column
   */
  children?: ReactNode | ReactNodeArray;

  /**
   * CSS property which effects how children are rendered
   */
  overflowY?: 'visibile' | 'scroll' | 'hidden' | 'auto';

  /**
   * Optional background color
   *
   * By default is transparent
   */
  background?: string;

  /**
   * If contents of the column should be centered
   */
  center?: boolean;

  /**
   * Additional custom styles to apply
   */
  style?: React.CSSProperties;
}

const ColWrapper = s.div<IColProps>(
  ({
    width,
    overflowY,
    sm,
    offsetSm,
    md,
    offsetMd,
    lg,
    offsetLg,
    flex,
  }): FlattenSimpleInterpolation => css`
    flex: ${width ? 'none' : 1};
    width: ${width || 'auto'};
    overflow-y: ${overflowY || 'visible'};
    overflow-x: visible;

    ${sm && `width: ${percent(sm)}; flex: none;`}
    ${(offsetSm || offsetSm === 0) && `margin-left: ${percent(offsetSm)};`}

    ${minWidth(PHONE)} {
      ${md && `width: ${percent(md)}; flex: none;`}
      ${(offsetMd || offsetMd === 0) && `margin-left: ${percent(offsetMd)};`}
    }

    ${minWidth(TABLET)} {
      ${lg && `width: ${percent(lg)}; flex: none;`}
      ${(offsetLg || offsetLg === 0) && `margin-left: ${percent(offsetLg)};`}
    }

    ${flex ? 'display: flex;' : ''}
  `,
);

const ColContainer = s.div<IColProps>(
  ({ background, flex, margin, center }): FlattenSimpleInterpolation => css`
    background: ${background || 'transparent'};
    overflow-x: visible;
    position: relative;

    ${center && 'text-align: center;'}
    ${margin && `margin-left: ${margin}; margin-right: ${margin};`}
    ${flex && 'flex: 1;'}
  `,
);

export const Col = ({
  margin,
  children,
  background,
  center,
  flex,
  ...other
}: IColProps): React.ReactElement => (
  <ColWrapper flex={flex} {...other}>
    <ColContainer
      flex={flex}
      margin={margin}
      background={background}
      center={center}
    >
      {children}
    </ColContainer>
  </ColWrapper>
);

export interface IColSpaceProps {
  width?: string;
}

export const ColSpace = s(Col)<IColSpaceProps>`
  flex: none;
  width: ${({ width }): string => width || '1rem'};

  ${maxWidth(PHONE)} {
    display: none;
  }
`;

interface IContainerProps {
  children: ReactNode;
  background?: string;
  foreground?: string;
}

export const MediumContainer = ({
  children,
  ...props
}: IContainerProps): React.ReactElement => (
  <Container {...props}>
    <Row>
      <Col sm={12} md={8} offsetMd={2} lg={6} offsetLg={3}>
        {children}
      </Col>
    </Row>
  </Container>
);

export const ThinContainer = ({
  children,
  ...props
}: IContainerProps): React.ReactElement => (
  <Container {...props}>
    <Row>
      <Col sm={12} md={8} offsetMd={2} lg={5} offsetLg={3.5}>
        {children}
      </Col>
    </Row>
  </Container>
);

export const GrayMediumContainer = (
  props: IContainerProps,
): React.ReactElement => <MediumContainer background={SNOW} {...props} />;

export const GrayThinContainer = (
  props: IContainerProps,
): React.ReactElement => (
  <ThinContainer background={SNOW} foreground={WHITE} {...props} />
);
