import React from 'react';
import SpotifyLogo from 'assets/images/spotify.png';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: black;
  color: white;
  width: auto;
  width: 185px;
  height: 50px;
  border: 2px solid #18d85f;
  border-radius: 20px;
  padding: 7px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 25px;
  height: 25px;
  margin: 0;
  margin-right: 10px;
`;

export const Link = styled.a``;

interface Props {
  spotifyLink: string;
}

const spotifyButton = ({ spotifyLink }: Props) => (
  <Link href={spotifyLink} target="_blank">
    <Container>
      <Image src={SpotifyLogo} />
      <div style={{ marginTop: 1 }}>Spotify Playlist</div>
    </Container>
  </Link>
);

export default spotifyButton;
