import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import * as Routes from 'constants/routes';
import CreatorSchedule from './CreatorSchedule';
import UploadVideo from './UploadVideo';
import Analytics from './Analytics';
import CreateLiveWorkout from './CreateLiveWorkout';
import EditLiveWorkout from './EditLiveWorkout';
import EditVideo from './EditVideo';
import Live from './Live';
import ManageWorkouts from './ManageWorkouts';
import WorkoutDropCalendar from './WorkoutDropCalendar';
import ManagePage from './ManagePage';
import LiveWorkout from './LiveWorkout';
import ViewWorkout from './ViewWorkout';
import Events from './Events';
import Programs from './Programs';
import EditProfile from './EditProfile';
import { useQuery } from 'react-query';
import { fetchUserProfile } from 'queries/user';
import { useEffect, useState } from 'react';
import InvalidSessionModal from '../components/InvalidSessionModal';
import NotificationsPage from './NotificationsPage';

const Dashboard = () => {
  const profileQuery = useQuery('profile', fetchUserProfile);
  const [isInvalidSession, setIsInvalidSession] = useState<boolean>(false);

  useEffect(() => {
    if (profileQuery.data) {
      // user type 1 === Inactive Creator
      if (profileQuery.data.type === 1) {
        setIsInvalidSession(true);
      }
    }
  }, [profileQuery.data]);

  return (
    <>
      <Route>
        <Switch>
          <Route path={Routes.CREATOR_SCHEDULE} component={CreatorSchedule} />
          <Route path={Routes.LIVE_WORKOUT_DETAIL} component={LiveWorkout} />
          <Route
            path={Routes.UPLOAD_LIVE_WORKOUT}
            component={CreateLiveWorkout}
          />
          <Route path={Routes.MANAGE_PAGE} component={ManagePage} />
          <Route path={Routes.EDIT_WORKOUT} component={EditLiveWorkout} />
          <Route path={Routes.EDIT_PROFILE} component={EditProfile} />
          <Route path={Routes.VIEW_WORKOUT} component={ViewWorkout} />
          <Route path={Routes.LIVE_VIDEO} component={Live} />
          <Route path={Routes.UPLOAD_WORKOUT} component={UploadVideo} />
          <Route path={Routes.EDIT_VIDEO} component={EditVideo} />
          <Route path={Routes.ANALYTICS} component={Analytics} />
          <Route path={Routes.MANAGE_WORKOUTS} component={ManageWorkouts} />
          <Route path={Routes.CALENDAR} component={WorkoutDropCalendar} />
          <Route path={Routes.EVENTS} component={Events} />
          <Route path={Routes.PROGRAMS} component={Programs} />
          <Route path={Routes.NOTIFICATIONS} component={NotificationsPage} />
          <Route path="/">
            <Redirect to={Routes.CREATOR_SCHEDULE} />
          </Route>
        </Switch>
      </Route>
      {isInvalidSession ? <InvalidSessionModal /> : null}
    </>
  );
};

export default withRouter(Dashboard);
