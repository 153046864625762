import React, { useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { BtnSelect } from 'components/BtnSelect';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import * as Routes from 'constants/routes';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { FormTextField } from 'components/FormTextField';
import Button from '@material-ui/core/Button';
import * as Colors from 'constants/theme';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import * as S from 'styles/login-live.styles';
import { CircularProgress } from '@material-ui/core';
import {
  CreateWorkoutRequest,
  UploadWorkoutFinishRequest,
} from '@solin-fitness/types';
import { uploadImage } from 'queries/file-uploads';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CloudinaryImage from 'components/CloudinaryImage';
import UploadImage from 'components/UploadImageModal';
import useAuthStore from 'hooks/useAuthStore';

const DEFAULT_WORKOUT_FORMAT = 'single-wide';

export const ImagePreview = styled.div`
  margin-bottom: 20px;
  object-fit: contain;
  background-color: #000000;
`;

interface InitialValues {
  thumbnailFile: string;
  thumbnailPreview: string | undefined;
  title: string;
  description: string;
  descriptionTwo: string;
  spotifyLink: string;
  length: number;
  tags: string[];
  videoUrl: string;
}

interface Props {
  workoutTags: string[];
  workoutId: number | null;
  isVideoUploaded: boolean;
  skippedToLink: boolean;
  createWorkout: (
    request: CreateWorkoutRequest | UploadWorkoutFinishRequest,
  ) => Promise<void>;
}

const validationSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  tags: yup.array().required(),
  length: yup.number().min(0).max(120).required(),
  descriptionTwo: yup.string().optional(),
  spotifyLink: yup.string().optional(),
  thumbnailFile: yup.string().required().nullable(),
  thumbnailPreview: yup.string().required().nullable(),
});

const UploadWorkoutDetails = (props: Props) => {
  const {
    workoutId,
    workoutTags,
    createWorkout,
    skippedToLink,
    isVideoUploaded,
  } = props;
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const history = useHistory();
  const auth = useAuthStore();
  const userId = auth.userId || 0;

  /**
   * If workoutId is null, tell creator to select video to be uploaded first
   */
  if (!workoutId && !props.skippedToLink) {
    return null;
  }

  const initialWorkoutValues: InitialValues = {
    thumbnailFile: '',
    thumbnailPreview: undefined,
    title: '',
    description: '',
    descriptionTwo: '',
    spotifyLink: '',
    length: 60,
    tags: [],
    videoUrl: '',
  };

  const handleSubmit = async (data: InitialValues) => {
    let request: CreateWorkoutRequest | UploadWorkoutFinishRequest;
    if (skippedToLink) {
      request = {
        userId,
        title: data.title,
        description: data.description,
        tags: data.tags,
        format: DEFAULT_WORKOUT_FORMAT,
        length: data.length,
        thumbnailPublicId: data.thumbnailFile,
        spotifyLink: data.spotifyLink,
        descriptionTwo: data.descriptionTwo,
        videoUrl: data.videoUrl,
      };
    } else {
      request = {
        title: data.title,
        description: data.description,
        tags: data.tags,
        format: DEFAULT_WORKOUT_FORMAT,
        length: data.length,
        thumbnailPublicId: data.thumbnailFile,
        userId,
        descriptionTwo: data.descriptionTwo,
        spotifyLink: data.spotifyLink,
      };
    }

    await createWorkout(request);
  };

  return (
    <>
      <S.Container style={{ marginTop: 16, marginBottom: 16 }}>
        {!!error && <Aside isError={true}>{error}</Aside>}
        <Formik
          validateOnChange={true}
          initialValues={initialWorkoutValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            console.log(data);

            await handleSubmit(data);

            setSubmitting(false);
            resetForm();
            setTimeout(() => {
              history.push(Routes.MANAGE_WORKOUTS);
            }, 500);
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => (
            <Form>
              <S.WrapCol>
                <>
                  <FormTextField
                    label="Title"
                    name="title"
                    type="text"
                    value={values.title}
                  />
                  <S.Spacer />
                </>

                <>
                  {values.title &&
                  values.thumbnailFile &&
                  !values.description ? (
                    <>
                      <div
                        style={{
                          color: 'red',
                          fontSize: '1.0rem',
                          marginBottom: '5px',
                        }}
                      >
                        * Description is required *
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  <Typography
                    style={{
                      color: Colors.themeColors.textColor,
                      fontSize: 16,
                    }}
                  >
                    Description
                  </Typography>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                      ],
                      link: {
                        addTargetToExternalLinks: true,
                      },
                    }}
                    data={values.description || ''}
                    onChange={(event: Event, editor: ClassicEditor) => {
                      const data = editor.getData();
                      setFieldValue('description', data);
                    }}
                  />
                  <S.Spacer />
                </>

                <>
                  <FormTextField
                    value={values.spotifyLink}
                    name="spotifyLink"
                    label="Music Playlist Link"
                    multiline={true}
                  />
                  <S.Spacer />
                </>

                {props.skippedToLink && (
                  <>
                    <FormTextField
                      value={values.videoUrl}
                      name="videoUrl"
                      label="Video Url"
                      required
                    />
                    <S.Spacer />
                  </>
                )}

                <>
                  <S.Spacer />
                  <Typography
                    id="discrete-slider"
                    gutterBottom
                    style={{
                      color: Colors.themeColors.textColor,
                      fontSize: 16,
                    }}
                  >
                    Duration (in minutes)
                  </Typography>
                  <Slider
                    value={values.length}
                    name="length"
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={120}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: number | number[],
                    ) => setFieldValue('length', value)}
                  />
                </>

                <>
                  <S.Spacer />
                  <BtnSelect
                    options={workoutTags.map((item: any) => ({
                      key: item,
                      value: item,
                    }))}
                    values={values.tags ? values.tags : []}
                    label="Type (select all that apply)"
                    onClick={(selectValues): void =>
                      setFieldValue('tags', selectValues)
                    }
                  />
                </>

                <>
                  {isUploading ? (
                    <CircularProgress style={{ color: Colors.GRAYS.medium }} />
                  ) : (
                    values.thumbnailPreview && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                        }}
                      >
                        <ImagePreview>
                          <CloudinaryImage
                            publicId={values.thumbnailPreview || ''}
                            width={350}
                            height={200}
                            alt={'preview thumbnail'}
                            fetchFormat={'auto'}
                            quality={'auto'}
                            crop={'scale'}
                          />
                        </ImagePreview>
                        <div style={{ paddingRight: 20 }} />
                        <IconButton
                          color="primary"
                          aria-label="remove thumbnail"
                          onClick={() => {
                            setFieldValue('thumbnailPreview', null);
                            setFieldValue('thumbnailFile', null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    )
                  )}

                  <Typography
                    style={{
                      color: Colors.themeColors.textColor,
                      fontSize: 16,
                      margin: '16px 0',
                    }}
                  >
                    Thumbnail
                  </Typography>

                  <UploadImage
                    fileName="drop"
                    onFinished={(publicId) => {
                      setFieldValue('thumbnailFile', publicId);
                      setFieldValue('thumbnailPreview', publicId);
                    }}
                    imageUploaded={!!values.thumbnailPreview}
                  />

                  <S.Spacer style={{ marginBottom: 20 }} />
                </>

                <Typography
                  style={{
                    color: Colors.themeColors.textColor,
                    fontSize: 16,
                    marginBottom: 45,
                  }}
                >
                  After you save your workout, you can publish it to your
                  subscribers through the "Workout Library" option in the menu
                  to the left
                </Typography>
                <Button
                  disabled={
                    isSubmitting || (!isVideoUploaded && !skippedToLink)
                  }
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  style={{
                    borderRadius: 35,
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size="2rem" />
                  ) : (
                    <Typography variant="h6">Save Workout</Typography>
                  )}
                </Button>
              </S.WrapCol>
            </Form>
          )}
        </Formik>
      </S.Container>
    </>
  );
};

export default UploadWorkoutDetails;
