import React from 'react';
import { IconProps } from './types';
import { Svg } from './Misc';

export const PlusIcon = (props: IconProps): React.ReactElement => (
  <Svg
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <title>{'Add Icon'}</title>
    <path d="M12 5v14M5 12h14" />
  </Svg>
);

export default PlusIcon;
