import s, { css } from 'styled-components';

import { BLUE, DARK_BLUE, themeColors } from 'constants/theme';
import { IconProps } from './types';

interface IIconCircleProps {
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

export const IconCircle = s.span<IIconCircleProps>`
  background: ${themeColors.primary};
  height: 1.8rem;
  width: 1.8rem;
  margin: 0;
  position: relative;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: ${themeColors.primary};
  }

  svg {
    margin-top: 50%;
    transform: translateY(-50%) scale(0.8);
  }
`;

// TODO add link prop to this to make it blue / dark blue
export const Svg = s.svg<IconProps>(
  ({ sm, md, lg, strokeWidth, isLink }) => css`
    stroke-width: ${strokeWidth || 2}px;
    vertical-align: top;
    line-height: 1;
    transform: scale(${sm ? 0.6 : md ? 0.8 : lg ? 1 : 1});
    ${isLink &&
    `
      color: ${BLUE};
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        color: ${DARK_BLUE};
      }
    `}
  `,
);
