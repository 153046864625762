import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormTextField } from 'components/FormTextField';
import { FormRadioButton } from 'components/FormRadioButton';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import { ProgramPrice } from 'context/ProgramBuilderContext';
import { CircularProgress } from '@material-ui/core';
import {
  Aside,
  SuccessAside,
} from 'components/ManageClassPrices/CreateClassPrice';
import { equals } from 'ramda';
import { useState, useEffect } from 'react';

const Header = styled.h2`
  font-family: 'Raleway';
  font-size: 20px;
  color: #000;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 400px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  border: none;
  width: 100%;
  background-color: ${(p) => p.theme.main.secondary};
  height: 44px;
  border-radius: 40px;
  box-shadow: 0px 14px 18px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 100ms;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: brightness(0.8);
  }
`;

const ButtonText = styled.p`
  color: #fff;
  font-family: 'Brother';
  font-size: 16px;
  font-weight: 500;
`;

enum IncludedInMembership {
  YES = 'yes',
  NO = 'no',
}

interface ProgramPriceInitialValues {
  amount: number;
  isIncludedInMembership: IncludedInMembership;
}

const validationSchema = yup.object({
  amount: yup.number().required().min(0),
  isIncludedInMembership: yup.string().required(),
});

interface Props {
  programPrice: ProgramPrice;
}

const ProgramPriceForm = ({ programPrice }: Props) => {
  const {
    handlePublishProgram,
    handleUpdateProgramPrice,
    error,
    isPriceUpdated,
    isProgramCompleted,
    isDetailsCompleted,
    isEditing,
  } = useProgramBuilder();

  const initialValues: ProgramPriceInitialValues = {
    amount: programPrice.amount,
    isIncludedInMembership: programPrice.isIncludedInMembership
      ? IncludedInMembership.YES
      : IncludedInMembership.NO,
  };

  const canPublish = isProgramCompleted && isDetailsCompleted;

  // Snackbar Handlers
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        const price: ProgramPrice = {
          amount: data.amount,
          isIncludedInMembership:
            data.isIncludedInMembership === IncludedInMembership.YES
              ? true
              : false,
        };
        if (!isEditing) {
          handlePublishProgram(price);
        } else {
          handleUpdateProgramPrice(price);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values }) => {
        const canUpdate = !equals(values, initialValues);
        const isDisabled = isEditing ? !canUpdate : !canPublish;
        return (
          <Form style={{ marginBottom: 0 }}>
            <Container>
              <InputContainer>
                <Header>Please set your price</Header>
                <FormTextField
                  label="Price"
                  name="amount"
                  type="number"
                  placeholder="0.00"
                />
              </InputContainer>
              <InputContainer>
                <Header>
                  Would you like to give your current subscribers access (i.e.,
                  if you have current subscribers, they will not pay for
                  access)?
                </Header>
                <RadioButtonContainer>
                  <FormRadioButton
                    label="Yes"
                    name="isIncludedInMembership"
                    type="radio"
                    value={IncludedInMembership.YES}
                  />
                  <FormRadioButton
                    label="No"
                    name="isIncludedInMembership"
                    type="radio"
                    value={IncludedInMembership.NO}
                  />
                </RadioButtonContainer>
              </InputContainer>
              {!!error ? <Aside isError>{error}</Aside> : null}
              <Button type="submit" disabled={isDisabled} onClick={handleClick}>
                {isSubmitting ? (
                  <CircularProgress
                    style={{ width: 24, height: 24, color: '#fff' }}
                  />
                ) : (
                  <ButtonText>
                    {isEditing ? 'Update Price & Access' : 'Publish'}
                  </ButtonText>
                )}
              </Button>
              {open && isEditing ? (
                <SuccessAside isSuccess>
                  {'Price updated successfully!'}
                </SuccessAside>
              ) : null}
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProgramPriceForm;
