import React from 'react';
import s from 'styled-components';

import { Row, Col } from 'components/shared';
import {
  BORDER_RADIUS_LG,
  SHORT_ANIMATION_DURATION,
  minWidth,
  DESKTOP,
  M1,
  M2,
} from 'constants/measurements';
import Typography from '@material-ui/core/Typography';
import * as Colors from 'constants/theme';

export interface TextProps {
  center?: boolean;
  color?: string;
  sm?: boolean;
  lg?: boolean;
  xs?: boolean;
  mb0?: boolean;
  mb1?: boolean;
  mb2?: boolean;
  mb3?: boolean;
  mb4?: boolean;
  fontSize?: string;
  fontSizeDesktop?: string;
  children: string | React.ReactNode | React.ReactNodeArray | string[];
  style?: React.CSSProperties;
  light?: boolean;
  lighter?: boolean;
  lightest?: boolean;
  bold?: boolean;
  medium?: boolean;
}

export const getFontSize = ({
  xs,
  sm,
  lg,
  normal,
}: Partial<TextProps> & { normal: number }): string => {
  const sizeNum = xs
    ? normal / (1.2 * 1.2)
    : sm
    ? normal / 1.2
    : lg
    ? normal * 1.2
    : normal;
  return `${sizeNum}em`;
};

interface BtnSelectTagProps {
  active: boolean;
}

export const BLACK_ALPHA = (alpha: number): string =>
  `rgba(50, 50, 52, ${alpha})`;
export const OUTLINE = 'rgba(0, 123, 255, 0.25)';
export const GRAY_2 = 'rgb(106, 106, 109)';

const BOLD_FONT_WEIGHT = 500;

const RequiredLabel = s.span`
  color: red;
`;

const BtnSelectOptionTag = s.a<BtnSelectTagProps>`
  width: 100%;
  display: block;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: ${BORDER_RADIUS_LG} !important;
  border-color: #C4C4C4;
  line-height: 1;
  padding: ${M2} 0;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: ${BOLD_FONT_WEIGHT};
  transition: all ${SHORT_ANIMATION_DURATION}ms ease;
  letter-spacing: 1px;

  font-size: ${getFontSize({ normal: 0.96 })};

  ${minWidth(DESKTOP)} {
    padding: ${M2} 0;
    font-size: ${getFontSize({ normal: 1.1 })}
  }


  &:active {
    background: ${Colors.themeColors.primary};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${OUTLINE};
  }

  ${({ active }): string =>
    active
      ? `
      color: ${Colors.themeColors.primary};
      border-color: ${Colors.themeColors.primary};
      background: ${Colors.themeColors.primary};

      &:active {
        color: ${Colors.themeColors.primary};
        border-color: ${Colors.themeColors.primary};
      }`
      : `
      color: ${GRAY_2};

      &:hover
      {
        color: ${GRAY_2};
        border-color: ${BLACK_ALPHA(0.99)};
      }`}
`;

export interface BtnSelectOption {
  key: string;
  value: string;
  helperText?: string;
}

interface BtnSelectProps {
  label?: string;
  options: BtnSelectOption[];
  values: string[];
  numCols?: number;
  onClick: (option: string[]) => void;
  isSingleSelect?: boolean;
  testId?: string;
  requiredLabel?: boolean;
}

interface BtnSelectOptionProps {
  option: BtnSelectOption;
  values: string[];
  onClick: (option: string[]) => void;
  isSingleSelect?: boolean;
  testIdWithIndex?: string;
}

const BtnSelectOption = ({
  values,
  option,
  onClick,
  isSingleSelect,
  testIdWithIndex,
}: BtnSelectOptionProps): React.ReactElement => {
  const active = values.includes(option.value);

  const handleClick = (): void => {
    if (isSingleSelect) {
      return onClick([option.value]);
    }

    const newValues = active
      ? values.filter((value) => value !== option.value)
      : values.concat([option.value]);

    return onClick(newValues);
  };

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  return (
    <>
      <BtnSelectOptionTag
        active={active}
        onClick={handleClick}
        tabIndex={0}
        key={`${active}`}
        onKeyPress={handleKeyPress}
        data-test={testIdWithIndex}
      >
        <Typography
          style={{
            color: active ? 'white' : Colors.themeColors.textColor,
            fontSize: 16,
          }}
        >
          {option.key}
        </Typography>
      </BtnSelectOptionTag>
      {option.helperText && (
        <Typography
          style={{
            color: Colors.themeColors.textColor,
            fontSize: 13,
            marginTop: -13,
            marginBottom: 15,
            fontStyle: 'italic',
          }}
        >
          {option.helperText}
        </Typography>
      )}
    </>
  );
};

export const BtnSelect = ({
  label,
  options,
  values,
  onClick,
  numCols = 3,
  isSingleSelect = false,
  testId,
  requiredLabel,
}: BtnSelectProps): React.ReactElement => (
  <>
    <Typography
      style={{
        color: Colors.themeColors.textColor,
        fontSize: 16,
        marginBottom: 15,
        marginTop: 10,
      }}
    >
      {label} {requiredLabel ? <RequiredLabel>* Required</RequiredLabel> : ''}
    </Typography>
    <Row alwaysFlex margin={M1}>
      {options.map((option, index) => (
        <Col
          sm={12 / numCols}
          md={12 / numCols}
          key={`${option.key}-${option.value}`}
          margin={M1}
        >
          <BtnSelectOption
            testIdWithIndex={`${testId}${index}`}
            values={values}
            option={option}
            onClick={onClick}
            isSingleSelect={isSingleSelect}
          />
        </Col>
      ))}
    </Row>
  </>
);
