import { api, Methods } from '../services/api';
import { Page } from 'types';
import { CurrencyCode } from '@solin-fitness/types/build/api/utils';
import {
  AddSubscriptionPromoRequest,
  CreateClassPriceRequest,
  EditConfigMetadata,
} from '@solin-fitness/types';

export const getPage = async () => await api<Page>(Methods.get, '/page-config');

// todo: move this interface to @types package

export interface PageSubscriptionRequest {
  type: string;
  amount: number;
  isActive: boolean;
  name: string;
  description: string;
  interval: string;
  currency: CurrencyCode;
}

export const createPageSubscription = async (data: PageSubscriptionRequest) =>
  await api(Methods.post, '/page-config/subscription', data);

export interface EditPageSubscriptionRequest {
  priceId: string;
  isActive: boolean;
}

export const editPageSubscription = async (data: EditPageSubscriptionRequest) =>
  await api(Methods.patch, '/page-config/subscription/is-active', data);

export const createPageClassPrice = async (data: CreateClassPriceRequest) =>
  await api(Methods.post, '/page-config/class-price', data);

export const editPageMetadata = async (
  pageId: number,
  data: EditConfigMetadata,
) => await api<void>(Methods.patch, `/page-config/${pageId}/metadata`, data);

export const createSubscriptionPromo = async (
  data: AddSubscriptionPromoRequest,
) => await api(Methods.post, '/page-config/promo', data);

export interface EditPagePromoRequest {
  promoId: string;
  isActive: boolean;
}

export const editPagePromotion = async (data: EditPagePromoRequest) =>
  await api(Methods.patch, '/page-config/promo', data);
