import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const useThemeContext = () => {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw new Error('ThemeContext must be used within a ThemeProvider');
  }
  return theme;
};

export default useThemeContext;
