import { apiWithFormData } from '../services/api';

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append('image', file);
  return await apiWithFormData<{ publicId: string }>('/upload/image', formData);
};

export const uploadDocument = async (file: File) => {
  const formData = new FormData();
  formData.append('document', file);
  return await apiWithFormData<{ documentUrl: string }>(
    '/upload/document',
    formData,
  );
};
