import React from 'react';
import * as S from 'styles/schedules.styles';
import * as Colors from 'constants/theme';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { NavMenu } from 'components/NavMenu';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import * as Routes from 'constants/routes';
import { Button } from 'components';
import useAuthStore from 'hooks/useAuthStore';
import useLogoutUser from 'hooks/useLogoutUser';

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
});

interface IProps {
  title: string;
}

type Anchor = 'left';

const HeaderLive = ({ title }: IProps): React.ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [state, setState] = React.useState({ left: false });

  const history = useHistory();
  const logout = useLogoutUser();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout.mutate(1, {
      onSuccess: () => {
        history.push(Routes.LOGIN);
        useAuthStore.persist.clearStorage();
      },
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <S.Header>
        <S.LogoWrap onClick={() => history.push(Routes.CREATOR_SCHEDULE)}>
          <S.LogoStatic>solin</S.LogoStatic>
        </S.LogoWrap>

        <S.MobileMenu>
          <IconButton onClick={toggleDrawer('left', true)}>
            <MenuIcon />
          </IconButton>
        </S.MobileMenu>

        <Typography
          style={{
            color: Colors.themeColors.textColor,
            fontWeight: 400,
            letterSpacing: 1,
            width: 300,
            fontSize: isMobile ? 18 : 23,
            marginLeft: isMobile ? 10 : 0,
          }}
          variant="h5"
        >
          {title}
        </Typography>

        <div style={{ marginLeft: 'auto' }}>
          <Button color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </div>

        <Drawer
          anchor={'left'}
          open={state.left}
          onClose={toggleDrawer('left', false)}
        >
          <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer('left', false)}
            onKeyDown={toggleDrawer('left', false)}
          >
            <NavMenu isMobileMenu={true} />
          </div>
        </Drawer>
      </S.Header>
    </ClickAwayListener>
  );
};

export default HeaderLive;
