import { useEffect, useRef, useState } from 'react';
import OnDemandWorkoutPicker from './Components/OnDemandWorkoutPicker';
import styled from 'styled-components';
import ProgramDay from './Components/ProgramDay';
import { ProgramSessionInterface } from 'context/ProgramBuilderContext';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import { motion } from 'framer-motion';
import useThemeContext from 'hooks/useThemeContext';
import { MinusIcon } from 'components/shared/Icons';
import DeleteModal from './Components/DeleteModal';

const Wrapper = styled.section`
  display: flex;
`;

const ProgramBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const WeekTitle = styled.h5<{ isSelected: boolean }>`
  font-family: 'Raleway';
  font-size: 28px;
  color: #000;
  line-height: 36px;
  font-weight: ${(p) => (p.isSelected ? 'bold' : '400')};
  cursor: pointer;
`;

const WeekContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding-bottom: 36px;
`;

const ProgramDaysWrapper = styled.div`
  position: relative;
`;

const ProgramDaysContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-right: 150px;
`;

const ButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 24px;
`;

const Button = styled.button<{ bg: string }>`
  border: none;
  width: 200px;
  background-color: ${(p) => p.bg};
  height: 44px;
  border-radius: 40px;
  box-shadow: 0px 14px 18px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 100ms;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: brightness(0.8);
  }
`;

const ButtonText = styled.p<{ color: string }>`
  margin: auto;
  color: ${(p) => p.color};
  font-family: 'Brother';
  font-size: 16px;
  font-weight: medium;
`;

const AddItemsButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 36px;
`;

const OnDemandWorkoutPickerContainer = styled.section`
  flex: 1;
  height: calc(100vh - 90px);
  overflow: auto;
  position: fixed;
  right: 40px;
  top: 90px;
`;

const MinusWeekWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aaa;
  border-radius: 50%;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    filter: grayscale(0);
    background-color: ${(p) => p.theme.main.secondary};
  }
`;

const DeleteWeekWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 46px;
`;

const DeleteWeekText = styled.p`
  font-family: 'Brother';
  font-size: 24;
  font-weight: 300;
  color: #aaa;
`;

const ProgramWeekBuilder = () => {
  const theme = useThemeContext();
  const targetRef = useRef<HTMLDivElement>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const {
    program,
    currentWeek,
    isEditing,
    handleAddNewWeek,
    handleAddNewDay,
    handleSetWeek,
    handleDeleteWeek,
    handleSaveProgramWeek,
    handleFinishProgramWeek,
    handleUpdateProgramWeek,
  } = useProgramBuilder();

  useEffect(() => {
    setIsSaved(false);
  }, [program]);

  const programRef = useRef(program);
  const editProgramRef = useRef(program);

  useEffect(() => {
    if (program.length > programRef.current.length) {
      handleSetWeek(program.length - 1);
    }
    programRef.current = program;
  }, [program]);

  const handleSave = async (toContinue: boolean) => {
    if (isEditing) {
      await handleUpdateProgramWeek(toContinue);
      if (!toContinue) {
        setIsSaved(true);
      }
    }

    if (toContinue) {
      handleFinishProgramWeek();
    } else {
      handleSaveProgramWeek();
      setIsSaved(true);
    }
  };

  return (
    <>
      <Wrapper>
        <ProgramBuilderContainer>
          <AddItemsButtonContainer>
            <Button bg="#fff" onClick={() => handleAddNewDay(currentWeek)}>
              <ButtonText color={theme.main.secondary}>{`+ Day for Week ${
                currentWeek + 1
              }`}</ButtonText>
            </Button>
            <Button
              bg={theme.main.secondary}
              onClick={() => handleAddNewWeek()}
            >
              <ButtonText color="#fff">+ New Week</ButtonText>
            </Button>
          </AddItemsButtonContainer>
          <WeekContainer>
            {program.map((_: any, week: number) => (
              <WeekTitle
                key={week}
                isSelected={week === currentWeek}
                onClick={() => handleSetWeek(week)}
              >{`Week ${week + 1}`}</WeekTitle>
            ))}
          </WeekContainer>
          {program.length > 1 ? (
            <DeleteWeekWrap>
              <MinusWeekWrap onClick={() => setOpenDeleteModal(true)}>
                <MinusIcon color="#fff" strokeWidth={2.5} />
              </MinusWeekWrap>
              <DeleteWeekText>{`Delete Week ${
                currentWeek + 1
              }`}</DeleteWeekText>
            </DeleteWeekWrap>
          ) : null}
          <ProgramDaysWrapper>
            <ProgramDaysContainer ref={targetRef} key={currentWeek}>
              {program[currentWeek].map(
                (day: ProgramSessionInterface[], i: number) => (
                  <ProgramDay
                    key={i}
                    week={currentWeek}
                    day={i}
                    sessions={day}
                    totalDaysInWeek={program[currentWeek].length}
                  />
                ),
              )}
            </ProgramDaysContainer>
          </ProgramDaysWrapper>
          <ButtonContainer>
            <Button bg="#fff" onClick={() => handleSave(false)}>
              <ButtonText color={theme.main.secondary}>
                {isSaved ? 'Saved' : 'Save'}
              </ButtonText>
            </Button>
            <Button bg={theme.main.secondary} onClick={() => handleSave(true)}>
              <ButtonText color="#fff">{'Save & Continue'}</ButtonText>
            </Button>
          </ButtonContainer>
        </ProgramBuilderContainer>
        <OnDemandWorkoutPickerContainer>
          <OnDemandWorkoutPicker />
        </OnDemandWorkoutPickerContainer>
      </Wrapper>
      <DeleteModal
        isOpen={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        primaryText="Are you sure you want to delete this week?"
        secondaryText="The weeks scheduled to occur after the deleted week will move up by one week (for example, if Week 3 is deleted, Week 4 becomes Week 3)"
        handleDelete={() => {
          handleDeleteWeek(currentWeek);
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
};

export default ProgramWeekBuilder;
