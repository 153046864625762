import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import Layout from 'components/Layout';
import { getWorkoutDropCalendar } from 'queries/calendar';
import { DropCalendar } from 'components/DropCalendar';
import { UploadStatus, WorkoutResponse } from '@solin-fitness/types';
import { getWorkouts } from 'queries/workouts';
import { Loading } from 'components/Loading';
import useConfig from 'hooks/useConfig';
import { WorkoutDropProvider } from 'context/WorkoutDropContext';
import { pipe, sort, filter } from 'ramda';

const WorkoutDropCalendar = () => {
  const { isLoading: isLoadingCalendar, data: calendar } = useQuery(
    'calendar',
    async () => await getWorkoutDropCalendar(),
    {
      cacheTime: 30000,
      staleTime: 10000000,
      select: (data) => data.filter((val) => val.type !== 'event'),
    },
  );
  const { isLoading: isLoadingWorkouts, data: workouts } = useQuery<
    WorkoutResponse[]
  >('workouts', async () => await getWorkouts(), {
    cacheTime: 30000,
    staleTime: 10000000,
    select: (data) =>
      pipe(
        sort(
          (a: WorkoutResponse, b: WorkoutResponse) =>
            // types package is saying `createdAt` is date, but its returned as string
            // this is why `as unknown as string` is needed
            -1 *
            (new Date(a.createdAt as unknown as string).getTime() -
              new Date(b.createdAt as unknown as string).getTime()),
        ),
        filter(
          (f: WorkoutResponse) => f.uploadStatus === UploadStatus.finished,
        ),
      )(data),
  });


  // filter workouts so that workout drops show up as options to re-add to schedule
  // as long as the workout drop does not already exist in the calendar
  const getWorkoutsFiltered = () => {
    if (workouts === undefined) {
      return [];
    }

    const filteredWorkouts = workouts?.filter((workout) => {
      const currDate = new Date();

      if (workout.isDrop && (new Date(workout.releaseDate) > currDate)) {
        const inCalendar = calendar?.find((calItem) => {
          if (calItem.type === 'workoutDrop' && calItem.data.id === workout.id) {
            return true;
          }
        });
        if (!!inCalendar) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    return filteredWorkouts;
  };

  // sort(
  //   (a: WorkoutResponse, b: WorkoutResponse) =>
  //     a.createdAt.getTime() - b.createdAt.getTime(),
  //   filtered,
  // );

  const { data: page } = useConfig();

  const isLoading = useMemo<boolean>(
    () => !!!calendar || !!!workouts || !!!page,
    [isLoadingCalendar, isLoadingWorkouts, calendar, workouts],
  );

  return (
    <Layout title="Calendar">
      {isLoading ? (
        <Loading />
      ) : (
        <WorkoutDropProvider>
          <DropCalendar
            workouts={workouts ? getWorkoutsFiltered() : []}
            calendar={calendar ?? []}
            workoutTags={page?.config.workoutTags ?? []}
          />
        </WorkoutDropProvider>
      )}
      {/* <div>{!!data && <pre>{JSON.stringify(data, null, 2)}</pre>}</div> */}
    </Layout>
  );
};

export default WorkoutDropCalendar;
