import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import Login from 'pages/Login';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { theme, muiTheme } from 'constants/theme';
import { QueryClientProvider, QueryClient } from 'react-query';
import { withErrorHandler } from 'components/ErrorHandler';
import * as Routes from 'constants/routes';
import { ProtectedRoute } from 'components/ProtectedRoute';
import Dashboard from 'pages/Dashboard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { setConfig } from 'cloudinary-build-url';
import LogRocket from 'logrocket';

setConfig({
  cloudName: 'solin-fitness',
});

import 'styles/index.css';
import 'assets/fonts/index.css';
import { API_ROOT } from 'services/api';

const IS_DEV = (API_ROOT || '').includes('staging') ? true : false;

if (!IS_DEV) {
  LogRocket.init('wc6p5e/solintv-dashboard');
  ReactGA.initialize('G-9H69RM2RTN');
}

const themeConst = createMuiTheme(muiTheme);

const queryClient = new QueryClient();

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={themeConst}>
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <div className="App">
            <Helmet>
              <title>Solin Stream | Dashboard</title>
            </Helmet>
            <Router>
              <Switch>
                <Route exact path="/" render={() => <Login />} />
                <Route path={Routes.LOGIN} component={Login} />
                <ProtectedRoute component={Dashboard} />
              </Switch>
            </Router>
          </div>
        </DndProvider>
      </QueryClientProvider>
    </MuiThemeProvider>
  </ThemeProvider>
);

export default withErrorHandler(App);
