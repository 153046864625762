import React, { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
  height?: number;
  style?: CSSProperties;
  onClick?: () => void;
  children: ReactNode | ReactNode[];
}

const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
`;

const Column: React.FC<Props & HTMLAttributes<HTMLDivElement>> = (props) => (
  <Container {...props} />
);

export default Column;
