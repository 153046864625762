import { Column } from 'components/Column';
import { Row } from 'components/Row';
import styled from 'styled-components';

interface ContainerProps {
  isOver?: boolean;
}

export const DayContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 270px;
  flex: 1;
  padding: 0px 12px;
  background-color: ${({ isOver }) => (isOver ? '#2b6190' : 'inherit')};
  border-radius: 30px;
`;

export const DayTitle = styled.h4<{ inPast?: boolean }>`
  color: ${({ inPast }) => (inPast ? '#FFF' : '#aeb7bc')};
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  text-transform: capitalize;
`;

export const CurrentDay = styled.div<{ isCurrentDate: boolean }>`
  background-color: #509ed0;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  visibility: ${({ isCurrentDate }) => (isCurrentDate ? 'visible' : 'hidden')};
  margin-bottom: 12px;
`;

export const BaseDay = styled.div<ContainerProps>`
  border-radius: 20px;
  width: 100%;
  max-height: 120px;
  flex: 1;
  margin-bottom: 12px;
`;

export const WorkoutDay = styled(BaseDay)`
  filter: brightness(0.6);
`;

export const LiveWorkoutDay = styled(BaseDay)`
  border: 2px solid #fff;
`;

export const ThumbnailImageWrapper = styled.div`
  position: absolute;
  top: -10px;
  bottom: -10px;
  margin: auto;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const WorkoutDetailsContainer = styled(Column)`
  position: absolute;
  top: 0;
  left: 2px;
  padding: 8px 8px;
  width: 100%;
`;

export const WorkoutDetails = styled.p`
  color: #fff;
  font-weight: 700;
  font-family: Roboto;
  font-size: 20;
  overflow: hidden;
  textoverflow: ellipsis;
  width: 100%;
`;

export const EmptyDay = styled(BaseDay)`
  background-color: ${({ theme }) => theme.main.lightPrimary};
  opacity: ${({ isOver }) => (isOver ? 1 : 0.1)};
`;

export const ScheduleDay = styled(Column)`
  background-color: #fff;
  border-radius: 20px;
  max-height: 120px;
  flex: 1;
  width: 100%;
  position: relative;
  margin: 0px 2px;
`;

export const Select = styled.select`
  width: 90%;
  border: none;
  color: ${({ theme }) => theme.main.primary};

  &:focus {
    border: none;
    outline: none;
  }
`;

export const Exit = styled(Row)`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.grays.veryLight};

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.grays.light};
    opacity: 0.95;
  }
`;
