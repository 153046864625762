import { Column } from 'components/Column';
import styled from 'styled-components';

export const ChatOuterContainer = styled.section`
  overflow: scroll;
  height: calc(100% - 470px);
`;

export const ChatInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ChatBubble = styled.div`
  max-width: 200px;
  color: white;
  background: ${({ theme }) => theme.main.primary};
  padding: 4px 12px;
  border-radius: 13px;
  margin-bottom: 7px;
  font-size: 12px;
  margin-left: auto;
`;

export const Chat = styled.p`
  max-width: 200px;
  font-size: 12px;
  line-height: 1.4;
`;

export const TextBox = styled.div`
  width: 275px;
  height: 227px;
  background: #ffffff;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 20px;
  right: 20px;

  z-index: 99;

  @media (max-width: 1200px) {
    position: relative;

    width: 100%;
    height: 67px;
    box-shadow: none;
    border-radius: 0px;
    margin-top: -20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  }
`;

export const TextArea = styled.textarea`
  border: none;
  resize: none;
  outline: none;
  font-family: 'Raleway';
  font-size: 20px;
  line-height: 19px;
  color: #7d7d7d;
  margin: 5px;
  height: 125px;
  z-index: 25;
  white-space: pre-line;

  @media (max-width: 1200px) {
    height: 30px;
    color: #7d7d7d !important;
    width: 100%;
  }
`;

export const MobileButton = styled.div<{ isActive: boolean }>`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background: ${({ isActive, theme }) => (isActive ? '#548dbf' : '#548DBF')};
  position: absolute;
  bottom: 7px;
  right: 7px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;

  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const BottomWrap = styled.div`
  display: flex;
  z-index: 25;
`;

export const TopWrap = styled.div`
  flex: 1;
`;
