/**
 * Return first and last date of week interval
 * @param curr current date
 * @param interval week interval
 */
export const getWeekInterval = (curr: Date, interval = 0): Date[] => {
  const first = curr.getDate() - curr.getDay() + 1;
  const last = first + 6;
  const addedInterval = interval * 7;
  const firstDay = new Date(
    new Date(curr.setDate(first + addedInterval)).setHours(0, 0, 0, 0),
  );
  const lastDay = new Date(
    new Date(curr.setDate(last + addedInterval)).setHours(23, 59, 59, 999),
  );
  return [firstDay, lastDay];
};

export const getDay = (date: Date, daysAway = 0): Date => {
  const temp = new Date(date);
  const day = temp.getDate() - temp.getDay() + daysAway;
  return new Date(temp.setDate(day));
};

export const getStartOfWeek = (): Date => {
  const curr = new Date();
  const first = curr.getDate() - curr.getDay() + 1;
  return new Date(curr.setDate(first));
};

/**
 * get start time of a calendar item
 * @param hour
 * @param minute
 * @param time
 * @returns
 */
export const getStartTime = (
  hour: number,
  minute: number,
  time: string,
): string => {
  let adjustedHour = hour;
  if (hour === 0) {
    adjustedHour = 12;
  }
  if (minute === 0) {
    return `${adjustedHour}${time}`;
  }
  return `${adjustedHour}:${minute}${time}`;
};
