import Layout from 'components/Layout';
import AccountInfo from 'components/AccountInfo/AccountInfo';
import { UserType } from '@solin-fitness/types';
import styled from 'styled-components';
import { useUserProfile } from 'queries/user';
import { useEffect, useState } from 'react';
import { FetchUserProfileResponse } from '@solin-fitness/types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EditProfilePic from 'components/AccountInfo/EditProfilePic';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

// TODO: add to netlify
const stripeKey = process.env.REACT_APP_PUBLIC_STRIPE_PK;
const stripePromise = loadStripe(stripeKey as string);

const EditProfile = () => {
  const { data: userProfile } = useUserProfile();

  return (
    <Layout title="Edit Profile">
      <Container>
        {userProfile ? (
          <>
            <EditProfilePic
              profilePictureUrlPublicId={userProfile?.profilePictureUrlPublicId}
            />
            <div style={{ height: 20 }} />
            <Elements
              stripe={stripePromise}
              options={{
                fonts: [
                  {
                    cssSrc:
                      'https://fonts.googleapis.com/css?family=Raleway&display=swap',
                  },
                ],
              }}
            >
              <AccountInfo profile={userProfile} />{' '}
            </Elements>
          </>
        ) : (
          'Loading...'
        )}
      </Container>
    </Layout>
  );
};
export default EditProfile;
