import React from 'react';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/FormTextField';
import * as yup from 'yup';
import { Modal } from 'components/Modal';
import useThemeContext from 'hooks/useThemeContext';
import Slider from '@material-ui/core/Slider';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageUpload from 'components/FileUploads/ImageUpload/ImageUpload';
import {
  ProgramTextSessionRequest,
  ProgramSessionResponse,
  ProgramSessionType,
} from '@solin-fitness/types';
import {
  Label,
  Header,
  FormElementsContainer,
  Button,
  ButtonContainer,
  ButtonText,
  HelperText,
} from './ProgramSession.styles';
import Dialog from 'components/Dialog/Dialog';
import ProgramSession from './ProgramSession';

interface TextInitialValues {
  title?: string;
  description?: string;
  length?: number;
  thumbnail?: string;
}

const validationSchema = yup.object({
  title: yup.string().required().min(1),
  description: yup.string().required(),
  length: yup.number().optional().min(1),
  thumbnail: yup.string().required().min(1),
});

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  editValues?: ProgramSessionResponse;
  handleAddSession: (
    data: Omit<ProgramTextSessionRequest, 'type' | 'week' | 'day' | 'session'>,
    isEditingSession?: boolean,
  ) => void;
}

const TextProgramSession: React.FC<Props> = ({
  isOpen,
  onCancel,
  handleAddSession,
  editValues,
}) => {
  const theme = useThemeContext();

  const initialValues: TextInitialValues = editValues
    ? {
        title: editValues.title,
        description: editValues.description,
        length: editValues.length,
        thumbnail: editValues.thumbnail,
      }
    : {
        title: '',
        description: '',
        length: 30,
        thumbnail: '',
      };

  return (
    <Dialog open={isOpen} onCancel={onCancel} bypassFocusLock>
      <Header>Text Session</Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          handleAddSession(
            {
              title: data.title as string,
              description: data.description as string,
              length: data.length as number,
              thumbnail: data.thumbnail as string,
            },
            editValues ? true : false,
          );

          setSubmitting(false);
          resetForm();
          onCancel();
        }}
      >
        {({ values, setFieldValue, errors, touched, setTouched }) => (
          <Form style={{ marginBottom: 0 }}>
            <FormElementsContainer>
              <FormTextField
                label="Title"
                name="title"
                type="input"
                InputLabelProps={{ shrink: true }}
              />
              <div>
                <Label>Description</Label>
                <CKEditor
                  editor={ClassicEditor}
                  enforceFocus={false}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                    ],
                    link: {
                      addTargetToExternalLinks: true,
                    },
                  }}
                  data={values.description || ''}
                  onChange={(e: Event, editor: ClassicEditor) => {
                    const data = editor.getData();
                    setFieldValue('description', data);
                  }}
                  onBlur={() => setTouched({ ...touched, description: true })}
                />
                {touched.description && !!errors?.description ? (
                  <HelperText>{errors.description}</HelperText>
                ) : null}
              </div>
              <div>
                <Label>Estimated Duration</Label>
                <Slider
                  value={values.length}
                  name="length"
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={120}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: number | number[],
                  ) => setFieldValue('length', value)}
                />
              </div>
              <div>
                <Label>Thumbnail</Label>
                <div style={{ height: 150 }}>
                  <ImageUpload
                    fileName="thumbnail"
                    thumbnail={values.thumbnail as string}
                    handleThumbnail={(value) =>
                      setFieldValue('thumbnail', value)
                    }
                  />
                </div>
              </div>
            </FormElementsContainer>
            <ButtonContainer>
              <Button type="button" onClick={onCancel}>
                <ButtonText style={{ '--color': theme.main.secondary }}>
                  Cancel
                </ButtonText>
              </Button>
              <Button type="submit" style={{ '--bg': theme.main.secondary }}>
                <ButtonText style={{ '--color': '#fff' }}>Save</ButtonText>
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default TextProgramSession;
