import styled from 'styled-components';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import { TABLET } from 'constants/measurements';

export const AccountInfoHeader = styled.h1`
  font-size: 46px;
  font-family: Raleway;
  text-align: center;
  padding: 18px;
`;

export const AccountInfoContainer = styled(Column)`
  margin-bottom: 36px;
  width: 100%;
`;

export const Input = styled.input<{ dark?: boolean }>`
  border: none;
  outline: none;
  height: 65px;
  width: 350px;
  background-color: ${({ theme }) => theme.main.lightPrimary};
  border-radius: 40px;
  color: ${({ theme }) => theme.main.primary};
  font-weight: bold;
  font-size: 26px;
  font-family: Raleway;
  text-align: center;

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: ${({ dark, theme }) =>
      dark ? theme.main.lightPrimary : theme.main.primary};
    opacity: 0.5;
  }

  @media (max-width: ${TABLET}) {
    width: 300px;
  }
`;

export const InputError = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.main.secondary};
  padding: 3px 14px 0px;
  font-family: Raleway;
  text-align: center;
`;

export const ErrorText = styled.p`
  font-size: 26px;
  color: ${({ theme }) => theme.main.secondary};
  padding: 12px 12px 18px;
  font-family: Roboto;
  font-weight: 600;
  width: 350px;
`;

export const InputContainer = styled.div`
  height: 59px;
  width: 350px;
  font-size: 16px;
  font-family: Raleway;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  position: relative;
`;

export const CraditCardLabel = styled.p`
  position: absolute;
  background: #f9f9f9;
  color: #585f63;
  left: 9px;
  top: -8px;
  font-size: 13px;
  padding: 0 3px;
`;

export const PasswordContainer = styled(Row)`
  width: 350px;
`;

export const EditButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 400;
  color: #34393c;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  outline: none;
  border: none;
  width: 386px;
  height: 90px;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.main.secondary};
  color: #fff;
  font-size: 36px;
  font-family: Raleway;
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.15);

  &:active {
    opacity: 0.5;
  }

  @media (max-width: ${TABLET}) {
    width: 350px;
  }
`;
