import React, { useEffect, useState } from 'react';
import * as S from './LiveWorkoutDetail.styles';
import ProfileImage from '../../components/ProfileImage';

interface IProps {
  startDatePassed: boolean;
  users: any;
}

const LiveWorkoutUsers = (props: IProps): React.ReactElement | null => {
  const { startDatePassed, users } = props;

  if (!users) {
    return null;
  }

  return (
    <div>
      <S.SectionTitle>
        {startDatePassed ? 'People Attended' : 'Sign Ups'}
      </S.SectionTitle>
      <S.PeopleCol>
        {users.length === 0 &&
          `Class has not been ${
            startDatePassed ? 'attended' : 'signed up'
          } by any users yet`}
        {users.map((lwUser: any) => {
          const userId = startDatePassed ? lwUser.user.id : lwUser.id;
          const firstName = startDatePassed
            ? lwUser.user.profile.firstName
            : lwUser.firstName;
          const lastName = startDatePassed
            ? lwUser.user.profile.lastName
            : lwUser.lastName;
          const profilePicturePublicId = startDatePassed
            ? lwUser.user.profile.profilePictureUrlPublicId
            : lwUser.profilePicturePublicId;

          return (
            <S.PepleRow key={userId}>
              <ProfileImage
                firstName={firstName}
                lastName={lastName}
                profilePictureUrlPublicId={profilePicturePublicId}
                userId={userId}
              />
              <S.PeopleName>
                {firstName} {lastName}
              </S.PeopleName>
            </S.PepleRow>
          );
        })}
      </S.PeopleCol>
    </div>
  );
};

export default LiveWorkoutUsers;
