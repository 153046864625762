import React, { useMemo } from 'react';
import { StripeUsersResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { api, Methods } from 'services/api';
import { Table } from 'components/Table';
import { DateTime } from 'luxon';
import { Loading } from 'components/Loading';
import { formatPrice } from 'util/helpers';

const CustomersAnalyticsTable: React.FC = () => {
  const { isLoading, data: customers } = useQuery<StripeUsersResponse[]>(
    'customers',
    async () =>
      api<StripeUsersResponse[]>(
        Methods.get,
        '/analytics/stripe/users',
        {},
        { timeout: 120000 },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const columns = useMemo<Column<StripeUsersResponse>[]>(
    () => [
      {
        Header: 'Customer',
        accessor: 'name',
      },
      {
        Header: 'Subscription',
        accessor: 'subscriptionName',
      },
      {
        Header: 'Subscription Price',
        accessor: 'price',
        Cell: (info: any) => {
          const value = info.row.original.price / 100 ?? 0;
          return formatPrice(value);
        },
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: (info: any) =>
          DateTime.fromISO(
            new Date(info.row.original.createdAt).toISOString(),
          ).toFormat('D'),
      },
      {
        Header: 'Next Payment',
        accessor: 'currentPeriodEnd',
        Cell: (info: any) =>
          DateTime.fromISO(
            new Date(info.row.original.currentPeriodEnd * 1000).toISOString(),
          ).toFormat('D'),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Payment Type',
        accessor: 'paymentMethodBrand',
      },
      {
        Header: 'Discount Code',
        accessor: 'discountCode',
        Cell: (info: any) => info.row.original.discountCode ?? 'N/A',
      },
      {
        Header: 'Canceled On',
        // need to use another field here since currentPeriodEnd is used on a column above
        accessor: 'subscriptionId',
        Cell: (info: any) => {
          if (info.row.original.status === 'canceled') {
            return DateTime.fromISO(
              new Date(info.row.original.currentPeriodEnd * 1000).toISOString(),
            ).toFormat('D');
          } else {
            return 'N/A';
          }
        },
      },
    ],
    [],
  );

  const data = useMemo<StripeUsersResponse[]>(
    () => (customers ? customers : []),
    [customers],
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table<StripeUsersResponse> columns={columns} data={data} />
      )}
    </div>
  );
};

export default CustomersAnalyticsTable;
