import { WorkoutResponse } from '@solin-fitness/types';
import CloudinaryImage from 'components/CloudinaryImage';
import { Play } from 'components/shared/Icons';
import React from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './programDragTypes';

const Title = styled.h4`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  color: ${(p) => p.theme.main.primary};
  margin-bottom: 8px;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 180px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const ImageWrap = styled.div`
  position: relative;
  width: 180px;
  height: 122px;
  border: 3px solid #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 10px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const PlayButtonWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div<{ isDragging: boolean }>`
  opacity: ${(p) => (p.isDragging ? 0.5 : 1)};
  cursor: ${(p) => (p.isDragging ? 'grabbing' : 'grab')};
`;

interface Props {
  workout: WorkoutResponse;
}

const OnDemandWorkoutTile: React.FC<Props> = ({ workout }) => {
  const { title, thumbnailPublicId } = workout;

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.ON_DEMAND_WORKOUT,
      item: workout,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [],
  );

  return (
    <Wrapper isDragging={isDragging} ref={drag}>
      <div>
        <Title>{title}</Title>
      </div>
      <ImageWrap>
        <CloudinaryImage
          publicId={thumbnailPublicId}
          alt="Workout thumbnail for program selection"
          width={180}
          height={122}
        />
        <PlayButtonWrap>
          <Play color="#fff" fill="#fff" />
        </PlayButtonWrap>
      </ImageWrap>
    </Wrapper>
  );
};

export default OnDemandWorkoutTile;
