import React, { useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { EDIT_VIDEO } from 'constants/routes';
import { WorkoutResponse } from '@solin-fitness/types';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import ScheduleWorkoutDrop from 'components/ManageWorkoutsTable/ScheduleWorkoutDrop';

interface Props {
  workout: WorkoutResponse;
  publishWorkout: (workout: WorkoutResponse) => void;
  deleteWorkout: (workout: WorkoutResponse) => void;
}

const WorkoutActionsCell = (props: Props) => {
  const { workout, publishWorkout, deleteWorkout } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);

  const history = useHistory();
  return (
    <>
      <Button
        variant="outlined"
        color={'primary'}
        disableElevation
        disabled={workout.uploadStatus === 'processing'}
        onClick={(e) => {
          e.preventDefault();
          publishWorkout(workout);
        }}
        id={`${workout.published ? 'workoutUnpublish' : 'workoutPublish'}${
          workout.title
        }`}
        style={{
          borderStyle: workout.published ? 'dotted' : 'solid',
          minWidth: 120,
          borderRadius: 35,
          marginRight: 8,
          marginBottom: 8,
        }}
      >
        {false ? (
          <CircularProgress size="1.5rem" />
        ) : workout.published ? (
          'Unpublish'
        ) : (
          'Publish'
        )}
      </Button>
      <IconButton
        style={{ marginRight: 8, marginBottom: 8 }}
        onClick={() => history.push(EDIT_VIDEO, { editWorkoutData: workout })}
      >
        <EditIcon id={`workoutEdit${workout.title}`} color="primary" />
      </IconButton>
      <IconButton
        style={{ marginRight: 8, marginBottom: 8 }}
        onClick={() => setOpenDeleteDialog(true)}
      >
        <DeleteIcon id={`workoutDelete${workout.title}`} color="secondary" />
      </IconButton>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete workout <span>{`${workout.title}`}</span> ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this workout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              deleteWorkout(workout);
              setOpenDeleteDialog(false);
            }}
            color="primary"
            autoFocus
            id={`deleteWorkoutConfirm${workout.title}`}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ScheduleWorkoutDrop
        workout={workout}
        isOpen={openSchedule}
        onCancel={() => setOpenSchedule(false)}
      />
    </>
  );
};

export default WorkoutActionsCell;
