import {
  EditProgramRequest,
  ProgramRequest,
  ProgramResponse,
} from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { api, Methods } from 'services/api';

export const getPrograms = async () =>
  await api<ProgramResponse[]>(Methods.get, '/programs?type=all');

export const usePrograms = (
  select?: (data: ProgramResponse[]) => ProgramResponse[],
) => useQuery('programs', getPrograms, { select });

export const createProgram = async (data: ProgramRequest) =>
  await api(Methods.post, '/programs', data);

export const editProgram = async (
  programId: number,
  data: EditProgramRequest,
) => await api(Methods.patch, `/programs/${programId}`, data);
