import React from 'react';
import { MessagePanel } from '../../React-components';
import { connect } from 'react-redux';
import { API_ROOT } from 'services/api';
import { ProfileResponse } from '@solin-fitness/types';

interface IProps {
  userData: ProfileResponse;
  isPrivateChannel: boolean;
  meetingId: string;
}

export const getCurrentChannel = (meetingId: string) => {
  const env = (API_ROOT || '').includes('staging') ? 'staging' : 'prod';
  return {
    id: `${meetingId}${env}`,
  };
};

const ChatPage = ({
  isPrivateChannel,
  userData,
  meetingId,
}: IProps): React.ReactElement => {
  if (userData === undefined) {
    return <>No user data</>;
  }

  const currentUser = userData && {
    uid: userData.id,
    displayName: `${userData.firstName} ${userData.lastName}`,
    photoURL: userData.profilePictureUrl || null,
  };

  const currentChannel = getCurrentChannel(meetingId);

  return (
    <MessagePanel
      isPrivateChannel={isPrivateChannel}
      key={currentChannel && currentChannel.id}
      currentChannel={currentChannel}
      currentUser={currentUser}
    />
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.user.data,
  currentChannel: state.channel.data,
  isPrivateChannel: state.channel.privateChannel,
  userPosts: state.channel.userPosts,
  primaryColor: state.color.primaryColor,
  secondaryColor: state.color.secondaryColor,
});

export default connect(mapStateToProps)(ChatPage);
