import React, { useState, useEffect } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AgoraRTC from 'agora-rtc-sdk-ng';
import MicIcon from 'assets/images/mic.png';
import SpeakerIcon from 'assets/images/speaker.png';

export const IconStyle = { color: 'white' };

export interface MediaDeviceInfo {
  readonly deviceId: string;
  readonly groupId: string;
  readonly kind: MediaDeviceKind;
  readonly label: string;
  toJSON(): any;
}

interface Props {
  onChangeMic: (mic: MediaDeviceInfo) => void;
  onChangeCam: (cam: MediaDeviceInfo) => void;
  currentMicName: string;
  currentCamName: string;
}

const SettingsLiveStream = ({
  onChangeMic,
  onChangeCam,
  currentMicName,
  currentCamName,
}: Props) => {
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[] | null>(
    null,
  );
  const [cameras, setCameras] = useState<MediaDeviceInfo[] | null>(null);
  const [selectedMic, setSelectedMic] = useState<MediaDeviceInfo | null>(null);
  const [selectedCam, setSelecteCam] = useState<MediaDeviceInfo | null>(null);

  useEffect(() => {
    const getDevices = async () => {
      const camerasRes = await AgoraRTC.getCameras();
      setCameras(camerasRes);
      //   if (currentCamName && camerasRes.length > 0) {
      //     const device = microphones.filter(
      //       mic => mic.label === currentCamName,
      //     )[0]
      //     setSelectedMic(device)
      //   }
      const microphonesRes = await AgoraRTC.getMicrophones();
      setMicrophones(microphonesRes);
      //   if (microphonesRes.length > 0 && currentMicName) {
      //     const device = cameras.filter(cam => cam.label === currentMicName)[0]
      //     setSelectedMic(device)
      //   }
    };
    getDevices();
  }, [currentCamName, currentMicName]);

  const handleChangeMic = (e: any) => {
    const label = e.target.value as string;
    const device = microphones?.filter((mic) => mic.label === label)[0];
    setSelectedMic(device as MediaDeviceInfo);
    onChangeMic(device as MediaDeviceInfo);
  };

  const handleChangeCam = (e: any) => {
    const label = e.target.value as string;
    const device = cameras?.filter((cam) => cam.label === label)[0];
    setSelecteCam(device as MediaDeviceInfo);
    onChangeCam(device as MediaDeviceInfo);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 30,
        }}
      >
        <img
          src={MicIcon}
          style={{ width: 30, height: 30, marginBottom: 0, marginRight: 10 }}
        />
        <FormControl style={{ marginRight: 50 }}>
          <Select
            labelId="select-outlined-label"
            id="select-outlined"
            value={selectedMic ? selectedMic.label : currentMicName}
            onChange={handleChangeMic}
            style={{ minWidth: 220 }}
            disableUnderline
            label={currentMicName.substring(0, 30)}
          >
            {microphones &&
              microphones.map((mic) => (
                <MenuItem key={mic.deviceId} value={mic.label}>
                  {mic.label.substring(0, 30)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={SpeakerIcon}
          style={{ width: 30, height: 30, marginBottom: 0, marginRight: 10 }}
        />

        <FormControl>
          <Select
            labelId="video-select-label"
            id="video-select"
            value={selectedCam ? selectedCam.label : currentCamName}
            onChange={handleChangeCam}
            label={currentCamName.substring(0, 30)}
            style={{ minWidth: 220 }}
            disableUnderline
          >
            {cameras &&
              cameras.map((cam) => (
                <MenuItem key={cam.deviceId} value={cam.label}>
                  {cam.label.substring(0, 30)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SettingsLiveStream;
