import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import { DateTime } from 'luxon';
import Button from 'components/Button';
import * as S from './PageOverview.styles';
import { GRAYS } from 'constants/theme';
import ClassPriceRow from './ClassPriceRow';
import EditMailchimp from './EditMailchimp';

interface Props {
  pageId: number;
  isMailchimpSetup: boolean;
  mailchimpAudienceId: string;
  mailchimpApiKey: string;
  mailchimpServerPrefix: string;
}

const PageMailchimp: React.FC<Props> = (props) => {
  const {
    pageId,
    isMailchimpSetup,
    mailchimpAudienceId,
    mailchimpApiKey,
    mailchimpServerPrefix,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <EditMailchimp
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        pageId={pageId}
        isMailchimpSetup={isMailchimpSetup}
        mailchimpApiKey={mailchimpApiKey}
        mailchimpAudienceId={mailchimpAudienceId}
        mailchimpServerPrefix={mailchimpServerPrefix}
      />
      <S.Section>
        <S.Container>
          <S.HeaderWrapper>
            <Typography variant="h4">Mailchimp Integration</Typography>
          </S.HeaderWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setIsOpen(true)}
            >
              Edit
            </Button>
          </S.EditButton>
        </S.Container>
        <div
          style={{
            margin: '0 -16px',
            borderBottom: `2px solid ${GRAYS.light}`,
          }}
        />
        <Row justifyContent="flex-start" style={{ padding: '12px 0' }}>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 200,
              textTransform: 'uppercase',
              marginRight: '20px',
            }}
          >
            Using Mailchimp Integration?
          </Typography>
          {isMailchimpSetup ? 'Yes' : 'No'}
        </Row>
        {isMailchimpSetup && (
          <>
            <Row
              justifyContent="flex-start"
              style={{
                padding: '12px 0',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: 200,
                  textTransform: 'uppercase',
                  marginRight: '20px',
                }}
              >
                Mailchimp API Key:
              </Typography>
              {mailchimpApiKey}
            </Row>
            <Row justifyContent="flex-start" style={{ padding: '12px 0' }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 200,
                  textTransform: 'uppercase',
                  marginRight: '20px',
                }}
              >
                Mailchimp Audience ID:
              </Typography>
              {mailchimpAudienceId}
            </Row>
            <Row justifyContent="flex-start" style={{ padding: '12px 0' }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 200,
                  textTransform: 'uppercase',
                  marginRight: '20px',
                }}
              >
                Mailchimp Server Prefix:
              </Typography>
              {mailchimpServerPrefix}
            </Row>
          </>
        )}
      </S.Section>
    </React.Fragment>
  );
};

export default PageMailchimp;
