import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { Radio, FormControlLabel } from '@material-ui/core';

type RadioProps = { label: string } & FieldAttributes<{}>;

const FormRadioButton: React.FC<RadioProps> = ({ label, ...props }) => {
  const [field] = useField<{}>(props);
  return (
    <FormControlLabel
      color="secondary"
      {...field}
      control={<Radio />}
      label={label}
    />
  );
};

export default FormRadioButton;
