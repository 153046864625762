import useThemeContext from 'hooks/useThemeContext';
import React, { useRef, useEffect } from 'react';
import AttendeeName from '../AttendeeName';
import * as S from './LiveWorkoutCreatorChat.styles';
import { Button } from 'components';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SendIcon from '@material-ui/icons/Send';
import { isMobile, isTablet } from 'react-device-detect';
import { useState, KeyboardEvent } from 'react';
import { FetchUserProfileResponse } from '@solin-fitness/types';
import Firebase, { ListenerType } from 'services/chat/Config/firebase';
import * as Colors from 'constants/theme';

const isMobileOrTablet = isMobile || isTablet;

interface Message {
  type: 'message';
  content?: string;
  timestamp: number;
  creator: {
    id: number;
    name: string;
    avataar: string;
  };
}

interface Props {
  messages: Message[];
  userData: FetchUserProfileResponse;
  currentChannelId: string;
  creatorId: number;
}

const messageRef = Firebase.database().ref('messages');

const LiveWorkoutCreatorChat: React.FC<Props> = ({
  messages,
  userData,
  currentChannelId,
  creatorId,
}) => {
  const theme = useThemeContext();
  const [chatText, setChatText] = useState('');

  const modifiedText = chatText.replace(/(?:\r\n|\r|\n)/g, '');

  const currentUser = userData;
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const handleChangeText = (e: any) => {
    setChatText(e.target.value);
  };

  // const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  //   if (e.key === 'Enter' && modifiedText !== '') {
  //     handleSend();
  //   }
  // };

  const handleSend = () => {
    if (modifiedText.length === 0) {
      return;
    }

    messageRef
      .child(currentChannelId)
      .push()
      .set({
        type: 'message',
        content: chatText,
        timestamp: Firebase.database.ServerValue.TIMESTAMP,
        creator: {
          id: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          avataar: currentUser.profilePictureUrlPublicId || null,
        },
      });

    setChatText('');
    // if (isMobileOrTablet) {
    //   setShowMobileChatBox(false);
    // }
  };

  return (
    <>
      <S.ChatOuterContainer>
        <S.ChatInnerContainer>
          {messages.map((val) => {
            const name = val.creator.name;
            return (
              <React.Fragment key={`${val.timestamp}${val.creator.id}`}>
                {val.creator.id === creatorId ? (
                  <p
                    style={{
                      fontSize: 15,
                      textAlign: 'right',
                      color: '#a2a2a2',
                    }}
                  >
                    {name}
                  </p>
                ) : (
                  <AttendeeName userId={val.creator.id} name={name} />
                )}

                <S.ChatBubble
                  style={{
                    backgroundColor: theme.main.primary,
                    color: 'white',
                  }}
                >
                  <S.Chat>{val.content}</S.Chat>
                </S.ChatBubble>
              </React.Fragment>
            );
          })}
          <div ref={lastMessageRef} />
        </S.ChatInnerContainer>
      </S.ChatOuterContainer>
      <S.TextBox>
        <S.TopWrap>
          <S.TextArea
            placeholder="start typing"
            value={chatText}
            onChange={handleChangeText}
            autoFocus={isMobileOrTablet ? true : false}
            style={{
              color: modifiedText.length > 0 ? '#F25252' : '#7d7d7d',
            }}
          />
          <S.MobileButton
            isActive={modifiedText.length > 0}
            onClick={handleSend}
          >
            <SendIcon style={{ fontSize: 29, color: 'white' }} />
          </S.MobileButton>
        </S.TopWrap>
        {!isMobileOrTablet && (
          <S.BottomWrap>
            <Button
              style={{
                height: 50,
                backgroundColor:
                  modifiedText.length > 0 ? '#F25252' : '#FDACAC',
                padding: 10,
                fontSize: 18,
                color: 'white',
                textTransform: 'capitalize',
                borderRadius: 10,
              }}
              fullWidth
              onClick={handleSend}
              endIcon={<PlayCircleFilledIcon style={{ fontSize: 40 }} />}
            >
              <div style={{ width: isMobileOrTablet ? 0 : 279 }}>
                Post to Channel
              </div>
            </Button>
          </S.BottomWrap>
        )}
      </S.TextBox>
    </>
  );
};

export default LiveWorkoutCreatorChat;
