import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { FormControlLabel, Checkbox, FormControlLabelProps } from '@material-ui/core';

interface CheckboxPropsBase
  extends Omit<
    FormControlLabelProps,
    'onChange' | 'value' | 'error' | 'variant' | 'helperText'
  > {
  readonly name: string;
  readonly placeholder?: string;
}

type FormikCheckboxProps = CheckboxPropsBase & FieldAttributes<{}>;

const FormikCheckbox: React.FC<FormikCheckboxProps> = (props) => {
  const [field] = useField<{}>(props);
  const value = props.control.props.value;

  return (
    <FormControlLabel
      {...props}
      {...field}
      control={<Checkbox checked={value} />}
    />
  );
};

export default FormikCheckbox;
