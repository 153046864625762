import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import { DateTime } from 'luxon';
import Button from 'components/Button';
import * as S from './PageOverview.styles';
import EditMetadata from 'components/ManagePage/EditMetadata';
import useThemeContext from 'hooks/useThemeContext';
import CloudinaryImage from 'components/CloudinaryImage';

interface Props {
  pageId: number;
  logo: string;
  lightLogo: string;
  name: string;
  description: string;
  bio: string;
}

const PageMetadata: React.FC<Props> = (props) => {
  const { pageId, logo, lightLogo, name, description, bio } = props;
  const theme = useThemeContext();
  const [open, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <EditMetadata
        isOpen={open}
        onCancel={() => setIsOpen(false)}
        pageId={pageId}
        pageLogoPublicId={logo}
        lightLogoPublicId={lightLogo}
        pageName={name}
        pageDescription={description}
        pageBio={bio}
      />
      <S.Section>
        <S.Container>
          <S.LogoWrapper>
            <S.Logo>
              <CloudinaryImage
                publicId={logo}
                width={96}
                height={96}
                alt={'page logo'}
                fetchFormat={'auto'}
                quality={'auto:best'}
                crop={'fit'}
              />
            </S.Logo>
            <S.Logo>
              <CloudinaryImage
                publicId={lightLogo}
                width={96}
                height={96}
                alt={'light logo'}
                fetchFormat={'auto'}
                quality={'auto:best'}
                crop={'fit'}
                style={{ background: 'rgba(0, 0, 0, 0.7)' }}
              />
            </S.Logo>
          </S.LogoWrapper>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setIsOpen(true)}
            >
              Edit
            </Button>
          </S.EditButton>
          <div>
            <Typography variant="h4">{name}</Typography>
            <Typography variant="body1">{description}</Typography>
            {bio && (
              <>
                <Typography variant="body2">Bio: {bio}</Typography>
              </>
            )}
          </div>
        </S.Container>
      </S.Section>
    </React.Fragment>
  );
};

export default PageMetadata;
