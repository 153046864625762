import React, { useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import * as S from './AccountInfo.styles';
// import * as I from 'components/Icons';
import { Formik, Form } from 'formik';
import { Row } from 'components/Row';
import * as yup from 'yup';
import { equals } from 'ramda';
import PaymentInput from '../PaymentInput';
import { PaymentMethodResult, StripeCardElement } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from 'react-query';
import {
  EditProfileRequest,
  FetchUserProfileResponse,
} from '@solin-fitness/types';
import { editUserProfile } from 'queries/user';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Column } from 'components/Column';
import { FormTextField } from 'components/FormTextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

interface AccountInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

interface Props {
  profile: FetchUserProfileResponse;
}

const validationSchema = yup.object({
  firstName: yup.string().required().min(1),
  lastName: yup.string().required().min(1),
  phoneNumber: yup.string().optional(),
  email: yup.string().required().min(1),
});

// todo: from user metadata paymentMethodId, use stripe api to get last 4

const AccountInfo: React.FC<Props> = (props) => {
  const { profile } = props;
  const queryClient = useQueryClient();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [hoverPassword, setHoverPassword] = useState<boolean>(false);
  const [hoverCard, setHoverCard] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPaymentInput, setShowPaymentInput] = useState<boolean>(false);
  const [cardComplete, setCardComplete] = useState<boolean>(false);

  const [error, setError] = useState<string>('');
  const initialValues = useMemo<AccountInfo>(
    () => ({
      firstName: profile.firstName,
      lastName: profile.lastName,
      phoneNumber: profile.phoneNumber || '',
      email: profile.email,
      password: '',
    }),
    [profile],
  );

  const handleEditProfile = useMutation(
    (data: EditProfileRequest) => editUserProfile(data),
    {
      onError: (err: any) => setError(err?.message || 'Please notify devs 🙈'),
      onSuccess: () => {
        setError('');
        queryClient.invalidateQueries('profile');
      },
    },
  );

  const handlePaymentMethod = async (
    fname: string,
    lname: string,
    email: string,
  ): Promise<string | undefined> => {
    if (!stripe || !elements) {
      setError('Uh oh, something went wrong with your payment card 😶');
      return undefined;
    }

    const card = elements.getElement(
      CardElement,
    ) as unknown as StripeCardElement;
    const { paymentMethod, error: stripeError }: PaymentMethodResult =
      await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: `${fname} ${lname}`,
          email,
        },
      });

    if (stripeError || !paymentMethod) {
      // todo: capture stripeError
      setError(
        stripeError?.message ||
          'Uh oh, something went wrong with your payment card 😶',
      );
      return undefined;
    }

    return paymentMethod.id;
  };

  return (
    <div style={{ width: 350 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);

          // if payment input has been filled out, generate payment method id
          // otherwise default to current payment method
          let paymentMethodId: string | undefined = profile.paymentMethodId;
          if (cardComplete) {
            paymentMethodId = await handlePaymentMethod(
              data.firstName,
              data.lastName,
              data.email,
            );
            if (!paymentMethodId) {
              setSubmitting(false);
              return;
            }
          }

          const { firstName, lastName, phoneNumber, email, password } = data;

          const passwordOption = !!password
            ? {
                password,
              }
            : {};

          const phoneNumberOption = !!phoneNumber
            ? {
                phoneNumber,
              }
            : {};
          //   handle edit profile

          await handleEditProfile.mutateAsync({
            firstName,
            lastName,
            email,
            ...(paymentMethodId ? { paymentMethodId } : {}),
            ...phoneNumberOption,
            ...passwordOption,
          });

          setOpenSnackbar(true);
          setShowPaymentInput(false);
          setFieldValue('password', '');
          setSubmitting(false);
          setCardComplete(false);
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <Form>
            <Column>
              <S.AccountInfoContainer>
                {error && <S.ErrorText>{error}</S.ErrorText>}
                <div style={{ width: '100%', marginBottom: 18 }}>
                  <FormTextField
                    type="text"
                    name="firstName"
                    label="First Name"
                  />
                </div>
                <div style={{ marginBottom: 18, width: '100%' }}>
                  <FormTextField
                    type="text"
                    name="lastName"
                    label="Last Name"
                  />
                </div>
                <div style={{ marginBottom: 18, width: '100%' }}>
                  <FormTextField
                    type="text"
                    name="phoneNumber"
                    label="Phone Number"
                  />
                </div>
                <div style={{ marginBottom: 25, width: '100%' }}>
                  <FormTextField type="text" name="email" label="Email" />
                </div>
                <S.InputContainer
                  style={{ marginBottom: 18, position: 'relative' }}
                >
                  {showPaymentInput || !profile.paymentMethodId ? (
                    <>
                      <div
                        style={{
                          width: '80%',
                          paddingLeft: 14,
                          paddingRight: 24,
                          paddingTop: 22,
                        }}
                      >
                        <PaymentInput
                          fontColor="#34393c"
                          backgroundColor="transparent"
                          onChange={(val: boolean) => setCardComplete(val)}
                        />
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          top: 20,
                          right: 24,
                          cursor: 'pointer',
                          opacity: hoverCard ? 0.5 : 1,
                        }}
                        onMouseEnter={() => setHoverCard(true)}
                        onMouseLeave={() => setHoverCard(false)}
                        onClick={() => {
                          setShowPaymentInput(false);
                          setCardComplete(false);
                        }}
                      >
                        {/* <I.XCircle /> */}
                      </div>
                    </>
                  ) : (
                    <Row
                      justifyContent="space-around"
                      style={{ height: '100%' }}
                    >
                      <p style={{ fontWeight: 400, color: '#34393C' }}>{`**** ${
                        profile.lastFour || ''
                      }`}</p>
                      <S.EditButton
                        type="button"
                        onClick={() => setShowPaymentInput(true)}
                      >
                        edit
                      </S.EditButton>
                    </Row>
                  )}
                  <S.CraditCardLabel>Credit Card</S.CraditCardLabel>
                </S.InputContainer>
                <S.PasswordContainer justifyContent="space-between">
                  <FormTextField
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="New Password"
                  />
                  <div
                    style={{
                      cursor: 'pointer',
                      opacity: hoverPassword ? 0.5 : 1,
                    }}
                    onMouseEnter={() => setHoverPassword(true)}
                    onMouseLeave={() => setHoverPassword(false)}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {/* <I.Eye color={theme.main.lightPrimary} /> */}
                  </div>
                </S.PasswordContainer>
              </S.AccountInfoContainer>
            </Column>
            <Button
              disabled={
                isSubmitting || (equals(initialValues, values) && !cardComplete)
              }
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              style={{
                opacity:
                  isSubmitting ||
                  (equals(initialValues, values) && !cardComplete)
                    ? 0.5
                    : 1,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                'Save Profile'
              )}
            </Button>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={'Profile updated'}
        autoHideDuration={3000}
      />
    </div>
  );
};

export default AccountInfo;
