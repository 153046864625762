import React from 'react';
import * as Routes from 'constants/routes';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { LiveWorkoutResponse } from '@solin-fitness/types';

interface Props {
  workout: LiveWorkoutResponse;
}

const JoinButton = ({ workout }: Props) => {
  const history = useHistory();

  const handleJoin = () => {
    if (workout.workoutVideoFormat === 'zoomLink' && window.open) {
      window.open(workout.zoomLink, '_blank');
    } else {
      history.push(Routes.LIVE_VIDEO_ROUTE(workout.id), {
        workoutData: workout,
      });
    }
  };

  return (
    <>
      <Button
        variant={'contained'}
        color="primary"
        id={`joinButton${workout.title}`}
        onClick={() => handleJoin()}
        style={{
          textTransform: 'capitalize',
          width: isMobile ? 80 : 130,
        }}
      >
        Join
      </Button>
    </>
  );
};

export default JoinButton;
