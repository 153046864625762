const getSymbolFromCurrency = require('currency-symbol-map');

export const getDisplayPrice = (
  amount: number,
  code: string,
  hideTwoDecimals?: boolean
): string => {
  const symbol = getSymbolFromCurrency(code);
  if (hideTwoDecimals) {
    return `${symbol}${amount / 100}`;
  }
  return `${symbol}${(amount / 100).toFixed(2)}`;
};
