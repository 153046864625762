import React from 'react';
import * as S from './WorkoutTile.styles';
import { WorkoutResponse } from '@solin-fitness/types';
import { DateTime } from 'luxon';
import { Row } from 'components/Row';
import { useDrag } from 'react-dnd';
import { ItemTypes } from 'components/DropCalendar/dragTypes';
import { Column } from 'components/Column';
import CloudinaryImage from 'components/CloudinaryImage';
import { imagePlaceholder } from 'constants/theme';

interface Props {
  workout: WorkoutResponse;
  canSchedule: boolean;
}

const WorkoutTile = ({ workout, canSchedule }: Props) => {
  const { title, thumbnailPublicId, length, createdAt } = workout;

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.WORKOUT,
      item: workout,
      canDrag: () => canSchedule,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [canSchedule],
  );

  return isDragging ? (
    <S.ItemWrap ref={dragPreview}>
      <S.ItemTitle>{title}</S.ItemTitle>
      <S.DraggingContainer>DROP ON DAY</S.DraggingContainer>
      <Row justifyContent="space-between" style={{ padding: '2px 8px' }}>
        <S.ItemTitle style={{ fontWeight: 700 }}>{length} min</S.ItemTitle>
        <S.ItemTitle>
          {DateTime.fromISO(
            createdAt as unknown as string,
          ).toRelativeCalendar()}
        </S.ItemTitle>
      </Row>
    </S.ItemWrap>
  ) : (
    <S.ItemWrap
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: canSchedule ? 'move' : 'default',
      }}
    >
      <S.ItemTitle>{title}</S.ItemTitle>
      <Column>
        <S.ItemImage>
          <CloudinaryImage
            publicId={thumbnailPublicId || imagePlaceholder}
            width={275}
            height={175}
            alt={'workout tile thumbnail'}
            fetchFormat={'auto'}
            quality={'auto'}
            crop={'scale'}
          />
        </S.ItemImage>
      </Column>
      <Row
        justifyContent="space-around"
        alignItems="baseline"
        style={{ padding: '4px 8px' }}
      >
        <S.ItemTitle style={{ fontWeight: 700 }}>{length} min</S.ItemTitle>
        <S.ItemTitle>
          {DateTime.fromISO(
            createdAt as unknown as string,
          ).toRelativeCalendar()}
        </S.ItemTitle>
      </Row>
    </S.ItemWrap>
  );
};

export default WorkoutTile;
