import { useMemo } from 'react';
import { ProgramPurchases, ProgramResponse } from '@solin-fitness/types';
import { PaginationTable } from 'components/Table';
import { DateTime } from 'luxon';
import { Column } from 'react-table';
import styled from 'styled-components';
import { ChevronLeft } from 'components/shared/Icons';
import { useHistory } from 'react-router';
import { sort } from 'ramda';
import Button from 'components/Button';
import DownloadIcon from '@material-ui/icons/GetApp';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 8px 12px;
  padding-left: 4px;
  border-radius: 30px;
  cursor: pointer;
  transition: 200ms ease-out;
  color: #000;

  &:hover {
    background-color: ${(p) => p.theme.main.primary};
    color: #fff;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BackText = styled.p`
  font-size: 18px;
  font-family: 'Raleway';
  line-height: 18px;
  color: inherit;
`;

const Header = styled.h1`
  font-family: 'Raleway';
  font-size: 30px;
  color: #000;
  flex: 1;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

interface Props {
  program: ProgramResponse;
}

interface SignUpTable {
  name: string;
  email: string;
  purchaseDate: string;
  amount: string;
}

const ProgramSignUpsTable = ({ program }: Props) => {
  const history = useHistory();
  const purchases = program?.purchases || [];

  const purchasesSorted = sort((a: ProgramPurchases, b: ProgramPurchases) => {
    const aDate = new Date(a.purchaseDate).getTime();
    const bDate = new Date(b.purchaseDate).getTime();
    return -1 * (aDate - bDate);
  }, purchases);

  const tableData = useMemo<SignUpTable[]>(
    () =>
      purchasesSorted.map((p) => ({
        name: p.user
          ? `${p.user.profile.firstName} ${p.user.profile.lastName}`
          : 'null',
        email: p.user ? p.user.email : 'null',
        purchaseDate: DateTime.fromISO(
          new Date(p.purchaseDate).toISOString(),
        ).toFormat('ff'),
        amount: `$${p.amount / 100}`,
      })),
    [purchasesSorted],
  );

  const columns = useMemo<Column<SignUpTable>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Date of Purchase',
        accessor: 'purchaseDate',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
    ],
    [],
  );

  const hadleExportData = () => {
    const csvFileData = tableData.map((item) => [
      item.name,
      item.email,
      item.purchaseDate,
      item.amount,
    ]);
    let csv = 'Name,Email,Date of purchase,Time of Purchase,Amount\n';
    csvFileData.forEach((row) => {
      csv += row.join(',');
      csv += '\n';
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${program.title.replace(
      '.',
      '',
    )}_purchases_${DateTime.fromISO(new Date().toISOString()).toFormat('ff')}`;
    hiddenElement.click();
  };

  return (
    <Container>
      <Row>
        <HeaderContainer onClick={() => history.goBack()}>
          <ChevronLeft />
          <BackText>Programs & Challenges</BackText>
        </HeaderContainer>
        <Button
          onClick={hadleExportData}
          variant="outlined"
          color="primary"
          endIcon={<DownloadIcon />}
        >
          CSV
        </Button>
      </Row>
      <Header>{program.title}</Header>

      <PaginationTable<SignUpTable>
        columns={columns}
        data={tableData}
        pagination={20}
      />
    </Container>
  );
};

export default ProgramSignUpsTable;
