import React from 'react';
import { IconProps } from './types';
import { Svg } from './Misc';

export const EditIcon = (props: IconProps): React.ReactElement => (
  <Svg
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
  </Svg>
);
