import CircularProgress from '@material-ui/core/CircularProgress';
import ImageUpload from 'components/FileUploads/ImageUpload/ImageUpload';
import VideoUpload from 'components/FileUploads/VideoUpload/VideoUpload';
import { PROGRAMS } from 'constants/routes';
import { ProgramAssets } from 'context/ProgramBuilderContext';
import { Form, Formik } from 'formik';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import useThemeContext from 'hooks/useThemeContext';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  Button,
  ButtonText,
  ButtonTypes,
  circularProgressStyle,
  SubmitButtonContainer,
} from './ProgramDetailsForm';

const Header = styled.h2`
  font-family: 'Raleway';
  font-size: 20px;
  color: #000;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 40px;
`;

const UploadRow = styled.div`
  display: flex;
  gap: 8px;
`;

const UploadInfoColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 32px;
  flex: 0 1 400px;
`;

const UploadContainer = styled.div`
  min-width: 315px;
  min-height: 190px;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  font-family: 'Raleway';
  color: #000;
`;

interface AssetInitialValues {
  backgroundImage: string;
  video: string;
  backgroundImage2: string;
  backgroundImage3: string;
  verticalBackgroundImage: string;
}

const validationSchema = yup.object({
  backgroundImage: yup.string().required(),
  video: yup.string().optional(),
  backgroundImage2: yup.string().optional(),
  backgroundImage3: yup.string().optional(),
  verticalBackgroundImage: yup.string().optional(),
});

interface Props {
  programAssets: ProgramAssets;
}

const ProgramAssetsForm = ({ programAssets }: Props) => {
  const theme = useThemeContext();
  const history = useHistory();

  const [isSubmittingExit, setIsSubmittingExit] = useState<boolean>(false);
  const [isExitState, setIsExitState] = useState<boolean>(false);
  const [isSubmittingContinue, setIsSubmittingContinue] =
    useState<boolean>(false);

  const initialValues: AssetInitialValues = {
    backgroundImage: programAssets.thumbnail,
    video: programAssets.video,
    backgroundImage2: programAssets.backgroundImage2 || '',
    backgroundImage3: programAssets.backgroundImage3 || '',
    verticalBackgroundImage: programAssets.verticalBackgroundImage || '',
  };

  const { isEditing, handleSaveProgramAssets, handleUpdateProgramAssets } =
    useProgramBuilder();

  const handleSave = async (
    data: AssetInitialValues,
    editing: boolean,
    toExit: boolean,
  ) => {
    const programAssetsData: ProgramAssets = {
      thumbnail: data.backgroundImage,
      video: data.video,
      backgroundImage2: data.backgroundImage2 || undefined,
      backgroundImage3: data.backgroundImage3 || undefined,
      verticalBackgroundImage: data.verticalBackgroundImage || undefined,
    };

    if (!editing) {
      handleSaveProgramAssets(programAssetsData, toExit);
    } else {
      await handleUpdateProgramAssets(programAssetsData, toExit);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data) => {
          // should be of type SubmitEvent
          const submitterExit =
            (window.event as any)?.submitter?.name === ButtonTypes.exit ||
            isExitState;
          if (submitterExit) {
            setIsSubmittingExit(true);

            await handleSave(data, isEditing, true);

            setIsSubmittingExit(false);

            history.push(PROGRAMS);
          } else {
            setIsSubmittingContinue(true);

            await handleSave(data, isEditing, false);

            setIsSubmittingContinue(false);
          }
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form style={{ marginBottom: 0 }}>
            <Container>
              <UploadRow>
                <UploadInfoColumn>
                  <Header>Program Background Image - Vertical</Header>

                  <DescriptionText>
                    This image will be front and center on the landing page of
                    your program on Mobile
                  </DescriptionText>
                </UploadInfoColumn>
                <UploadContainer>
                  <ImageUpload
                    thumbnail={values.verticalBackgroundImage}
                    fileName="verticalBackgroundImage"
                    handleThumbnail={(value) =>
                      setFieldValue('verticalBackgroundImage', value)
                    }
                    aspectRatio={9 / 16}
                  />
                </UploadContainer>
              </UploadRow>
              <UploadRow>
                <UploadInfoColumn>
                  <Header>
                    Program Background Image - Horizontal
                    <span style={{ fontSize: 14, color: theme.main.secondary }}>
                      {'   '}required
                    </span>
                  </Header>

                  <DescriptionText>
                    This image will be front and center on the landing page of
                    your program
                  </DescriptionText>
                </UploadInfoColumn>
                <UploadContainer>
                  <ImageUpload
                    thumbnail={values.backgroundImage}
                    fileName="backgroundImage"
                    handleThumbnail={(value) =>
                      setFieldValue('backgroundImage', value)
                    }
                  />
                </UploadContainer>
              </UploadRow>
              <UploadRow>
                <UploadInfoColumn>
                  <Header>Program Preview Video</Header>
                  <DescriptionText>
                    This will be used to show consumer's what the program is
                    about in a short litle video. It will be on the program
                    landing page.
                  </DescriptionText>
                </UploadInfoColumn>
                <UploadContainer>
                  <VideoUpload
                    video={values.video}
                    handleVideo={(value) => setFieldValue('video', value)}
                  />
                </UploadContainer>
              </UploadRow>
              <UploadRow>
                <UploadInfoColumn>
                  <Header>What You Get Image</Header>
                  <DescriptionText>
                    This image will on the program landing page next to the
                    highlights.
                  </DescriptionText>
                </UploadInfoColumn>
                <UploadContainer>
                  <ImageUpload
                    thumbnail={values.backgroundImage2}
                    fileName="backgroundImage2"
                    handleThumbnail={(value) =>
                      setFieldValue('backgroundImage2', value)
                    }
                  />
                </UploadContainer>
              </UploadRow>
              <UploadRow>
                <UploadInfoColumn>
                  <Header>From Creator Image</Header>
                  <DescriptionText>
                    This image will be on the program landing page and will be
                    next to the program's description
                  </DescriptionText>
                </UploadInfoColumn>
                <UploadContainer>
                  <ImageUpload
                    thumbnail={values.backgroundImage3}
                    fileName="backgroundImage3"
                    handleThumbnail={(value) =>
                      setFieldValue('backgroundImage3', value)
                    }
                  />
                </UploadContainer>
              </UploadRow>
              <SubmitButtonContainer>
                {isEditing ? (
                  <Button
                    name={ButtonTypes.exit}
                    onClick={() => setIsExitState(true)}
                    type="submit"
                    bg="#fff"
                  >
                    {isSubmittingExit ? (
                      <CircularProgress style={circularProgressStyle} />
                    ) : (
                      <ButtonText color={theme.main.secondary}>
                        {'Save & Exit'}
                      </ButtonText>
                    )}
                  </Button>
                ) : null}
                <Button
                  name={ButtonTypes.continue}
                  type="submit"
                  bg={theme.main.secondary}
                >
                  {isSubmittingContinue ? (
                    <CircularProgress style={circularProgressStyle} />
                  ) : (
                    <ButtonText color="#fff">{'Save & Continue'}</ButtonText>
                  )}
                </Button>
              </SubmitButtonContainer>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProgramAssetsForm;
