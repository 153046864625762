import { useContext } from 'react';
import { WorkoutDropContext } from 'context/WorkoutDropContext';

export const useWorkoutDrop = () => {
  const context = useContext(WorkoutDropContext);
  if (context === undefined) {
    throw new Error('useWorkoutDrop must be used within a WorkoutDropProvider');
  }
  return context;
};
