import React, { useMemo } from 'react';
import { LiveWorkoutAnalyticsResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { Column, Row } from 'react-table';
import * as Routes from 'constants/routes';
import { api, Methods } from 'services/api';
import { useHistory } from 'react-router-dom';
import { PaginationTable } from 'components/Table';

import WorkoutOverviewCell from 'components/ManageWorkoutsTable/WorkoutOverviewCell';
import { DateTime } from 'luxon';
import { Loading } from 'components/Loading';
import { formatPrice } from 'util/helpers';

const LiveWorkoutAnalyticsTable: React.FC = () => {
  const { isLoading, data: liveWorkouts } = useQuery<
    LiveWorkoutAnalyticsResponse[]
  >('liveWorkoutsAnalytics', async () =>
    api<LiveWorkoutAnalyticsResponse[]>(
      Methods.get,
      '/analytics/live-workouts',
    ),
  );
  const history = useHistory();

  const goToLiveWorkoutDetail = (liveWorkout: LiveWorkoutAnalyticsResponse) => {
    const liveWorkoutId: number = liveWorkout.id;
    history.push(Routes.LIVE_WORKOUT_DETAIL_ROUTE(liveWorkoutId), {
      liveWorkoutId,
    });
  };

  const customSort = useMemo(
    () =>
      (
        rowA: Row<LiveWorkoutAnalyticsResponse>,
        rowB: Row<LiveWorkoutAnalyticsResponse>,
      ) => {
        const aTitle = rowA.values.title;
        const bTitle = rowB.values.title;
        return aTitle < bTitle ? -1 : 1;
      },
    [],
  );

  const columns = useMemo<Column<LiveWorkoutAnalyticsResponse>[]>(
    () => [
      {
        Header: 'Live Workout',
        accessor: 'title',
        sortType: customSort,
        Cell: ({
          row,
        }: {
          row: { original: LiveWorkoutAnalyticsResponse };
        }) => (
          <WorkoutOverviewCell
            thumbnailPublicId={row.original.thumbnailPublicId}
            title={row.original.title}
            description={row.original.description}
          />
        ),
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: (info: any) =>
          DateTime.fromISO(
            new Date(info.row.original.startDate).toISOString(),
          ).toFormat('D'),
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: (info: any) => {
          const value = info.row.original.price / 100 ?? 0;
          return formatPrice(value);
        },
      },
      {
        Header: 'Attendees',
        accessor: 'numberOfAttendees',
      },
      {
        Header: 'Drop Ins',
        accessor: 'earnedFromDropIns',
        Cell: (info: any) => {
          const value = info.row.original.earnedFromDropIns ?? 0;
          return formatPrice(value);
        },
      },
      {
        Header: 'Recorded',
        accessor: 'recordLiveWorkout',
        Cell: (info: any) =>
          `${info.row.original.recordLiveWorkout ? 'Yes' : 'No'}`,
      },
    ],
    [],
  );

  const data = useMemo<LiveWorkoutAnalyticsResponse[]>(
    () => (liveWorkouts ? liveWorkouts : []),
    [liveWorkouts],
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <PaginationTable<LiveWorkoutAnalyticsResponse>
          columns={columns}
          data={data}
          handleRowClick={goToLiveWorkoutDetail}
          pagination={20}
        />
      )}
    </div>
  );
};

export default LiveWorkoutAnalyticsTable;
