import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { PHONE } from 'constants/measurements';
import { useQuery } from 'react-query';
import { Page } from 'types';
import CreateLiveWorkoutForm from 'components/CreateLiveWorkoutForm';
import { getPage } from 'queries/page';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const Wrap = styled.div`
  width: 500px;
  margin-top: 20px;
  @media (max-width: ${PHONE}) {
    margin-top: 10px;
    width: 90%;
  }
`;

const CreateWorkout = (): React.ReactElement => {
  const { isLoading, data: pageQuery } = useQuery<Page, Error>(
    'creatorPage',
    () => getPage(),
  );

  if (isLoading || !pageQuery) {
    return (
      <Layout title="Schedule workout">
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <CircularProgress />
        </div>
      </Layout>
    );
  }

  const liveConfig = pageQuery.config;
  const tags = pageQuery.config.workoutTags;

  // TODO QueryParamMessage
  return (
    <Layout title="Schedule workout">
      {isLoading && !pageQuery ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Wrap>
            {/* <QueryParamMessage /> */}
            <CreateLiveWorkoutForm
              types={tags || []}
              classPrices={liveConfig.classPrices}
            />
          </Wrap>
        </Container>
      )}
    </Layout>
  );
};

export default CreateWorkout;
