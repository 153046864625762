import { EventResponse } from '@solin-fitness/types';
import { Row } from 'components/Row';
import { ChevronLeft } from 'components/shared/Icons';
import React from 'react';
import styled from 'styled-components';
import { Image, Transformation } from 'cloudinary-react';
import { DateTime } from 'luxon';
import useThemeContext from 'hooks/useThemeContext';
import { Link, useHistory } from 'react-router-dom';

const BackText = styled.p`
  font-size: 16px;
  font-family: 'Roboto';
  color: #000;
`;

const LabelText = styled.p`
  font-size: 24px;
  font-family: 'Roboto';
  color: var(--color, #000);
  font-weight: var(--font-weight, 400);
  padding: var(--padding);
`;

const DescriptionText = styled.p`
  font-size: 20px;
  font-family: 'Roboto';
  color: var(--color, #000);
  font-weight: var(--font-weight, 200);
  padding: var(--padding);
`;

const Container = styled.div`
  padding: 46px 10%;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 62px;
`;

const EventInfoContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
`;

const JoinButton = styled(Link)`
  margin-top: 8px;
  background-color: ${(p) => p.theme.main.primary};
  border-radius: 8px;
  width: 182px;
  height: 53px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AttendeeRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Attendee = ({
  firstName,
  lastName,
  image,
}: {
  firstName: string;
  lastName: string;
  image: string | undefined;
}) => (
  <AttendeeRow>
    {image ? (
      <Image
        cloudName="solin-fitness"
        publicId={image}
        width={50}
        height={50}
        style={{ paddingRight: 20 }}
      >
        <Transformation
          crop="fit"
          width={50}
          height={50}
          fetchFormat="auto"
          quality="auto"
        />
      </Image>
    ) : (
      <div
        style={{
          width: 50,
          height: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1.5px solid green',
        }}
      >
        <LabelText>{`${firstName.slice(0.1)}${lastName.slice(
          0,
          1,
        )}`}</LabelText>
      </div>
    )}
    <DescriptionText>{`${firstName} ${lastName}`}</DescriptionText>
  </AttendeeRow>
);

interface Props {
  event: EventResponse;
}

const Event: React.FC<Props> = ({ event }) => {
  const {
    backgroundImagePublicId,
    title,
    host,
    startDate,
    description,
    length,
    amount,
    usersThatPurchased,
  } = event;
  const theme = useThemeContext();
  const history = useHistory();

  // Jul 7, 2021 8:30am

  return (
    <section>
      <Row
        justifyContent="flex-start"
        style={{ cursor: 'pointer' }}
        onClick={() => history.goBack()}
      >
        <ChevronLeft color="#000" />
        <BackText>Back</BackText>
      </Row>
      <Container>
        <EventContainer>
          <Image
            cloudName="solin-fitness"
            publicId={backgroundImagePublicId}
            width={355}
            height={195}
            style={{
              borderRadius: 20,
              boxShadow: '0px 4px 24px 6px rgba(0,0,0,0.25)',
            }}
          >
            <Transformation
              crop="thumb"
              width={355}
              height={195}
              fetchFormat="auto"
              quality="auto:best"
            />
          </Image>
          <EventInfoContainer>
            <div style={{ flex: 1 }}>
              <LabelText>{title}</LabelText>
              <DescriptionText>{`with ${host.profile.firstName} ${host.profile.lastName}`}</DescriptionText>
            </div>
            <div style={{ flex: 1, paddingTop: 24 }}>
              <LabelText style={{ '--font-weight': 200 }}>
                {DateTime.fromJSDate(new Date(startDate)).toFormat(
                  'MMM d, yyyy h:mma',
                )}
              </LabelText>
              <JoinButton
                to={{
                  pathname: `/events/${event.id}/room`,
                  state: {
                    event,
                  },
                }}
              >
                <LabelText style={{ '--color': '#fff', '--font-weight': 200 }}>
                  Join
                </LabelText>
              </JoinButton>
            </div>
          </EventInfoContainer>
        </EventContainer>
        <LabelText style={{ '--color': theme.main.primary }}>
          Description
        </LabelText>
        <DescriptionText
          style={{ '--padding': '10px 0px 30px' }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <LabelText style={{ '--color': theme.main.primary }}>
          Duration
        </LabelText>
        <DescriptionText
          style={{ '--padding': '10px 0px 30px' }}
        >{`${length} minutes`}</DescriptionText>
        <LabelText style={{ '--color': theme.main.primary }}>Price</LabelText>
        <DescriptionText style={{ '--padding': '10px 0px 30px' }}>
          ${Math.round(amount / 100)}
        </DescriptionText>
        {!!usersThatPurchased?.length ? (
          <>
            <LabelText style={{ '--color': theme.main.primary }}>
              {`${usersThatPurchased.length} Confirmed Attendees`}
            </LabelText>
            {usersThatPurchased.map((user, index) => (
              <Attendee
                key={index}
                firstName={user.profile.firstName}
                lastName={user.profile.lastName}
                image={user.profile.profilePictureUrlPublicId}
              />
            ))}
          </>
        ) : (
          <LabelText style={{ '--color': theme.main.primary }}>
            No Confirmed Attendees Yet
          </LabelText>
        )}
      </Container>
    </section>
  );
};

export default Event;
