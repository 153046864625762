import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { useState, useEffect } from 'react';
import Button from 'components/Button';
import { Typography } from '@material-ui/core';

interface Props {
  title: string;
  selected: boolean;
  handleToggle: (value: boolean) => Promise<void>;
}

const NotificationToggle: React.FC<Props> = (props) => {
  const { title, selected, handleToggle } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailToggle, setEmailToggle] = useState<boolean>(!!selected);

  const handleEmailToggle = () => {
    setEmailToggle(!emailToggle);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    await handleToggle(emailToggle);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex' }}>
        <ToggleWrapper>
          <ToggleText>{title}</ToggleText>
          <Switch
            checked={emailToggle}
            size="medium"
            color="primary"
            onChange={handleEmailToggle}
          />
        </ToggleWrapper>
        {selected !== emailToggle ? (
          <Button
            // fullWidth
            disabled={false}
            variant="contained"
            size="small"
            color="primary"
            type="submit"
            style={{
              borderRadius: 35,
              marginLeft: 20,
            }}
          >
            <Typography variant="button">
              {isLoading ? 'Saving' : 'Save'}
            </Typography>
          </Button>
        ) : null}
      </div>
    </form>
  );
};

const ToggleWrapper = styled.div`
  background: white;
  padding: 2px 8px;
  border-radius: 30px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding-left: 14px;
`;

const ToggleText = styled.p`
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

export default NotificationToggle;
