import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

export type ListenerType = (
  a: firebase.database.DataSnapshot | null,
  b?: string | null | undefined,
) => any;

const firebaseConfig = {
  apiKey: 'AIzaSyBfulzr084bUyk2fqCgyBcusYXjo4t4CQA',
  authDomain: 'solintv-2398f.firebaseapp.com',
  projectId: 'solintv-2398f',
  storageBucket: 'solintv-2398f.appspot.com',
  messagingSenderId: '818764606997',
  appId: '1:818764606997:web:ee64eea4c0ee9c0caea81c',
  databaseURL: 'https://solintv-2398f-default-rtdb.firebaseio.com/',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
