import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { FormTextField } from 'components/FormTextField';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import { Column } from 'components/Column';
import { equals } from 'ramda';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { editPageMetadata } from 'queries/page';
import { EditConfigMetadata } from '@solin-fitness/types';
import styled from 'styled-components';

const BulletPointsList = styled.ul`
  margin-bottom: 0;
  padding: 0;
  padding-top: 16px;
  width: 80%;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const DeleteButton = styled.button`
  border: none;
  outline: none;
  color: red;
  border-radius: 4px;
  font-size: 18px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #ffcccb;
  }
`;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  pageId: number;
  listItems: string[];
}

const EditToggles: React.FC<Props> = (props) => {
  const { isOpen, onCancel, pageId, listItems } = props;
  const initialValues = {
    listItems,
    newListItem: '',
  };
  const [error, setError] = useState('');
  const queryClient = useQueryClient();

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal top={5} width={800} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Edit Sales Bullets
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await editMetadata.mutateAsync({
            id: pageId,
            data: {
              landingPageListItems: data.listItems,
            },
          });

          setSubmitting(false);
          setError('');
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form style={{ marginBottom: 0 }}>
            <Column
              alignItems="flex-start"
              style={{ paddingBottom: 24, width: '100%' }}
            >
              <Row
                justifyContent="space-around"
                alignItems="flex-start"
                style={{ paddingTop: 24 }}
              >
                <Column alignItems="flex-start" style={{ flex: 1 }}>
                  <Typography variant="h6">Bullet Point Items</Typography>
                  <FieldArray name="listItems">
                    {(arrayHelpers) => (
                      <>
                        <BulletPointsList>
                          {values.listItems.map((val, index) => (
                            <li key={`listItems.${index}`}>
                              <ListItem>
                                <Typography variant="body1">{val}</Typography>
                                <DeleteButton
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  &#10005;
                                </DeleteButton>
                              </ListItem>
                            </li>
                          ))}
                        </BulletPointsList>
                        <Row style={{ paddingBottom: 12 }}>
                          <FormTextField name="newListItem" />
                          <Button
                            size="large"
                            disabled={(values.listItems?.length || 0) >= 3}
                            onClick={() => {
                              arrayHelpers.push(values.newListItem);
                              setFieldValue('newListItem', '');
                            }}
                            color="primary"
                            style={{ margin: 16, fontSize: '1.2rem' }}
                          >
                            add
                          </Button>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                </Column>
              </Row>
            </Column>
            {!equals(
              {
                ...initialValues,
              },
              {
                landingPageListItems: values.listItems,
              },
            ) && (
              <Button
                fullWidth
                disabled={isSubmitting}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{
                  borderRadius: 35,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="inherit" size="2rem" />
                ) : (
                  <Typography variant="h6">Save</Typography>
                )}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditToggles;
