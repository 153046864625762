import {
  ProgramResponse,
  ProgramSessionBaseRequest,
  ProgramSessionResponse,
  ProgramSessionType,
  ProgramTextSessionRequest,
  ProgramDocumentSessionRequest,
  ProgramOnDemandSessionRequest as ProgramOnDemandSessionRequestTypes,
  ProgramLiveWorkoutSessionRequest as ProgramLiveWorkoutSessionRequestTypes,
  EditProgramRequest,
  ProgramRequest,
  ProgramPeriodType,
} from '@solin-fitness/types';
import {
  FullProgram,
  Program,
  ProgramDetails,
  ProgramPrice,
  ProgramSessionInterface,
  ProgramOnDemandSessionRequest,
  ProgramLiveWorkoutSessionRequest,
  ProgramSessionEmptyType,
  ProgramAssets,
} from 'context/ProgramBuilderContext';

const convertToProgramSession = (
  session: ProgramSessionResponse,
): ProgramSessionInterface => {
  const base: ProgramSessionBaseRequest = {
    type: session.type,
    week: session.week,
    day: session.day,
    session: session.session,
  };

  switch (session.type) {
    case ProgramSessionType.ON_DEMAND_WORKOUT:
      const onDemand: ProgramOnDemandSessionRequest = {
        ...base,
        workoutId: session.workout!.id,
        thumbnail: session.workout!.thumbnailPublicId,
        title: session.workout!.title,
      };
      return onDemand;
    case ProgramSessionType.LIVE_WORKOUT:
      const liveWorkout: ProgramLiveWorkoutSessionRequest = {
        ...base,
        liveWorkoutId: session.liveWorkout!.id,
        thumbnail: session.liveWorkout!.thumbnailPublicId,
        title: session.liveWorkout!.title,
      };
      return liveWorkout;
    case ProgramSessionType.TEXT:
      const text: ProgramTextSessionRequest = {
        ...base,
        title: session.title!,
        description: session.description!,
        length: session.length!,
        thumbnail: session.thumbnail!,
      };
      return text;
    case ProgramSessionType.DOCUMENT:
      const document: ProgramDocumentSessionRequest = {
        ...base,
        title: session.title!,
        description: session.description!,
        length: session.length!,
        thumbnail: session.thumbnail!,
        document: session.document!,
      };
      return document;
  }
};

const fillEmpties = (data: ProgramSessionInterface[][]) => {
  const updatedWeek: ProgramSessionInterface[][] = [];
  for (const day of data) {
    if (!!day) {
      updatedWeek.push(day);
    } else {
      updatedWeek.push([]);
    }
  }

  return updatedWeek;
};

export const convertToFullProgram = (data: ProgramResponse): FullProgram => {
  const price: ProgramPrice = {
    amount: data.amount / 100,
    isIncludedInMembership: data.isIncludedInMembership,
  };

  const details: ProgramDetails = {
    type: data.type,
    title: data.title,
    description: data.description,
    details: data.details,
    testimonials: data.testimonials,
  };

  const assets: ProgramAssets = {
    thumbnail: data.backgroundImagePublicId,
    video: data.video,
    backgroundImage2: data.backgroundImage2,
    backgroundImage3: data.backgroundImage3,
    verticalBackgroundImage: data.verticalBackgroundImage,
  };

  const program: Program = [];
  data.sessions.forEach((val) => {
    const { week, day, session: sessionIndex } = val;
    // check if week exists
    if (!program?.[week]) {
      program[week] = [];
    }
    // check if day exists
    if (!program[week]?.[day]) {
      program[week][day] = [];
    }
    // insert session
    program[week][day][sessionIndex] = convertToProgramSession(val);
  });

  // re fill empty weeks and days so they are included in the UI
  // when showing the program content
  const filledProgram: Program = [];
  for (const week of program) {
    if (!!week) {
      const filledWeek = fillEmpties(week);
      filledProgram.push(filledWeek);
    } else {
      filledProgram.push([[]]);
    }
  }

  if (!!!filledProgram.length) {
    filledProgram.push([[]]);
  }

  return {
    program: filledProgram,
    details,
    assets,
    price,
    programComplete: true,
    detailsComplete: true,
    priceComplete: true,
    assetsComplete: true,
  };
};

type ProgramSessionRequest = (
  | ProgramOnDemandSessionRequestTypes
  | ProgramDocumentSessionRequest
  | ProgramTextSessionRequest
  | ProgramLiveWorkoutSessionRequestTypes
)[];

export const convertToProgramSessionsRequest = (
  program: Program,
): ProgramSessionRequest => {
  // flatten 3d array
  const allSessions = program.flat().flat();
  const sessionsWithEmpties: (
    | ProgramOnDemandSessionRequestTypes
    | ProgramDocumentSessionRequest
    | ProgramTextSessionRequest
    | ProgramLiveWorkoutSessionRequestTypes
    | undefined
  )[] = allSessions.map((val) => {
    if (val.type === ProgramSessionEmptyType.EMPTY) {
      return undefined;
    }

    if (val.type === ProgramSessionType.ON_DEMAND_WORKOUT) {
      const data = val as ProgramOnDemandSessionRequest;
      const onDemand: ProgramOnDemandSessionRequestTypes = {
        type: data.type,
        week: data.week,
        day: data.day,
        session: data.session,
        workoutId: data.workoutId,
      };
      return onDemand;
    }

    if (val.type === ProgramSessionType.LIVE_WORKOUT) {
      const data = val as ProgramLiveWorkoutSessionRequest;
      const liveWorkout: ProgramLiveWorkoutSessionRequestTypes = {
        type: data.type,
        week: data.week,
        day: data.day,
        session: data.session,
        liveWorkoutId: data.liveWorkoutId,
      };

      return liveWorkout;
    }

    return val;
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const sessions = sessionsWithEmpties.filter(Boolean) as (
    | ProgramOnDemandSessionRequestTypes
    | ProgramDocumentSessionRequest
    | ProgramTextSessionRequest
    | ProgramLiveWorkoutSessionRequestTypes
  )[];

  return sessions;
};

export const getProgramRequest = (
  programDetails: ProgramDetails,
  programAssets: ProgramAssets,
  programPrice: ProgramPrice,
  program: Program,
): ProgramRequest => {
  const sessions = convertToProgramSessionsRequest(program);
  const request: ProgramRequest = {
    type: programDetails.type,
    periodType: ProgramPeriodType.WEEK,
    title: programDetails.title,
    description: programDetails.description,
    details: programDetails.details.filter((detail) => !!detail),
    testimonials: programDetails.testimonials,
    backgroundImagePublicId: programAssets.thumbnail,
    video: programAssets.video,
    backgroundImage2: programAssets.backgroundImage2,
    backgroundImage3: programAssets.backgroundImage3,
    verticalBackgroundImage: programAssets.verticalBackgroundImage,
    amount: programPrice.amount * 100, // convert to cents
    isIncludedInMembership: programPrice.isIncludedInMembership,
    sessions,
  };

  return request;
};

export const getEditProgramRequest = (
  programDetails: ProgramDetails,
  programAssets: ProgramAssets,
  programPrice: ProgramPrice,
  program: Program,
): EditProgramRequest => {
  const sessions = convertToProgramSessionsRequest(program);
  const editRequest: EditProgramRequest = {
    type: programDetails.type,
    periodType: ProgramPeriodType.WEEK,
    title: programDetails.title,
    description: programDetails.description,
    details: programDetails.details.filter((detail) => !!detail),
    testimonials: programDetails.testimonials,
    backgroundImagePublicId: programAssets.thumbnail,
    video: programAssets.video,
    backgroundImage2: programAssets.backgroundImage2,
    backgroundImage3: programAssets.backgroundImage3,
    verticalBackgroundImage: programAssets.verticalBackgroundImage,
    amount: programPrice.amount * 100, // convert to cents
    isIncludedInMembership: programPrice.isIncludedInMembership,
    sessions,
  };

  return editRequest;
};
