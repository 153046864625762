import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { FormTextField } from 'components/FormTextField';
import { FieldArray, Form, Formik } from 'formik';
import {
  EditEventRequest,
  EventRequest,
  EventResponse,
} from '@solin-fitness/types';
import * as yup from 'yup';
import { useState } from 'react';
import { uploadImage } from 'queries/file-uploads';
import { useMutation, useQueryClient } from 'react-query';
import { CircularProgress } from '@material-ui/core';
import Upload from 'components/shared/Icons/Upload';
import useThemeContext from 'hooks/useThemeContext';
import { Row } from 'components/Row';
import Button from 'components/Button';
import { DateTime } from 'luxon';
import { createEvent, editEvent } from 'queries/events';
import { useHistory } from 'react-router-dom';
import { equals } from 'ramda';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadImage from 'components/UploadImageModal';
import CloudinaryImage from 'components/CloudinaryImage';
import { X } from 'components/shared/Icons';

const validationSchema = yup.object({
  date: yup.string().required().min(1),
  time: yup
    .string()
    .required()
    .test('is-time', "Start time's must be in 1 minute intervals.", (value) => {
      const minute = Number(value?.substring(3, 5));
      return minute % 1 === 0;
    }),
  title: yup.string().required().min(1),
  description: yup.string().required().min(1),
  length: yup.number().required(),
  amount: yup.number().required().min(0),
  backgroundImagePublicId: yup.string().required().min(1),
  details: yup.array().of(yup.string()).required().min(1),
  location: yup.string().optional(),
});

const HeaderContainer = styled.div`
  border-bottom: 2px solid ${(p) => p.theme.grays.light};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FormContainer = styled(Form)`
  margin-bottom: 0;
  padding-bottom: 72px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
`;

const FileInputContainer = styled.div`
  height: 212px;
  background-color: ${(p) => p.theme.main.lightPrimary};
  border-radius: 20px;
  border: none;
  position: relative;
`;

const FileInput = styled.input`
  /* visibility: hidden; */
  opacity: 0;
  width: 100%;
  height: 100%;
`;

const FileDetailsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FileDetailsText = styled.p`
  font-size: 20px;
  font-family: 'Raleway';
`;

const DetailsList = styled.ul`
  margin: 0;
  padding-left: 24px;
  /* list-style-type: none; */
`;

const DeleteDetailsButton = styled.button`
  border: none;
  font-size: 16px;
  color: ${(p) => p.theme.main.secondary};
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.main.lightSecondary};
  }
`;

const RemoveButton = styled.button<{ circle?: boolean }>`
  display: ${(p) => (p.circle ? 'none' : 'block')};
  padding: 0;
  border: none;
  background: white;
  opacity: 0.8;
  cursor: pointer;

  width: 32px;
  height: 32px;

  position: absolute;
  top: ${(p) => (p.circle ? 0 : '8px')};
  right: ${(p) => (p.circle ? 0 : '8px')};
  left: ${(p) => (p.circle ? 0 : undefined)};
  bottom: ${(p) => (p.circle ? 0 : undefined)};
  border-radius: 50%;
  margin: auto;

  &:hover {
    display: block;
  }
`;

interface EventInitialValues {
  date: string;
  time: string;
  title: string;
  description: string;
  length: number;
  amount: number;
  backgroundImagePublicId: undefined | string;
  details: string[];
  newDetail: string;
  location: string;
}

interface Props {
  event?: EventResponse;
}

const EventForm: React.FC<Props> = ({ event }) => {
  const initialValues: EventInitialValues = event
    ? {
        date: DateTime.fromJSDate(new Date(event.startDate)).toFormat(
          'yyyy-MM-dd',
        ),
        time: DateTime.fromJSDate(new Date(event.startDate)).toFormat('HH:mm'),
        title: event.title,
        description: event.description,
        length: event.length,
        amount: Math.round(event.amount / 100),
        backgroundImagePublicId: event.backgroundImagePublicId,
        details: event.details,
        newDetail: '',
        location: event.location,
      }
    : {
        date: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        time: DateTime.fromJSDate(new Date()).toFormat('HH:mm'),
        title: '',
        description: '',
        length: 0,
        amount: 0,
        backgroundImagePublicId: undefined,
        details: [],
        newDetail: '',
        location: 'livestream',
      };

  const history = useHistory();
  const theme = useThemeContext();
  const queryClient = useQueryClient();

  const [error, setError] = useState<string>('');
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleCreateEvent = useMutation(
    (data: EventRequest) => createEvent(data),
    {
      onError: (err: any) => {
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        );
        window.scrollTo(0, 0);
      },
      onSuccess: () => queryClient.invalidateQueries('events'),
    },
  );

  const handleEditEvent = useMutation(
    (data: EditEventRequest) => editEvent(event!.id, data),
    {
      onError: (err: any) => {
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        );
        window.scrollTo(0, 0);
      },
      onSuccess: () => queryClient.invalidateQueries('events'),
    },
  );

  const handleEnterButton = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const small = true;
  const iconSize = small ? 24 : 40;

  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" style={{ padding: '12px 24px' }}>
          {event ? event.title : 'New Event'}
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => history.goBack()}
          style={{ margin: '0 24px' }}
        >
          Cancel
        </Button>
      </HeaderContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          if (!event) {
            // new event being created
            const { date, time } = data;
            const hour = Number(time.substring(0, 2));
            const minute = Number(time.substring(3, 5));
            const startDate = DateTime.fromISO(date);

            const createRequest: EventRequest = {
              startDate: startDate.set({ hour, minute }).toString(),
              title: data.title,
              description: data.description,
              length: data.length,
              amount: Math.round(data.amount * 10000) / 100,
              backgroundImagePublicId: data.backgroundImagePublicId as string,
              details: data.details,
            };

            await handleCreateEvent.mutateAsync(createRequest);
            setSubmitting(false);
            resetForm();

            history.push('/events');
          } else {
            const { date, time } = data;
            const hour = Number(time.substring(0, 2));
            const minute = Number(time.substring(3, 5));
            const startDate = DateTime.fromISO(date);

            const editRequest: EditEventRequest = {
              startDate: startDate.set({ hour, minute }).toString(),
              title: data.title,
              description: data.description,
              length: data.length,
              amount: Math.round(data.amount * 10000) / 100,
              backgroundImagePublicId: data.backgroundImagePublicId,
              details: data.details,
            };

            await handleEditEvent.mutateAsync(editRequest);
            setSubmitting(false);
            resetForm();
            history.push('/events');
          }
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <FormContainer>
            {!!error ? (
              <div style={{ paddingTop: 24 }}>
                <Aside isError>{error}</Aside>
              </div>
            ) : null}
            <Section>
              <InputContainer>
                <Typography variant="h6">Overview</Typography>
                <Typography variant="body2">
                  This will show to consumer on the events{' '}
                </Typography>
              </InputContainer>
              <InputContainer>
                <FormTextField label="Title" name="title" type="input" />
                <Typography variant="body2">Description</Typography>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                    ],
                    link: {
                      addTargetToExternalLinks: true,
                    },
                  }}
                  data={values.description || ''}
                  onChange={(_: any, editor: ClassicEditor) => {
                    const data = editor.getData();
                    setFieldValue('description', data);
                  }}
                />
              </InputContainer>
            </Section>
            <Section>
              <InputContainer>
                <Typography variant="h6">Event Image</Typography>
                <Typography variant="body2">
                  This will show be the background image that consumer see on
                  your event landing page. We recommend using a high quality
                  image that will fill the width of the screen
                </Typography>
              </InputContainer>
              <InputContainer>
                {!values.backgroundImagePublicId ? (
                  <FileInputContainer>
                    <UploadImage
                      onFinished={(publicId) =>
                        setFieldValue('backgroundImagePublicId', publicId)
                      }
                      fileName={'event'}
                      buttonToRender={
                        <FileDetailsContainer>
                          <Upload
                            color={theme.main.primary}
                            width={iconSize}
                            height={iconSize}
                            strokeWidth={1.5}
                          />
                          <FileDetailsText>
                            Click here to{' '}
                            <span style={{ color: theme.main.primary }}>
                              browse
                            </span>
                          </FileDetailsText>
                        </FileDetailsContainer>
                      }
                    />
                  </FileInputContainer>
                ) : null}

                {values.backgroundImagePublicId ? (
                  <FileInputContainer>
                    <FileDetailsContainer>
                      <CloudinaryImage
                        publicId={values.backgroundImagePublicId}
                        style={{
                          objectFit: 'contain',
                          height: '100%',
                          width: 'fit-content',
                          borderRadius: 20,
                        }}
                      />
                      <RemoveButton
                        circle={false}
                        onClick={() =>
                          setFieldValue('backgroundImagePublicId', undefined)
                        }
                      >
                        <X color="black" />
                      </RemoveButton>
                    </FileDetailsContainer>
                  </FileInputContainer>
                ) : null}
              </InputContainer>
            </Section>
            <Section>
              <InputContainer>
                <Typography variant="h6">Details</Typography>
                <Typography variant="body2">
                  This will show the consumer when the event is, what time the
                  event starts and where the event will be
                </Typography>
              </InputContainer>
              <InputContainer>
                <FormTextField
                  id="date-picker-dialog"
                  label="Date"
                  type="date"
                  name="date"
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <FormTextField
                  id="time-picker"
                  label="Time"
                  name="time"
                  type="time"
                  style={{ width: '100%', marginTop: 20 }}
                  InputLabelProps={{
                    shrink: true,
                    disabled: true,
                  }}
                />
                <FormTextField label="Location" name="location" type="input" />
                <FormTextField
                  label="Length (minutes)"
                  name="length"
                  type="number"
                  placeholder="0.00"
                />
                <FormTextField
                  label="Price"
                  name="amount"
                  type="number"
                  placeholder="0.00"
                />
                <Typography variant="body1" style={{ paddingTop: 8 }}>
                  Bullet Points About Event
                </Typography>
                <FieldArray name="details">
                  {(arrayHelpers) => (
                    <>
                      <DetailsList>
                        {values.details.map((val, index) => (
                          <li key={index}>
                            <Row justifyContent="flex-start">
                              <Typography variant="body1">{val}</Typography>
                              <div style={{ flex: 1 }} />
                              <DeleteDetailsButton
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                remove
                              </DeleteDetailsButton>
                            </Row>
                          </li>
                        ))}
                      </DetailsList>
                      <Row>
                        <FormTextField
                          name="newDetail"
                          placeholder="Fun fact about the event!"
                          onKeyDown={handleEnterButton}
                        />
                        <Button
                          size="large"
                          onClick={() => {
                            arrayHelpers.push(values.newDetail);
                            setFieldValue('newDetail', '');
                          }}
                          color="primary"
                          style={{ margin: 16, fontSize: '1.2rem' }}
                        >
                          add
                        </Button>
                      </Row>
                    </>
                  )}
                </FieldArray>
              </InputContainer>
            </Section>
            <Button
              disabled={
                equals(initialValues, values) ||
                isSubmitting ||
                !values.backgroundImagePublicId
              }
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              fullWidth
              style={{
                marginTop: 24,
                borderRadius: 35,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="h6">
                  {event ? 'Update Event' : 'Create Event'}
                </Typography>
              )}
            </Button>
          </FormContainer>
        )}
      </Formik>
    </>
  );
};

export default EventForm;
