import { api, Methods } from '../services/api';

interface LoginResponse {
  [key: string]: any;
  data: {
    userId: number;
    userType: number;
  };
  pageUrl: string;
}

export const getLogin = async (
  email: string,
  password: string,
): Promise<LoginResponse> =>
  await api<LoginResponse>(Methods.post, '/auth/login?platform=dashboard', {
    email,
    password,
  });
