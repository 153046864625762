import React, { useMemo } from 'react';
import { StripePaymentsResponse } from '@solin-fitness/types';
import s from 'styled-components';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { api, Methods } from 'services/api';
import { PaginationTable } from 'components/Table';
import { DateTime } from 'luxon';
import { Loading } from 'components/Loading';
import { formatPrice } from 'util/helpers';

const LoadingMessage = s.div`
  width: 100%;
  font-size: 1.2rem;
  margin-top: 20px;
  text-align: center;
`;

const StripePaymentsTable: React.FC = () => {
  const { isLoading, data: payments } = useQuery<StripePaymentsResponse[]>(
    'payments',
    async () =>
      api<StripePaymentsResponse[]>(
        Methods.get,
        '/analytics/stripe/payments',
        {},
        { timeout: 120000 },
      ),
    { refetchOnWindowFocus: false },
  );

  const columns = useMemo<Column<StripePaymentsResponse>[]>(
    () => [
      {
        Header: 'Customer',
        accessor: 'customerName',
      },
      {
        Header: 'Email',
        accessor: 'customerEmail',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (info: any) => info.row.original.description ?? 'N/A',
      },
      {
        Header: 'Created',
        accessor: 'createdDate',
        Cell: (info: any) =>
          DateTime.fromISO(
            new Date(info.row.original.createdDate * 1000).toISOString(),
          ).toFormat('D'),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Due',
        accessor: 'amount_due',
        Cell: (info: any) => formatPrice(info.row.original.amount_due),
      },
      {
        Header: 'Paid',
        accessor: 'amount_paid',
        Cell: (info: any) => formatPrice(info.row.original.amount_paid),
      },
    ],
    [],
  );

  const data = useMemo<StripePaymentsResponse[]>(
    () => (payments ? payments : []),
    [payments],
  );

  return (
    <div>
      {isLoading ? (
        <div>
          <Loading />
          <LoadingMessage>
            Retrieving payment data. Please note this process may take up to two
            minutes..{' '}
          </LoadingMessage>
        </div>
      ) : (
        <PaginationTable<StripePaymentsResponse>
          columns={columns}
          data={data}
        />
      )}
    </div>
  );
};

export default StripePaymentsTable;
