import React from 'react';
import { Image, Transformation } from 'cloudinary-react';

interface Props {
  publicId: string;
  width?: number | string;
  height?: number | string;
  quality?: string | number;
  fetchFormat?: string;
  gravity?: string;
  alt?: string;
  crop?: string;
  style?: React.CSSProperties;
}

const CloudinaryImage: React.FC<Props> = (props: Props) => {
  const { publicId, width, height, alt, crop, fetchFormat, gravity, quality, style } = props;

  return (
    <Image
      cloudName={'solin-fitness'}
      publicId={publicId}
      width={width || ''}
      height={height || ''}
      alt={alt || ''}
      style={style || undefined}
    >
      <Transformation
        width={width || ''}
        height={height || ''}
        quality={quality || ''}
        fetchFormat={fetchFormat || ''}
        gravity={gravity || ''}
        crop={crop || ''}
      />
    </Image>
  );
};

export default CloudinaryImage;
