import {
  EditProgramRequest,
  ProgramDocumentSessionRequest,
  ProgramLiveWorkoutSessionRequest as ProgramLiveWorkoutSessionRequestTypes,
  ProgramOnDemandSessionRequest as ProgramOnDemandSessionRequestTypes,
  ProgramRequest,
  ProgramTestimonial,
  ProgramTextSessionRequest,
  ProgramType,
} from '@solin-fitness/types';
import { PROGRAM_KEY } from 'components/Programs/ViewPrograms/ViewPrograms';
import { PROGRAMS } from 'constants/routes';
import { createProgram, editProgram } from 'queries/programs';
import { useState, createContext, useEffect, ReactNode, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getEditProgramRequest, getProgramRequest } from 'services/programs';
import { v4 as uuidv4 } from 'uuid';

export enum ProgramSessionEmptyType {
  EMPTY = 'empty',
}

interface ProgramSessionEmptyState {
  type: ProgramSessionEmptyType.EMPTY;
}

export interface ProgramOnDemandSessionRequest
  extends ProgramOnDemandSessionRequestTypes {
  thumbnail: string;
  title: string;
}

export interface ProgramLiveWorkoutSessionRequest
  extends ProgramLiveWorkoutSessionRequestTypes {
  thumbnail: string;
  title: string;
}

export type ProgramSessionInterface =
  | ProgramOnDemandSessionRequest
  | ProgramTextSessionRequest
  | ProgramDocumentSessionRequest
  | ProgramLiveWorkoutSessionRequest
  | ProgramSessionEmptyState;

export type Program = ProgramSessionInterface[][][];

export interface ProgramDetails {
  type: ProgramType;
  title: string;
  description: string;
  details: string[];
  testimonials: ProgramTestimonial[];
}

export interface ProgramAssets {
  thumbnail: string;
  video: string;
  backgroundImage2?: string;
  backgroundImage3?: string;
  verticalBackgroundImage?: string;
}

export interface ProgramPrice {
  amount: number;
  isIncludedInMembership: boolean;
}

export interface FullProgram {
  program: Program;
  details: ProgramDetails;
  assets: ProgramAssets;
  price: ProgramPrice;
  programComplete: boolean;
  detailsComplete: boolean;
  priceComplete: boolean;
  assetsComplete: boolean;
}

export type FullProgramRecords = Record<string, FullProgram>;

export enum BreadCrumb {
  GENERAL = 'General',
  ASSETS = 'Assets',
  CONTENT = 'Content',
  PRICE = 'Price & Access',
}

/**
 * Program is a 3 dimensional array
 * outer layer - weeks
 * middle layer - day
 * inner layer - session
 * ex: program[0][2][1] is week 1, day 3, session 2 (since starting index's is 0
 * but in the UI it will show as starting index of 1)
 *
 * default program is 1 week, 1 day, no sessions
 */

export const EMPTY_PROGRAM_SESSION = {
  type: ProgramSessionEmptyType.EMPTY,
};
const EMPTY_PROGRAM_DAY = [EMPTY_PROGRAM_SESSION];
const EMPTY_PROGRAM_WEEK = [EMPTY_PROGRAM_DAY];
const DEFAULT_PROGRAM: Program = [EMPTY_PROGRAM_WEEK];

interface ProgramBuilderInterface {
  program: Program;
  programDetails: ProgramDetails;
  programAssets: ProgramAssets;
  programPrice: ProgramPrice;
  currentWeek: number;
  currentDay: number;
  selectedBreadCrumb: BreadCrumb;
  isProgramCompleted: boolean;
  isDetailsCompleted: boolean;
  isAssetsCompleted: boolean;
  isPriceCompleted: boolean;
  isPriceUpdated: boolean;
  error: string;
  isEditing: boolean;
  workoutTags: string[];
  handleSelectBreadCrumb: (value: BreadCrumb) => void;
  handleSetWeek: (week: number) => void;
  handleSession: (
    item: ProgramSessionInterface,
    isEditingSession?: boolean,
  ) => void;
  handleAddNewWeek: () => void;
  handleAddNewDay: (week: number) => void;
  handleAddNewSession: (weekValue: number, dayValue: number) => void;
  handleDeleteDay: (weekValue: number, dayValue: number) => void;
  handleDeleteWeek: (weekValue: number) => void;
  handleDeleteSession: (
    weekValue: number,
    dayValue: number,
    sessionValue: number,
  ) => void;
  handleSaveProgramDetails: (value: ProgramDetails, toExit?: boolean) => void;
  handleUpdateProgramDetails: (
    value: ProgramDetails,
    toExit?: boolean,
  ) => Promise<void>;
  handleSaveProgramAssets: (value: ProgramAssets, toExit?: boolean) => void;
  handleUpdateProgramAssets: (
    value: ProgramAssets,
    toExit?: boolean,
  ) => Promise<void>;
  handleSaveProgramPrice: (value: ProgramPrice) => void;
  handleSaveProgramWeek: () => void;
  handleFinishProgramWeek: () => void;
  handleUpdateProgramWeek: (toContinue?: boolean) => Promise<void>;
  handlePublishProgram: (price: ProgramPrice) => Promise<void>;
  handleUpdateProgramPrice: (price: ProgramPrice) => Promise<void>;
}

const ProgramBuilderContext = createContext<ProgramBuilderInterface>(
  undefined!,
);

interface Props {
  fullProgram?: FullProgram;
  programId: string;
  workoutTags: string[];
  isEditing?: boolean;
  children: ReactNode | ReactNode[];
}

const DEFAULT_WEEK = 0;
const DEFAULT_DAY = 0;

interface CurrentProgram {
  program: Program;
  currentWeek: number;
  currentDay: number;
}

const initialProgram: CurrentProgram = {
  program: DEFAULT_PROGRAM,
  currentWeek: DEFAULT_WEEK,
  currentDay: DEFAULT_DAY,
};

const initialProgramDetails: ProgramDetails = {
  type: ProgramType.PROGRAM,
  title: '',
  description: '',
  details: [],
  testimonials: [],
};

const initialProgramAssets: ProgramAssets = {
  thumbnail: '',
  video: '',
};

const initialProgramPrice: ProgramPrice = {
  amount: 0,
  isIncludedInMembership: true,
};

const ProgramBuilderProvider = ({
  fullProgram,
  programId,
  workoutTags,
  isEditing,
  children,
}: Props) => {
  const programIdRef = useRef<string>(programId || uuidv4());

  useEffect(() => {
    if (programId) {
      programIdRef.current = programId;
    }
  }, [programId]);

  const [selectedBreadCrumb, setSelectedBreadCrumb] = useState<BreadCrumb>(
    BreadCrumb.GENERAL,
  );
  const [currentProgram, setCurrentProgram] = useState<CurrentProgram>(
    fullProgram
      ? {
          program: fullProgram.program,
          currentWeek: DEFAULT_WEEK,
          currentDay: DEFAULT_DAY,
        }
      : initialProgram,
  );
  const [programDetails, setProgramDetails] = useState<ProgramDetails>(
    fullProgram?.details || initialProgramDetails,
  );
  const [programAssets, setProgramAssets] = useState<ProgramAssets>(
    fullProgram?.assets || initialProgramAssets,
  );
  const [programPrice, setProgramPrice] = useState<ProgramPrice>(
    fullProgram?.price || initialProgramPrice,
  );
  const [programComplete, setProgramComplete] = useState<boolean>(
    !!fullProgram?.programComplete,
  );
  const [detailsComplete, setDetailsComplete] = useState<boolean>(
    !!fullProgram?.detailsComplete,
  );
  const [assetsComplete, setAssetsComplete] = useState<boolean>(
    !!fullProgram?.assetsComplete,
  );
  const [priceComplete, setPriceComplete] = useState<boolean>(
    !!fullProgram?.priceComplete,
  );

  const [priceUpdate, setPriceUpdate] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const { program, currentWeek, currentDay } = currentProgram;

  const history = useHistory();
  const queryClient = useQueryClient();
  const saveProgram = useMutation(
    (data: ProgramRequest) => createProgram(data),
    {
      onMutate: () => setError(''),
      onSuccess: () => {
        queryClient.invalidateQueries('programs');
        history.push(PROGRAMS);
      },
      onError: (err: any) =>
        setError(err?.message || 'Something went wrong! Please contact a dev'),
    },
  );

  const updateProgram = useMutation(
    (data: EditProgramRequest) => editProgram(Number(programId), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('programs');
      },
      onError: (err: any) =>
        setError(err?.message || 'Something went wrong! Please contact a dev'),
    },
  );

  const handleSelectBreadCrumb = (value: BreadCrumb) =>
    setSelectedBreadCrumb(value);

  const saveProgramToLocalStorage = (programToSave: FullProgram) => {
    // reset error message if an error already exists in state
    if (error) {
      setError('');
    }

    if (isEditing) {
      // early exit if editing program
      return;
    }
    const localStorageValue = localStorage.getItem(PROGRAM_KEY);
    if (localStorageValue) {
      // programs are already saved

      const currentProgramsSaved = JSON.parse(
        localStorageValue,
      ) as unknown as FullProgramRecords;

      currentProgramsSaved[programIdRef.current] = {
        program: programToSave.program,
        details: programToSave.details,
        assets: programToSave.assets,
        price: programToSave.price,
        programComplete: programToSave.programComplete,
        detailsComplete: programToSave.detailsComplete,
        priceComplete: programToSave.programComplete,
        assetsComplete: programToSave.assetsComplete,
      };

      localStorage.setItem(PROGRAM_KEY, JSON.stringify(currentProgramsSaved));
    } else {
      const firstProgramToSave: FullProgramRecords = {};
      firstProgramToSave[programIdRef.current] = {
        program: programToSave.program,
        details: programToSave.details,
        assets: programToSave.assets,
        price: programToSave.price,
        programComplete: programToSave.programComplete,
        detailsComplete: programToSave.detailsComplete,
        priceComplete: programToSave.programComplete,
        assetsComplete: programToSave.assetsComplete,
      };

      localStorage.setItem(PROGRAM_KEY, JSON.stringify(firstProgramToSave));
    }
  };

  const removeProgramFromLocalStorage = () => {
    const localStorageValue = localStorage.getItem(PROGRAM_KEY);
    if (localStorageValue) {
      // program has been saved, need to remove
      const currentProgramsSaved = JSON.parse(
        localStorageValue,
      ) as unknown as FullProgramRecords;

      const updatedProgramsSaved: FullProgramRecords = {};
      Object.keys(currentProgramsSaved).forEach((key) => {
        if (key !== programIdRef.current) {
          updatedProgramsSaved[key] = currentProgramsSaved[key];
        }
      });

      localStorage.setItem(PROGRAM_KEY, JSON.stringify(updatedProgramsSaved));
    }
  };

  const handlePublishProgram = async (price: ProgramPrice) => {
    // set program price and mark price as complete

    try {
      const request = getProgramRequest(
        programDetails,
        programAssets,
        price,
        program,
      );
      await saveProgram.mutateAsync(request);

      setProgramPrice(price);
      setPriceComplete(true);
      removeProgramFromLocalStorage();
    } catch (err) {
      // fail silently
    }
  };

  const handleUpdateProgramPrice = async (price: ProgramPrice) => {
    try {
      const editRequest = getEditProgramRequest(
        programDetails,
        programAssets,
        price,
        program,
      );
      await updateProgram.mutateAsync(editRequest);
      setProgramPrice(price);
      setPriceUpdate(true);
      setPriceComplete(true);
    } catch {
      // fail silently
    }
  };

  const handleSetWeek = (week: number) =>
    setCurrentProgram({
      ...currentProgram,
      currentWeek: week,
    });

  const handleSession = (
    item: ProgramSessionInterface,
    isEditingSession?: boolean,
  ) => {
    // enter into program sessions
    if (item.type === ProgramSessionEmptyType.EMPTY) {
      return;
    }
    const { week, day, session } = item;

    const programCopy = [...program];
    // set current session to item selected
    programCopy[week][day][session] = item;

    if (!isEditingSession) {
      // add new empty program session to day
      programCopy[week][day].push(EMPTY_PROGRAM_SESSION);
    }

    setCurrentProgram({
      ...currentProgram,
      program: programCopy,
    });
  };

  const handleAddNewWeek = () => {
    const programCopy = [...program];
    programCopy.push([[EMPTY_PROGRAM_SESSION]]);
    setCurrentProgram({
      ...currentProgram,
      program: programCopy,
    });
  };

  const handleAddNewDay = (week: number) => {
    const programCopy = [...program];

    // remove empty session from current day
    const programWeek = programCopy[week].map((day) =>
      day.filter((session) => session.type !== ProgramSessionEmptyType.EMPTY),
    );
    programCopy[week] = programWeek;

    programCopy[week].push([EMPTY_PROGRAM_SESSION]);
    setCurrentProgram({
      ...currentProgram,
      program: programCopy,
    });
  };

  const handleAddNewSession = (weekValue: number, dayValue: number) => {
    const programCopy = [...program];

    // remove empty session from current day
    const programWeek = programCopy[weekValue].map((day) =>
      day.filter((session) => session.type !== ProgramSessionEmptyType.EMPTY),
    );
    programCopy[weekValue] = programWeek;

    programCopy[weekValue][dayValue].push(EMPTY_PROGRAM_SESSION);

    setCurrentProgram({
      ...currentProgram,
      program: programCopy,
    });
  };

  const handleDeleteDay = (weekValue: number, dayValue: number) => {
    const programCopy = [...program];

    // filter out deleted day
    // and update day value in sessions to match
    const updatedProgramWeek = programCopy[weekValue]
      .filter((_, index) => index !== dayValue)
      .map((day, index) =>
        day.map((session) => ({
          ...session,
          day: index,
        })),
      );

    programCopy[weekValue] = updatedProgramWeek;

    setCurrentProgram({
      ...currentProgram,
      program: programCopy,
    });
  };

  const handleDeleteWeek = (weekValue: number) => {
    // filter out deleted week
    // and update week value in sessions to match
    const programCopy = program
      .filter((_, index) => index !== weekValue)
      .map((week, index) =>
        week.map((day) =>
          day.map((session) => ({
            ...session,
            week: index,
          })),
        ),
      );

    if (weekValue >= programCopy.length) {
      setCurrentProgram({
        ...currentProgram,
        program: programCopy,
        currentWeek: programCopy.length - 1,
      });
    } else {
      setCurrentProgram({
        ...currentProgram,
        program: programCopy,
      });
    }
  };

  const handleDeleteSession = (
    weekValue: number,
    dayValue: number,
    sessionValue: number,
  ) => {
    const programCopy = [...program];

    const updatedProgramDay = programCopy[weekValue][dayValue].filter(
      (_, index) => index !== sessionValue,
    );

    // reset session indexes so they are in order
    const updatedProgramDayWithIndexes: ProgramSessionInterface[] =
      updatedProgramDay.map((session, index) => ({
        ...session,
        session: index,
      }));

    programCopy[weekValue][dayValue] = updatedProgramDayWithIndexes;

    setCurrentProgram({
      ...currentProgram,
      program: programCopy,
    });
  };

  const handleSaveProgramWeek = () => {
    saveProgramToLocalStorage({
      program,
      details: programDetails,
      assets: programAssets,
      price: programPrice,
      programComplete,
      detailsComplete,
      priceComplete,
      assetsComplete,
    });
  };

  // to be used when saving a program content (weeks/days/sessions)
  const handleFinishProgramWeek = () => {
    saveProgramToLocalStorage({
      program,
      details: programDetails,
      assets: programAssets,
      price: programPrice,
      programComplete: true,
      detailsComplete,
      priceComplete,
      assetsComplete,
    });
    setSelectedBreadCrumb(BreadCrumb.PRICE);
    setProgramComplete(true);
  };

  // to be used when saving a program's content to the backend
  const handleUpdateProgramWeek = async (toContinue = false) => {
    try {
      const editRequest = getEditProgramRequest(
        programDetails,
        programAssets,
        programPrice,
        program,
      );
      await updateProgram.mutateAsync(editRequest);

      if (toContinue) {
        setSelectedBreadCrumb(BreadCrumb.PRICE);
      }
    } catch {
      // fail silently, error gets caught in mutation
    }
  };

  // to be used when saving a new program
  const handleSaveProgramDetails = (value: ProgramDetails, toExit = false) => {
    setProgramDetails(value);
    saveProgramToLocalStorage({
      program,
      details: value,
      assets: programAssets,
      price: programPrice,
      programComplete,
      detailsComplete: true,
      priceComplete,
      assetsComplete,
    });
    if (!toExit) {
      setSelectedBreadCrumb(BreadCrumb.ASSETS);
    }
    setDetailsComplete(true);
  };

  // to be used when saving a program that is being edited
  const handleUpdateProgramDetails = async (
    value: ProgramDetails,
    toExit = false,
  ) => {
    try {
      setProgramDetails(value);
      const editRequest = getEditProgramRequest(
        value,
        programAssets,
        programPrice,
        program,
      );
      await updateProgram.mutateAsync(editRequest);

      if (!toExit) {
        setSelectedBreadCrumb(BreadCrumb.ASSETS);
      }
    } catch {
      // fail silently, error gets caught in mutation
    }
  };

  const handleSaveProgramAssets = (value: ProgramAssets, toExit = false) => {
    setProgramAssets(value);
    saveProgramToLocalStorage({
      program,
      details: programDetails,
      assets: value,
      price: programPrice,
      programComplete,
      detailsComplete,
      priceComplete,
      assetsComplete,
    });
    if (!toExit) {
      setSelectedBreadCrumb(BreadCrumb.CONTENT);
    }
    setAssetsComplete(true);
  };

  const handleUpdateProgramAssets = async (
    value: ProgramAssets,
    toExit = false,
  ) => {
    try {
      setProgramAssets(value);
      const editRequest = getEditProgramRequest(
        programDetails,
        value,
        programPrice,
        program,
      );
      await updateProgram.mutateAsync(editRequest);

      if (!toExit) {
        setSelectedBreadCrumb(BreadCrumb.CONTENT);
      }
    } catch {
      // fail silently, error gets caught in mutation
    }
  };

  const handleSaveProgramPrice = (value: ProgramPrice) => {
    setProgramPrice(value);
  };

  const value = {
    program,
    programDetails,
    programAssets,
    programPrice,
    currentWeek,
    currentDay,
    selectedBreadCrumb,
    isProgramCompleted: programComplete,
    isDetailsCompleted: detailsComplete,
    isAssetsCompleted: assetsComplete,
    isPriceCompleted: priceComplete,
    isPriceUpdated: priceUpdate,
    error,
    isEditing: !!isEditing,
    workoutTags,
    handleSelectBreadCrumb,
    handleSetWeek,
    handleSession,
    handleAddNewWeek,
    handleAddNewDay,
    handleAddNewSession,
    handleDeleteWeek,
    handleDeleteDay,
    handleDeleteSession,
    handleSaveProgramDetails,
    handleUpdateProgramDetails,
    handleSaveProgramAssets,
    handleUpdateProgramAssets,
    handleSaveProgramPrice,
    handleSaveProgramWeek,
    handleFinishProgramWeek,
    handleUpdateProgramWeek,
    handlePublishProgram,
    handleUpdateProgramPrice,
  };

  return (
    <ProgramBuilderContext.Provider value={value}>
      {children}
    </ProgramBuilderContext.Provider>
  );
};

export { ProgramBuilderContext, ProgramBuilderProvider };
