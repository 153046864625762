import React from 'react';
import { Page } from 'types';
import PageDetails from 'components/ManagePage/PageDetails';
import PageAssets from 'components/ManagePage/PageAssets';
import PageMetadata from './PageMetadata';
import PageSubscriptions from './PageSubscriptions';
import PageClassPrices from './PageClassPrices';
import PageMailchimp from './PageMailchimp';
import PageToggles from './PageToggles';
import PageTestimonials from './PageTestimonails';

interface Props {
  page: Page;
}

const PageOverview: React.FC<Props> = (props) => {
  const { page } = props;
  return (
    <React.Fragment>
      <PageMetadata
        pageId={page.id}
        logo={page.config.pageLogoPublicId}
        lightLogo={page.config.lightLogoPublicId}
        name={page.config.pageName}
        description={page.config.pageDescription}
        bio={page.config.bio}
      />
      <PageDetails pageId={page.id} workoutTags={page.config.workoutTags} />
      <PageToggles
        pageId={page.id}
        listItems={page.config.landingPageListItems}
      />
      <PageSubscriptions
        pageId={page.id}
        subscriptions={page.config.subscriptions}
        promoCodes={page.config.promoCodes}
        trialPeriodDays={page.config.trialPeriodDays}
      />
      <PageClassPrices pageId={page.id} classPrices={page.config.classPrices} />
      <PageTestimonials
        pageId={page.id}
        testimonials={page.config.testimonials}
      />
      <PageAssets
        pageId={page.id}
        teaserThumbnailPublicId={(page.config as any).teaserThumbnailPublicId}
        teaserVideo={page.config.teaserVideo}
        secondaryPageImagePublicId={
          (page.config as any).secondaryPageImagePublicId
        }
        backgroundImage1PublicId={(page.config as any).backgroundImage1PublicId}
        backgroundImage2PublicId={(page.config as any).backgroundImage2PublicId}
        backgroundImage3PublicId={(page.config as any).backgroundImage3PublicId}
        learnMoreImagePublicId={(page.config as any).learnMoreImagePublicId}
        verticalBackgroundImage={(page.config as any).verticalBackgroundImage}
        lightLogoPublicId={(page.config as any).lightLogoPublicId}
      />
      <PageMailchimp
        pageId={page.id}
        isMailchimpSetup={page.config.isMailchimpSetup}
        mailchimpApiKey={page.config.mailchimpApiKey}
        mailchimpAudienceId={page.config.mailchimpAudienceId}
        mailchimpServerPrefix={page.config.mailchimpServerPrefix}
      />
    </React.Fragment>
  );
};

export default PageOverview;
