import { Modal } from 'components/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Label as FormLabel,
  Header,
  Button,
  ButtonContainer,
  ButtonText,
} from './ProgramSession.styles';
import { FormTextField } from 'components/FormTextField';
import Slider from '@material-ui/core/Slider';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Formik } from 'formik';
import { BtnSelect, BtnSelectOption } from 'components/BtnSelect';
import useThemeContext from 'hooks/useThemeContext';
import ImageUpload from 'components/FileUploads/ImageUpload/ImageUpload';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';
import {
  CreateLiveWorkoutRequest,
  ProgramSessionType,
} from '@solin-fitness/types';
import { createLiveWorkout } from 'queries/live-workouts';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import { ProgramLiveWorkoutSessionRequest } from 'context/ProgramBuilderContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from 'components/Dialog/Dialog';

const Row = styled.div`
  display: flex;
  gap: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Label = styled(FormLabel)`
  padding: var(--padding, 4px);
`;

enum WorkoutVideoFormat {
  stream = 'stream',
  zoomLink = 'zoomLink',
}

const validationSchema = yup.object({
  date: yup.string().required(),
  time: yup
    .string()
    .required()
    .test('is-time', "Start time's must be in 1 minute intervals.", (value) => {
      const minute = Number(value?.substring(3, 5));
      return minute % 1 === 0;
    }),
  title: yup.string().required(),
  description: yup.string().required(),
  thumbnail: yup.string().required(),
  workoutExercises: yup.string().optional(),
  length: yup.number().required(),
  workoutVideoFormat: yup
    .string()
    .oneOf(Object.values(WorkoutVideoFormat))
    .required(),
  types: yup.array().of(yup.string()).required().min(1),
  zoomLink: yup.string().optional().nullable(),
  playlistLink: yup.string().optional().nullable(),
});

interface InitialLiveWorkoutValues {
  date: string;
  time: string;
  title: string;
  description: string;
  workoutExercises: string;
  thumbnail: string;
  length: number;
  playlistLink: string;
  workoutVideoFormat: WorkoutVideoFormat;
  zoomLink: string;
  types: string[];
}

const liveWorkoutFormatOptions: BtnSelectOption[] = [
  {
    key: 'One-to-many',
    value: WorkoutVideoFormat.stream,
    helperText: 'See Trainer Only',
  },
  {
    key: 'Zoom',
    value: WorkoutVideoFormat.zoomLink,
    helperText: 'Zoom link you will use for this class',
  },
];

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  workoutTags: string[];
  week: number;
  day: number;
  sessionIndex: number;
}

const LiveWorkoutProgramSession = ({
  isOpen,
  onCancel,
  workoutTags,
  week,
  day,
  sessionIndex,
}: Props) => {
  const theme = useThemeContext();
  const { handleSession } = useProgramBuilder();

  const [error, setError] = useState<string>('');
  const currentDate = DateTime.fromJSDate(new Date());
  const initialValues: InitialLiveWorkoutValues = {
    date: currentDate.toFormat('yyyy-MM-dd'),
    time: currentDate.toFormat('T'),
    title: '',
    description: '',
    workoutExercises: '',
    thumbnail: '',
    length: 60,
    playlistLink: '',
    workoutVideoFormat: WorkoutVideoFormat.stream,
    zoomLink: '',
    types: [],
  };

  const workoutTagsOptions: BtnSelectOption[] = workoutTags.map((tag) => ({
    key: tag,
    value: tag,
  }));

  const queryClient = useQueryClient();

  const handleLiveWorkout = useMutation(
    (data: CreateLiveWorkoutRequest) => createLiveWorkout(data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Dialog open={isOpen} width={900} onCancel={onCancel} bypassFocusLock>
      <Header>Live Workout Session</Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          try {
            const { date, time } = data;
            const hour = Number(time.substring(0, 2));
            const minute = Number(time.substring(3, 5));
            const startDate = DateTime.fromISO(date);

            // need to manually set following fields
            // isInProgram - true
            // recordLiveWorkout - true

            const zoomLink =
              data.workoutVideoFormat === WorkoutVideoFormat.zoomLink
                ? {
                    zoomLink: data.zoomLink,
                  }
                : undefined;

            const request: CreateLiveWorkoutRequest = {
              startDate: startDate.set({ hour, minute }).toString(),
              title: data.title,
              description: data.description,
              length: data.length,
              thumbnailPublicId: data.thumbnail,
              workoutVideoFormat: data.workoutVideoFormat,
              ...zoomLink,
              type: data.types,
              playlistLink: data.playlistLink || undefined,
              recordLiveWorkout: true,
              isIncludedInMembership: true,
              workoutExercises: !!data.workoutExercises
                ? data.workoutExercises
                : undefined,
              isInProgram: true,
            };

            // create live workout in db
            const res = await handleLiveWorkout.mutateAsync(request);
            const { data: liveWorkout } = res;

            const session: ProgramLiveWorkoutSessionRequest = {
              type: ProgramSessionType.LIVE_WORKOUT,
              liveWorkoutId: liveWorkout.id,
              week,
              day,
              session: sessionIndex,
              thumbnail: data.thumbnail,
              title: data.title,
            };
            handleSession(session);

            setSubmitting(false);
            resetForm();
            onCancel();
            // add live workout program session
          } catch (err) {
            // fail silently
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form style={{ marginBottom: 0 }}>
            <Container>
              <Row>
                <FormTextField
                  id="date-picker-dialog"
                  label="Date"
                  type="date"
                  name="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <FormTextField
                  id="time-picker"
                  label="Time"
                  name="time"
                  type="time"
                  className="time-input-mui"
                  InputLabelProps={{
                    shrink: true,
                    disabled: true,
                  }}
                />
              </Row>
              <Row>
                <div style={{ flex: 1 }}>
                  <FormTextField
                    label="Title"
                    name="title"
                    placeholder="Ab burner"
                    type="input"
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormTextField
                    label="Description"
                    name="description"
                    placeholder="This will be 20 minutes of high intensity ab burning routines"
                    multiline={true}
                    rows={2}
                  />
                </div>
                <div style={{ flex: 1, padding: '6px 0px 4px' }}>
                  <ImageUpload
                    thumbnail={values.thumbnail}
                    fileName="thumbnail"
                    handleThumbnail={(value) =>
                      setFieldValue('thumbnail', value)
                    }
                  />
                </div>
              </Row>
              <div>
                <Label>Overview</Label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                    ],
                    link: {
                      addTargetToExternalLinks: true,
                    },
                  }}
                  data={values.workoutExercises}
                  onChange={(_: Event, editor: ClassicEditor) => {
                    const data = editor.getData();
                    setFieldValue('workoutExercises', data);
                  }}
                />
              </div>
              <div>
                <Label style={{ '--padding': '8px 4px 0px' }}>Length</Label>
                <Slider
                  value={values.length}
                  name="length"
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={120}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: number | number[],
                  ) => setFieldValue('length', value)}
                />
              </div>
              <FormTextField
                label="Playlist Link - optional"
                name="playlistLink"
                placeholder="https://spotify.com/your-awesome-playlist-url"
                type="input"
                InputLabelProps={{ shrink: true }}
              />
              <div>
                <Label style={{ '--padding': '4px 4px 0px' }}>
                  Live Workout Format
                </Label>
                <BtnSelect
                  isSingleSelect
                  options={liveWorkoutFormatOptions}
                  values={[values.workoutVideoFormat]}
                  onClick={(selectValues): void =>
                    setFieldValue('workoutVideoFormat', selectValues[0])
                  }
                />
              </div>
              {values.workoutVideoFormat === WorkoutVideoFormat.zoomLink ? (
                <FormTextField
                  label="Zoom Link"
                  name="zoomLink"
                  placeholder="https://zoom/us/your-meeting-id"
                  type="input"
                  InputLabelProps={{ shrink: true }}
                />
              ) : null}
              <div>
                <Label style={{ '--padding': '4px 4px 0px' }}>
                  Type
                  <span style={{ fontSize: 14, paddingLeft: 8 }}>
                    {' '}
                    select at least 1
                  </span>
                </Label>
                <BtnSelect
                  options={workoutTagsOptions}
                  values={values.types}
                  onClick={(selectValues): void =>
                    setFieldValue('types', selectValues)
                  }
                />
              </div>
              <ButtonContainer>
                <Button onClick={onCancel}>
                  <ButtonText style={{ '--color': theme.main.secondary }}>
                    Cancel
                  </ButtonText>
                </Button>
                <Button type="submit" style={{ '--bg': theme.main.secondary }}>
                  {isSubmitting ? (
                    <CircularProgress style={{ color: '#fff' }} />
                  ) : (
                    <ButtonText style={{ '--color': '#fff' }}>Save</ButtonText>
                  )}
                </Button>
              </ButtonContainer>
            </Container>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default LiveWorkoutProgramSession;
