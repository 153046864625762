import React, { useState, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Typography, CircularProgress, Checkbox } from '@material-ui/core';
import {
  AddSubscriptionPromoRequest,
  Interval,
  PageSubscriptions,
} from '@solin-fitness/types';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import {
  PageSubscriptionRequest,
  createPageSubscription,
  createSubscriptionPromo,
} from 'queries/page';
import { CurrencyCode } from '@solin-fitness/types/build/api/utils';
import { FormTextField } from 'components/FormTextField';
import { FormRadioButton } from 'components/FormRadioButton';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { toUpper } from 'cypress/types/lodash';
import { of } from 'rxjs';
import { Column } from 'components/Column';
import { Row } from 'components/Row';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  subscriptions: PageSubscriptions[];
}

enum CouponDetail {
  amountOff = 'amountOff',
  percentOff = 'percentOff',
}

const validationSchema = yup.object({
  name: yup.string().required().min(1),
  code: yup
    .string()
    .matches(
      /^[a-zA-Z0-9_.-]*$/,
      'Invalid Coupon Code. Coupon code may only contain alphanumeric characters. Special characters are not permitted.',
    )
    .required()
    .min(4),
  priceId: yup.array().of(yup.string()).required().min(1),
  isAmountOff: yup.string().required(),
  valueOff: yup.number().required().min(1),
});

const CreateCoupon: React.FC<Props> = ({ isOpen, onCancel, subscriptions }) => {
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
  const createCoupon = useMutation(
    (data: AddSubscriptionPromoRequest) => createSubscriptionPromo(data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  const initialValues = useMemo(
    () => ({
      name: '',
      code: '',
      priceId: [] as string[],
      isAmountOff: CouponDetail.amountOff,
      valueOff: 0,
    }),
    [isOpen],
  );

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setError('');
        onCancel();
      }}
    >
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Create Coupon
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const { isAmountOff, valueOff, code, name, priceId } = data;
          const value =
            isAmountOff === CouponDetail.amountOff
              ? {
                  amountOff: valueOff * 100,
                }
              : {
                  percentOff: valueOff,
                };

          await createCoupon.mutateAsync({
            name,
            code: code.toUpperCase(),
            priceId,
            ...value,
          });

          setSubmitting(false);
          resetForm();
          setError('');
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form style={{ marginBottom: 0 }}>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6">Coupon Information</Typography>
              <FormTextField
                label="Name"
                name="name"
                placeholder="Your coupon's name"
                type="input"
              />
              <FormTextField
                label="Coupon Code"
                name="code"
                type="input"
                placeholder="10OFF"
                autoCapitalize="on"
              />
            </div>
            <Typography variant="h6">
              Apply to following subscriptions
            </Typography>
            <Column style={{ padding: '0 12px', marginBottom: 36 }}>
              {subscriptions.map((val) => {
                const isChecked = values.priceId.includes(val.priceId);
                return (
                  <Row key={val.priceId} justifyContent="space-between">
                    <Typography variant="subtitle2">{val.name}</Typography>

                    <Checkbox
                      color="primary"
                      checked={isChecked}
                      onChange={(e, checked) => {
                        if (!checked) {
                          const newPriceIds = values.priceId.filter(
                            (id) => id !== val.priceId,
                          );
                          setFieldValue('priceId', newPriceIds);
                        } else {
                          setFieldValue('priceId', [
                            ...values.priceId,
                            val.priceId,
                          ]);
                        }
                      }}
                    />
                  </Row>
                );
              })}
            </Column>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6" children="Details on discount" />
              <FormRadioButton
                label="Amount Off"
                name="isAmountOff"
                type="radio"
                value={CouponDetail.amountOff}
              />
              <FormRadioButton
                label="Percent Off"
                name="isAmountOff"
                type="radio"
                value={CouponDetail.percentOff}
              />
              <FormTextField
                label={
                  values.isAmountOff === CouponDetail.amountOff
                    ? 'Amount Off'
                    : 'Percent Off'
                }
                name="valueOff"
                type="number"
                placeholder="0.00"
              />
            </div>
            <Button
              fullWidth
              disabled={isSubmitting}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              style={{
                borderRadius: 35,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="h6">Create</Typography>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateCoupon;
