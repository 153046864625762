export const LOGIN = '/login';
export const REGISTER = '/register';
export const EDIT_PROFILE = '/edit-profile';
export const LIVE_VIDEO = '/live/:id';
export const VIEW_WORKOUT = '/view-workout';
export const LIVE_WORKOUT_DETAIL = '/live-workout/:id';
export const MANAGE_WORKOUTS = '/manage-workouts';
export const EDIT_VIDEO = '/edit-video';
export const ANALYTICS = '/analytics';
export const UPLOAD_LIVE_WORKOUT = '/live-workout';
export const EDIT_WORKOUT = '/edit-workout';
export const CREATOR_SCHEDULE = '/schedule';
export const UPLOAD_WORKOUT = '/workout';
export const MANAGE_SUBSCRIPTIONS = '/manage-subscriptions';
export const MANAGE_CLASS_PRICES = '/manage-class-prices';
export const CALENDAR = '/calendar';
export const MANAGE_PAGE = '/settings';
export const EVENTS = '/events';
export const PROGRAMS = '/programs';
export const NOTIFICATIONS = '/notifications';

export const LIVE_VIDEO_ROUTE = (id: string | number) => `/live/${id}`;
export const LIVE_WORKOUT_DETAIL_ROUTE = (id: string | number) =>
  `/live-workout/${id}`;
export const TRAINER_DETAIL = (id: number) => `/trainer-profile?id=${id}`;

export const FACEBOOK_LINK = process.env.GATSBY_CLIENT_FACEBOOK;
export const INSTAGRAM_LINK = process.env.GATSBY_CLIENT_INSTAGRAM;
export const CLIENT_NAME = process.env.GATSBY_CLIENT_NAME;
export const HOME_CAPTION = process.env.GATSBY_HOME_CAPTION;
export const CONTACT_EMAIL = process.env.GATSBY_CONTACT_EMAIL;
export const SUPPORT_EMAIL = process.env.GATSBY_SUPPORT_EMAIL;
