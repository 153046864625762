import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/FormTextField';
import Button from 'components/Button';
import * as yup from 'yup';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import { equals } from 'ramda';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { editPageMetadata } from 'queries/page';
import { EditConfigMetadata } from '@solin-fitness/types';
import { BtnSelect } from 'components/BtnSelect';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  pageId: number;
  isMailchimpSetup: boolean;
  mailchimpAudienceId: string;
  mailchimpApiKey: string;
  mailchimpServerPrefix: string;
}

export const DescriptionLength = styled.div`
  width: 100%;
  font-size: 0.8rem;
  text-align: right;
`;

const validationSchema = yup.object({
  isMailchimpSetup: yup.boolean().required(),
  mailchimpAudienceId: yup.string().when('isMailchimpSetup', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().optional().nullable(),
  }),
  mailchimpApiKey: yup.string().when('isMailchimpSetup', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().optional().nullable(),
  }),
  mailchimpServerPrefix: yup.string().when('isMailchimpSetup', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().optional().nullable(),
  }),
});

const EditMailchimp: React.FC<Props> = (props) => {
  const {
    isOpen,
    onCancel,
    pageId,
    isMailchimpSetup,
    mailchimpAudienceId,
    mailchimpApiKey,
    mailchimpServerPrefix,
  } = props;
  const queryClient = useQueryClient();
  const [error, setError] = useState('');
  const initialValues = useMemo(
    () => ({
      isMailchimpSetup,
      mailchimpAudienceId,
      mailchimpApiKey,
      mailchimpServerPrefix,
    }),
    [
      isMailchimpSetup,
      mailchimpAudienceId,
      mailchimpApiKey,
      mailchimpServerPrefix,
    ],
  );

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal top={10} width={600} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Edit Mailchimp Integration
      </Typography>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await editMetadata.mutateAsync({
            id: pageId,
            data: {
              isMailchimpSetup: data.isMailchimpSetup,
              mailchimpAudienceId: data.mailchimpAudienceId,
              mailchimpApiKey: data.mailchimpApiKey,
              mailchimpServerPrefix: data.mailchimpServerPrefix,
            },
          });

          setSubmitting(false);
          setError('');
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form style={{ marginBottom: 0 }}>
            <Row
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ paddingBottom: 36, flexWrap: 'wrap' }}
            >
              <BtnSelect
                label="Use mailchimp integration?"
                isSingleSelect
                testId="isMailchimpSetup"
                options={[
                  { key: 'Yes', value: 'true' },
                  { key: 'No', value: 'false' },
                ]}
                values={[`${values.isMailchimpSetup}`]}
                onClick={(selectValues: any): void => {
                  const val = selectValues[0] === 'true' ? true : false;
                  setFieldValue('isMailchimpSetup', val);
                }}
              />
            </Row>
            {values.isMailchimpSetup && (
              <>
                <FormTextField
                  label="Mailchimp API Key"
                  name="mailchimpApiKey"
                  type="input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormTextField
                  label="Mailchimp Audience/List ID"
                  name="mailchimpAudienceId"
                  type="input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormTextField
                  label="Mailchimp Server Prefix (ex: us7)"
                  name="mailchimpServerPrefix"
                  type="input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
            {!equals(initialValues, values) && (
              <Button
                fullWidth
                disabled={isSubmitting}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{
                  borderRadius: 35,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="inherit" size="2rem" />
                ) : (
                  <Typography variant="h6">Save</Typography>
                )}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditMailchimp;
