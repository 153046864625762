import { CheckIcon, ChevronLeft } from 'components/shared/Icons';
import { BreadCrumb } from 'context/ProgramBuilderContext';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ProgramAssetsForm from './Components/ProgramAssetsForm';
import ProgramDetailsForm from './Components/ProgramDetailsForm';
import ProgramPriceForm from './Components/ProgramPriceForm';
import ProgramWeekBuilder from './ProgramWeekBuilder';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Header = styled.h1`
  font-family: 'Raleway';
  font-size: 30px;
  color: #000;
`;

const BreadCrumbs = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const BreadCrumbItem = styled.li`
  float: left;
  display: flex;
  gap: 12px;
  padding: 0 16px;
  cursor: pointer;

  &:first-child {
    padding-left: 0px;
  }
`;

const BreadCrumbText = styled.p<{ isSelected: boolean }>`
  font-family: 'Raleway';
  font-weight: ${(p) => (p.isSelected ? 'bold' : 'normal')};
  font-size: 22px;
  margin: 0;
`;

const CheckMarkContainer = styled.div<{ isComplete: boolean }>`
  flex-shrink: 0;
  border: 2.5px solid ${(p) => p.theme.main.primary};
  background-color: ${(p) => (p.isComplete ? p.theme.main.primary : '#fff')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 8px 12px;
  padding-left: 4px;
  border-radius: 30px;
  cursor: pointer;
  transition: 200ms ease-out;
  color: #000;

  &:hover {
    background-color: ${(p) => p.theme.main.primary};
    color: #fff;
  }
`;

const BackText = styled.p`
  font-size: 18px;
  font-family: 'Raleway';
  line-height: 18px;
  color: inherit;
`;

const CreateProgram = () => {
  const {
    programDetails,
    programAssets,
    programPrice,
    selectedBreadCrumb,
    handleSelectBreadCrumb,
    isProgramCompleted,
    isDetailsCompleted,
    isAssetsCompleted,
    isPriceCompleted,
    isEditing,
  } = useProgramBuilder();

  const history = useHistory();

  return (
    <Container>
      <HeaderContainer onClick={() => history.goBack()}>
        <ChevronLeft />
        <BackText>Programs & Challenges</BackText>
      </HeaderContainer>
      <Header>{isEditing ? 'Edit' : 'Create'}</Header>
      <BreadCrumbs>
        {Object.values(BreadCrumb).map((value, index) => {
          const isComplete =
            value === BreadCrumb.GENERAL
              ? isDetailsCompleted
              : value === BreadCrumb.ASSETS
              ? isAssetsCompleted
              : value === BreadCrumb.CONTENT
              ? isProgramCompleted
              : isPriceCompleted;
          return (
            <BreadCrumbItem
              key={index}
              onClick={() => handleSelectBreadCrumb(value)}
            >
              <BreadCrumbText isSelected={selectedBreadCrumb === value}>
                {value}
              </BreadCrumbText>
              <CheckMarkContainer isComplete={isComplete}>
                <div style={{ visibility: isComplete ? 'visible' : 'hidden' }}>
                  <CheckIcon
                    color="#fff"
                    width={20}
                    height={20}
                    strokeWidth={2.5}
                  />
                </div>
              </CheckMarkContainer>
            </BreadCrumbItem>
          );
        })}
      </BreadCrumbs>
      {(() => {
        switch (selectedBreadCrumb) {
          case BreadCrumb.GENERAL:
            return (
              <ProgramDetailsForm
                key="programDetailsForm"
                programDetails={programDetails}
              />
            );
          case BreadCrumb.ASSETS:
            return <ProgramAssetsForm programAssets={programAssets} />;
          case BreadCrumb.CONTENT:
            return <ProgramWeekBuilder />;
          case BreadCrumb.PRICE:
            return (
              <ProgramPriceForm
                key="programPriceForm"
                programPrice={programPrice}
              />
            );
          default:
            return null;
        }
      })()}
    </Container>
  );
};

export default CreateProgram;
