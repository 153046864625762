import 'styles/ReactHorizontalDatePicker.css';
import ReactHorizontalDatePicker from 'components/CalendarHorizontal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useQuery, useQueryClient } from 'react-query';
import { DateTime } from 'luxon';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useState } from 'react';
import UsersAttending from 'components/UsersAttending';
import * as Routes from 'constants/routes';
import * as S from 'styles/schedules.styles';
import * as Colors from 'constants/theme';
import { ILiveWorkout, User } from 'types/index';
import { useHistory } from 'react-router-dom';
import { getUser } from 'services/auth';
import { getLiveWorkouts, deleteLiveWorkoutApi } from 'queries/live-workouts';
import JoinButton from './JoinButton';
import CloudinaryImage from 'components/CloudinaryImage';
import { API_ROOT } from 'services/api';
import LogRocket from 'logrocket';
import { getPage } from 'queries/page';
import { PageConfigResponse } from '@solin-fitness/types';

const IS_DEV = (API_ROOT || '').includes('staging') ? true : false;

const daysAddedToSelectedDate = [1, 2, 3, 4, 5, 6, 7];

interface Props {
  trainerId?: number;
  userProfileId?: number;
  fullWidth?: boolean;
}

const ScheduleComponent = (props: Props): React.ReactElement => {
  const today = DateTime.fromJSDate(new Date());
  const [selectedDate, setSelectedDate] = React.useState<DateTime | null>(
    today,
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Workout deleted');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<number | false>(
    false,
  );

  const [openEditDialog, setOpenEditDialog] = useState<ILiveWorkout | false>(
    false,
  );

  const userData = getUser();

  const history = useHistory();

  const queryClient = useQueryClient();

  const liveWorkoutsQuery = useQuery<ILiveWorkout[], Error>(
    'liveWorkouts',
    () => getLiveWorkouts(),
  );

  const { isLoading, data: pageConfig } = useQuery<PageConfigResponse, Error>(
    'creatorPage',
    () => getPage(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const workouts = liveWorkoutsQuery.data || [];

  useEffect(() => {
    if (!IS_DEV) {
      LogRocket.identify(userData?.email || '', {
        username: userData?.username || '',
        userId: userData?.id || '',
      });
    }
  }, []);

  const getWorkoutsFiltered = () => {
    if (workouts === undefined) {
      return [];
    }
    const workoutsFiltered =
      props.trainerId !== undefined
        ? workouts.filter((item) => item.user.id === props.trainerId)
        : workouts;

    if (props.userProfileId !== undefined) {
      return workouts.filter((item) => {
        const count = item.usersAttending.filter(
          (user) => user.id === props.userProfileId,
        );
        return count.length === 1;
      });
    }
    return workoutsFiltered;
  };

  const filterWorkoutsByDate = (daysToAdd: number) => {
    if (selectedDate === null) {
      return workouts;
    }
    const workoutsFiltered = getWorkoutsFiltered();

    return workoutsFiltered.filter((workout) => {
      const startDate = DateTime.fromISO(workout.startDate);

      const diff = startDate
        .startOf('day')
        .diff(selectedDate.startOf('day'), 'days')
        .toObject();

      if (
        diff.days !== undefined &&
        diff.days >= daysToAdd &&
        diff.days < daysToAdd + 1
      ) {
        return workout;
      }
    });
  };

  const goToEdit = (workout: ILiveWorkout, hasPayingCustomers: boolean) => {
    if (!hasPayingCustomers) {
      history.push(Routes.EDIT_WORKOUT, { workoutData: workout });
    } else {
      setOpenEditDialog(workout);
    }
  };

  const handleDeleteWorkout = (
    workoutId: number | string,
    startDatePassed: boolean,
  ) => {
    if (!startDatePassed) {
      setOpenDeleteDialog(workoutId as number);
    }
  };

  const deleteWorkout = () => {
    const id = openDeleteDialog as number;
    deleteLiveWorkoutApi(id).then(() => {
      setOpenSnackbar(true);
      setOpenDeleteDialog(false);
      queryClient.invalidateQueries('liveWorkouts');
    });
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const onSelectedDay = (date: string) => {
    const dateTime = DateTime.fromJSDate(new Date(date)) || null;
    setSelectedDate(dateTime);
  };

  const isEmpty = () => {
    let numberOfWorkouts = 0;
    daysAddedToSelectedDate.map((day, indexOrg) => {
      numberOfWorkouts += filterWorkoutsByDate(indexOrg).length;
    });
    return numberOfWorkouts === 0 ? true : false;
  };

  const handleClickActions = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const handleGoToDetail = (workout: ILiveWorkout) => {
    history.push(Routes.LIVE_WORKOUT_DETAIL_ROUTE(workout.id), {
      liveWorkoutId: workout.id,
    });
  };

  const goToTrainerDetail = (user: User) => {
    history.push(Routes.TRAINER_DETAIL(user.id), {
      state: { user },
    });
  };

  const getHasPayingCustomers = (workoutId: number) => {
    const workoutObject = workouts.filter((w) => w.id === workoutId)[0];
    return (
      workoutObject &&
      workoutObject.isForSale &&
      workoutObject.usersAttending.length > 0
    );
  };

  const cantEditMessage =
    'You cannot delete this workout because the start date has already passed. Contact Solin support if you need to edit or delete this workout.';

  return (
    <S.Container>
      <S.Wrap fullWidth={props.fullWidth}>
        {props.userProfileId !== undefined && (
          <S.SectionHeader>Scheduled Live Workouts</S.SectionHeader>
        )}
        {props.trainerId === undefined && props.userProfileId === undefined && (
          <S.CalendarSection>
            <ReactHorizontalDatePicker
              // @ts-ignore
              selectedDay={onSelectedDay}
              enableScroll={false}
              enableDays={180}
            />
          </S.CalendarSection>
        )}

        <S.ClassesSection>
          {liveWorkoutsQuery.isLoading && (
            <S.LoaderWrap>
              <CircularProgress />
            </S.LoaderWrap>
          )}

          {!liveWorkoutsQuery.isLoading && isEmpty() && (
            <S.EmptyState>
              {
                'No workouts are scheduled for this time period. Schedule a workout and it will appear here!'
              }
            </S.EmptyState>
          )}

          {daysAddedToSelectedDate.map((day, indexOrg) => {
            const filteredWorkouts = filterWorkoutsByDate(indexOrg);
            const dayPlus = selectedDate?.plus({ days: indexOrg });

            return (
              <React.Fragment key={`${day}.${indexOrg}`}>
                {filteredWorkouts.length > 0 && (
                  <S.DateTitle>
                    {dayPlus && dayPlus.toFormat('cccc')}
                    {', '}
                    {dayPlus && dayPlus.toFormat('LLL d')}
                  </S.DateTitle>
                )}
                {filteredWorkouts.map((workout: ILiveWorkout, index) => {
                  const startDateFormatted = DateTime.fromISO(
                    workout.startDate,
                  ).toLocaleString(DateTime.TIME_SIMPLE as any);
                  const currDate = new Date();
                  const startDatePassed =
                    currDate > new Date(workout.startDate);

                  // const userSignedUp =
                  //   workout.usersAttending.filter(
                  //     (user) => userId === user.id,
                  //   ).length > 0;

                  const hasPayingCustomers =
                    workout.isForSale && workout.usersAttending.length > 0;

                  return (
                    <S.RowWrap key={workout.id}>
                      <S.ClassImage>
                        <CloudinaryImage
                          publicId={
                            workout.thumbnailPublicId ||
                            workout.user.profile.profilePictureUrlPublicId
                          }
                          alt={'scheduled live class trainer profile image'}
                          width={90}
                          height={90}
                          fetchFormat={'auto'}
                          quality={'auto:best'}
                          crop={'fill'}
                          gravity={'auto:face'}
                          // @ts-ignore
                          onClick={() => goToTrainerDetail(workout.user)}
                        />
                      </S.ClassImage>
                      <S.ClassRow
                        id={`scheduleRow${workout.title}`}
                        key={workout.id}
                        onClick={() => handleGoToDetail(workout)}
                        hasBorder={filteredWorkouts.length - 1 !== index}
                      >
                        <S.ClassDesc>
                          {workout.title}
                          <br />
                          <S.SmallDesc>
                            with {workout.user.profile.firstName}{' '}
                            {workout.user.profile.lastName}
                          </S.SmallDesc>
                          <S.ClassTime>
                            {`${startDateFormatted} - ${workout.length}m`}
                          </S.ClassTime>
                        </S.ClassDesc>

                        <S.ClassActions onClick={handleClickActions}>
                          <S.HoverIcons
                            isTest={workout.title.includes('TestCypressTitle')}
                          >
                            <Tooltip title={'Edit Workout'}>
                              <IconButton
                                id={`editLwButton${workout.title}`}
                                onClick={() =>
                                  goToEdit(workout, hasPayingCustomers)
                                }
                              >
                                <EditIcon
                                  style={{
                                    color: Colors.themeColors.textColor,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title={
                                startDatePassed
                                  ? cantEditMessage
                                  : 'Delete Workout'
                              }
                            >
                              <IconButton
                                style={{ marginRight: -5 }}
                                id={`deleteLwButton${workout.title}`}
                                onClick={() =>
                                  handleDeleteWorkout(
                                    workout.id,
                                    startDatePassed,
                                  )
                                }
                              >
                                <DeleteIcon
                                  style={{
                                    color: Colors.themeColors.textColor,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </S.HoverIcons>

                          <UsersAttending workout={workout} />
                          <JoinButton workout={workout} />
                        </S.ClassActions>
                      </S.ClassRow>
                    </S.RowWrap>
                  );
                })}
              </React.Fragment>
            );
          })}
        </S.ClassesSection>
      </S.Wrap>

      <Dialog
        open={openEditDialog ? true : false}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Live Class</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            People have already scheduled and/or purchased this class. Would you
            still like to edit the live class?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => goToEdit(openEditDialog as ILiveWorkout, false)}
            color="primary"
            autoFocus
            id="deleteLwConfirmButton"
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete live workout ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getHasPayingCustomers(openDeleteDialog as number)
              ? 'People have already scheduled and/or purchased this class. Would you still like to delete the live class?'
              : 'Are you sure you want to delete this live class ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={deleteWorkout}
            color="primary"
            autoFocus
            id="deleteLwConfirmButton"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
    </S.Container>
  );
};

export default ScheduleComponent;
