import { EditConfigMetadata } from '@solin-fitness/types';
import Layout from 'components/Layout';
import NotificationToggle from 'components/Notifications/NotificationToggle';
import { editPageMetadata } from 'queries/page';
import { useMutation, useQueryClient } from 'react-query';
import useConfig from 'hooks/useConfig';
import { Loading } from 'components/Loading';

const NotificationsPage = () => {
  const queryClient = useQueryClient();
  const { isLoading, data: config } = useConfig();
  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  const handleCommunityEmailToggle = async (value: boolean) =>
    editMetadata.mutateAsync({
      id: config?.id || 0,
      data: { communityEmailsEnabled: value },
    });

  const handlePurchasesEmailToggle = async (value: boolean) =>
    editMetadata.mutateAsync({
      id: config?.id || 0,
      data: { purchaseEmailsEnabled: value },
    });

  return (
    <Layout title="Email Notifications">
      {config ? (
        <>
          <NotificationToggle
            title={'Send me an email every time a program is purchased'}
            selected={config.config.purchaseEmailsEnabled}
            handleToggle={handlePurchasesEmailToggle}
          />
          <br />
          <NotificationToggle
            title={'Send me an email for every community post'}
            selected={config.config.communityEmailsEnabled}
            handleToggle={handleCommunityEmailToggle}
          />
          <br />
          <div>
            Note: When a consumer sends a tip we will always send you an email.
            If you would like to turn that off, please email
            michael@solinfitness.com.
          </div>
        </>
      ) : null}

      {isLoading ? <Loading /> : null}
    </Layout>
  );
};

// random comment for new deploy

export default NotificationsPage;
