import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

type Props = TypographyProps & {
  children: string;
};

const Text = (props: Props) => <Typography {...props}>{props.children}</Typography>;

export default Text;
