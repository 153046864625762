import { CircularProgress } from '@material-ui/core';
import { Row } from 'components/Row';
import React from 'react';

const Loading = () => (
  <Row style={{ marginTop: '5%' }}>
    <CircularProgress size="2rem" />
  </Row>
);

export default Loading;
