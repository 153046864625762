import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  Menu,
  MenuPopover,
  MenuButton,
  MenuItem,
  useMenuButtonContext,
} from '@reach/menu-button';
import { ChevronDown } from 'components/shared/Icons/ChevronDown';
import useThemeContext from '../../hooks/useThemeContext';
import '@reach/menu-button/styles.css';

const Text = styled.p`
  font-family: 'Raleway';
  font-size: var(--font-size);
  color: var(--color);
  font-weight: var(--weight);
  text-align: center;
  padding: var(--padding);
`;

const Dropdown = styled(MenuButton)`
  background-color: transparent;
  border: none;
  color: var(--color);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  width: var(--width, 100%);
  box-shadow: var(--box-shadow, none);
  border-radius: 30px;
  height: 50px;

  &:focus {
    outline: none;
  }
`;

const AnimatedPoper = motion(MenuPopover);

const Popover = styled(AnimatedPoper)`
  margin-top: 12px;
  width: var(--width);
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
`;

const PopoverItem = styled(MenuItem)`
  padding: 2px 0px;
  margin: 5px 5px;
  border-radius: 20px;

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &:hover {
    background-color: #f7f7f7;
    p {
      font-weight: 500;
    }
  }
`;

export interface DropdownOption {
  label: string | number | ReactNode;
  value: string | number;
}

export interface DropdownProps {
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => void;
  width?: string;
  showShadow?: boolean;
  defaultOption?: DropdownOption;
  wrapStyle?: React.CSSProperties;
}

const DropdownPopover: React.FC<DropdownProps> = (props) => {
  const { options, onSelect, width, showShadow, defaultOption, wrapStyle } =
    props;
  const { isExpanded } = useMenuButtonContext();
  const theme = useThemeContext();
  const [selectedOption, setSelectedOption] = useState<DropdownOption>(
    defaultOption || options[0],
  );
  const targetRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useLayoutEffect(() => {
    // get inital container width for popover with
    if (targetRef.current) {
      setContainerWidth(targetRef.current.offsetWidth);
    }

    // add resize listener for consequent screen size changes
    const resizeListener = () => {
      if (targetRef.current) {
        setContainerWidth(targetRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const handleSelect = (option: DropdownOption) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div ref={targetRef}>
      <Dropdown
        style={{
          '--width': width,
          '--box-shadow': showShadow
            ? '0px 5px 10px rgba(0, 0, 0, 0.1)'
            : 'none',
          ...wrapStyle,
        }}
      >
        <Text
          style={{
            '--font-size': 22 + 'px',
            '--color': showShadow
              ? theme.main.lightSecondary
              : theme.main.secondary,
            '--padding': 12,
            '--weight': 700,
          }}
        >
          {selectedOption.label}
        </Text>
        <motion.div
          onClick={(e) => e.preventDefault()}
          variants={{
            up: {
              rotate: 0,
            },
            down: {
              rotate: 180,
            },
          }}
          initial="up"
          animate={isExpanded ? 'down' : 'up'}
        >
          <ChevronDown color={theme.main.secondary} width={36} height={36} />
        </motion.div>
      </Dropdown>
      <Popover
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{ '--width': containerWidth + 'px' }}
      >
        {options.map((option, index) => (
          <PopoverItem key={index} onSelect={() => handleSelect(option)}>
            <Text
              style={{
                '--font-size': 18 + 'px',
                '--padding': '8px 0px',
                '--color': theme.main.secondary,
              }}
            >
              {option.label}
            </Text>
          </PopoverItem>
        ))}
      </Popover>
    </div>
  );
};

const DropdownMenu: React.FC<DropdownProps> = (props) => (
  <Menu>
    <DropdownPopover {...props} />
  </Menu>
);

export default DropdownMenu;
