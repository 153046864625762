import {
  WorkoutResponse,
  WorkoutAnalyticsResponse,
  LiveWorkoutAnalyticsResponse,
} from '@solin-fitness/types';
import { imagePlaceholder } from 'constants/theme';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import * as Routes from 'constants/routes';
import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import useThemeContext from 'hooks/useThemeContext';
import CloudinaryImage from 'components/CloudinaryImage';

const useStyles = makeStyles({
  watch: {
    marginLeft: 36,
    borderRadius: 35,
    height: 48,
    padding: '0 20px',
    border: '1px solid #639ac9',
    color: '#639ac9',
  },
});

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.text.primary};
`;

const Description = styled.div`
  height: 20px;
  overflow: hidden;
`;

const ImgWrap = styled.div`
  position: relative;

  img {
    width: 201px;
    height: 100px;
  }
`;

const Badge = styled.div`
  background: ${(p) => p.theme.main.primary};
  color: white;
  padding: 4px 10px;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 10px;
`;

interface Props {
  thumbnailPublicId: string;
  title: string;
  description: string;
  isDrop?: boolean;
  workoutData?: WorkoutResponse;
}

const WorkoutOverviewCell = ({
  thumbnailPublicId,
  title,
  description,
  isDrop,
  workoutData,
}: Props) => {
  const theme = useThemeContext();
  const history = useHistory();

  const classes = useStyles();
  return (
    <Container>
      <ImgWrap>
        <CloudinaryImage
          publicId={thumbnailPublicId || imagePlaceholder}
          alt={'workout cell thumbnail'}
          width={400}
          height={200}
          fetchFormat={'auto'}
          quality={'auto:best'}
          crop={'scale'}
          style={{
            borderRadius: 6,
            margin: 0,
            marginRight: 20,
            objectFit: 'cover',
          }}
        />
        {isDrop && <Badge>Drop</Badge>}
      </ImgWrap>
      <div style={{ flex: 1 }}>
        <Title>{title}</Title>
        <Description
          style={{ color: theme.text.secondary }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></Description>
      </div>
      {workoutData && (
        <div>
          <Button
            className={classes.watch}
            variant="outlined"
            onClick={() => {
              history.push(Routes.VIEW_WORKOUT, {
                workoutData,
              });
            }}
          >
            Watch
          </Button>
        </div>
      )}
    </Container>
  );
};

export default WorkoutOverviewCell;
