import styled from 'styled-components';
import * as Colors from 'constants/theme';
import { PHONE } from 'constants/measurements';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  position: relative;
  overflow: scroll;
  @media (max-width: ${PHONE}) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
  }
`;

export const Wrap = styled.div`
  position: flex;
  width: 80%;
  text-align: left;
  @media (max-width: ${PHONE}) {
    width: 100%;
    padding: 20px;
  }
`;

export const TrainerName = styled.div`
  color: white;
  margin-top: 8px;
  font-size: 18px;
  color: ${Colors.themeColors.textColor};

  @media (max-width: ${PHONE}) {
    font-size: 16px;
  }
`;

export const WorkoutTitle = styled.div`
  color: white;
  font-size: 26px;
  font-size: 500;
  color: ${Colors.themeColors.textColor};

  @media (max-width: ${PHONE}) {
    font-size: 22px;
  }
`;

export const TrainerImage = styled.div`
  img {
    border-radius: 50%;
    border: 3px solid white;
    @media (max-width: ${PHONE}) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
  @media (max-width: ${PHONE}) {
    margin-left: 20px;
  }
`;

export const WorkoutDate = styled.div`
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 19px;
  color: ${Colors.themeColors.textColor};
  @media (max-width: ${PHONE}) {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const SectionTitle = styled.div`
  color: ${Colors.themeColors.primary};
  font-size: 22px;
  font-size: 500;
  margin-top: 50px;
  @media (max-width: ${PHONE}) {
    margin-top: 30px;
    font-size: 20px;
  }
`;

export const SectionText = styled.div`
  color: white;
  margin-top: 8px;
  color: ${Colors.themeColors.textColor};
`;

export const PeopleCol = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  color: ${Colors.themeColors.textColor};
`;

export const PepleRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const PeopleName = styled.div`
  margin-left: 20px;
`;

export const CloseIcon = styled.div`
  width: 100%;
`;

export const PeopleImageStyle = { width: 40, height: 40, borderRadius: 20 };

export const ChipStyle = {
  color: `${Colors.themeColors.textColor}`,
  borderColor: `${Colors.themeColors.textColor}`,
  marginRight: 10,
  marginBottom: 10,
};
