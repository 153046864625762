import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Modal as MuiModal } from '@material-ui/core';

interface ContainerProps {
  width?: number;
  top?: number;
  backgroundColor?: string;
}
interface ModalProps {
  open: boolean;
  onCancel: () => void;
  children: React.ReactNode | React.ReactNode[];
}

type Props = ContainerProps & ModalProps;

const ModalContainer = styled.div<ContainerProps>`
  position: absolute;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.background};
  border-radius: 10px;
  top: ${({ top }) => `${top ?? 5}%`};
  left: calc(50% - ${({ width }) => (width ? width / 2 : '250')}px);
  outline: none;
  padding: 30px;
  width: ${({ width }) => (width ? width : '500')}px;

  @media (max-width: 820px) {
    width: 90%;
    left: 5%;
  }
  max-height: 95%;
  overflow: scroll;
  box-shadow: 0 15px 35px hsla(0, 0%, 0%, 0.2);
`;

/**
 * Generic Modal component to use throughout app
 * Width is the width of the modal's inner container, default 500px
 * Offset is the offset of the modal from the its current outer box
 */
const Modal: React.FC<Props> = ({
  open,
  onCancel,
  width,
  top,
  children,
  backgroundColor,
}) => (
  <>
    {open && (
      <MuiModal open={open} disableEnforceFocus>
        <ModalContainer
          onClick={(e) => e.stopPropagation()}
          width={width}
          top={top}
          backgroundColor={backgroundColor}
        >
          <IconButton
            aria-label="close"
            color="default"
            onClick={onCancel}
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          {children}
        </ModalContainer>
      </MuiModal>
    )}
  </>
);

export default Modal;
