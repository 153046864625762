import React from 'react';
import { fetchUserById } from 'queries/user';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import ProfileImage from 'components/ProfileImage';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

export const Name = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: black;
  margin-left: 10px;
`;

export const PersonWrap = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding-top: 10px;
  height: calc(100vh - 218px);
  overflow: scroll;
`;

interface IProps {
  userId: number | string;
}

const AttendeeName = (props: IProps): React.ReactElement | null => {
  const { userId } = props;

  const { data: user } = useQuery(
    ['userProfile', userId],
    () => fetchUserById(Number(userId)),
    {
      staleTime: 1000 * 60 * 60,
      retry: false,
    },
  );

  if (!user) {
    return null;
  }

  return (
    <PersonWrap>
      <ProfileImage
        firstName={user.profile.firstName}
        lastName={user.profile.lastName}
        profilePictureUrlPublicId={user.profile.profilePictureUrlPublicId}
        userId={user.id}
        imageSize={{ width: 32, height: 32 }}
      />
      <Name>
        {user.profile.firstName} {user.profile.lastName}
      </Name>
    </PersonWrap>
  );
};

interface Props {
  usersWatching: IAgoraRTCRemoteUser[];
}

const Attendees = ({ usersWatching }: Props): React.ReactElement | null => (
  <Container>
    {usersWatching.map((user) => (
      <AttendeeName userId={user.uid} />
    ))}
  </Container>
);

export default Attendees;
