import React from 'react';
import * as S from 'styles/schedules.styles';
import CloudinaryImage from 'components/CloudinaryImage';

import { ILiveWorkout } from 'types';

interface IProps {
  workout: ILiveWorkout;
}

interface IUser {
  firstName: string;
  id: number;
  lastName: string;
  profilePicturePublicId: string | null;
}

interface IPropsImage {
  style: any;
  image: string | null;
  user: IUser;
  placeholderStyle?: any;
}

export const UserProfileImage = ({
  style,
  image,
  user,
  placeholderStyle,
}: IPropsImage) => {
  const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

  return image ? (
    <S.UserImage>
      <CloudinaryImage
        publicId={image}
        alt={'user image'}
        width={31}
        height={31}
        fetchFormat={'auto'}
        quality={'auto'}
        crop={'scale'}
        style={style}
      />
    </S.UserImage>
  ) : (
    <S.ProfileWrapSchedule style={placeholderStyle}>
      {initials}
    </S.ProfileWrapSchedule>
  );
};

const ProfileImage = ({ workout }: IProps): React.ReactElement | null => (
  <S.UsersWrap>
    {workout.usersAttending.length > 0 && (
      <>
        <UserProfileImage
          style={{
            width: workout.usersAttending.length === 1 ? 36 : 31,
          }}
          user={workout.usersAttending[0]}
          image={workout.usersAttending[0].profilePicturePublicId}
        />
        {workout.usersAttending.length > 1 && (
          <UserProfileImage
            style={{
              width: workout.usersAttending.length === 1 ? 36 : 31,
            }}
            user={workout.usersAttending[1]}
            image={workout.usersAttending[1].profilePicturePublicId}
          />
        )}
        {workout.usersAttending.length > 2 && (
          <UserProfileImage
            style={{
              width: workout.usersAttending.length === 1 ? 36 : 30,
            }}
            user={workout.usersAttending[2]}
            image={workout.usersAttending[2].profilePicturePublicId}
          />
        )}
        {workout.usersAttending.length > 3 && (
          <S.UserTotal>+{workout.usersAttending.length - 3}</S.UserTotal>
        )}
      </>
    )}
  </S.UsersWrap>
);

export default ProfileImage;
