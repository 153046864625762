import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Row } from 'components/Row';
import Button from 'components/Button';
import { GRAYS } from 'constants/theme';
import * as S from './PageOverview.styles';
import EditToggles from './EditToggles';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'components/Column';

interface Props {
  pageId: number;
  listItems: string[];
}

const PageToggles: React.FC<Props> = (props) => {
  const { pageId, listItems } = props;
  const [open, setIsOpen] = useState<boolean>(false);

  return (
    <S.Section>
      <EditToggles
        isOpen={open}
        onCancel={() => setIsOpen(false)}
        pageId={pageId}
        listItems={listItems}
      />
      <S.Container>
        <S.HeaderWrapper>
          <Typography display="inline" variant="h5">
            Tell your fans what they will get (3 bullets recommended)
          </Typography>
        </S.HeaderWrapper>
        <S.EditButton>
          <Button
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => setIsOpen(true)}
          >
            Edit
          </Button>
        </S.EditButton>
      </S.Container>
      <div
        style={{
          margin: '0 -16px',
          borderBottom: `2px solid ${GRAYS.light}`,
        }}
      />
      <S.Bullets>
        <Row
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ paddingTop: 18 }}
        >
          <Column style={{ flex: 1 }} alignItems="flex-start">
            <Typography variant="h6">Bullet Details</Typography>
            <ul style={{ marginBottom: 0, paddingTop: 12 }}>
              {!!listItems.length ? (
                listItems.map((val, i) => (
                  <li key={`listItems.${i}`}>
                    <Typography variant="body1">{val}</Typography>
                  </li>
                ))
              ) : (
                <Typography variant="body1">None</Typography>
              )}
            </ul>
          </Column>
        </Row>
      </S.Bullets>
    </S.Section>
  );
};

export default PageToggles;
