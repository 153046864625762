import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useMutation, useQueryClient } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import { DateTime } from 'luxon';
import Button from 'components/Button';
import * as S from './PageOverview.styles';
import { PageClassPrices } from '@solin-fitness/types';
import { GRAYS, PRIMARY } from 'constants/theme';
import Switch from '@material-ui/core/Switch';
import {
  editPageSubscription,
  EditPageSubscriptionRequest,
} from 'queries/page';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  price: PageClassPrices;
}

const ClassPriceRow: React.FC<Props> = (props) => {
  const { price } = props;

  return (
    <React.Fragment>
      <Row justifyContent="flex-start" style={{ padding: '16px 0' }}>
        <Column alignItems="flex-start" style={{ flex: 3 }}>
          <Typography variant="h6">
            {`$${(price.amount / 100).toFixed(2)}`}
          </Typography>
          <Typography
            variant="h6"
            style={{
              color: PRIMARY,
              marginBottom: 0,
              lineHeight: 0.8,
            }}
          >
            {price.name}
          </Typography>
        </Column>
        <Column alignItems="flex-start" style={{ flex: 1 }}>
          <Typography
            variant="body1"
            align="left"
            style={{
              color: GRAYS.dark,
              marginBottom: 0,
              lineHeight: 0.8,
            }}
          >
            {price.description}
          </Typography>
        </Column>
      </Row>
      <div
        style={{
          margin: '0 -16px',
          borderBottom: `2px solid ${GRAYS.light}`,
        }}
      />
    </React.Fragment>
  );
};

export default ClassPriceRow;
