import { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import TodayIcon from '@material-ui/icons/Today';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import * as S from 'styles/layout.styles';
import Button from 'components/Button';
import * as Routes from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { getUser } from 'services/auth';
import { Column } from '../Column';
import { getPageUrl } from 'services/auth';
import { UserType } from '@solin-fitness/types';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';

const key = 'navmenu:index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: theme.palette.text.primary,
    },
  }),
);

const REACT_APP_SOLIN_STREAM_URL = process.env.REACT_APP_SOLIN_STREAM_URL;

interface Props {
  isMobileMenu: boolean;
}

const NavMenu: React.FC<Props> = ({ isMobileMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const user = getUser();

  const xPage = getPageUrl();

  const [selectedIndex, setSelectedIndex] = useState<number>(() => {
    const localIndex = localStorage.getItem(key);
    if (localIndex !== undefined) {
      return Number(localIndex);
    }
    return 0;
  });

  const handleListItemClicked = (index: number, path: string) => {
    setSelectedIndex(index);
    localStorage.setItem(key, String(index));
    history.push(path);
  };

  return (
    <S.Nav isMobileMenu={isMobileMenu}>
      {isMobileMenu && (
        <S.SideMenuLogoWrap>
          <S.Logo src="https://storage.googleapis.com/video-on-demand-base-images/solinLogoBlue.png" />
        </S.SideMenuLogoWrap>
      )}

      <Column style={{ height: 'auto', margin: '0 16px' }}>
        <Button
          variant="outlined"
          size="medium"
          fullWidth
          color="primary"
          data-test="trainerScheduleWorkoutButton"
          onClick={() => {
            history.push(Routes.UPLOAD_LIVE_WORKOUT);
            setSelectedIndex(-1);
          }}
          startIcon={<AddIcon />}
          style={{
            borderRadius: 35,
            textTransform: 'capitalize',
            marginBottom: 16,
          }}
        >
          Live Workout
        </Button>
        <Button
          variant="outlined"
          size="medium"
          fullWidth
          color="primary"
          onClick={() => {
            history.push(Routes.UPLOAD_WORKOUT);
            setSelectedIndex(-1);
          }}
          startIcon={<AddIcon />}
          style={{
            borderRadius: 35,
            textTransform: 'capitalize',
            marginBottom: 16,
          }}
        >
          On-Demand
        </Button>
      </Column>

      <div className={classes.root}>
        <List component="nav">
          <ListItem
            button
            selected={selectedIndex === 0}
            onClick={() => handleListItemClicked(0, Routes.CREATOR_SCHEDULE)}
          >
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary="Schedule" />
          </ListItem>

          {user && user.type === UserType.adminCreator && (
            <>
              <ListItem
                button
                selected={selectedIndex === 1}
                onClick={() => handleListItemClicked(1, Routes.MANAGE_PAGE)}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Page" />
              </ListItem>

              <ListItem
                button
                selected={selectedIndex === 2}
                onClick={() => handleListItemClicked(2, Routes.ANALYTICS)}
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Analytics" />
              </ListItem>
            </>
          )}

          <ListItem
            button
            selected={selectedIndex === 3}
            onClick={() => handleListItemClicked(3, Routes.EVENTS)}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === 4}
            onClick={() => handleListItemClicked(4, Routes.MANAGE_WORKOUTS)}
          >
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Workout Library" />
          </ListItem>

          {user && user.type === UserType.adminCreator && (
            <>
              <ListItem
                button
                selected={selectedIndex === 5}
                onClick={() => handleListItemClicked(5, Routes.CALENDAR)}
              >
                <ListItemIcon>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText primary="Drop's Calendar" />
              </ListItem>
            </>
          )}

          <ListItem
            button
            selected={selectedIndex === 6}
            onClick={() => handleListItemClicked(6, Routes.EDIT_PROFILE)}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" />
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === 7}
            onClick={() => handleListItemClicked(7, Routes.PROGRAMS)}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Programs & Challenges" />
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === 8}
            onClick={() => handleListItemClicked(8, Routes.NOTIFICATIONS)}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email Notifications" />
          </ListItem>

          <ListItem
            button
            component="a"
            href={`${REACT_APP_SOLIN_STREAM_URL}${xPage}`}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={`solin.stream/${xPage}`} />
          </ListItem>
        </List>
      </div>
    </S.Nav>
  );
};

export default NavMenu;
