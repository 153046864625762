import React, { useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import Button from 'components/Button';
import * as S from './PageOverview.styles';
import { PageSubscriptions as PageSubs, PromoCode } from '@solin-fitness/types';
import { GRAYS } from 'constants/theme';
import SubscriptionRow from './SubscriptionRow';
import CreateSubscription from 'components/ManageSubscriptions/CreateSubscription';
import CreateCoupon from 'components/ManageSubscriptions/CreateCoupon';
import CouponRow from './CouponRow';
import EditIcon from '@material-ui/icons/Edit';
import useThemeContext from 'hooks/useThemeContext';
import { Form, Formik } from 'formik';
import { FormTextField } from 'components/FormTextField';
import { editPageMetadata } from 'queries/page';
import { useMutation, useQueryClient } from 'react-query';
import { CircularProgress } from '@material-ui/core';

interface Props {
  pageId: number;
  subscriptions: PageSubs[];
  promoCodes: PromoCode[];
  trialPeriodDays: number;
}

const PageSubscriptions: React.FC<Props> = (props) => {
  const theme = useThemeContext();
  const queryClient = useQueryClient();
  const { pageId, subscriptions, promoCodes, trialPeriodDays } = props;
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState<boolean>(false);
  const [isCouponOpen, setIsCouponOpen] = useState<boolean>(false);
  const [isEditingTrialPeriod, setIsEditingTrialPeriod] =
    useState<boolean>(false);

  const handleTrialPeriodDays = useMutation(
    (days: number) =>
      editPageMetadata(pageId, {
        trialPeriodDays: days,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <React.Fragment>
      <CreateSubscription
        isOpen={isSubscriptionOpen}
        onCancel={() => setIsSubscriptionOpen(false)}
      />
      <CreateCoupon
        isOpen={isCouponOpen}
        onCancel={() => setIsCouponOpen(false)}
        subscriptions={subscriptions}
      />
      <S.Section>
        <S.Container>
          <Typography display="inline" variant="h4">
            Subscriptions
          </Typography>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setIsSubscriptionOpen(true)}
            >
              Add tier
            </Button>
          </S.EditButton>
        </S.Container>
        <div
          style={{
            margin: '0 -16px',
            borderBottom: `2px solid ${theme.grays.light}`,
          }}
        />
        <Row justifyContent="flex-start" style={{ padding: '12px 0' }}>
          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              flex: 3,
              textTransform: 'uppercase',
            }}
          >
            Price
          </Typography>
          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              flex: 3,
              textTransform: 'uppercase',
            }}
          >
            Description
          </Typography>

          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              flex: 3,
              textTransform: 'uppercase',
              textAlign: 'center',
            }}
          >
            Active
          </Typography>
          <Typography style={{ flex: 1 }}></Typography>
        </Row>
        <div
          style={{
            margin: '0 -16px',
            borderBottom: `2px solid ${theme.grays.light}`,
          }}
        />
        {subscriptions?.map((sub) => (
          <SubscriptionRow key={sub.priceId} sub={sub} />
        ))}
        <div
          style={{
            margin: '8px -16px',
            borderBottom: `2px solid ${theme.grays.light}`,
          }}
        />
        <Column alignItems="flex-start" style={{ padding: '16px 0px' }}>
          <Row
            justifyContent="space-between"
            style={{ position: 'relative', padding: '8px 0px' }}
          >
            <Row justifyContent="flex-start">
              <Typography variant="h5">Free Trial Period</Typography>
              <Typography variant="h6" style={{ paddingLeft: 24 }}>
                <span style={{ color: theme.main.primary, fontWeight: 700 }}>
                  {trialPeriodDays}
                </span>{' '}
                days
              </Typography>
            </Row>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setIsEditingTrialPeriod(true)}
            >
              Edit
            </Button>
          </Row>
          {isEditingTrialPeriod && (
            <Formik
              initialValues={{ trialPeriodDays }}
              onSubmit={async (data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await handleTrialPeriodDays.mutateAsync(data.trialPeriodDays);

                setSubmitting(false);
                resetForm();
                setIsEditingTrialPeriod(false);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form style={{ marginBottom: 0, width: '100%' }}>
                  <Column alignItems="flex-end">
                    <div style={{ maxWidth: 150 }}>
                      <FormTextField
                        label=""
                        name="trialPeriodDays"
                        type="number"
                      />

                      <Row justifyContent="flex-end">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          style={{ marginRight: 16 }}
                          onClick={() => setIsEditingTrialPeriod(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            isSubmitting ||
                            values.trialPeriodDays === trialPeriodDays
                          }
                          variant="contained"
                          size="small"
                          color="primary"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <CircularProgress color="inherit" size="1.65rem" />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </Row>
                    </div>
                  </Column>
                </Form>
              )}
            </Formik>
          )}
        </Column>
        <div
          style={{
            margin: '8px -16px',
            borderBottom: `2px solid ${theme.grays.light}`,
          }}
        />
        <div
          style={{
            margin: '8px -16px',
            borderBottom: `2px solid ${theme.grays.light}`,
          }}
        />
        <Row
          justifyContent="space-between"
          style={{ position: 'relative', padding: '8px 0px' }}
        >
          <Typography variant="h5">Coupons</Typography>
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setIsCouponOpen(true)}
          >
            Add coupon
          </Button>
        </Row>
        <div
          style={{
            margin: '0 -16px',
            borderBottom: `2px solid ${theme.grays.light}`,
          }}
        />
        {!!promoCodes.length && (
          <>
            <Row justifyContent="flex-start" style={{ padding: '12px 0' }}>
              <Typography
                variant="caption"
                style={{
                  fontWeight: 600,
                  flex: 3,
                  textTransform: 'uppercase',
                }}
              >
                Coupon
              </Typography>
              <Typography
                variant="caption"
                style={{
                  fontWeight: 600,
                  flex: 3,
                  textTransform: 'uppercase',
                }}
              >
                Discount Code
              </Typography>

              <Typography
                variant="caption"
                style={{
                  fontWeight: 600,
                  flex: 3,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                }}
              >
                Active
              </Typography>
              <Typography style={{ flex: 1 }}></Typography>
            </Row>
            <div
              style={{
                margin: '0 -16px',
                borderBottom: `2px solid ${theme.grays.light}`,
              }}
            />
            {promoCodes.map((val) => (
              <CouponRow key={val.promoId} promo={val} />
            ))}
          </>
        )}
      </S.Section>
    </React.Fragment>
  );
};

export default PageSubscriptions;
