import { SECONDARY } from 'constants/theme';
import React, { useState } from 'react';
import { MinCalendar } from '../CalendarCarousel';
import * as S from './CalendarTile.styles';
import * as I from 'components/shared/Icons';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import { getStartTime } from 'util/date-fns';
import CloudinaryImage from 'components/CloudinaryImage';

interface Props {
  val: MinCalendar;
  canRemove: boolean;
}

const WorkoutCalendarTile: React.FC<Props> = ({ val, canRemove }) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const {
    isScheduling,
    handleScheduling,
    handleWorkoutSelected,
    handleRemovingDrop,
  } = useWorkoutDrop();
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  return (
    <S.BaseDay
      style={{
        position: 'relative',
        opacity: isScheduling && isRemoving ? 0.5 : !canRemove ? 0.3 : 1,
      }}
      // only allow removal of calendar items in the future
      onMouseEnter={() => (canRemove ? setIsHover(true) : undefined)}
      onMouseLeave={() => (canRemove ? setIsHover(false) : undefined)}
    >
      <S.WorkoutDay
        style={{ width: '100%', height: '100%' }}
      />
      {val.thumbnailPublicId && (
        <S.ThumbnailImageWrapper>
          <CloudinaryImage
            publicId={val.thumbnailPublicId}
            style={{ width: '100%', height: '100%', borderRadius: '20px' }}
            alt={'workout drop thumbnail'}
            fetchFormat={'auto'}
            quality={'auto:best'}
            crop={'fill'}
          />
        </S.ThumbnailImageWrapper>
      )}
      <S.WorkoutDetailsContainer
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <S.WorkoutDetails>
          <span style={{ opacity: 0.8 }}>
            {getStartTime(val.hour, val.minute, val.time)}
          </span>
          <br />
          {val.title}
        </S.WorkoutDetails>
      </S.WorkoutDetailsContainer>
      {/* only show exit button if there is nothing currently being scheduled */}
      {!isScheduling && (
        <S.Exit
          style={{ top: -10, display: isHover ? 'flex' : 'none' }}
          onClick={() => {
            setIsRemoving(true);
            handleRemovingDrop(true);
            handleWorkoutSelected(val.id);
            handleScheduling(true);
          }}
        >
          <I.X style={{ color: SECONDARY }} />
        </S.Exit>
      )}
    </S.BaseDay>
  );
};

export default WorkoutCalendarTile;
