import { api, Methods } from '../services/api';
import {
  FetchUserProfileResponse,
  UserResponse,
  EditProfileRequest,
} from '@solin-fitness/types';
import { useQuery } from 'react-query';

export const fetchUserById = async (userId: number): Promise<UserResponse> =>
  api<UserResponse>(Methods.get, `/user/${userId}`).then((res) => res);

export const editUserProfile = async (data: EditProfileRequest): Promise<any> =>
  await api(Methods.patch, '/user/profile', data);

export const fetchUserProfile = async (): Promise<FetchUserProfileResponse> =>
  await api<FetchUserProfileResponse>(Methods.get, '/user/profile');

export const useUserProfile = (enabled = true) =>
  useQuery('profile', fetchUserProfile, {
    enabled,
    cacheTime: 15 * 60 * 1000,
  });
