import React from 'react';
import { LiveWorkoutDetail } from 'components/LiveWorkoutDetail';
import { LiveWorkoutResponse } from '@solin-fitness/types';
import Layout from 'components/Layout';
import * as I from 'components/shared/Icons';
import styled from 'styled-components';
import { PHONE } from '../constants/measurements';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { getLiveWorkoutById } from 'queries/live-workouts';

export const Wrap = styled.div`
  margin-top: 20px;
  @media (max-width: ${PHONE}) {
    margin-top: 0;
    padding: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const LiveWorkout = (): React.ReactElement => {
  const location = useLocation<{ liveWorkoutId: number }>();
  const liveWorkoutId = location.state.liveWorkoutId;
  const { isLoading, data: liveWorkout } = useQuery<LiveWorkoutResponse, Error>(
    'liveWorkout',
    () => getLiveWorkoutById(liveWorkoutId),
  );
  const history = useHistory();

  return (
    <Layout title="Live Workout Details">
      {isLoading || !liveWorkout ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Wrap>
          <Header onClick={history.goBack}>
            <I.ChevronLeft lg />
            <div style={{ marginLeft: 12 }}>Back</div>
          </Header>
          <LiveWorkoutDetail liveWorkoutData={liveWorkout} />
        </Wrap>
      )}
    </Layout>
  );
};

export default LiveWorkout;
