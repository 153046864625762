import { CircularProgress } from '@material-ui/core';
import Upload from 'components/shared/Icons/Upload';
import { X } from 'components/shared/Icons/X';
import useThemeContext from 'hooks/useThemeContext';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { apiWithFormData } from 'services/api';
import styled from 'styled-components';

const RemoveButton = styled.button<{ circle?: boolean }>`
  display: ${(p) => (p.circle ? 'none' : 'block')};
  padding: 0;
  border: none;
  background: white;
  opacity: 0.8;
  cursor: pointer;

  width: 32px;
  height: 32px;

  position: absolute;
  top: ${(p) => (p.circle ? 0 : '8px')};
  right: ${(p) => (p.circle ? 0 : '8px')};
  left: ${(p) => (p.circle ? 0 : undefined)};
  bottom: ${(p) => (p.circle ? 0 : undefined)};
  border-radius: 50%;
  margin: auto;

  &:hover {
    display: block;
  }
`;

const FileInputContainer = styled.div<{
  borderRadius?: string;
}>`
  --bg-color: hsl(208deg, 25%, 95%);
  --bg-color-hover: hsl(208deg, 45%, 95%);
  width: 100%;
  height: 100%;
  border: dashed ${(p) => p.theme.main.lightPrimary};
  border-radius: ${(p) => p.borderRadius || '8px'};
  position: relative;
  background: var(--bg-color);
  transition: 200ms;
  overflow: ${(p) => (p.borderRadius ? 'hidden' : 'visible')};

  &:hover {
    background-color: var(--bg-color-hover);
  }

  &:hover ${RemoveButton} {
    display: block;
  }
`;

const FileInput = styled.input`
  /* visibility: hidden; */
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FileDetailsContainer = styled.div`
  position: absolute;
  inset: -3.3px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const FileDetailsText = styled.p<{ small?: boolean }>`
  font-size: ${(p) => (p.small ? '14px' : '20px')};
  font-family: 'Raleway';
  padding: 0 24px;
  text-align: center;
  word-break: break-all;
`;

interface Props {
  video: string;
  handleVideo: (value: string) => void;
  circle?: boolean;
  small?: boolean;
}

const FILE_SIZE_LIMIT = 1048576 * 50; // 1mb in bytes (binary) ~ 50 mb

const VideoUpload: React.FC<Props> = ({
  video,
  handleVideo,
  circle,
  small,
}) => {
  const theme = useThemeContext();
  const [uploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [progress, setProgress] = useState<number>(0);

  const onUploadProgress = (event: ProgressEvent) => {
    const { loaded, total } = event;
    const value = Math.round((loaded / total) * 100);
    setProgress(value);
  };

  const videoUpload = useMutation(
    (fileToUpload: File) => {
      const formData = new FormData();
      formData.append('video', fileToUpload);
      return apiWithFormData<{ videoUrl: string }>('/upload/video', formData, {
        onUploadProgress,
      });
    },
    {
      onMutate: () => setIsUploading(true),
      onSuccess: () => setIsUploading(false),
      onError: (err: any) => {
        setIsUploading(false);
        setError(
          err?.message || 'Uh oh! Something went wrong. Please try again',
        );
      },
    },
  );

  const iconSize = small ? 24 : 40;

  return (
    <FileInputContainer borderRadius={circle ? '50%' : undefined}>
      {!!error ? (
        <FileDetailsContainer>
          <FileDetailsText small={small}>{error}</FileDetailsText>
        </FileDetailsContainer>
      ) : null}
      {!uploading && !!!error && !!!video ? (
        <FileDetailsContainer>
          <Upload
            color={theme.main.primary}
            width={iconSize}
            height={iconSize}
            strokeWidth={1.5}
          />
          <FileDetailsText small={small}>
            Click here to{' '}
            <span style={{ color: theme.main.primary }}>browse</span>
          </FileDetailsText>
        </FileDetailsContainer>
      ) : null}
      {uploading ? (
        <FileDetailsContainer>
          <CircularProgress value={progress} />
        </FileDetailsContainer>
      ) : null}
      {!!video ? (
        <FileDetailsContainer>
          <video
            controls
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '100%'
            }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </FileDetailsContainer>
      ) : null}
      <FileInput
        type="file"
        accept=".mp4"
        onChange={async (e) => {
          const files = e.currentTarget.files;
          if (files && files.length > 0) {
            const upload = files[0];
            if (upload.size > FILE_SIZE_LIMIT) {
              setError(
                'File size is too large\n Please upload a file 50mb or less',
              );
              return;
            }
            setError('');
            const date = new Date().getTime();

            // new file to update name
            const new_upload = new File([upload], `${date}_video_upload`, {
              type: upload.type,
            });

            const { videoUrl } = await videoUpload.mutateAsync(new_upload);
            handleVideo(videoUrl);
          }
        }}
        style={{
          display: video ? 'none' : 'block',
        }}
      />
      {!!video ? (
        <RemoveButton circle={circle} onClick={() => handleVideo('')}>
          <X color="black" />
        </RemoveButton>
      ) : null}
    </FileInputContainer>
  );
};

export default VideoUpload;
