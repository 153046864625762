import React, { useState, useEffect, useRef } from 'react';
import { Segment } from 'semantic-ui-react';
import { MyFirebase } from '../../../Config';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Picker, emojiIndex } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import ProgressBar from '../ProgressBar/ProgressBar';
import * as Colors from 'constants/theme';
import styled from 'styled-components';

interface IProps {
  currentChannel: any;
  currentUser: any;
  isPrivateChannel: any;
  messageRef: any;
}

interface IState {
  message: string;
  loading: boolean;
  error: any;
  isModalOpen: boolean;
  uploadState: string;
  uploadTask: any;
  percentUploaded: number;
  currentChannel: any;
  currentUser: any;
  messageRef: any;
  typingRef: any;
  isEmojiPickerOpen: boolean;
}

const innerTheme = createMuiTheme({
  palette: {
    type: 'light',
    text: { primary: Colors.TEXT_COLOR },
    primary: {
      main: '#000',
    },
  },
});

export const TextFieldWrap = styled.div`
  margin-right: 15px;
  width: 100%;
  .MuiInputBase-input {
    color: ${Colors.TEXT_COLOR} !important;
  }
  .MuiOutlinedInput-notchedOutline {
    color: black !important;
  }
`;

const MessageForm: React.FC<IProps> = ({
  currentChannel,
  currentUser,
  isPrivateChannel,
  messageRef,
}) => {
  const [state, setState] = useState<IState>({
    message: '',
    loading: false,
    error: [],
    isModalOpen: false,
    uploadState: '',
    uploadTask: null,
    percentUploaded: 0,
    currentChannel,
    currentUser,
    messageRef,
    typingRef: MyFirebase.database().ref('typing'),
    isEmojiPickerOpen: false,
  });

  // const handleOpenModal = () => {
  //   setState(prevState => ({ ...prevState, isModalOpen: true }))
  // }

  // const handleCloseModal = () => {
  //   setState((prevState) => ({ ...prevState, isModalOpen: false }));
  // };

  const handleOnChange = (event: any) => {
    event.persist();
    setState((prevState) => ({ ...prevState, message: event.target.value }));
  };

  const enterPressed = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleOnSendMessage();
    }
  };

  const handleOnSendMessage = () => {
    if (state.message && /\S/.test(state.message)) {
      setState((prevState) => ({ ...prevState, loading: true }));
      state
        .messageRef()
        .child(currentChannel.id)
        .push()
        .set({
          content: state.message,
          timestamp: MyFirebase.database.ServerValue.TIMESTAMP,
          creator: {
            id: currentUser.uid,
            name: currentUser.displayName,
            avataar: currentUser.photoURL,
          },
        })
        .then(() => {
          setState((prevState: any) => ({
            ...prevState,
            loading: false,
            message: '',
          }));
          state.typingRef
            .child(currentChannel.id)
            .child(currentUser.uid)
            .remove();
        })
        .catch((err: any) => {
          setState((prevState: any) => ({
            ...prevState,
            loading: false,
            error: state.error.concat(err),
          }));
        });
    } else {
      setState((prevState: any) => ({
        ...prevState,
        loading: false,
        error: state.error.concat({ message: 'Add a Reply' }),
      }));
    }
  };

  // const getPath = () => {
  //   if (isPrivateChannel) {
  //     return `chat/private/${state.currentChannel.id}`;
  //   } else {
  //     return 'chat/public';
  //   }
  // };

  // const handleUploadMedia = (file: any, metaData: any) => {
  //   const filePath = `${getPath()}/${uuidv4()}.jpg`;
  //   setState((prevState: any) => ({
  //     ...prevState,
  //     uploadState: 'uploading',
  //     uploadTask: MyFirebase.storage()
  //       .ref()
  //       .child(filePath)
  //       .put(file, metaData),
  //   }));
  // };

  useEffect(() => {
    if (state.uploadTask !== null) {
      state.uploadTask.on('state_changed', (snap: any) => {
        const percentUploaded = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100,
        );
        setState((prevState: any) => ({ ...prevState, percentUploaded }));
      });
    }
  }, [state.uploadTask]);

  useEffect(() => {
    const sendFileToMessage = (
      downloadURL: any,
      ref: any,
      pathToUpload: any,
    ) => {
      ref
        .child(pathToUpload)
        .push()
        .set({
          image: downloadURL,
          timestamp: MyFirebase.database.ServerValue.TIMESTAMP,
          creator: {
            id: state.currentUser.uid,
            name: state.currentUser.displayName,
            avataar: state.currentUser.photoURL,
          },
        })
        .then(() => {
          setState((prevState: any) => ({
            ...prevState,
            uploadState: 'done',
            uploadTask: null,
            percentUploaded: 0,
          }));
        })
        .catch((err: any) => {
          setState((prevState: any) => ({
            ...prevState,
            error: state.error.concat(err),
          }));
        });
    };

    if (state.uploadState === 'uploading') {
      try {
        if (state.percentUploaded === 100) {
          state.uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL: string) => {
              sendFileToMessage(
                downloadURL,
                state.messageRef(),
                state.currentChannel.id,
              );
            })
            .catch((err: any) => {
              setState((prevState: any) => ({
                ...prevState,
                error: state.error.concat(err),
                uploadState: 'error',
                uploadTask: null,
              }));
            });
        }
      } catch (error) {
        alert(
          'Something went wrong with this file. Please try with different one',
        );
      }
    }
  }, [state]);

  const handleUserTyping = (event: any) => {
    if (event.ctrlKey && event.keyCode === 13) {
      handleOnSendMessage();
    }

    const { message, typingRef } = state;
    if (message.trim().length > 0) {
      typingRef
        .child(state.currentChannel.id)
        .child(state.currentUser.uid)
        .set(state.currentUser.displayName);
    } else {
      typingRef
        .child(state.currentChannel.id)
        .child(state.currentUser.uid)
        .remove();
    }
  };

  // const handleToggleEmojiPicker = () => {
  //   setState((prevState: any) => ({
  //     ...prevState,
  //     isEmojiPickerOpen: !state.isEmojiPickerOpen,
  //   }))
  // }

  const inputRef: any = useRef();

  const handleAddEmoji = (emoji: any) => {
    const oldMesage = state.message;
    const newMessage = colonToUnicode(` ${oldMesage} ${emoji.colons} `);
    setState((prevState: any) => ({
      ...prevState,
      message: newMessage,
      isEmojiPickerOpen: false,
    }));
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const colonToUnicode = (message: string) =>
    message.replace(/:[A-Za-z0-9_+-]+:/g, (x) => {
      x = x.replace(/:/g, '');
      const emoji: any = emojiIndex.emojis[x];
      if (typeof emoji !== 'undefined') {
        const unicode = emoji.native;
        if (typeof unicode !== 'undefined') {
          return unicode;
        }
      }
      x = ':' + x + ':';
      return x;
    });

  return (
    <Segment className="message__form">
      {state.isEmojiPickerOpen && (
        <Picker
          set="apple"
          onClick={handleAddEmoji}
          title="Pick your emoji"
          emoji="point_up"
        />
      )}
      <ThemeProvider theme={innerTheme}>
        <TextFieldWrap>
          <TextField
            onKeyDown={handleUserTyping}
            onKeyPress={enterPressed}
            onChange={handleOnChange}
            ref={inputRef}
            value={state.message}
            label="Message"
            placeholder="Write your message"
            multiline={true}
            variant="outlined"
            style={{ width: '100%', marginRight: 15 }}
          />
        </TextFieldWrap>
      </ThemeProvider>

      <Button
        color="primary"
        style={{
          textTransform: 'capitalize',
          width: 90,
        }}
        variant="contained"
        disabled={state.loading}
        onClick={handleOnSendMessage}
      >
        {state.loading ? 'Sending..' : 'Chat'}
      </Button>
      {/* <Button
        style={{
          color: 'white',
          background: Colors.PRIMARY,
          width: 'calc(50% - 4px)',
        }}
        content="Upload Media"
        labelPosition="right"
        icon="cloud upload"
        disabled={state.uploadState === 'uploading'}
        onClick={handleOpenModal}
      /> */}
      {/* <FileModal
        open={state.isModalOpen}
        closeModal={handleCloseModal}
        uploadMedia={handleUploadMedia}
      /> */}

      <ProgressBar
        percentUploaded={state.percentUploaded}
        uploadState={state.uploadState}
      />
    </Segment>
  );
};

export default MessageForm;
