import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Button } from 'components';
import useAuthStore from 'hooks/useAuthStore';
import { LOGIN } from 'constants/routes';
import useLogoutUser from 'hooks/useLogoutUser';
import { useHistory } from 'react-router-dom';

const Modal = () => {
  const history = useHistory();
  const logout = useLogoutUser();

  const handleLogout = () => {
    logout.mutate(1, {
      onSuccess: () => {
        history.push(LOGIN);
        useAuthStore.persist.clearStorage();
      },
    });
  };

  return (
    <Overlay>
      <Content>
        <Backdrop />
        <Container>
          <HeaderRow>
            <Typography variant="h4">
              Your session is no longer valid
            </Typography>
          </HeaderRow>

          <Typography variant="body1">
            This likely occurred because you signed into a consumer account on
            the Solin website.
          </Typography>
          <Typography variant="body1">
            This causes some issues with our authentication if you are
            attempting to use two different accounts in the same browser at the
            same time.
          </Typography>
          <Typography variant="body1">
            However, you can still do this if you choose to use separate
            browsers (i.e. Use <strong>Chrome</strong> for the dashboard and{' '}
            <strong>Safari</strong> for the Solin consumer website).
          </Typography>
          <Typography variant="body1">
            Please log back in to revalidate your session. If you have any other
            questions or need further clarification please email{' '}
            <a href="mailto:michael@solinfitness.com" target="_blank">
              michael@solinfitness.com
            </a>
            .
          </Typography>

          <Button color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Container>
      </Content>
    </Overlay>
  );
};

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Content = styled(DialogContent)`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Backdrop = styled.div`
  pointer-events: none;
  position: absolute;
  inset: 0;
  background: hsl(0, 0%, 0%, 0.3);
`;

const Container = styled.div<{ hideGap?: boolean; maxWidth?: number }>`
  width: clamp(
    350px,
    50vw,
    ${(p) => (p.maxWidth ? `${p.maxWidth}px` : '775px')}
  );
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: white;
  padding: 26px;
  gap: 18px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075), 0px 2px 2px rgba(0, 0, 0, 0.075),
    0px 4px 4px rgba(0, 0, 0, 0.075), 0px 8px 8px rgba(0, 0, 0, 0.075);
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SubTextWrapper = styled.div`
  width: 100%;
`;

export default Modal;
