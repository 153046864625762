import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import { DateTime } from 'luxon';
import Button from 'components/Button';
import * as S from './PageOverview.styles';
import { PageClassPrices as ClassPrices } from '@solin-fitness/types';
import { GRAYS, PRIMARY } from 'constants/theme';
import Switch from '@material-ui/core/Switch';
import CreateSubscription from 'components/ManageSubscriptions/CreateSubscription';
import CreateClassPrice from 'components/ManageClassPrices/CreateClassPrice';
import ClassPriceRow from './ClassPriceRow';

interface Props {
  pageId: number;
  classPrices: ClassPrices[];
}

const PageClassPrices: React.FC<Props> = (props) => {
  const { pageId, classPrices } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <CreateClassPrice isOpen={isOpen} onCancel={() => setIsOpen(false)} />
      <S.Section>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'relative',
            paddingBottom: 16,
          }}
        >
          <Typography variant="h5">Class Prices</Typography>
          <S.EditButton>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setIsOpen(true)}
            >
              Add price
            </Button>
          </S.EditButton>
        </Row>
        <div
          style={{
            margin: '0 -16px',
            borderBottom: `2px solid ${GRAYS.light}`,
          }}
        />
        <Row justifyContent="flex-start" style={{ padding: '12px 0' }}>
          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              flex: 3,
              textTransform: 'uppercase',
            }}
          >
            Price
          </Typography>
          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              flex: 1,
              textTransform: 'uppercase',
            }}
          >
            Description
          </Typography>
        </Row>
        <div
          style={{
            margin: '0 -16px',
            borderBottom: `2px solid ${GRAYS.light}`,
          }}
        />
        {classPrices?.map((price) => (
          <ClassPriceRow key={price.priceId} price={price} />
        ))}
      </S.Section>
    </React.Fragment>
  );
};

export default PageClassPrices;
