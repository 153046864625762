import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';
import { useTable, Column, useSortBy } from 'react-table';

interface Props<T extends object> {
  columns: Column<T>[];
  data: T[];
  handleRowClick?: (data: any) => void;
}

const Table = <T extends object>(props: Props<T>) => {
  const { columns, data, handleRowClick } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <TableContainer>
      <MuiTable {...getTableProps()}>
        {/**
         * Head of table
         * want easy way to sort rows
         * need way to determine which rows
         * you can sort */}
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {/** Can make this prettier down the road */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {/* Body of table */}
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                {...row.getRowProps()}
                onClick={() => {
                  if (!handleRowClick) {
                    return;
                  } else {
                    handleRowClick(row.original);
                  }
                }}
              >
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
