import React, { useState, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { useQueryClient, useMutation } from 'react-query';
import { FormTextField } from 'components/FormTextField';
import { FormikCheckbox } from 'components/FormCheckbox';
import {
  PageClassPrices,
  CreateLiveWorkoutRequest,
  LiveWorkoutResponse,
} from '@solin-fitness/types';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Colors from 'constants/theme';
import Checkbox from '@material-ui/core/Checkbox';
import { getDisplayPrice } from 'services/currency-codes';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { BtnSelect } from 'components/BtnSelect';
import {
  createLiveWorkout as createPageLiveWorkout,
  editLiveWorkout as editPageLiveWorkout,
} from 'queries/live-workouts';
import { uploadImage } from 'queries/file-uploads';
import * as Routes from 'constants/routes';
import { ErrorMessage } from 'components/shared/Message';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CircularProgress } from '@material-ui/core';
import CloudinaryImage from 'components/CloudinaryImage';
import UploadImage from 'components/UploadImageModal';

enum ValueLabelDisplay {
  auto = 'auto',
  on = 'on',
  off = 'off',
}

export const Spacer = styled.div`
  margin-top: 20px;
`;

export const TimePickerWrap = styled.div`
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none !important;
  }
`;

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

export const ImagePreview = styled.div`
  margin-bottom: 20px;
`;

const RequiredLabel = styled.span`
  color: red;
`;

interface Props {
  types: string[];
  classPrices: PageClassPrices[];
  isEditing?: boolean;
  workoutData?: LiveWorkoutResponse;
}

enum WorkoutVideoFormat {
  zoom = 'zoom',
  stream = 'stream',
  zoomLink = 'zoomLink',
}

// TODO: confirm zoomLink and playlistLink start with https??

const validationSchema = yup.object({
  startDateNoTime: yup.string().required(),
  startTime: yup
    .string()
    .required()
    .test('is-time', "Start time's must be in 1 minute intervals.", (value) => {
      const minute = Number(value?.substring(3, 5));
      return minute % 1 === 0;
    }),
  title: yup.string().required(),
  description: yup.string().required(),
  length: yup.number().required(),
  workoutVideoFormat: yup
    .mixed<WorkoutVideoFormat>()
    .oneOf(Object.values(WorkoutVideoFormat))
    .required(),
  type: yup.array().required(),
  zoomLink: yup.string().optional().nullable(),
  playlistLink: yup.string().optional().nullable(),
  recordLiveWorkout: yup.boolean().required(),
  isForSale: yup.boolean().optional(),
  priceId: yup.string().when('isForSale', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().optional().nullable(),
  }),
  thumbnailFile: yup.string().optional().nullable(),
  thumbnailPreview: yup.string().optional().nullable(),
  isIncludedInMembership: yup.boolean().optional(),
  workoutExercises: yup.string().optional(),
});

const CreateLiveWorkoutFormik: React.FC<Props> = ({
  types,
  classPrices,
  isEditing,
  workoutData,
}) => {
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const currDate = useMemo(() => DateTime.fromJSDate(new Date()), []);
  const liveStreamFormatOptions = useMemo(
    () => [
      {
        key: 'One-to-many',
        value: 'stream',
        helperText: 'See Trainer Only',
      },
      {
        key: 'Zoom',
        value: 'zoomLink',
        helperText: 'Zoom link you will use for this class',
      },
    ],
    [],
  );
  const inputTypes = useMemo(
    () => [
      {
        title: {
          label: 'Title',
          type: 'text',
        },
        description: {
          label: 'Description',
          type: 'text',
          rows: 4,
        },
        type: {
          label: 'Type',
          type: 'text',
          required: true,
        },
        liveStreamFormat: {
          label: 'Live Video Format',
          type: 'text',
          required: true,
        },
      },
    ],
    [{}],
  );
  const queryClient = useQueryClient();
  const history = useHistory();
  const workoutId = workoutData ? workoutData.id : 0;

  const createLiveWorkout = useMutation(
    (data: CreateLiveWorkoutRequest) => createPageLiveWorkout(data),
    {
      onError: (err: any) => {
        if (err?.message === 'User is forbidden') {
          history.push(Routes.LOGIN);
        }
        window.scrollTo(0, 0);
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        );
      },
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  const editLiveWorkout = useMutation(
    (data: CreateLiveWorkoutRequest) => editPageLiveWorkout(data, workoutId),
    {
      onError: (err: any) => {
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        );
        window.scrollTo(0, 0);
      },
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  const liveWorkoutStartDate = workoutData
    ? DateTime.fromISO(workoutData.startDate).toFormat('yyyy-MM-dd')
    : currDate.toFormat('yyyy-MM-dd');
  const liveWorkoutStartTime = workoutData
    ? DateTime.fromISO(workoutData.startDate).toFormat('T')
    : currDate.toFormat('T');
  const defaultPlaylistLink =
    workoutData && workoutData.playlistLink
      ? workoutData.playlistLink
      : undefined;

  const initialLiveWorkoutValues = {
    title: workoutData ? workoutData.title : '',
    description: workoutData ? workoutData.description : '',
    zoomLink: workoutData ? workoutData.zoomLink : '',
    playlistLink: defaultPlaylistLink,
    length: workoutData ? workoutData.length : 60,
    type: workoutData ? workoutData.tags : undefined,
    workoutVideoFormat: workoutData
      ? workoutData.workoutVideoFormat
      : WorkoutVideoFormat.stream,
    startDateNoTime: liveWorkoutStartDate,
    startTime: liveWorkoutStartTime,
    recordLiveWorkout: workoutData ? workoutData.recordLiveWorkout : true,
    isForSale: workoutData ? workoutData.isForSale : false,
    isIncludedInMembership: workoutData
      ? workoutData.isIncludedInMembership
      : true,
    thumbnailFile: workoutData ? workoutData.thumbnailPublicId : undefined,
    priceId: workoutData ? workoutData.priceId : '',
    thumbnailPreview: workoutData ? workoutData.thumbnailPublicId : undefined,
    workoutExercises:
      workoutData && workoutData.workoutExercises
        ? workoutData.workoutExercises
        : undefined,
  };

  return (
    <div>
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        validateOnChange={true}
        initialValues={initialLiveWorkoutValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const { startDateNoTime, startTime } = data;
          const hour = Number(startTime.substring(0, 2));
          const minute = Number(startTime.substring(3, 5));
          const startDate = DateTime.fromISO(startDateNoTime);

          const structuredData = {
            startDate: startDate.set({ hour, minute }).toString(),
            title: data.title,
            description: data.description,
            length: data.length,
            thumbnailPublicId: data.thumbnailFile || undefined,
            workoutVideoFormat: data.workoutVideoFormat,
            type: data.type || [],
            zoomLink: data.zoomLink ? data.zoomLink : undefined,
            playlistLink: data.playlistLink ? data.playlistLink : undefined,
            recordLiveWorkout: data.recordLiveWorkout,
            isForSale: data.isForSale,
            priceId: data.priceId ? data.priceId : undefined,
            isIncludedInMembership: data.isIncludedInMembership,
            workoutExercises: data.workoutExercises,
          };

          if (isEditing && workoutId) {
            await editLiveWorkout.mutateAsync(structuredData);
          } else {
            await createLiveWorkout.mutateAsync(structuredData);
          }
          setSubmitting(false);
          resetForm();
          history.push(Routes.CREATOR_SCHEDULE);
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form>
            <FormTextField
              id="date-picker-dialog"
              label="Workout Date"
              type="date"
              name="startDateNoTime"
              data-test="createLW-date"
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TimePickerWrap>
              <FormTextField
                id="time-picker"
                label="Workout Time"
                name="startTime"
                type="time"
                style={{ width: '100%', marginTop: 20 }}
                className="time-input-mui"
                InputLabelProps={{
                  shrink: true,
                  disabled: true,
                }}
              />
            </TimePickerWrap>

            <Spacer />

            <FormTextField
              label="Title"
              name="title"
              placeholder="Title"
              inputProps={{
                id: 'title',
              }}
              style={{ width: '100%' }}
            />

            <Spacer />

            <FormTextField
              label="Description"
              name="description"
              placeholder="Tell consumers a little more about your class and list any equipment needed"
              multiline={true}
              style={{ width: '100%' }}
              inputProps={{
                id: 'description',
              }}
            />

            <Spacer />

            <Typography
              style={{ color: Colors.themeColors.textColor, fontSize: 16 }}
            >
              Workout overview
            </Typography>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                ],
                link: {
                  addTargetToExternalLinks: true,
                },
              }}
              data={values.workoutExercises || ''}
              onChange={(event: Event, editor: ClassicEditor) => {
                const data = editor.getData();
                setFieldValue('workoutExercises', data);
              }}
            />

            <Spacer />

            <Typography
              id="discrete-slider"
              gutterBottom
              style={{ color: Colors.themeColors.textColor, fontSize: 16 }}
            >
              Length
            </Typography>

            <Slider
              name="length"
              value={values.length}
              aria-labelledby="discrete-slider"
              valueLabelDisplay={ValueLabelDisplay.auto}
              step={5}
              marks
              min={10}
              max={120}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: number | number[],
              ) => setFieldValue('length', value)}
            />

            <Spacer />

            <FormTextField
              label="Playlist Link"
              name="playlistLink"
              placeholder="Provide a link to a playlist for the workout (Optional)"
              style={{ width: '100%' }}
              inputProps={{
                id: 'playlistLink',
              }}
            />

            <Spacer />

            <BtnSelect
              {...inputTypes[0].liveStreamFormat}
              isSingleSelect
              options={liveStreamFormatOptions}
              testId={'workoutVideoFormat'}
              values={
                values.workoutVideoFormat ? [values.workoutVideoFormat] : []
              }
              onClick={(selectValues): void =>
                setFieldValue('workoutVideoFormat', selectValues[0])
              }
            />

            {values.workoutVideoFormat !== 'zoomLink' && (
              <>
                <FormikCheckbox
                  name="recordLiveWorkout"
                  control={<Checkbox value={values.recordLiveWorkout} />}
                  label={
                    <span style={{ fontSize: 15 }}>
                      Record live workout and publish to on-demand workout
                      library
                    </span>
                  }
                  style={{
                    color: Colors.themeColors.textColor,
                    marginBottom: 5,
                  }}
                />

                <Typography
                  gutterBottom
                  style={{
                    color: Colors.themeColors.textColor,
                    fontSize: 16,
                    marginBottom: 40,
                  }}
                >
                  After you save your workout, you can publish it to your
                  subscribers through the "Workout Library" option in the menu
                  to the left
                </Typography>

                {values.recordLiveWorkout && (
                  <>
                    {isUploading ? (
                      <CircularProgress
                        style={{ color: Colors.GRAYS.medium }}
                      />
                    ) : (
                      values.thumbnailPreview && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <ImagePreview>
                            <CloudinaryImage
                              publicId={values.thumbnailPreview || ''}
                              alt={'live workout image preview'}
                              width={350}
                              height={200}
                              fetchFormat={'auto'}
                              quality={100}
                              crop={'scale'}
                              style={{ borderRadius: 20 }}
                            />
                          </ImagePreview>
                          <div style={{ paddingRight: 20 }} />
                          <IconButton
                            color="primary"
                            aria-label="remove thumbnail"
                            onClick={() => {
                              setFieldValue('thumbnailPreview', null);
                              setFieldValue('thumbnailFile', null);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      )
                    )}

                    <Typography
                      gutterBottom
                      style={{
                        color: Colors.themeColors.textColor,
                        fontSize: 16,
                        marginBottom: 10,
                      }}
                    >
                      Thumbnail
                      {!values.thumbnailPreview && (
                        <RequiredLabel> * Required</RequiredLabel>
                      )}
                    </Typography>

                    <UploadImage
                      fileName="liveWorkout"
                      onFinished={(publicId) => {
                        setFieldValue('thumbnailFile', publicId);
                        setFieldValue('thumbnailPreview', publicId);
                      }}
                      imageUploaded={!!values.thumbnailPreview}
                    />

                    <Spacer style={{ marginBottom: 50 }} />
                  </>
                )}
              </>
            )}

            {values.workoutVideoFormat === 'zoomLink' && (
              <>
                <FormTextField
                  {...inputTypes[0].title}
                  name="zoomLink"
                  label="Zoom link"
                />
                <Spacer />
              </>
            )}

            <BtnSelect
              {...inputTypes[0].type}
              testId="type"
              requiredLabel={values.type?.length ? false : true}
              options={
                types ? types.map((item) => ({ key: item, value: item })) : []
              }
              values={values.type ? values.type : []}
              onClick={(selectValues): void =>
                setFieldValue('type', selectValues)
              }
            />

            <BtnSelect
              label="Workout Class is Purchasable"
              isSingleSelect
              testId="isForSale"
              options={[
                { key: 'Yes', value: 'true' },
                { key: 'No', value: 'false' },
              ]}
              values={[`${values.isForSale}`]}
              onClick={(selectValues): void => {
                const val = selectValues[0] === 'true' ? true : false;
                setFieldValue('isForSale', val);
              }}
            />
            {values.isForSale && (
              <>
                <BtnSelect
                  label="Workout Price"
                  isSingleSelect
                  requiredLabel={values.priceId ? false : true}
                  testId="priceId"
                  options={
                    classPrices
                      ? classPrices.map((item: any) => {
                          const key = getDisplayPrice(
                            item.amount,
                            item.currency,
                          );
                          return {
                            key,
                            value: item.priceId,
                          };
                        })
                      : [
                          {
                            value: '',
                            key: '',
                          },
                        ]
                  }
                  values={values.priceId ? [values.priceId] : ['']}
                  onClick={(selectValues): void =>
                    setFieldValue('priceId', selectValues[0])
                  }
                />
                <BtnSelect
                  label="Is Included in membership"
                  isSingleSelect
                  testId="isIncludedInMembership"
                  options={[
                    {
                      key: 'Yes',
                      value: 'true',
                      helperText: 'Class will be free for subscribers.',
                    },
                    {
                      key: 'No',
                      value: 'false',
                      helperText: 'Everyone will have to pay for class access.',
                    },
                  ]}
                  values={[`${values.isIncludedInMembership}`]}
                  onClick={(selectValues): void => {
                    const val = selectValues[0] === 'true' ? true : false;
                    setFieldValue('isIncludedInMembership', val);
                  }}
                />
              </>
            )}

            <Spacer />

            <Container>
              <Button
                disabled={isSubmitting}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{
                  borderRadius: 35,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="inherit" size="2rem" />
                ) : (
                  <Typography variant="h6">
                    {workoutData ? 'Save Live Workout' : 'Create Live Workout'}
                  </Typography>
                )}
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateLiveWorkoutFormik;
