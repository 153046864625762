import React, { useEffect, useState } from 'react';
import { UserResponse } from '@solin-fitness/types';
import { fetchUserById } from 'queries/user';
import { useQuery } from 'react-query';
import styled from 'styled-components';

interface IProps {
  userId: number;
  name: string;
}

export const Name = styled.p`
  font-size: 15;
  text-align: right;
  color: #a2a2a2;

  @media (max-width: 1025px) {
    color: black;
    font-weight: 500;
  }
`;

const AttendeeName = (props: IProps): React.ReactElement | null => {
  const { userId, name } = props;
  const { data: user } = useQuery(
    ['userProfile', userId],
    () => fetchUserById(userId),
    {
      staleTime: 1000 * 60 * 60,
      retry: false,
    },
  );

  if (!user) {
    return null;
  }

  return <Name>{name}</Name>;
};

export default AttendeeName;
