import React from 'react';
import Layout from 'components/Layout';
import useConfig from 'hooks/useConfig';
import PageOverview from 'components/ManagePage/PageOverview';
import UploadImage from 'components/UploadImageModal';

const ManagePage = () => {
  const { isLoading, data, Loading } = useConfig();
  return (
    <Layout title="Page Settings">
      {isLoading || !data ? <Loading /> : <PageOverview page={data} />}
    </Layout>
  );
};

export default ManagePage;
