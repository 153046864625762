import { WorkoutResponse } from '@solin-fitness/types';

const includesSearchTerm = (str: string, term: string) => {
  if (str) {
    return str.toLowerCase().trim().includes(term.toLowerCase().trim());
  } else {
    return false;
  }
};

/**
 * @param array - tags from workout
 * @param categories - categories to filter by
 */
const includesCategory = (array: string[], categories: string[]) => {
  const result = categories.filter((val) => array.includes(val));
  return result.length === categories.length;
};

export const searchWorkouts = (
  workouts: WorkoutResponse[],
  searchTerm: string,
  categories: string[],
): WorkoutResponse[] =>
  workouts.filter((val) => {
    if (
      includesSearchTerm(val.title, searchTerm) &&
      includesCategory(val.tags, categories)
    ) {
      return true;
    }
    return false;
  });
