import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';

import * as Colors from 'constants/theme';
import Layout from 'components/Layout';

Sentry.init({
  dsn: 'https://cc3649d1a10449b8843c8c5a5def4def@o373149.ingest.sentry.io/5633325',
});

const FONT_FAMILY =
  process.env.GATSBY_FONT_FAMILY !== undefined
    ? process.env.GATSBY_FONT_FAMILY
    : 'Roboto';

const GlobalStyle = createGlobalStyle`
 .uppy-Dashboard-isFixed {
    overflow: auto !important;
  }
  ${() => `
    body {
      font-family: ${FONT_FAMILY}, 'Roboto';
    }

  `}
`;

interface Props {
  error: Error | null;
}

const FallbackComponent = ({ error }: Props) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Layout title="Error Page">
      <div
        style={{
          color: Colors.themeColors.textColor,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 200,
          flexDirection: 'column',
        }}
      >
        <b>An eror has occured</b> <br />
        <div
          style={{ cursor: 'pointer', color: Colors.PRIMARY }}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </div>
        {showMore && (
          <>
            <b>Error:</b> {error && error.message} <br />
          </>
        )}
      </div>
    </Layout>
  );
};

export const withErrorHandler = (Component: any) => {
  class WithErrorHandler extends React.Component {
    state = {
      error: null,
    };

    componentDidMount() {
      const favicon = process.env.GATSBY_FAVICON;
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        (link as any).rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      if (favicon !== undefined) {
        (link as any).href = favicon;
      }
    }

    render() {
      return (
        <>
          <Sentry.ErrorBoundary
            onError={(error) => this.setState({ error })}
            fallback={<FallbackComponent error={this.state.error} />}
            // removing beforeCapture since it wasn't passing in any relevant data
          >
            <GlobalStyle />
            <Component {...this.props} />
          </Sentry.ErrorBoundary>
        </>
      );
    }
  }
  return WithErrorHandler;
};
