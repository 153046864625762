import { useQuery } from 'react-query';
import { api, Methods } from 'services/api';
import {
  EventResponse,
  EventRequest,
  EditEventRequest,
} from '@solin-fitness/types';

export const getEvents = async () =>
  await api<EventResponse[]>(Methods.get, '/events');

export const useEvents = () => useQuery('events', getEvents);

export const createEvent = async (data: EventRequest) =>
  await api(Methods.post, '/events', data);

export const editEvent = async (id: number, data: EditEventRequest) =>
  await api(Methods.patch, `/events/${id}`, data);
