import { CalendarResponse, WorkoutResponse } from '@solin-fitness/types';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import React, { useState, useEffect } from 'react';
import * as S from './DropCalendar.styles';
import * as I from 'components/shared/Icons';
import { LIGHT_SECONDARY, PRIMARY, SECONDARY } from 'constants/theme';
import { CalendarCarousel } from 'components/DropCalendar/CalendarCarousel';
import useDebounce from 'hooks/useDebounce';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { searchWorkouts } from 'services/workouts-search';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';
import { WorkoutCarousel } from 'components/DropCalendar/WorkoutCarousel';
import styled from 'styled-components';

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    marginRight: 36,
  },
  save: {
    color: SECONDARY,
    marginRight: 12,
  },
});

const Container = styled.div`
  padding-bottom: 50px;
`;

interface Props {
  workouts: WorkoutResponse[];
  calendar: CalendarResponse[];
  workoutTags: string[];
}

const DropCalendar: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { workouts, calendar, workoutTags } = props;
  const {
    isScheduling,
    isSaving,
    error,
    calendarDays,
    weeks,
    calendarIndex,
    workoutPageIndex,
    isRemovingDrop,
    handleSaveWorkoutDrop,
    handleWorkoutPageIndex,
    handleScheduling,
    handleRemovingDrop,
  } = useWorkoutDrop();

  // search & filter state
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchableWorkouts, setSearchableWorkouts] =
    useState<WorkoutResponse[]>(workouts);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  useEffect(() => {
    setSearchableWorkouts(workouts);
  }, [workouts]);

  // search workouts after a search term is entered in input
  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      const result = searchWorkouts(workouts, searchTerm, selectedCategories);
      setSearchableWorkouts(result);
      handleWorkoutPageIndex(1);
      setTimeout(() => setIsSearching(false), 1000);
    } else {
      setSearchableWorkouts(workouts);
    }
  }, [debouncedSearchTerm]);

  // search workouts after category is selected/unselected
  useEffect(() => {
    const result = searchWorkouts(workouts, searchTerm, selectedCategories);
    setSearchableWorkouts(result);
    handleWorkoutPageIndex(1);
  }, [selectedCategories]);

  return (
    <Container>
      <Column
        justifyContent="flex-start"
        style={{ maxWidth: 1050, marginLeft: 'auto', marginRight: 'auto' }}
      >
        {error && (
          <Aside isError={true} style={{ alignSelf: 'flex-start' }}>
            {error}
          </Aside>
        )}
        <Row
          justifyContent="space-between"
          alignItems="baseline"
          style={{ width: '100%', padding: '0 36px 4px' }}
        >
          <S.Title>Schedule your workouts</S.Title>
          <Row alignItems="baseline" style={{ width: 'auto' }}>
            {isScheduling && (
              <>
                <S.Save onClick={handleSaveWorkoutDrop}>
                  {isSaving ? 'saving' : 'save'}
                </S.Save>
                {isRemovingDrop && (
                  <S.Save
                    onClick={() => {
                      handleScheduling(false);
                      handleRemovingDrop(false);
                    }}
                    style={{ backgroundColor: '#F2AC57', marginRight: 48 }}
                  >
                    cancel
                  </S.Save>
                )}
              </>
            )}
            {isSaving && (
              <CircularProgress size=".8rem" className={classes.save} />
            )}
            <S.Week>{weeks[calendarIndex]}</S.Week>
          </Row>
        </Row>
        <S.Section
          style={{
            backgroundColor: '#495057',
            borderRadius: 30,
            padding: '24px 24px 0px 24px',
          }}
        >
          <CalendarCarousel calendar={calendar} days={calendarDays} />
        </S.Section>
        <S.Section
          style={{
            boxShadow: '0px 0px 0px',
            paddingTop: 8,
            padding: '0px 24px 0px',
          }}
        >
          <WorkoutCarousel workouts={searchableWorkouts} />
          <S.FiltersContainer justifyContent="flex-start">
            <S.CategoryContainer
              showCategories={showCategories}
              alignItems="flex-start"
            >
              <Row
                onClick={() => setShowCategories(!showCategories)}
                style={{ cursor: 'pointer', padding: '12px 24px 0px' }}
              >
                <h5 style={{ paddingRight: 48, fontWeight: 500 }}>
                  Category name
                </h5>
                <div
                  style={{
                    transform: showCategories
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  }}
                >
                  <I.ChevronDown
                    color={LIGHT_SECONDARY}
                    width={36}
                    height={36}
                  />
                </div>
              </Row>
              {showCategories && (
                <S.CategoryList>
                  {workoutTags.map((val) => (
                    <S.Category
                      onClick={() => {
                        const index = selectedCategories.indexOf(val);
                        if (index === -1) {
                          setSelectedCategories([...selectedCategories, val]);
                        } else {
                          setSelectedCategories(
                            selectedCategories.filter((v) => v !== val),
                          );
                        }
                      }}
                    >
                      {val}
                      {selectedCategories.includes(val) && (
                        <I.CheckIcon color="#FFF" />
                      )}
                    </S.Category>
                  ))}
                </S.CategoryList>
              )}
            </S.CategoryContainer>
            <S.SearchContainerOuter>
              <S.SearchContainerInner
                justifyContent="space-between"
                hasSearch={!!searchTerm}
              >
                <S.SearchInput
                  hasSearch={!!searchTerm}
                  type="input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                  placeholder="search videos..."
                />
                {isSearching ? (
                  <CircularProgress size={24} className={classes.root} />
                ) : (
                  <I.Search
                    color="#FFF"
                    style={{ marginRight: 36, opacity: 0.6 }}
                  />
                )}
              </S.SearchContainerInner>
            </S.SearchContainerOuter>
            <S.Pagination>{`${workoutPageIndex} of ${Math.ceil(
              searchableWorkouts.length / 3,
            )}`}</S.Pagination>
          </S.FiltersContainer>
        </S.Section>
      </Column>
    </Container>
  );
};

export default DropCalendar;
