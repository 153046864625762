import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getPageUrl } from './auth';
import * as Sentry from '@sentry/react';

/**
 * Generic api service to be used with `react-query` to fetch server data
 * Can specify the type of interface to be returned by calling function
 * api<Interface>
 */

export enum Methods {
  delete = 'delete',
  get = 'get',
  patch = 'patch',
  post = 'post',
  put = 'put',
}

export const API_ROOT = process.env.REACT_APP_API_ROOT;

const baseUrl = API_ROOT;

axios.defaults.headers.common['Content-Type'] = 'application/json';

export interface ApiOptions {
  version?: number;
  customAuthHeader?: string;
  customApiRoot?: string;
  timeout?: number;
  onUploadProgress?: (event: ProgressEvent) => void;
  xUploadIndex?: string;
  xWorkoutId?: number;
}

export const api = async <T>(
  method: Methods,
  url: string,
  data?: any,
  options: ApiOptions = {},
): Promise<T> => {
  axios.defaults.headers.common['x-page'] = getPageUrl();

  try {
    const apiClient = axios.create({});
    let parsedUrl = url;

    parsedUrl = `${options.customApiRoot || baseUrl}${parsedUrl}`;

    if (options.customAuthHeader) {
      apiClient.defaults.headers.Authorization = `${options.customAuthHeader}`;
    }

    const response = await apiClient({
      method,
      url: parsedUrl,
      data,
      timeout: options.timeout || 15000,
      withCredentials: true,
      baseURL: options.customApiRoot || baseUrl,
    });
    return response.data;
  } catch (err: any) {
    if (err.response) {
      if (err.response.data.msg) {
        Sentry.captureException(
          `code: ${err.response.data.errorCode}, msg: ${err.response.data.msg}, erroId: ${err.response.data.errorId}`,
        );
      } else {
        Sentry.captureException(err.response);
      }
      throw new Error(
        err.response?.data.msg ||
          'Uh oh 😅, did not expect that! Please notify Solin team',
      );
    } else {
      Sentry.captureException(err);
      throw new Error(
        'Uh oh 😅, did not expect that! Please notify Solin team',
      );
    }
  }
};

export const apiWithFormData = async <T>(
  url: string,
  data: FormData,
  options: ApiOptions = {},
): Promise<T> => {
  try {
    const parsedUrl = `${API_ROOT}${url}`;

    const headers: any = {
      'x-page': getPageUrl(),
      'Content-Type': 'multipart/form-data',
      'x-upload-index': options.xUploadIndex,
      'x-workout-id': options.xWorkoutId,
    };

    const config: AxiosRequestConfig = {
      headers,
      withCredentials: true,
      baseURL: baseUrl,
      onUploadProgress: options.onUploadProgress || undefined,
    };

    const response = await axios.post(parsedUrl, data, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};
