import { WorkoutResponse } from '@solin-fitness/types';
import { api, Methods } from 'services/api';

export interface ScheduleWorkoutDropRequest {
  isDrop: boolean;
  releaseDate?: string;
}

export const scheduleWorkoutDrop = async (
  data: ScheduleWorkoutDropRequest,
  workoutId: number,
) => await api(Methods.post, `/workouts/${workoutId}/drop`, data);

export const getWorkouts = async () =>
  await api<WorkoutResponse[]>(Methods.get, '/workouts');
