import React, { useMemo } from 'react';
import { StripeSubscriptionsResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { api, Methods } from 'services/api';
import { Table } from 'components/Table';
import { Loading } from 'components/Loading';
import { formatPrice } from 'util/helpers';

const SubscriptionsAnalyticsTable: React.FC = () => {
  const { isLoading, data: subscriptions } = useQuery<
    StripeSubscriptionsResponse[]
  >(
    'subscriptions',
    async () =>
      api<StripeSubscriptionsResponse[]>(
        Methods.get,
        '/analytics/stripe/subscriptions',
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const columns = useMemo<Column<StripeSubscriptionsResponse>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Interval',
        accessor: 'interval',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: (info: any) => {
          const value = info.row.original.price / 100 ?? 0;
          return formatPrice(value);
        },
      },
      {
        Header: 'Active Users',
        accessor: 'numberOfUsers',
      },
    ],
    [],
  );

  const data = useMemo<StripeSubscriptionsResponse[]>(
    () => (subscriptions ? subscriptions : []),
    [subscriptions],
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table<StripeSubscriptionsResponse> columns={columns} data={data} />
      )}
    </div>
  );
};

export default SubscriptionsAnalyticsTable;
