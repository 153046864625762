import * as S from 'styles/video.styles';
import { getUser } from 'services/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Page } from 'types';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPage } from 'queries/page';
import { EventResponse } from '@solin-fitness/types';
import EventRoom from 'components/Events/EventRoom';

interface EventRoomState {
  event: EventResponse;
}

const EventRoomPage = () => {
  const location = useLocation<EventRoomState>();

  const event = location.state.event;

  const { isLoading, data: pageConfig } = useQuery<Page, Error>(
    'creatorPage',
    () => getPage(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const userData = getUser();

  if (!userData || isLoading) {
    return (
      <S.LoaderWrap>
        <CircularProgress />
      </S.LoaderWrap>
    );
  }

  return event === null ? (
    <S.EmptyState>This event does not exist</S.EmptyState>
  ) : (
    <>
      <EventRoom event={event} pageConfig={pageConfig as Page} />
    </>
  );
};

export default EventRoomPage;
