import { useState, useEffect } from 'react';
import {
  getResourceId,
  startRecording,
  stopRecording,
  createWorkoutFromRecording,
} from 'services/agora-recording';
import { ILiveWorkout } from '../types';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalVideoTrack,
  ILocalAudioTrack,
} from 'agora-rtc-sdk-ng';
import { removeOnFirebase } from './firebaseTimer';
import * as Sentry from '@sentry/react';

interface JoinProps {
  appid: string | undefined;
  channel: string;
  token?: string;
  uid?: number | null;
  videoQuality: '1080p_1' | '720p_3' | '1440p_1';
  recordSession: boolean;
  trainerData: {
    firstName: string;
    lastName: string;
    id: number | string;
  };
}

interface AgoraProps {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  leave: (workoutData?: ILiveWorkout) => Promise<void>;
  join: (props: JoinProps) => void;
  remoteUsers: IAgoraRTCRemoteUser[];
  createPublishAudioTrack: () => Promise<void>;
  createPublishVideoTrack: () => Promise<void>;
}

const useAgora = (
  client: IAgoraRTCClient,
  role: 'host' | 'audience',
  hostId: string | number,
  handlePermissionDenied: () => void,
): AgoraProps => {
  const [localVideoTrack, setLocalVideoTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined);

  const [joinState, setJoinState] = useState(false);

  const [recordingData, setRecordingData] = useState<
    | undefined
    | { sid: string; resourceId: string; channel: string; uid: string }
  >(undefined);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  const isAudience = role === 'audience';

  const createLocalTracks = async (
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig,
    videoQuality?: '1080p_1' | '720p_3' | '1440p_1',
  ): Promise<[IMicrophoneAudioTrack | null, ICameraVideoTrack | null]> => {
    try {
      const [microphoneTrack, cameraTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks(
          audioConfig,
          videoConfig,
        );
      cameraTrack.setEncoderConfiguration(videoQuality || '720p_3');
      setLocalAudioTrack(microphoneTrack);
      setLocalVideoTrack(cameraTrack);
      return [microphoneTrack, cameraTrack];
    } catch (e) {
      if (e && e.code === 'PERMISSION_DENIED') {
        handlePermissionDenied();
      }
      return [null, null];
    }
  };

  const createVideoTracks = async (
    videoConfig?: CameraVideoTrackInitConfig,
  ): Promise<ICameraVideoTrack> => {
    const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
    setLocalVideoTrack(cameraTrack);
    return cameraTrack;
  };

  const createAudioTracks = async (
    audioConfiguration?: MicrophoneAudioTrackInitConfig,
  ): Promise<IMicrophoneAudioTrack> => {
    let audioConfig: MicrophoneAudioTrackInitConfig;
    if (audioConfiguration) {
      audioConfig = audioConfiguration;
      audioConfiguration.ANS = false;
    } else {
      audioConfig = {
        ANS: false,
      };
    }
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
      audioConfig,
    );
    setLocalAudioTrack(microphoneTrack);
    return microphoneTrack;
  };

  // const createLocalScreenSharingTrack = async () => {
  //   const videoTrack = await AgoraRTC.createScreenVideoTrack({})
  //   if (!(videoTrack instanceof Array)) {
  //     setLocalVideoTrack(videoTrack)
  //     return videoTrack
  //   }
  // }

  const createPublishVideoTrack = async () => {
    const cameraTrack = await createVideoTracks();
    await client.publish([cameraTrack]);
  };

  const createPublishAudioTrack = async () => {
    const microphoneTrack = await createAudioTracks();
    await client.publish([microphoneTrack]);
  };

  const startRecordingFunc = async (props: JoinProps) => {
    const id = props.uid as number;
    const modifiedId = `${id * 1000}`;
    const resourceId = await getResourceId(props.channel, modifiedId);
    if (resourceId) {
      const response = await startRecording(
        props.channel,
        modifiedId,
        resourceId,
        hostId as string,
        props.trainerData,
        props.videoQuality,
      );
      if (response) {
        setRecordingData({
          sid: response.sid,
          resourceId,
          channel: props.channel,
          uid: modifiedId,
        });
        localStorage.setItem(
          'RECORDING_DATA',
          JSON.stringify({
            sid: response.sid,
            resourceId,
            channel: props.channel,
            uid: modifiedId,
          }),
        );
      } else {
        startRecordinAgainWithNewId(props);
      }
    }
  };

  const startRecordinAgainWithNewId = async (props: JoinProps) => {
    const id = props.uid as number;
    const modifiedId = `${id * 1001}`;
    const resourceId = await getResourceId(props.channel, modifiedId);
    if (resourceId) {
      const response = await startRecording(
        props.channel,
        modifiedId,
        resourceId,
        hostId as string,
        props.trainerData,
        props.videoQuality,
      );
      if (response) {
        setRecordingData({
          sid: response.sid,
          resourceId,
          channel: props.channel,
          uid: modifiedId,
        });
        localStorage.setItem(
          'RECORDING_DATA',
          JSON.stringify({
            sid: response.sid,
            resourceId,
            channel: props.channel,
            uid: modifiedId,
          }),
        );
      } else {
        Sentry.captureMessage('Failed to start recording for a second time');
      }
    }
  };

  const join = async (props: JoinProps) => {
    if (!client) {
      return;
    }

    client.setLowStreamParameter({
      width: 120,
      height: 120,
      framerate: 15,
      bitrate: 120,
    });

    client
      .enableDualStream()
      .then(() => {
        console.log('enable dual stream success');
      })
      .catch((err) => {
        console.log(err);
      });

    if (!isAudience) {
      await client.join(
        props.appid || '',
        props.channel,
        props.token || null,
        props.uid,
      );

      if (hostId === client.uid) {
        const cameraConfig: CameraVideoTrackInitConfig = {
          facingMode: 'environment',
          optimizationMode: 'motion',
        };

        const [microphoneTrack, cameraTrack] = await createLocalTracks(
          { ANS: false },
          cameraConfig,
          props.videoQuality,
        );
        if (microphoneTrack && cameraTrack) {
          await client.publish([microphoneTrack, cameraTrack]);
          if (props.recordSession) {
            startRecordingFunc(props);
          }
        }
      }
    }

    if (isAudience) {
      await client.join(
        props.appid || '',
        props.channel,
        props.token || null,
        props.uid,
      );
    }

    setJoinState(true);
  };

  const leave = async (workoutData?: ILiveWorkout) => {
    if (
      hostId === client.uid &&
      recordingData &&
      recordingData.sid &&
      recordingData.resourceId
    ) {
      const stopResponse = await stopRecording(
        recordingData.resourceId,
        recordingData.sid,
        recordingData.channel,
        recordingData.uid,
      );
      if (workoutData && stopResponse) {
        await createWorkoutFromRecording(
          workoutData.id,
          stopResponse.serverResponse.fileList,
        );
        removeOnFirebase(`channel${workoutData.id}`);
      }
    }

    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setRecordingData(undefined);
    setJoinState(false);
    await client?.leave();
  };

  // @ts-ignore
  useEffect(() => {
    if (!client) {
      return;
    }
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video',
    ) => {
      const isHost = hostId === user.uid;
      const HIGH_STREAM = 0;
      const LOW_STREAM = 1;

      await client.subscribe(user, mediaType);
      await client.setRemoteVideoStreamType(
        user.uid,
        isHost ? HIGH_STREAM : LOW_STREAM,
      );
      setRemoteUsers(Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleTrackChange = () => {
      createPublishAudioTrack();
    };

    // eslint-disable-next-line
    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on('track-ended', handleTrackChange);

    return async () => {
      await leave();
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
      client.off('track-ended', handleTrackChange);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    // startScreenShare,
    // stopScreenShare,
    createPublishAudioTrack,
    createPublishVideoTrack,
  };
};

export default useAgora;
