import React from 'react';
import ScheduleComponent from 'components/Schedule';
import Layout from 'components/Layout';

const CreatorSchedule = (): React.ReactElement => (
  <Layout title="Workout Schedule">
    <ScheduleComponent />
  </Layout>
);

export default CreatorSchedule;
