import React, { useMemo } from 'react';
import { UserAnalyticsResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import styled from 'styled-components';
import { api, Methods } from 'services/api';
import { Table } from 'components/Table';
import { DateTime } from 'luxon';
import { Loading } from 'components/Loading';
import { formatPrice } from 'util/helpers';

const Container = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const UserAnalyticsTable: React.FC = () => {
  const { isLoading, data: userList } = useQuery<UserAnalyticsResponse[]>(
    'userList',
    async () =>
      api<UserAnalyticsResponse[]>(
        Methods.get,
        '/analytics/users',
        {},
        { timeout: 120000 },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const columns = useMemo<Column<UserAnalyticsResponse>[]>(
    () => [
      {
        Header: 'User',
        accessor: 'name',
        Cell: (info: any) => info.row.original.name,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (info: any) => {
          if (info.row.original.createdAt) {
            return DateTime.fromISO(
              new Date(info.row.original.createdAt).toISOString(),
            ).toFormat('D');
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'Next Payment',
        accessor: 'currentPeriodEnd',
        Cell: (info: any) => {
          if (info.row.original.currentPeriodEnd) {
            return DateTime.fromISO(
              new Date(info.row.original.currentPeriodEnd * 1000).toISOString(),
            ).toFormat('D');
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (info: any) => {
          if (info.row.original.status) {
            return `${info.row.original.status[0].toUpperCase()}${info.row.original.status.substring(
              1,
            )}`;
          } else {
            return 'Drop In';
          }
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (info: any) => {
          if (info.row.original.type) {
            return `${info.row.original.type[0].toUpperCase()}${info.row.original.type.substring(
              1,
            )}`;
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: (info: any) => {
          if (info.row.original.price) {
            const value = info.row.original.price / 100 ?? 0;
            return formatPrice(value);
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'Drop Ins',
        accessor: 'spentOnDropIns',
        Cell: (info: any) => {
          const value = info.row.original.spentOnDropIns ?? 0;
          return formatPrice(value);
        },
      },
      {
        Header: 'Joined Classes',
        accessor: 'numberOfJoinedClasses',
        Cell: (info: any) => info.row.original.numberOfJoinedClasses ?? 0,
      },
      {
        Header: 'Discount Code',
        accessor: 'discountCode',
        Cell: (info: any) => info.row.original.discountCode ?? 'N/A',
      },
    ],
    [],
  );

  const data = useMemo<UserAnalyticsResponse[]>(
    () => (userList ? userList : []),
    [userList],
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table<UserAnalyticsResponse> columns={columns} data={data} />
      )}
    </div>
  );
};

export default UserAnalyticsTable;
