import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useMutation, useQueryClient } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import { Column } from 'components/Column';
import { Row } from 'components/Row';
import { DateTime } from 'luxon';
import Button from 'components/Button';
import { PromoCode } from '@solin-fitness/types';
import { GRAYS, PRIMARY } from 'constants/theme';
import Switch from '@material-ui/core/Switch';
import { EditPagePromoRequest, editPagePromotion } from 'queries/page';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  promo: PromoCode;
}

const CouponRow: React.FC<Props> = (props) => {
  const { promo } = props;
  const [isActive, setIsActive] = useState<boolean>(promo.isValid);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const editSubscription = useMutation(
    (data: EditPagePromoRequest) => editPagePromotion(data),
    {
      onError: (err: any) => {
        setIsSubmitting(false);
      },
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  const handleEdit = async () => {
    setIsSubmitting(true);
    await editSubscription.mutateAsync({
      promoId: promo.promoId,
      isActive,
    });
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Row justifyContent="flex-start" style={{ padding: '16px 0' }}>
        <Column alignItems="flex-start" style={{ flex: 3 }}>
          <Typography variant="h6">{promo.name}</Typography>
          <Typography
            variant="h6"
            style={{
              color: PRIMARY,
              marginBottom: 0,
              lineHeight: 0.8,
            }}
          >
            {promo.amountOff
              ? `$${Math.round(promo.amountOff) / 100} off`
              : `${promo.percentOff}% off`}
          </Typography>
        </Column>
        <Column alignItems="flex-start" style={{ flex: 3 }}>
          <Typography
            variant="body1"
            align="left"
            style={{
              color: GRAYS.dark,
              marginBottom: 0,
              lineHeight: 0.8,
            }}
          >
            {promo.code}
          </Typography>
        </Column>
        <Column style={{ flex: 3 }}>
          <Switch
            checked={isActive}
            size="medium"
            onChange={() => setIsActive(!isActive)}
            color="primary"
          />
        </Column>
        <Column style={{ flex: 1 }}>
          {promo.isValid !== isActive && (
            <Button
              variant="contained"
              onClick={async () => await handleEdit()}
              color="primary"
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="1.4rem" />
              ) : (
                <Typography variant="caption">Save</Typography>
              )}
            </Button>
          )}
        </Column>
      </Row>
      <div
        style={{
          margin: '0 -16px',
          borderBottom: `2px solid ${GRAYS.light}`,
        }}
      />
    </React.Fragment>
  );
};

export default CouponRow;
