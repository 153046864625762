import {
  ProgramSessionEmptyType,
  ProgramSessionInterface,
} from 'context/ProgramBuilderContext';
import React, { useState } from 'react';
import styled from 'styled-components';
import ProgramSession from './ProgramSession';
import { MinusIcon, PlusIcon } from 'components/shared/Icons';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import DeleteModal from './DeleteModal';

const Container = styled.section`
  display: flex;
`;

const ProgramDayRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

const DayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 32px;
  gap: 12px;
  height: 96px;
`;

const DayText = styled.h4`
  font-family: 'Brother';
  font-size: 24px;
  font-weight: 300;
  color: #000;
  white-space: nowrap;
`;

const MinusDayWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aaa;
  border-radius: 50%;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    filter: grayscale(0);
    background-color: ${(p) => p.theme.main.secondary};
  }
`;

const AddSessionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.main.secondary};
  border-radius: 50%;
  margin-left: 24px;
  align-self: center;
  flex-shrink: 0;
  cursor: pointer;
  transition: 100ms;

  &:hover {
    filter: brightness(0.8);
  }
`;

interface Props {
  week: number;
  day: number;
  sessions: ProgramSessionInterface[];
  totalDaysInWeek: number;
}

const ProgramDay: React.FC<Props> = ({
  week,
  day,
  sessions,
  totalDaysInWeek,
}) => {
  const { handleAddNewSession, handleDeleteDay } = useProgramBuilder();
  const lastSessionType = sessions[sessions.length - 1]?.type;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  return (
    <>
      <Container>
        <DayTextContainer>
          <DayText>{`Day ${day + 1}`}</DayText>
          {totalDaysInWeek > 1 ? (
            <MinusDayWrap onClick={() => setOpenDeleteModal(true)}>
              <MinusIcon color="#fff" strokeWidth={2.5} />
            </MinusDayWrap>
          ) : null}
        </DayTextContainer>
        <ProgramDayRow>
          {sessions.map((session, i) => (
            <ProgramSession
              key={i}
              sessionData={session}
              week={week}
              day={day}
              sessionIndex={i}
            />
          ))}
          {lastSessionType !== ProgramSessionEmptyType.EMPTY ? (
            <AddSessionButtonContainer
              onClick={() => handleAddNewSession(week, day)}
            >
              <PlusIcon color="#fff" />
            </AddSessionButtonContainer>
          ) : null}
        </ProgramDayRow>
      </Container>
      <DeleteModal
        isOpen={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        primaryText="Are you sure you want to delete this day?"
        secondaryText="The days of this week schedule to occur after the deleted day will
            move up by one day (for example, if Day 3 is deleted, Day 4 becomes
            Day 3)."
        handleDelete={() => {
          handleDeleteDay(week, day);
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
};

export default ProgramDay;
