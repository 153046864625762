import React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import styled from 'styled-components';
import useThemeContext from 'hooks/useThemeContext';

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsl(0deg 0% 0% / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled(DialogContent)`
  width: 50%;
  /* want to move the  modal up instead of center of screen */
  margin-top: -250px;
`;

const Container = styled.div`
  background-color: #000;
  opacity: 0.9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 30px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
`;

const Text = styled.p`
  font-family: 'Raleway';
  font-size: 16;
  color: #fff;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
`;

const Button = styled.button`
  border: none;
  width: 160px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: var(--bg);
  cursor: pointer;
`;

const ButtonText = styled.p`
  font-family: 'Brother';
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: var(--color);
`;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  primaryText: string;
  secondaryText?: string;
  handleDelete: () => void;
}

const DeleteModal: React.FC<Props> = ({
  isOpen,
  onCancel,
  primaryText,
  secondaryText,
  handleDelete,
}) => {
  const theme = useThemeContext();

  return (
    <Overlay isOpen={isOpen}>
      <Content>
        <Container>
          <Text>{primaryText}</Text>
          {!!secondaryText ? (
            <Text>
              <span style={{ fontStyle: 'italic' }}>{secondaryText}</span>
            </Text>
          ) : null}
          <ButtonContainer>
            <Button
              style={{ '--bg': theme.main.secondary }}
              onClick={() => handleDelete()}
            >
              <ButtonText style={{ '--color': '#fff' }}>Yes</ButtonText>
            </Button>
            <Button style={{ '--bg': '#fff' }} onClick={() => onCancel()}>
              <ButtonText style={{ '--color': '#000' }}>No</ButtonText>
            </Button>
          </ButtonContainer>
        </Container>
      </Content>
    </Overlay>
  );
};

export default DeleteModal;
