import React from 'react';
import { WorkoutResponse } from '@solin-fitness/types';
import SpotifyButton from 'components/SpotifyButton';
import * as S from './ViewWorkout.styles';
import { Loading } from 'components/Loading';
import { CircularProgress } from '@material-ui/core';
import { SectionTag } from 'components/shared/Section';

interface ViewWorkoutProps {
  workoutData: WorkoutResponse;
}

const ViewWorkout: React.FC<ViewWorkoutProps> = ({ workoutData }) => {
  if (!workoutData) {
    return <Loading />;
  }

  return (
    <SectionTag>
      <div>
        {!workoutData ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div>
            <S.PlayerWrapper>
              <S.Player
                controls
                width="100%"
                height="100%"
                playing={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
                url={workoutData.videos[0].videoUrl}
              />
            </S.PlayerWrapper>

            <S.Row>
              <S.VideoInfoWrap>
                <S.Primary>{workoutData.title}</S.Primary>
                <S.Secondary>
                  with {workoutData.user.profile.firstName}{' '}
                  {workoutData.user.profile.lastName}
                </S.Secondary>
                {workoutData.headerOne && (
                  <S.NoteHeader>{workoutData.headerOne}</S.NoteHeader>
                )}
                {workoutData.description && (
                  <S.Note
                    dangerouslySetInnerHTML={{
                      __html: workoutData.description,
                    }}
                  ></S.Note>
                )}
                {workoutData.headerTwo && (
                  <S.NoteHeader>{workoutData.headerTwo}</S.NoteHeader>
                )}
              </S.VideoInfoWrap>
              {workoutData.spotifyLink && (
                <SpotifyButton spotifyLink={workoutData.spotifyLink} />
              )}
            </S.Row>
          </div>
        )}
      </div>
    </SectionTag>
  );
};

export default ViewWorkout;
