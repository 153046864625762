import { FetchUserProfileResponse } from '@solin-fitness/types';

enum LOCAL_STORAGE_KEYS {
  AUTH_TOKEN = 'authtoken',
  USER = 'user',
  PAGE_URL = 'pageurl',
}

export interface UserSession {
  accessToken: string;
  refreshToken: string;
  pageUrl: string;
  id: number;
  userType: number;
  expiresAt: number;
}

export const saveUser = (user: FetchUserProfileResponse) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
};

export const getUser = () => {
  const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
  if (!user) {
    return null;
  }
  const profile: FetchUserProfileResponse = JSON.parse(user);
  return profile;
};

export const savePageUrl = (pageUrl: string) =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.PAGE_URL, pageUrl);

export const getPageUrl = () => {
  const pageUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.PAGE_URL);
  return pageUrl || '';
};
