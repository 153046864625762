import React from 'react';
import * as S from 'styles/video.styles';
import { getUser } from 'services/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Page } from 'types';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPage } from 'queries/page';
import { LiveWorkoutRoom } from 'components/LiveWorkoutRoom';
import { LiveWorkoutResponse } from '@solin-fitness/types';

const Live = () => {
  const location = useLocation<{ workoutData: LiveWorkoutResponse }>();

  const workoutData = location.state.workoutData;

  const { isLoading, data } = useQuery<Page, Error>(
    'creatorPage',
    () => getPage(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const pageConfig = data;
  const userData = getUser();

  if (!userData || isLoading) {
    return (
      <S.LoaderWrap>
        <CircularProgress />
      </S.LoaderWrap>
    );
  }

  return workoutData === null ? (
    <S.EmptyState>Workout with this does not exist</S.EmptyState>
  ) : (
    <>
      <LiveWorkoutRoom
        workoutData={workoutData}
        pageConfig={pageConfig as Page}
      />
    </>
  );
};

export default Live;
