import React from 'react';
import 'styles/layout.css';
import Header from 'components/Header';
import { NavMenu } from 'components/NavMenu';
import styled from 'styled-components';
import { PHONE } from 'constants/measurements';

const Container = styled.div`
  background: ${({ theme }) => theme.background};
  height: 100%;
  display: flex;
`;

// const Row = styled.div`
//   padding-top: 70px;
//   height: 100%;
// `;

const Body = styled.div`
  background: ${({ theme }) => theme.background};

  vertical-align: top;
  padding: 8px;
  padding-top: 90px;
  height: 100vh;
  width: 100%;
  margin-left: 20px;
  overflow: auto;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background: ${({ theme }) => theme.padding};
  border-bottom: 2px solid ${({ theme }) => theme.grays.light};
`;

interface LayoutProps {
  children: React.ReactNode;
  title: string;
}

const Layout = ({ children, title }: LayoutProps): React.ReactElement => (
  <Container>
    <HeaderContainer>
      <Header title={title} />
    </HeaderContainer>
    <NavMenu isMobileMenu={false} />
    <Body>{children}</Body>
  </Container>
);

export default Layout;
