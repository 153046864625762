// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormTextField } from 'components/FormTextField';
import ImageUpload from 'components/FileUploads/ImageUpload/ImageUpload';
import { Formik, Form, FieldArray } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { CircularProgress, TextField } from '@material-ui/core';
import useThemeContext from 'hooks/useThemeContext';
import { ProgramDetails } from 'context/ProgramBuilderContext';
import { useProgramBuilder } from 'hooks/useProgramBuilder';
import { FormRadioButton } from 'components/FormRadioButton';
import { ProgramType } from '@solin-fitness/types';
import ProgramTestimonial from './ProgramTestimonial';
import { CSSProperties, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PROGRAMS } from 'constants/routes';

const Header = styled.h2`
  font-family: 'Raleway';
  font-size: 20px;
  color: #000;
`;

const OverviewContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-bottom: 24px;
`;

const TypeContainer = styled.div`
  display: flex;
  gap: 4px;
  padding-bottom: 24px;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
`;

const Label = styled.h4`
  font-family: 'Raleway';
  font-size: 16px;
  color: ${(p) => p.theme.text.primary};
  padding: 24px 12px 12px;
  font-weight: 300;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TestimonialFormContainer = styled.div`
  flex: 0 1 33.3333%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 36px;
`;

const TestimonalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  padding-top: 18px;
`;

const TestimonialImageUploadWrap = styled.div`
  width: 150px;
  height: 150px;
  align-self: center;
`;

export const Button = styled.button<{ bg: string }>`
  border: none;
  width: 100%;
  background-color: ${(p) => p.bg};
  height: 44px;
  border-radius: 40px;
  box-shadow: 0px 14px 18px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 100ms;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: brightness(0.8);
  }
`;

export const ButtonText = styled.p<{ color: string }>`
  margin: auto;
  color: ${(p) => p.color};
  font-family: 'Brother';
  font-size: 16px;
  font-weight: medium;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
`;

export enum ButtonTypes {
  continue = 'continue',
  exit = 'exit',
}

interface TestimonialValues {
  image: string;
  name: string;
  testimonial: string;
}

interface ProgramDetailsInitialValues {
  type: ProgramType;
  title: string;
  description: string;
  detailOne: string;
  detailTwo: string;
  detailThree: string;
  testimonials: TestimonialValues[];
  newTestimonialImage: string;
  newTestimonialName: string;
  newTestimonialTestimonial: string;
}

const validationSchema = yup.object({
  type: yup.string().required(),
  title: yup.string().required().min(1),
  description: yup.string().required().min(1),
  detailOne: yup.string().optional(),
  detailTwo: yup.string().optional(),
  detailThree: yup.string().optional(),
  testimonials: yup
    .array()
    .of(
      yup.object({
        image: yup.string().required().min(1),
        name: yup.string().required().min(1),
        testimonial: yup.string().required().min(1),
      }),
    )
    .required(),
});

const checkEmptyValues = (values: string[]): boolean =>
  !!values.filter((value) => !!!value).length;

const isComplete = (object: ProgramDetailsInitialValues): boolean => {
  const values = Object.keys(object).filter((key) => {
    if (key === 'title' || key === 'description') {
      const value = object[key];
      return !!!value;
    }

    return false;
  });

  // if values has not items (length 0), required fields are filled in
  return !!!values.length;
};

const convertToInitialValues = (
  data: ProgramDetails,
): ProgramDetailsInitialValues => ({
  type: data.type,
  title: data.title,
  description: data.description,
  detailOne: data.details?.[0] || '',
  detailTwo: data.details?.[1] || '',
  detailThree: data.details?.[2] || '',
  testimonials: data.testimonials.map((val) => ({
    image: val.imagePublicId,
    name: val.name,
    testimonial: val.quote,
  })),
  // separating these into different fields because you can run into name/testimonial not
  // getting updated correctly if the image result returns its value at the same time
  newTestimonialImage: '',
  newTestimonialName: '',
  newTestimonialTestimonial: '',
});

interface Props {
  programDetails: ProgramDetails;
}

const ProgramDetailsForm = ({ programDetails }: Props) => {
  const theme = useThemeContext();
  const history = useHistory();

  const initialValues: ProgramDetailsInitialValues =
    convertToInitialValues(programDetails);

  const { handleSaveProgramDetails, handleUpdateProgramDetails, isEditing } =
    useProgramBuilder();

  const [isSubmittingExit, setIsSubmittingExit] = useState<boolean>(false);
  const [isExitState, setIsExitState] = useState<boolean>(false);
  const [isSubmittingContinue, setIsSubmittingContinue] =
    useState<boolean>(false);

  const handleSave = async (
    data: ProgramDetailsInitialValues,
    editing: boolean,
    toExit: boolean,
  ) => {
    const programDetailsData: ProgramDetails = {
      type: data.type,
      title: data.title,
      description: data.description,
      details: [data.detailOne, data.detailTwo, data.detailThree],
      testimonials: data.testimonials.map((val) => ({
        imagePublicId: val.image,
        name: val.name,
        quote: val.testimonial,
      })),
    };

    if (!editing) {
      handleSaveProgramDetails(programDetailsData, toExit);
    } else {
      await handleUpdateProgramDetails(programDetailsData, toExit);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        // should be of type SubmitEvent
        const submitterExit =
          (window.event as any)?.submitter?.name === ButtonTypes.exit ||
          isExitState;
        if (submitterExit) {
          setIsSubmittingExit(true);

          await handleSave(data, isEditing, true);

          setIsSubmittingExit(false);

          history.push(PROGRAMS);
        } else {
          setIsSubmittingContinue(true);

          await handleSave(data, isEditing, false);

          setIsSubmittingContinue(false);
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ marginBottom: 0 }}>
          <TypeContainer>
            <Header>Type</Header>
            <InputContainer>
              <RadioButtonContainer>
                <FormRadioButton
                  label="Program"
                  name="type"
                  type="radio"
                  value={ProgramType.PROGRAM}
                />
                <FormRadioButton
                  label="Challenge"
                  name="type"
                  type="radio"
                  value={ProgramType.CHALLENGE}
                />
              </RadioButtonContainer>
            </InputContainer>
          </TypeContainer>

          <Header>Overview</Header>

          <OverviewContainer>
            <InputContainer>
              <FormTextField
                label="Title"
                placeholder="Fat Burner"
                name="title"
                type="input"
              />
              <div>
                <Label>Description</Label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                    ],
                    link: {
                      addTargetToExternalLinks: true,
                    },
                  }}
                  data={values.description || ''}
                  onChange={(e: Event, editor: ClassicEditor) => {
                    const data = editor.getData();
                    setFieldValue('description', data);
                  }}
                />
              </div>
              <DetailsContainer>
                <Label>Highlights</Label>
                <FormTextField
                  label=""
                  placeholder="What will you focus on?"
                  name="detailOne"
                  type="input"
                  multiline
                />
                <FormTextField
                  label=""
                  placeholder="What is the duration?"
                  name="detailTwo"
                  type="input"
                  multiline
                />
                <FormTextField
                  label=""
                  placeholder="What are you most excited about?"
                  name="detailThree"
                  type="input"
                  multiline
                />
              </DetailsContainer>
            </InputContainer>
          </OverviewContainer>
          <div>
            <Header>Testimonials</Header>
            <TestimonalContainer>
              <FieldArray name="testimonials">
                {(arrayHelpers) => (
                  <>
                    {values.testimonials.map((testimonial, index) => (
                      <ProgramTestimonial
                        key={index}
                        image={testimonial.image}
                        name={testimonial.name}
                        testimonial={testimonial.testimonial}
                        onDelete={() => arrayHelpers.remove(index)}
                      />
                    ))}
                    {values.testimonials.length < 3 ? (
                      <TestimonialFormContainer
                        key={values.testimonials.length}
                      >
                        <TestimonialImageUploadWrap>
                          <ImageUpload
                            circle
                            small
                            thumbnail={values.newTestimonialImage}
                            doNotUse16to9AspectRatio={true}
                            fileName="newTestimonialImage"
                            handleThumbnail={(value) =>
                              setFieldValue('newTestimonialImage', value)
                            }
                          />
                        </TestimonialImageUploadWrap>
                        <TextField
                          label="Name"
                          variant="outlined"
                          placeholder="John Doe"
                          onChange={(e: any) =>
                            setFieldValue(
                              'newTestimonialName',
                              e.currentTarget.value,
                            )
                          }
                          type="input"
                        />
                        <TextField
                          label="Testimonial"
                          variant="outlined"
                          placeholder="This program is so amazing!"
                          type="input"
                          multiline
                          onChange={(e: any) =>
                            setFieldValue(
                              'newTestimonialTestimonial',
                              e.currentTarget.value,
                            )
                          }
                        />
                        <Button
                          bg={theme.main.primary}
                          disabled={checkEmptyValues([
                            values.newTestimonialImage,
                            values.newTestimonialName,
                            values.newTestimonialTestimonial,
                          ])}
                          onClick={() => {
                            arrayHelpers.push({
                              image: values.newTestimonialImage,
                              name: values.newTestimonialName,
                              testimonial: values.newTestimonialTestimonial,
                            });
                            setFieldValue('newTestimonialImage', '');
                            setFieldValue('newTestimonialName', '');
                            setFieldValue('newTestimonialTestimonial', '');
                          }}
                        >
                          <ButtonText color="#fff">Add Testimonial</ButtonText>
                        </Button>
                      </TestimonialFormContainer>
                    ) : null}
                  </>
                )}
              </FieldArray>
            </TestimonalContainer>
            <SubmitButtonContainer>
              {isEditing ? (
                <Button
                  name={ButtonTypes.exit}
                  bg="#fff"
                  type="submit"
                  onClick={() => setIsExitState(true)}
                >
                  {isSubmittingExit ? (
                    <CircularProgress style={circularProgressStyle} />
                  ) : (
                    <ButtonText color={theme.main.secondary}>
                      {'Save & Exit'}
                    </ButtonText>
                  )}
                </Button>
              ) : null}
              <Button
                name={ButtonTypes.continue}
                bg={theme.main.secondary}
                type="submit"
                disabled={!isComplete(values)}
              >
                {isSubmittingContinue ? (
                  <CircularProgress style={circularProgressStyle} />
                ) : (
                  <ButtonText color="#fff">{'Save & Continue'}</ButtonText>
                )}
              </Button>
            </SubmitButtonContainer>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const circularProgressStyle: CSSProperties = {
  width: 24,
  height: 24,
  color: '#fff',
};

export default ProgramDetailsForm;
