import styled from 'styled-components';
import { PRIMARY } from 'constants/theme';
import { Form } from 'formik';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormContainer = styled(Form)`
  background: white;
  width: 400px;
  height: auto;
  border-radius: 40px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.15);

  @media (max-width: 1100px) {
    width: 350px;
    padding: 30px;
    margin: 20px;
    height: auto;
    margin-top: -30px;
  }
`;

export const Logo = styled.img`
  width: 160px;
  height: 60px;
  margin: 80px;
`;

export const SecondaryText = styled.div`
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 23px;
  color: ${({ theme }) => theme.main.primary};
  width: 100%;
  margin-top: 10px;
  text-align: center;
`;
