import { useEffect, useState } from 'react';
import {
  FullProgram,
  FullProgramRecords,
  Program,
  ProgramDetails,
  ProgramPrice,
} from 'context/ProgramBuilderContext';
import styled from 'styled-components';
import ProgramCard from './ProgramCard';
import { useHistory } from 'react-router-dom';
import { ProgramResponse, ProgramType } from '@solin-fitness/types';
import Button from 'components/Button';
import { convertToFullProgram } from 'services/programs';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 18px;
`;

const Card = styled.div`
  flex: 0 1 50%;
  padding: 12px;
  transition: 200ms ease-out;

  &:hover {
    opacity: 0.96;
  }
`;

const HeaderText = styled.h1`
  padding: 0 12px 12px;
  font-family: 'Raleway';
  font-weight: 300;
  color: #000;
  font-size: clamp(1.5rem, 3vw, 2rem);
`;

const Wrapper = styled.section`
  position: relative;
  padding-top: 24px;
  min-height: 100%;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 12px;
`;

const EmptyContainer = styled.div`
  position: relative;
  padding: 24px;
  margin-top: -48px;
  max-width: 50%;
`;

const EmptyText = styled.p`
  font-family: 'Raleway';
  font-size: 22px;
  color: #000;
`;

export const PROGRAM_KEY = 'programs';

const getProgramsFromLocalStorage = (): FullProgramRecords => {
  const localStorageValue = localStorage.getItem(PROGRAM_KEY);
  if (localStorageValue) {
    const localStoragePrograms = JSON.parse(
      localStorageValue,
    ) as unknown as FullProgramRecords;
    return localStoragePrograms;
  }
  return {};
};

interface Props {
  programs: ProgramResponse[];
}

const ViewPrograms = ({ programs }: Props) => {
  const history = useHistory();

  const [incompletePrograms, setIncompletePrograms] =
    useState<FullProgramRecords>(() => getProgramsFromLocalStorage());

  useEffect(() => {
    const localStoragePrograms = getProgramsFromLocalStorage();

    setIncompletePrograms(localStoragePrograms);
  }, [programs]);

  const handleInProgressProgram = (data: FullProgram, programId: string) => {
    history.push('programs/create', {
      program: data,
      programId,
    });
  };

  const handleEditProgram = (data: ProgramResponse) => {
    const program = convertToFullProgram(data);
    history.push(`programs/${data.id}/edit`, {
      program,
      programId: data.id,
    });
  };

  const handleViewPurchases = (data: ProgramResponse) => {
    history.push(`programs/${data.id}`, {
      programId: data.id,
    });
  };

  const isEmpty =
    !!!Object.keys(incompletePrograms).length && !!!programs.length;

  return (
    <Wrapper>
      <ButtonContainer>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => history.push('programs/create')}
        >
          Create New Program or Challenge
        </Button>
      </ButtonContainer>
      {isEmpty ? (
        <EmptyContainer>
          <EmptyText>
            No programs created. Hit button in top right to get started!
          </EmptyText>
        </EmptyContainer>
      ) : (
        <>
          {!!Object.keys(incompletePrograms).length ? (
            <>
              <HeaderText>In Progress</HeaderText>
              <Container>
                {Object.keys(incompletePrograms).map((key) => (
                  <Card key={key}>
                    <ProgramCard
                      title={incompletePrograms[key].details.title}
                      thumbnail={incompletePrograms[key].assets.thumbnail}
                      type={ProgramType.PROGRAM}
                      handleClick={() =>
                        handleInProgressProgram(incompletePrograms[key], key)
                      }
                    />
                  </Card>
                ))}
              </Container>
            </>
          ) : null}
          {!!programs.length ? (
            <>
              <HeaderText>Current Programs and Challenges</HeaderText>
              <Container>
                {programs.map((program) => (
                  <Card key={program.id}>
                    <ProgramCard
                      id={program.id}
                      published={program.published}
                      title={program.title}
                      thumbnail={program.backgroundImagePublicId}
                      type={program.type}
                      handleClick={() => handleEditProgram(program)}
                      handleViewPurchases={() => handleViewPurchases(program)}
                    />
                  </Card>
                ))}
              </Container>
            </>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default ViewPrograms;
