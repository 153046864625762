import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useQueryClient, useMutation } from 'react-query';
import { CircularProgress, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import PublishIcon from '@material-ui/icons/Publish';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Row } from 'components/Row';
import {
  GRAYS,
  LIGHT_PRIMARY,
  LIGHT_SECONDARY,
  PRIMARY,
  SECONDARY,
} from 'constants/theme';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { editPageMetadata } from 'queries/page';
import { EditConfigMetadata } from '@solin-fitness/types';
import { Column } from 'components/Column';
import { apiWithFormData } from 'services/api';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';

interface Props {
  pageId: number;
  isOpen: boolean;
  onCancel: () => void;
}

interface InitialValues {
  video: File | null;
}

const FILE_SIZE_LIMIT = 1048576 * 50; // 1mb in bytes (binary) ~ 50 mb

const EditTeaserVideo: React.FC<Props> = (props) => {
  const { isOpen, onCancel, pageId } = props;
  const queryClient = useQueryClient();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const [video, setVideo] = useState<string | ArrayBuffer | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const initialValues = useMemo<InitialValues>(
    () => ({
      video: null,
    }),
    [],
  );

  const onUploadProgress = (event: ProgressEvent) => {
    const { loaded, total } = event;
    const value = Math.round((loaded / total) * 100);
    setProgress(value);
  };

  const handleVideo = useMutation(
    (file: File) => {
      const formData = new FormData();
      formData.append('video', file);
      return apiWithFormData<{ videoUrl: string }>('/upload/video', formData, {
        onUploadProgress,
      });
    },
    {
      onError: (err: any) =>
        setError(err?.message || 'Uh oh! Something went wrong 🤭'),
    },
  );

  const editMetadata = useMutation(
    ({ id, data }: { id: number; data: EditConfigMetadata }) =>
      editPageMetadata(id, data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev 😥',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal width={510} open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Teaser Video
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (!data.video) {
            setSubmitting(false);
            return;
          }
          const { videoUrl } = await handleVideo.mutateAsync(data.video);
          await editMetadata.mutateAsync({
            id: pageId,
            data: {
              teaserVideo: videoUrl,
            },
          });
          setSubmitting(false);
          setVideo(null);
          setProgress(0);
          resetForm();
          onCancel();
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form style={{ marginBottom: 0 }}>
            {!!error && <Aside isError={true}>{error}</Aside>}
            <DropZone
              onClick={() => hiddenFileInput.current?.click()}
              style={{
                display: video ? 'none' : 'flex',
              }}
            >
              <Column>
                <PublishIcon fontSize="large" color="primary" />
                <Typography
                  variant="h5"
                  align="center"
                  style={{ width: '70%', fontWeight: 700, letterSpacing: 0.8 }}
                >
                  Select a file to upload
                </Typography>
              </Column>
              <input
                type="file"
                accept=".mp4"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={(e) => {
                  const files = e.currentTarget.files;
                  if (files && files.length > 0) {
                    const target = files[0];
                    if (target.size > FILE_SIZE_LIMIT) {
                      setError(
                        'File size is too large\n Please upload a file 50mb or less',
                      );
                      return;
                    }
                    setError('');
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                      setVideo(reader.result),
                    );
                    reader.readAsDataURL(files[0]);
                    setFieldValue('video', files[0]);
                  }
                }}
              />
            </DropZone>
            {video && (
              <>
                <TeaserVideo
                  controls
                  onPause={() => undefined}
                  controlsList="nodownload noremoteplayback"
                >
                  <source src={video as string} type="video/mp4" />
                </TeaserVideo>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  style={{
                    borderRadius: 35,
                    marginTop: 24,
                    marginBottom: 36,
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size="2rem" />
                  ) : (
                    <Typography variant="h6">Save</Typography>
                  )}
                </Button>
                <div
                  style={{
                    borderRadius: 15,
                    height: 15,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    margin: '0 -30px -30px',
                  }}
                >
                  <ProgressBar value={progress} />
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditTeaserVideo;

const DropZone = styled(Row)`
  height: 450px;
  border: 3px dashed ${({ theme }) => theme.main.lightPrimary};
  border-radius: 24px;

  &:hover {
    opacity: 0.5;
    background-color: ${({ theme }) => theme.grays.light};
  }
`;

const ProgressBar = styled.div<{ value: number }>`
  height: 100%;
  background: linear-gradient(
    90deg,
    ${PRIMARY},
    ${LIGHT_PRIMARY},
    ${LIGHT_SECONDARY},
    ${SECONDARY}
  );
  clip-path: ${({ value }) =>
    `polygon(0% 0%, ${value}% 0%, ${value}% 100%, 0% 100%)`};
`;

const TeaserVideo = styled.video`
  width: 450px;
  height: 300px;
  border-radius: 50px;
  outline: none;
  background-color: #000;
  filter: drop-shadow(10px 4px 52px rgba(0, 0, 0, 0.25));
`;
