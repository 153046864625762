import { useEffect, useState } from 'react';
import * as S from 'components/LiveWorkoutRoom/LiveWorkoutRoom.styles';
import { EventResponse } from '@solin-fitness/types';
import { Page } from 'types';
import AgoraRTC from 'agora-rtc-sdk-ng';
import useAgora from 'services/agora-ng';
import { getUser } from 'services/auth';
import MediaPlayer from 'components/LiveWorkoutRoom/MediaPlayer';
import { useHistory } from 'react-router-dom';
import { CREATOR_SCHEDULE, EVENTS } from 'constants/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import Firebase, { ListenerType } from 'services/chat/Config/firebase';
import LiveDeviceSettings from 'components/LiveWorkoutRoom/LiveDeviceSettings';
import LiveFire from 'components/LiveWorkoutRoom/LiveFire';
import LiveWorkoutCreatorChat from '../LiveWorkoutRoom/LiveWorkoutCreatorChat/LiveWorkoutCreatorChat';
import { getCurrentChannel } from 'services/chat/Pages/Chat/ChatPage';
import { FetchUserProfileResponse } from '@solin-fitness/types';
import { isMobile, isTablet } from 'react-device-detect';
import LiveRoomMobile from '../LiveWorkoutRoom/LiveRoomMobile';
import { useWindowSize } from 'util/helpers';
import Attendees from '../LiveWorkoutRoom/Attendees';
import { Message } from 'components/LiveWorkoutRoom/LiveWorkoutRoom';

const isMobileOrTablet = isTablet || isMobile;

export interface GroupedMessage {
  userId: number;
  messages: Message[];
}

interface Props {
  event: EventResponse;
  pageConfig: Page;
}

interface RecordingData {
  sid: string;
  resourceId: string;
  channel: string;
  uid: string;
}
const appID = process.env.REACT_APP_AGORA_APP_ID || '';
const token = undefined;
const role = 'host';

const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });

const messageRef = Firebase.database().ref('messages');

const videoFormatRef = Firebase.database().ref('videoFormat');

const VERTICAL = 'vertical';
const HORIZONTAL = 'horizontal';

const EventRoom = (props: Props) => {
  const { event } = props;
  const [isLeaving, setLeaving] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [showAttendees, setShowAttendees] = useState(false);
  const history = useHistory();

  const permissionCallback = () => null;
  const { localAudioTrack, localVideoTrack, leave, join, remoteUsers } =
    useAgora(client, role, event.host.id, permissionCallback);

  // get current creator
  const user = getUser();

  const { width, height } = useWindowSize();
  const meetingId = `channel-event-${event.id}`;

  const currentChannelId = getCurrentChannel(meetingId).id;

  let messageListener: ListenerType | null = null;

  const addMessageListner = () => {
    const loadedMesage: Message[] = [];
    messageListener = messageRef
      .child(currentChannelId)
      .on('child_added', (snap: any) => {
        loadedMesage.push(snap.val());
        setMessages(() => [...loadedMesage]);
      });
  };

  const sendInfoAboutVideoFormat = (
    type: typeof VERTICAL | typeof HORIZONTAL,
  ) => {
    videoFormatRef.child(`${currentChannelId}`).push().set({
      type,
    });
  };

  useEffect(() => {
    if (height > width) {
      sendInfoAboutVideoFormat(VERTICAL);
    } else {
      sendInfoAboutVideoFormat(HORIZONTAL);
    }
  }, [height, width]);

  useEffect(() => {
    join({
      appid: appID,
      channel: meetingId,
      token,
      uid: user?.id,
      recordSession: false,
      videoQuality: '720p_3',
      trainerData: {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        id: user?.id || '',
      },
    });
    addMessageListner();

    return () => {
      messageRef.off('child_added', messageListener as ListenerType);
    };
  }, []);

  const handleLeave = async () => {
    setLeaving(true);
    await leave();

    history.push(EVENTS);
  };

  const usersWatching = remoteUsers.filter(
    (userItem) => userItem.uid !== event.host.id,
  );

  const handleChangeCam = (cam: MediaDeviceInfo) => {
    // @ts-ignore
    // (setDevice is not in agora TS types, but is in docs)
    localVideoTrack.setDevice(cam.deviceId).then(() => {
      client.publish([localVideoTrack as any]);
    });
  };

  const handleChangeMic = (cam: MediaDeviceInfo) => {
    // @ts-ignore
    // (setDevice is not in agora TS types, but is in docs)
    localAudioTrack.setDevice(cam.deviceId).then(() => {
      client.publish([localAudioTrack as any]);
    });
  };

  if (isMobileOrTablet) {
    return (
      <LiveRoomMobile
        videoTrack={localVideoTrack}
        audioTrack={localAudioTrack}
        uid={user?.id || ''}
        meetingId={meetingId}
        usersWatching={usersWatching}
        currentChannelId={currentChannelId}
        userData={user as FetchUserProfileResponse}
        messages={messages}
        handleLeave={handleLeave}
        isLeaving={isLeaving}
        client={client}
      />
    );
  }

  return (
    <S.Container>
      <S.Wrap>
        <S.MainColumn>
          <S.MainVideo>
            <MediaPlayer
              videoTrack={localVideoTrack}
              audioTrack={localAudioTrack}
              isLocal={true}
              isLocalVideoMuted={false}
              isLocalAudioMuted={false}
              isHost={true}
              isMobileFullscreen={false}
              initialUnmuteDone={false}
              uid={user?.id || ''}
              meetingId={meetingId}
            />
          </S.MainVideo>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
            <LiveDeviceSettings
              onChangeMic={handleChangeMic}
              onChangeCam={handleChangeCam}
              currentMicName={
                localAudioTrack
                  ? localAudioTrack.getMediaStreamTrack().label
                  : ''
              }
              currentCamName={
                localVideoTrack
                  ? localVideoTrack.getMediaStreamTrack().label
                  : ''
              }
            />
            <LiveFire meetingId={meetingId} />
          </div>
        </S.MainColumn>

        <S.SideColumn style={{ justifyContent: 'flex-start' }}>
          <S.LeaveButton onClick={handleLeave}>
            {isLeaving ? (
              <CircularProgress
                style={{ marginRight: 10 }}
                color="secondary"
                size={15}
              />
            ) : (
              <S.Dot />
            )}
            <S.LeaveButtonText>
              <b style={{ marginRight: 2 }}>Stop</b> Live
            </S.LeaveButtonText>
          </S.LeaveButton>

          <S.PeopleCount>{usersWatching.length} watching</S.PeopleCount>
          <S.AttendeesButtonDesktop
            onClick={() => setShowAttendees(!showAttendees)}
          >
            {showAttendees ? 'Return to chat' : 'See attendees'}
          </S.AttendeesButtonDesktop>
          {showAttendees ? (
            <Attendees usersWatching={usersWatching} />
          ) : (
            <LiveWorkoutCreatorChat
              creatorId={event.host.id}
              currentChannelId={currentChannelId}
              userData={user as FetchUserProfileResponse}
              messages={messages}
            />
          )}
        </S.SideColumn>
      </S.Wrap>
    </S.Container>
  );
};

export default EventRoom;
