import useAuthStore from 'hooks/useAuthStore';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { ...rest } = props;

  const { isSignedIn } = useAuthStore();

  if (!isSignedIn) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
};

export default ProtectedRoute;
