import React from 'react';
import Stripe from 'stripe';
import { useQuery, useQueryClient } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Row } from 'components/Row';
import { api, Methods } from 'services/api';
import { formatPrice } from 'util/helpers';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 50px;
`;

const BalanceAnalytics: React.FC = () => {
  const queryClient = useQueryClient();
  const { isLoading, data: balance } = useQuery<Stripe.Balance>(
    'balance',
    async () => api<Stripe.Balance>(Methods.get, '/analytics/stripe/balance'),
  );

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Row>
            <Typography
              variant="h4"
              style={{
                width: '100%',
                lineHeight: 1.1,
                fontWeight: 300,
              }}
            >
              Stripe Account Balance
            </Typography>
          </Row>
          {balance ? (
            <Container>
              <Row style={{ padding: '1em 0' }}>
                Amount Pending: {formatPrice(balance.pending[0].amount / 100)}
              </Row>
              <Row style={{ padding: '1em 0' }}>
                Amount Available:{' '}
                {formatPrice(balance.available[0].amount / 100)}
              </Row>
              <Row style={{ padding: '1em 0' }}>
                Future Payouts:{' '}
                {formatPrice(
                  balance.instant_available
                    ? balance.instant_available[0].amount / 100
                    : 0,
                )}
              </Row>
            </Container>
          ) : null}
        </Container>
      )}
    </div>
  );
};

export default BalanceAnalytics;
