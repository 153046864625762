import { api, Methods } from '../services/api';
import { ILiveWorkout } from 'types';
import {
  CreateLiveWorkoutRequest,
  LiveWorkoutResponse,
  CreateLiveWorkoutResponse,
} from '@solin-fitness/types';

export const getLiveWorkouts = async () =>
  await api<ILiveWorkout[]>(Methods.get, '/live-workouts?type=all');

export const getLiveWorkoutById = async (id: number) =>
  await api<LiveWorkoutResponse>(Methods.get, `/live-workouts/${id}`);

export const createLiveWorkout = async (data: CreateLiveWorkoutRequest) =>
  await api<CreateLiveWorkoutResponse>(Methods.post, '/live-workouts', data);

export const editLiveWorkout = async (
  data: CreateLiveWorkoutRequest,
  id: number,
): Promise<ILiveWorkout> =>
  api(Methods.patch, `/live-workouts/${id}`, data).then(
    (res) => res as ILiveWorkout,
  );

export const deleteLiveWorkoutApi = async (id: number): Promise<ILiveWorkout> =>
  api(Methods.delete, `/live-workouts/${id}`).then(
    (res) => res as ILiveWorkout,
  );
