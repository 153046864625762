import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { PRIMARY, SECONDARY } from 'constants/theme';

export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

export const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const VideoInfoWrap = styled.div`
  margin: 16px 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
`;

export const Primary = styled.div`
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: ${PRIMARY};
`;

export const Secondary = styled.div`
  fone-size: 18px;
  font-weight: 400;
  color: ${PRIMARY};
`;

export const NoteHeader = styled.div`
  margin-top: 12px;
  fone-size: 18px;
  font-weight: 400;
  color: ${SECONDARY};
`;

export const Note = styled.div`
  fone-size: 16px;
  font-weight: 400;
  color: ${PRIMARY};
`;

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${PRIMARY};
`;
