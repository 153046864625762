import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import useThemeContext from 'hooks/useThemeContext';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

interface Props {
  onChange: (isCompleted: boolean) => void;
  fontColor?: string;
  fontWeight?: string;
  invalidIconColor?: string;
  backgroundColor?: string;
}

const PaymentInput: React.FC<Props> = ({
  onChange,
  fontColor,
  fontWeight,
  invalidIconColor,
  backgroundColor,
}) => {
  const theme = useThemeContext();

  return (
    <CardElement
      options={{
        style: {
          base: {
            fontSize: '16px',
            fontFamily: 'Raleway',
            color: fontColor || theme.main.primary,
            '::placeholder': {
              color: fontColor || theme.main.primary,
            },
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: fontColor || theme.main.primary,
              backgroundColor: backgroundColor || theme.main.lightPrimary,
            },
            fontWeight: fontWeight || 'normal',
            backgroundColor: backgroundColor || theme.main.primary,
          },
          invalid: {
            iconColor: invalidIconColor || theme.main.lightSecondary,
            color: theme.main.secondary,
          },
        },
      }}
      onChange={(event: StripeCardElementChangeEvent) =>
        onChange(event.complete)
      }
    />
  );
};

export default PaymentInput;
