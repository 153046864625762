import * as S from 'styles/video.styles';
import * as I from 'components/shared/Icons';
import { useHistory, useLocation } from 'react-router-dom';
import { PRIMARY } from 'constants/theme';
import { ViewWorkout as ViewWorkoutSection } from 'components/ViewWorkout';
import { WorkoutResponse } from '@solin-fitness/types';
import Layout from 'components/Layout';

const ViewWorkout = () => {
  const location = useLocation<{ workoutData: WorkoutResponse }>();
  const workoutData = location.state.workoutData;
  const history = useHistory();

  if (!workoutData) {
    return (
      <Layout title="View Workout">
        <S.Header onClick={history.goBack}>
          <I.ChevronLeft lg color={PRIMARY} />
          <div style={{ marginLeft: 12 }}>Back</div>
        </S.Header>
        <S.EmptyState>
          Cannot find workout. Please try again and contact a Solin rep if issue
          continues.
        </S.EmptyState>
      </Layout>
    );
  }

  return (
    <Layout title="View Workout">
      <S.Header onClick={history.goBack}>
        <I.ChevronLeft lg color={PRIMARY} />
        <div style={{ marginLeft: 12 }}>Back</div>
      </S.Header>
      <ViewWorkoutSection workoutData={workoutData} />
    </Layout>
  );
};

export default ViewWorkout;
