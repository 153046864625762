import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Typography, CircularProgress } from '@material-ui/core';
import { Interval } from '@solin-fitness/types';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { PageSubscriptionRequest, createPageSubscription } from 'queries/page';
import { CurrencyCode } from '@solin-fitness/types/build/api/utils';
import { FormTextField } from 'components/FormTextField';
import { FormRadioButton } from 'components/FormRadioButton';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Aside } from 'components/ManageClassPrices/CreateClassPrice';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required().min(1),
  description: yup.string().required().min(1),
  amount: yup.number().required().min(1),
  interval: yup.string().required(),
});

const initialSubscriptionValues = {
  name: '',
  description: '',
  amount: 0.0,
  interval: '',
};

// TODO: better error handling
const CreateSubscription: React.FC<Props> = ({ isOpen, onCancel }) => {
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
  const createSubscription = useMutation(
    (data: PageSubscriptionRequest) => createPageSubscription(data),
    {
      onError: (err: any) =>
        setError(
          err?.message || 'Uh oh, mistakes were made. Please notify a dev',
        ),
      onSuccess: () => queryClient.invalidateQueries('creatorPage'),
    },
  );

  return (
    <Modal open={isOpen} onCancel={onCancel}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        Create Subscription
      </Typography>
      {!!!error && (
        <Aside isError={false}>
          All values below are <strong>final</strong>. You can only toggle if a
          subscription is <strong>active</strong> once created.
        </Aside>
      )}
      {!!error && <Aside isError={true}>{error}</Aside>}
      <Formik
        initialValues={initialSubscriptionValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          // async call
          await createSubscription.mutateAsync({
            ...data,
            type: data.interval === Interval.month ? 'monthly' : 'annual',
            isActive: true,
            amount: Math.round(data.amount * 10000) / 100,
            currency: CurrencyCode.USD,
          });
          setSubmitting(false);
          resetForm();
          onCancel();
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ marginBottom: 0 }}>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6" children="Subscription Information" />
              <FormTextField label="Name" name="name" type="input" />
              <FormTextField
                label="Description"
                name="description"
                type="input"
              />
            </div>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6" children="Pricing" />
              <FormTextField
                label="Price"
                name="amount"
                type="number"
                placeholder="0.00"
              />
            </div>
            <div style={{ marginBottom: 36 }}>
              <Typography variant="h6" children="Billing Interval" />
              <FormRadioButton
                label="Monthly"
                name="interval"
                type="radio"
                value={Interval.month}
              />
              <FormRadioButton
                label="Annual"
                name="interval"
                type="radio"
                value={Interval.year}
              />
            </div>
            <Button
              fullWidth
              disabled={isSubmitting}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              style={{
                borderRadius: 35,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <Typography variant="h6">Submit</Typography>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateSubscription;
