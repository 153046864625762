import { EventResponse } from '@solin-fitness/types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Image, Transformation } from 'cloudinary-react';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import useThemeContext from 'hooks/useThemeContext';
import Calendar from 'components/shared/Icons/Calendar';
import { EditIcon } from 'components/shared/Icons/Edit';
import { Row } from 'components/Row';
import { getDisplayPrice } from 'services/currency-codes';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { Link, useHistory } from 'react-router-dom';
import { getPageUrl } from 'services/auth';

const Container = styled.div`
  position: relative;
  width: 300px;
  border-radius: 20px;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DateBox = styled.div`
  position: absolute;
  right: 20px;
  top: 140px;
  background-color: #fff;

  width: 50px;
  height: 50px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const DateBoxHeader = styled.p`
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background-color: ${(p) => p.theme.main.secondary};
  letter-spacing: 0.5px;
  text-align: center;
  padding: 2px 0px;
`;

const DateBoxNumber = styled.p`
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 600;
  color: #000;
  text-align: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px;
  flex: 1;
`;

const EditIconWrap = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  border-radius: 50%;
  background-color: #fff;
  opacity: 0.7;
  padding: 8px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const CopyEventLinkButton = styled.button<{ isCopied?: boolean }>`
  margin-top: 6px;
  border: none;
  font-family: 'Raleway';
  font-size: 16px;
  padding: 12px 18px;
  color: #fff;
  background-color: ${(p) =>
    p.isCopied ? p.theme.main.primary : p.theme.main.lightPrimary};
  border-radius: 20px;
  text-align: center;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

const JoinRoomButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 12px; */
`;

const JoinRoomButton = styled(Link)`
  font-family: 'Roboto';
  font-size: 18px;
  padding: 8px 18px;
  color: ${(p) => p.theme.main.primary};
  text-align: center;
  border: 2px solid transparent;

  &:hover {
    border-radius: 20px;
    border: 2px solid ${(p) => p.theme.main.primary};
  }
`;

const TitleLink = styled(Link)`
  color: #000;

  &:hover {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

const TextContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

interface Props {
  event: EventResponse;
  isPastEvent?: boolean;
}

/**
 * Event card will show preview of event
 * Will show the background image, title, description, price,
 * date, number of users who purchased
 */

const EventCard: React.FC<Props> = ({ event, isPastEvent }) => {
  const history = useHistory();
  const theme = useThemeContext();
  const {
    backgroundImagePublicId,
    title,
    startDate,
    amount,
    location,
    usersThatPurchased,
  } = event;

  const [copiedLink, setCopiedLink] = useState<boolean>(false);

  useEffect(() => {
    if (copiedLink) {
      setTimeout(() => {
        if (navigator) {
          const page = getPageUrl();
          // todo: maybe move this to an env var if we care to
          const path = `https://solin.stream/${page}/event/${event.id}`;
          navigator.clipboard.writeText(path);
          setCopiedLink(false);
        }
      }, 500);
    }
  }, [copiedLink]);

  const date = DateTime.fromJSDate(new Date(startDate));
  const calendarDateString = date.toFormat('DDDD @ t');
  const month = date.toFormat('MMM');
  const day = date.toFormat('d');

  return (
    <Container>
      <Image
        cloudName="solin-fitness"
        publicId={backgroundImagePublicId}
        width={300}
        height={175}
      >
        <Transformation
          crop="thumb"
          width={300}
          height={175}
          fetchFormat="auto"
          quality="auto:best"
        />
      </Image>
      <InfoContainer>
        <TitleLink
          to={{
            pathname: `/events/${event.id}`,
            state: {
              event,
            },
          }}
        >
          <Typography variant="h6">
            <TextContainer>
              {title}
            </TextContainer>
          </Typography>
        </TitleLink>
        <Row justifyContent="flex-start">
          <Calendar width={16} height={16} color={theme.main.secondary} />
          <Typography
            variant="caption"
            style={{ paddingLeft: 8, color: theme.main.secondary }}
          >
            {calendarDateString}
          </Typography>
        </Row>
        {/* <div style={{ flex: 4, maxHeight: 135, overflow: 'hidden' }}>
          <Typography
            variant="body2"
            style={{ paddingTop: 24, textOverflow: 'ellipsis' }}
          >
            {description}
          </Typography>
        </div> */}
        <Row justifyContent="space-between" style={{ paddingTop: 24 }}>
          <Typography variant="caption">Paid Event</Typography>
          <Typography variant="caption" style={{ fontWeight: 700 }}>
            {getDisplayPrice(amount, 'USD')}
          </Typography>
        </Row>
        <Row justifyContent="space-between">
          <Typography variant="caption">Location</Typography>
          <Typography variant="caption" style={{ fontWeight: 700 }}>
            {location}
          </Typography>
        </Row>
        <ButtonContainer>
          {!isPastEvent ? (
            <CopyEventLinkButton
              isCopied={copiedLink}
              onClick={() => setCopiedLink(true)}
            >
              {copiedLink
                ? 'Copied to clipboard!'
                : 'Copy link to post to following!'}
            </CopyEventLinkButton>
          ) : null}
          {!!usersThatPurchased?.length ? (
            <Row justifyContent="space-between">
              <Typography variant="caption">Purchases</Typography>
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                {usersThatPurchased?.length}
              </Typography>
            </Row>
          ) : null}
          {!isPastEvent ? (
            <JoinRoomButtonWrap>
              <JoinRoomButton
                to={{
                  pathname: `/events/${event.id}/room`,
                  state: {
                    event,
                  },
                }}
              >
                Join Event Room
              </JoinRoomButton>
            </JoinRoomButtonWrap>
          ) : null}
        </ButtonContainer>
      </InfoContainer>
      <DateBox>
        <DateBoxHeader>{month}</DateBoxHeader>
        <DateBoxNumber>{day}</DateBoxNumber>
      </DateBox>
      {!isPastEvent ? (
        <Tooltip label="Edit Event">
          <EditIconWrap
            onClick={() =>
              history.push(`events/${event.id}/edit`, {
                event,
              })
            }
          >
            <EditIcon color={theme.main.primary} />
          </EditIconWrap>
        </Tooltip>
      ) : null}
    </Container>
  );
};

export default EventCard;
