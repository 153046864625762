import React from 'react';
import { DateTime } from 'luxon';
import * as S from './LiveWorkoutDetail.styles';
import LiveWorkoutUsers from './LiveWorkoutUsers';
import { LiveWorkoutResponse } from '@solin-fitness/types';
import JoinButton from 'components/JoinButton';
import Chip from '@material-ui/core/Chip';
import CloudinaryImage from 'components/CloudinaryImage';

interface IProps {
  liveWorkoutData: LiveWorkoutResponse | null;
}

const LiveWorkoutDetail = ({
  liveWorkoutData,
}: IProps): React.ReactElement | null => {
  if (!liveWorkoutData) {
    return null;
  }

  const {
    user,
    title,
    startDate,
    description,
    length,
    usersAttending,
    tags,
    workoutVideoFormat,
    attendees,
  } = liveWorkoutData;
  const currDate = new Date();
  const startDatePassed = currDate > new Date(startDate);

  const startDateFormatted = DateTime.fromISO(startDate).toFormat('DD t');

  return (
    <S.Container>
      <S.Wrap>
        <S.Row>
          <S.TrainerImage>
            <CloudinaryImage
              publicId={user.profile.profilePictureUrlPublicId}
              width={180}
              height={180}
              alt={'live workout trainer profile image'}
              fetchFormat={'auto'}
              quality={'auto:best'}
              crop={'fill'}
              gravity={'auto:face'}
            />
          </S.TrainerImage>
          <S.Col>
            <S.WorkoutTitle>{title}</S.WorkoutTitle>
            <S.TrainerName>
              with {user.profile.firstName} {user.profile.lastName}
            </S.TrainerName>
            <S.WorkoutDate>{startDateFormatted}</S.WorkoutDate>
            {/* TODO - fix this */}
            <JoinButton
              workout={liveWorkoutData as unknown as LiveWorkoutResponse}
            ></JoinButton>
          </S.Col>
        </S.Row>
        <S.SectionTitle>Description</S.SectionTitle>
        <S.SectionText>{description}</S.SectionText>
        <S.SectionTitle>Workout Type</S.SectionTitle>
        <S.SectionText>
          {workoutVideoFormat === 'zoomLink'
            ? 'Zoom class'
            : 'One-to-Many (See Trainer Only)'}
        </S.SectionText>
        <S.SectionTitle>Duration</S.SectionTitle>
        <S.SectionText>{length} minutes</S.SectionText>
        <S.SectionTitle>Tags</S.SectionTitle>
        <S.SectionText>
          {tags &&
            tags.map((tag: string, index) => (
              <Chip
                label={tag}
                key={index}
                variant="outlined"
                style={S.ChipStyle}
              />
            ))}
        </S.SectionText>

        <LiveWorkoutUsers
          startDatePassed={startDatePassed}
          users={startDatePassed ? attendees : usersAttending}
        />
      </S.Wrap>
    </S.Container>
  );
};

export default LiveWorkoutDetail;
