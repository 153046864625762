import React from 'react';
import styled from 'styled-components';
import * as Colors from 'constants/theme';
import CloudinaryImage from 'components/CloudinaryImage';

export const Image = styled.div`
  img {
    border-radius: 50%;
    margin-bottom: 0;
    object-fit: cover;
  }
`;

export const Initials = styled.div<{
  imageSize?: { width: number; height: number };
}>`
  width: ${({ imageSize }) => (imageSize && imageSize.width) || 40}px;
  height: ${({ imageSize }) => (imageSize && imageSize.height) || 40}px;
  border-radius: 50%;
  font-size: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: default;
`;

interface IProps {
  imageSize?: { width: number; height: number };
  firstName: string;
  lastName: string;
  profilePictureUrlPublicId: string | null;
  userId: number;
}

const ProfileImage = ({
  firstName,
  lastName,
  profilePictureUrlPublicId,
  imageSize,
  userId,
}: IProps): React.ReactElement | null => {
  const initials = `${firstName ? firstName.charAt(0) : ''}${
    lastName ? lastName.charAt(0) : ''
  }`;

  const getInitialsColor = () => {
    if (userId % 7 === 0) {
      return Colors.PRIMARY;
    }
    if (userId % 3 === 0) {
      return Colors.SECONDARY;
    }
    if (userId % 4 === 0) {
      return Colors.LIGHT_PRIMARY;
    }
    if (userId % 5 === 0) {
      return Colors.LIGHT_SECONDARY;
    }
    return Colors.PRIMARY;
  };

  return (
    <>
      {profilePictureUrlPublicId ? (
        <Image>
          <CloudinaryImage
            publicId={profilePictureUrlPublicId}
            alt={'profile image'}
            width={imageSize?.width || 50}
            height={imageSize?.height || 50}
            fetchFormat={'auto'}
            quality={'auto:best'}
            crop={'fill'}
            gravity={'auto:face'}
          />
        </Image>
      ) : (
        <Initials
          style={{ backgroundColor: getInitialsColor() }}
          imageSize={imageSize}
        >
          {initials}
        </Initials>
      )}
    </>
  );
};

export default ProfileImage;
