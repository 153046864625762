import { Switch, Route, useRouteMatch } from 'react-router';
import Layout from 'components/Layout';
import {
  FullProgram,
  ProgramBuilderProvider,
} from 'context/ProgramBuilderContext';
import CreateProgram from 'components/Programs/CreateProgram/CreateProgram';
import { useLocation } from 'react-router-dom';
import ViewPrograms from 'components/Programs/ViewPrograms/ViewPrograms';
import { usePrograms } from 'queries/programs';
import useConfig from 'hooks/useConfig';
import { isMobile } from 'react-device-detect';
import ProgramSignUpsTable from 'components/Programs/ProgramSignUpsTable/ProgramSignUpsTable';
import { Loading } from 'components/Loading';

interface ProgramsLocationState {
  program: FullProgram;
  programId: string;
}

const Programs = () => {
  const { path } = useRouteMatch();
  const location = useLocation<ProgramsLocationState>();
  const program = location.state?.program;
  const programId = location.state?.programId;

  const { data, isLoading } = usePrograms();

  const programs = (data || []).sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const { data: config } = useConfig();

  const programResponse = programs?.find((p) => p.id === +programId);

  const workoutTags = config?.config.workoutTags || [];

  return (
    <Switch>
      <Route exact path={path}>
        <Layout title="Programs & Challenges">
          {isLoading ? <Loading /> : <ViewPrograms programs={programs || []} />}
        </Layout>
      </Route>
      <Route path={`${path}/create`}>
        <Layout title="Programs & Challenges">
          <ProgramBuilderProvider
            fullProgram={program}
            programId={programId}
            workoutTags={workoutTags}
          >
            <CreateProgram />
          </ProgramBuilderProvider>
        </Layout>
      </Route>
      <Route path={`${path}/${programId}/edit`}>
        <Layout title="Programs & Challenges">
          <ProgramBuilderProvider
            fullProgram={program}
            programId={programId}
            workoutTags={workoutTags}
            isEditing
          >
            <CreateProgram />
          </ProgramBuilderProvider>
        </Layout>
      </Route>
      <Route path={`${path}/${programId}`}>
        <Layout title="Programs & Challenges">
          <ProgramSignUpsTable program={programResponse!} />
        </Layout>
      </Route>
    </Switch>
  );
};

export default Programs;
