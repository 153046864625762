import React, { useMemo, useState, useEffect } from 'react';
import { WorkoutResponse } from '@solin-fitness/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Column, Row } from 'react-table';
import { api, Methods } from 'services/api';
import * as Routes from 'constants/routes';
import * as I from 'components/shared/Icons';
import * as S from './ManageWorkoutsTable.styles';
import { makeStyles } from '@material-ui/core/styles';
import { LIGHT_SECONDARY, PRIMARY, SECONDARY } from 'constants/theme';
import { useHistory } from 'react-router-dom';
import { PaginationTable } from 'components/Table';
import Layout from 'components/Layout';
import WorkoutOverviewCell from 'components/ManageWorkoutsTable/WorkoutOverviewCell';
import WorkoutActionsCell from 'components/ManageWorkoutsTable/WorkoutActionsCell';
import { DateTime } from 'luxon';
import { Loading } from 'components/Loading';
import { useGetWorkouts } from 'queries/hooks/useGetWorkouts';
import { CircularProgress } from '@material-ui/core';
import { searchWorkouts } from 'services/workouts-search';
import useDebounce from 'hooks/useDebounce';

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    marginRight: 36,
  },
  save: {
    color: SECONDARY,
    marginRight: 12,
  },
});

const ManageWorkoutsTable: React.FC = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { isLoading, data: workouts, isFetching } = useGetWorkouts();
  const history = useHistory();
  const [isPublishing, setIsPublishing] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const data = useMemo<WorkoutResponse[]>(
    () => (workouts ? workouts : []),
    [workouts],
  );
  const [searchableWorkouts, setSearchableWorkouts] =
    useState<WorkoutResponse[]>(data);

  useEffect(() => {
    setSearchableWorkouts(data);
  }, [workouts]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      const result = searchWorkouts(data, searchTerm, selectedCategories);
      setSearchableWorkouts(result);
      setTimeout(() => setIsSearching(false), 1000);
    } else {
      setSearchableWorkouts(data);
    }
  }, [debouncedSearchTerm]);

  const publishWorkout = useMutation(
    async (workout: WorkoutResponse) =>
      await api(Methods.patch, `/workouts/${workout.id}`, {
        userId: workout.user.id,
        videoUrl: workout.videos[0].videoUrl,
        workout: {
          published: !workout.published,
        },
      }),
    {
      onSuccess: () => {
        setIsPublishing(false);
        queryClient.invalidateQueries('workouts');
      },
    },
  );

  const deleteWorkout = useMutation(
    async (workout: WorkoutResponse) =>
      await api(Methods.delete, `/workouts/${workout.id}`),
    {
      onSuccess: () => queryClient.invalidateQueries('workouts'),
    },
  );

  const customSort = useMemo(
    () => (rowA: Row<WorkoutResponse>, rowB: Row<WorkoutResponse>) => {
      const aTitle = rowA.values.title;
      const bTitle = rowB.values.title;
      return aTitle < bTitle ? -1 : 1;
    },
    [],
  );

  const columns = useMemo<Column<WorkoutResponse>[]>(
    () => [
      {
        Header: 'Workout',
        accessor: 'title',
        sortType: customSort,
        Cell: ({ row }: { row: { original: WorkoutResponse } }) => (
          <WorkoutOverviewCell
            thumbnailPublicId={row.original.thumbnailPublicId}
            title={row.original.title}
            description={row.original.description}
            isDrop={row.original.isDrop}
            workoutData={row.original}
          />
        ),
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (info: any) =>
          DateTime.fromISO(
            new Date(info.row.original.createdAt).toISOString(),
          ).toFormat('ff'),
      },
      {
        Header: 'Upload Status',
        accessor: 'uploadStatus',
      },
      {
        Header: 'Actions',
        id: 'action',
        disableSortBy: true,
        Cell: (info: any) => (
          <WorkoutActionsCell
            workout={info.row.original}
            publishWorkout={(workout: WorkoutResponse) => {
              setIsPublishing(true);
              publishWorkout.mutate(workout);
            }}
            deleteWorkout={(workout: WorkoutResponse) =>
              deleteWorkout.mutate(workout)
            }
          />
        ),
      },
    ],
    [],
  );

  return (
    <Layout title={'Manage Workouts'}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* {isPublishing || isFetching ? (
            <S.LoaderOverlay>
              <CircularProgress size={45} />
            </S.LoaderOverlay>
          ) : null} */}

          <S.ContainerOuter>
            <S.SearchContainerInner
              justifyContent="space-between"
              hasSearch={!!searchTerm}
            >
              <S.SearchInput
                hasSearch={!!searchTerm}
                type="input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                placeholder="Search by title..."
              />

              {isSearching ? (
                <CircularProgress size={24} className={classes.root} />
              ) : (
                <I.Search
                  color="#FFF"
                  style={{ marginRight: 36, opacity: 0.6 }}
                />
              )}
            </S.SearchContainerInner>
          </S.ContainerOuter>

          <PaginationTable<WorkoutResponse>
            columns={columns}
            data={searchableWorkouts}
          />
        </>
      )}
    </Layout>
  );
};
export default ManageWorkoutsTable;
