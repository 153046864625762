/* eslint-disable */
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import 'styles/ReactHorizontalDatePicker.css';
import {
  format,
  addWeeks,
  subWeeks,
  addDays,
  subDays,
  isSameDay,
  isBefore,
} from 'date-fns';
import { isMobile } from 'react-device-detect';
import * as Colors from 'constants/theme';
import styled from 'styled-components';
import { DateTime } from 'luxon';
export const StyledDay = styled.div<{
  isSelected: boolean;
  isDisabled: boolean;
}>`
  border: 2px solid
    ${({ isSelected }) => (isSelected ? Colors.themeColors.primary : 'none')};
  color: ${({ isSelected, isDisabled }) =>
    isSelected
      ? Colors.themeColors.primary
      : isDisabled
      ? 'color: rgb(176, 176, 176)'
      : Colors.themeColors.textColor};
`;
export default React.memo(function ReactHorizontalDatePicker({
  //@ts-ignore
  enableDays,
  //@ts-ignore
  enableScroll,
  //@ts-ignore
  selectedDay,
}) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [headingDate, setHeadingDate] = useState(new Date())
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [currentDate] = useState(new Date());
  const scrollWidth = 250;

  enableScroll = enableScroll || false;
  enableDays = enableScroll === true ? enableDays || 90 : isMobile ? 3 : 9;
  // const heading_dateFormat = 'MMM yyyy'

  const applyStyles = (day: any) => {
    const classes = [];
    if (isSameDay(day, selectedDate)) {
      classes.push(' date-day-Item-selected');
    }

    if (isBefore(day, currentDate)) {
      classes.push(' date-day-item-disabled');
    }
    return classes.join(' ');
  };

  const _handlePosition = (pos: any) => {
    const currentPosition = pos.currentPosition;
    const previousPosition = pos.previousPosition;

    if (previousPosition == 'inside' && currentPosition == 'above') {
      // setHeadingDate(date)
    }
    if (previousPosition == 'above' && currentPosition == 'inside') {
      // setHeadingDate(addDays(date, -1))
    }
  };

  const _verticalList = () => {
    const _dayFormat = 'E';
    const _dateFormat = 'dd';
    // const _monthFormat = 'MMM'
    const _verticalListItems = [];
    const _startDay = subDays(currentWeek, 1);

    for (let i = 0; i < enableDays; i++) {
      const _day = format(addDays(_startDay, i), _dayFormat);
      const _date = format(addDays(_startDay, i), _dateFormat);
      // const _month = format(addDays(_startDay, i), _monthFormat)

      const isSelected = isSameDay(addDays(_startDay, i), selectedDate);
      const isDisabled = isBefore(addDays(_startDay, i), currentDate);

      _verticalListItems.push(
        <Waypoint
          key={i}
          horizontal={true}
          onPositionChange={(pos) =>
            // @ts-ignore
            _date == 1 ? _handlePosition(pos, addDays(_startDay, i)) : ''
          }
        >
          <div className="wrapper">
            {/* @ts-ignore */}
            {format(addDays(_startDay, i), _dateFormat) == 1 ? (
              <div
                className="scroll-head"
                style={{ color: Colors.themeColors.textColor }}
              >
                {format(addDays(_startDay, i), 'MMMM').toUpperCase()}
              </div>
            ) : (
              <div className="blank-space-div"></div>
            )}
            <StyledDay
              className={`datepicker-date-day-Item wrapper ${applyStyles(
                addDays(_startDay, i),
              )}`}
              isSelected={isSelected}
              isDisabled={isDisabled}
              data-test={`liveScheduleDateOption${i}`}
              onClick={() => onDateClick(addDays(_startDay, i))}
            >
              <div className="datepicker-day-label ">
                {DateTime.fromJSDate(addDays(_startDay, i)).toFormat('d')}
              </div>
              <div className="datepicker-date-label ripple ">{_date}</div>
            </StyledDay>
          </div>
        </Waypoint>,
      );
    }

    return (
      <div
        id="container"
        className={
          enableScroll === true
            ? ' datepicker-datelist-scrollable'
            : ' datepicker-dateList'
        }
      >
        {_verticalListItems}
      </div>
    );
  };

  const onDateClick = (day: any) => {
    setSelectedDate(day);
    selectedDay(day);
  };

  const nextScroll = () => {
    if (enableScroll) {
      // @ts-ignore
      document.getElementById('container').scrollLeft += scrollWidth;
    } else {
      if (isMobile) {
        setCurrentWeek(addDays(currentWeek, 3));
      } else {
        setCurrentWeek(addWeeks(currentWeek, 1));
      }
    }
    enableScroll;
  };

  const prevScroll = () => {
    enableScroll
      ? // @ts-ignore
        (document.getElementById('container').scrollLeft -= scrollWidth)
      : isMobile
      ? setCurrentWeek(subDays(currentWeek, 3))
      : setCurrentWeek(subWeeks(currentWeek, 1));
  };

  const dateDiff = DateTime.fromJSDate(new Date())
    .diff(DateTime.fromJSDate(currentWeek), 'days')
    .toObject();

  const showPrevIcon = dateDiff && dateDiff?.days && dateDiff?.days < 0;

  return (
    <div className="datepicker-strip">
      <span
        className="datepicker-month-label "
        style={{ color: Colors.themeColors.primary }}
        data-test={'liveWorkoutDate'}
      >
        {DateTime.fromJSDate(selectedDate).toFormat('cccc, LLLL dd')}
      </span>
      <div className="datepicker">
        <div className="wrapper" style={{ marginTop: 10 }}>
          <div className="scroll-head">{/*format(headingDate, 'MMM')*/}</div>
          {showPrevIcon ? (
            <div className="button-previous">
              {' '}
              <button
                className="datepicker-button-previous"
                onClick={prevScroll}
                style={{ color: Colors.themeColors.primary }}
              >
                ❯
              </button>
            </div>
          ) : null}
        </div>
        {_verticalList()}
        <div className="wrapper" style={{ marginTop: 10 }}>
          <div className="blank-space-div"></div>

          <div className="button-previous">
            {' '}
            <button
              className="datepicker-button-next"
              onClick={nextScroll}
              style={{ color: Colors.themeColors.primary }}
            >
              ❯
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
