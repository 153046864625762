import { DialogOverlay, DialogContent } from '@reach/dialog';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsl(0deg 0% 0% / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  /* we can't change this since the Layout header uses a z-index */
  z-index: 100;
`;

const Content = styled(DialogContent)`
  position: relative;
  width: var(--width, 50%);
  background: white;
  padding: 30px;
  border-radius: 10px;
  max-height: 95%;
  overflow-y: auto;
`;

interface Props {
  width?: number;
  open: boolean;
  onCancel: () => void;
  children: React.ReactNode | React.ReactNode[];
  bypassFocusLock?: boolean;
}

const Dialog = ({
  width,
  open,
  onCancel,
  bypassFocusLock,
  children,
}: Props) => (
  <Overlay
    isOpen={open}
    onDismiss={onCancel}
    dangerouslyBypassFocusLock={bypassFocusLock}
  >
    <Content style={{ '--width': width ? width + 'px' : undefined }}>
      <IconButton
        aria-label="close"
        color="default"
        onClick={onCancel}
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      {children}
    </Content>
  </Overlay>
);

export default Dialog;
