import {
  CalendarResponse,
  LiveWorkoutResponse,
  WorkoutResponse,
} from '@solin-fitness/types';
import React, { useEffect, useMemo, useRef } from 'react';
import CalendarTile from '../CalendarTile/CalendarTile';
import * as I from 'components/shared/Icons';
import * as S from 'components/DropCalendar/DropCalendar.styles';
import { PRIMARY } from 'constants/theme';
import { groupBy } from 'ramda';
import { useWorkoutDrop } from 'hooks/useWorkoutDrop';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface Props {
  calendar: CalendarResponse[];
  days: Date[];
}

export interface MinCalendar {
  type: 'liveWorkout' | 'workoutDrop' | 'event';
  id: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  thumbnailPublicId: string;
  title: string;
  time: 'am' | 'pm';
}

const CalendarCarousel: React.FC<Props> = (props) => {
  const { calendar, days } = props;
  const calendarCarouselRef = useRef<Carousel>(null);
  const { calendarIndex, handleCalendarNext, handleCalendarPrev } =
    useWorkoutDrop();

  const minCalendar = useMemo<MinCalendar[]>(
    () =>
      calendar.map((val) => {
        const data = val.data as WorkoutResponse | LiveWorkoutResponse;
        const date = new Date(val.date);
        const hour = date.getHours();
        // hour > 12 => PM | hour < 12 => am
        const time = hour >= 12 ? 'pm' : 'am';
        return {
          type: val.type as unknown as 'liveWorkout' | 'workoutDrop',
          id: data.id,
          thumbnailPublicId: data.thumbnailPublicId,
          title: data.title,
          month: date.getMonth(),
          day: date.getDate(),
          hour: [0, 12].includes(hour) ? 12 : hour % 12,
          minute: date.getMinutes(),
          time,
        };
      }),
    [calendar],
  );

  const sortedCalendar: Record<string, MinCalendar[]> = groupBy(
    (a: MinCalendar) => `${a.month}.${a.day}`,
    minCalendar,
  );

  return (
    <div style={{ position: 'relative' }}>
      <Carousel
        ref={calendarCarouselRef}
        responsive={{
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 7,
            slidesToSlide: 7,
          },
        }}
        swipeable={false}
        draggable={false}
        arrows={false}
      >
        {days.map((day) => (
          <CalendarTile
            key={day.getTime().toString()}
            date={day}
            calendar={
              sortedCalendar[`${day.getMonth()}.${day.getDate()}`] ?? []
            }
          />
        ))}
      </Carousel>
      <S.LeftAbsoluteContainer>
        <S.ArrowButton
          style={{ left: -25, opacity: calendarIndex === 0 ? 0.5 : 1 }}
          disabled={calendarIndex === 0}
          onClick={() => {
            if (calendarCarouselRef.current) {
              const currentSlide =
                calendarCarouselRef.current.state.currentSlide;
              calendarCarouselRef.current.goToSlide(currentSlide - 7);
              handleCalendarPrev();
            }
          }}
        >
          <I.ChevronLeft color={'#CDCDCD'} style={{ marginLeft: -4 }} />
        </S.ArrowButton>
      </S.LeftAbsoluteContainer>
      <S.RightAbsoluteContainer>
        <S.ArrowButton
          isRight
          disabled={calendarIndex === 5}
          style={{ right: -25, opacity: calendarIndex === 5 ? 0.5 : 1 }}
          onClick={() => {
            if (calendarCarouselRef.current) {
              const currentSlide =
                calendarCarouselRef.current.state.currentSlide;
              calendarCarouselRef.current.goToSlide(currentSlide + 7);
              handleCalendarNext();
            }
          }}
        >
          <I.ChevronRight color={'#CDCDCD'} style={{ marginRight: -4 }} />
        </S.ArrowButton>
      </S.RightAbsoluteContainer>
    </div>
  );
};

export default CalendarCarousel;
