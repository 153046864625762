import { DateTime } from 'luxon';
import React, {
  useState,
  ReactNode,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  ScheduleWorkoutDropRequest,
  scheduleWorkoutDrop,
} from 'queries/workouts';
import { getStartOfWeek, getDay } from 'util/date-fns';

export enum TimeOfDay {
  AM = 'am',
  PM = 'pm',
}

interface WorkoutDropInterface {
  isScheduling: boolean;
  isSaving: boolean;
  error: string;
  time: number[];
  ampm: TimeOfDay;
  calendarDays: Date[];
  weeks: string[];
  calendarIndex: number;
  workoutPageIndex: number;
  isRemovingDrop: boolean;
  handleWorkoutSelected: (val: number) => void;
  handleScheduling: (val: boolean) => void;
  handleTime: (val: number[]) => void;
  handleAmPm: (val: TimeOfDay) => void;
  handleSaveWorkoutDrop: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void>;
  resetScheduleTimes: () => void;
  handleSelectedDate: (val: Date) => void;
  handleCalendarIndex: (val: number) => void;
  handleWorkoutPageIndex: (val: number) => void;
  handleRemovingDrop: (val: boolean) => void;
  handleCalendarNext: () => void;
  handleCalendarPrev: () => void;
  handleCalendarSkip: (val: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const WorkoutDropContext = createContext<WorkoutDropInterface>(undefined!);

interface Props {
  children: ReactNode | ReactNode[];
}

const WorkoutDropProvider = ({ children }: Props) => {
  const [isScheduling, setIsScheduling] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRemovingDrop, setIsRemovingDrop] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [calendarDays, setCalendarDays] = useState<Date[]>([]);
  const [weeks, setWeeks] = useState<string[]>([]);
  const [calendarIndex, setCalendarIndex] = useState<number>(0);
  const [workoutPageIndex, setWorkoutPageIndex] = useState<number>(1);

  const currHour = useMemo(() => {
    const hour = new Date().getHours();
    return (hour % 12) + 1;
  }, []);
  const [selectedWorkout, setSelectedWorkout] = useState<number | undefined>(
    undefined,
  );
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [time, setTime] = useState<number[]>([currHour, 0]);
  const [ampm, setAmPm] = useState<TimeOfDay>(TimeOfDay.PM);
  const [releaseDate, setReleaseDate] = useState<Date | undefined>(undefined);
  const queryClient = useQueryClient();

  const handleWorkoutSelected = (val: number) => setSelectedWorkout(val);
  const handleScheduling = (val: boolean) => setIsScheduling(val);
  const handleTime = (val: number[]) => setTime(val);
  const handleAmPm = (val: TimeOfDay) => setAmPm(val);
  const handleSelectedDate = (val: Date) => setSelectedDate(val);
  const handleCalendarIndex = (num: number) =>
    setCalendarIndex(calendarIndex + num);
  const handleCalendarNext = () => setCalendarIndex(calendarIndex + 1);
  const handleCalendarPrev = () => setCalendarIndex(calendarIndex - 1);
  const handleCalendarSkip = (val: number) => setCalendarIndex(val);
  const handleWorkoutPageIndex = (num: number) => setWorkoutPageIndex(num);
  const handleRemovingDrop = (val: boolean) => setIsRemovingDrop(val);

  useEffect(() => {
    const startOfWeek = getStartOfWeek();
    const firstDay = getDay(startOfWeek, -6);
    const lastDay = getDay(startOfWeek, 35);
    const array: Date[] = [];
    for (const d = firstDay; d <= lastDay; d.setDate(d.getDate() + 1)) {
      const temp = new Date(d);
      array.push(temp);
    }
    // setCalendarDays(array);
  }, []);

  useEffect(() => {
    const startOfWeek = getStartOfWeek();
    const firstDay = getDay(startOfWeek, 1);
    const lastDay = getDay(startOfWeek, 35);
    const weeksArray: string[] = [];
    const array: Date[] = [];
    for (const d = firstDay; d <= lastDay; d.setDate(d.getDate() + 7)) {
      const start = new Date(d);
      const mon = new Date(start);
      const tue = new Date(start.setDate(start.getDate() + 1));
      const wed = new Date(start.setDate(start.getDate() + 1));
      const thu = new Date(start.setDate(start.getDate() + 1));
      const fri = new Date(start.setDate(start.getDate() + 1));
      const sat = new Date(start.setDate(start.getDate() + 1));
      const sun = new Date(start.setDate(start.getDate() + 1));
      array.push(...[mon, tue, wed, thu, fri, sat, sun]);

      const startMonth = mon.toLocaleString('default', { month: 'long' });
      const endMonth = sun.toLocaleString('default', { month: 'long' });
      const week = `${startMonth} ${mon.getDate()} - ${
        startMonth === endMonth ? sun.getDate() : `${endMonth} ${sun.getDate()}`
      }`;
      weeksArray.push(week);
    }
    setWeeks(weeksArray);
    setCalendarDays(array);
  }, []);

  useEffect(() => {
    if (selectedWorkout) {
      const datetime = DateTime.fromJSDate(selectedDate);
      const hour =
        ampm === 'pm' && time[0] < 12
          ? time[0] + 12
          : ampm === 'am' && time[0] === 12
          ? 0
          : time[0];
      const datetimeWithTime = datetime.set({
        hour,
        minute: time[1],
        second: 0,
      });
      setReleaseDate(datetimeWithTime.toJSDate());
    }
  }, [time, ampm, selectedDate, selectedWorkout]);

  const scheduleDrop = useMutation(
    ({ data, id }: { data: ScheduleWorkoutDropRequest; id: number }) =>
      scheduleWorkoutDrop(data, id),
    {
      onMutate: () => setIsSaving(true),
      onError: (err: any) => {
        setIsSaving(false);
        setError(err?.message || 'Unexpected error, please notify dev 🤯');
      },
      onSuccess: () => {
        setIsSaving(false);
        setIsScheduling(false);
        setIsRemovingDrop(false);
        resetScheduleTimes();
        queryClient.invalidateQueries('workouts');
        queryClient.invalidateQueries('calendar');
      },
    },
  );

  const handleSaveWorkoutDrop = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (!selectedWorkout) {
      throw Error('Workout is not selected for drop');
    }

    setIsSaving(true);
    const payload: ScheduleWorkoutDropRequest = !isRemovingDrop
      ? {
          isDrop: true,
          releaseDate: releaseDate?.toISOString(),
        }
      : {
          isDrop: false,
        };

    await scheduleDrop.mutateAsync({
      id: selectedWorkout,
      data: payload,
    });
  };

  const resetScheduleTimes = () => {
    setTime([currHour, 0]);
    setAmPm(TimeOfDay.PM);
    setReleaseDate(undefined);
    setSelectedWorkout(undefined);
    setSelectedDate(new Date());
  };

  const value = {
    isScheduling,
    isSaving,
    error,
    time,
    ampm,
    calendarDays,
    weeks,
    calendarIndex,
    workoutPageIndex,
    isRemovingDrop,
    handleScheduling,
    handleTime,
    handleAmPm,
    handleWorkoutSelected,
    handleSaveWorkoutDrop,
    resetScheduleTimes,
    handleSelectedDate,
    handleCalendarIndex,
    handleWorkoutPageIndex,
    handleRemovingDrop,
    handleCalendarNext,
    handleCalendarPrev,
    handleCalendarSkip,
  };

  return (
    <WorkoutDropContext.Provider value={value}>
      {children}
    </WorkoutDropContext.Provider>
  );
};

export { WorkoutDropProvider, WorkoutDropContext };
